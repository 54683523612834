import React, { Component } from 'react';

import moment from 'moment-business-time'

class Delai extends Component {

    constructor(props) {
      super(props);

      moment.locale('fr', {
        workinghours: {
            0: null,
            1: ['09:00:00', '19:00:00'],
            2: ['09:00:00', '19:00:00'],
            3: ['09:00:00', '19:00:00'],
            4: ['09:00:00', '19:00:00'],
            5: ['09:00:00', '19:00:00'],
            6: null
        },
        holidays: [
          '*-01-01',
          '*-03-28',
          '*-05-01',
          '*-05-05',
          '*-05-08',
          '*-05-16',
          '*-07-14',
          '*-08-15',
          '*-11-01',
          '*-11-11',
          '*-12-25',
        ]
      });
    }


  // https://coderwall.com/p/wkdefg/converting-milliseconds-to-hh-mm-ss-mmm
  msToTime(duration) {
    var minutes = parseInt((duration/(1000*60))%60, 10)
        , hours = parseInt((duration/(1000*60*60))%24, 10);

    minutes = (minutes < 10) ? ("0" + minutes) : minutes;

    return hours + " h " + minutes;
  }

  render() {

    var date = this.props.date;

    var diffHours = moment().workingDiff(moment(date), 'hours', true);

    if (parseFloat(diffHours) > parseFloat(8)) { // display day + hours
      return (
        <span>{Math.round(diffHours/8)} j</span>
      );
    }

    return (
      <span>
        {this.msToTime(moment().workingDiff(moment(date), 'milliseconds', true))}
      </span>
    );
  }
}

export default Delai;
