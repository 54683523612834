import React, {Component} from 'react';
import { browserHistory } from 'react-router'
import Tooltip from "rc-tooltip";

import Globals from "../Globals";
import moment from 'moment';
import FilterDate from '../Components/FilterDate';
import Loader from '../Components/Loader';


let lodash = require('lodash');


class Hway extends Component {
    constructor(state) {
        super(state);

        this.state = {
            fetchInProgress : false,
            leads: [],
            dateStart: moment().startOf('days'),
            dateEnd: moment().endOf('days'),      
            datePeriode: 'days'
        };
    }

    /***
     * Au chargement du composant
     */
    componentWillMount() {
        this.fetchAffaires();
    }

    /**
     * Affaires gagnées
     */
     fetchAffaires(){
        this.setState({
          fetchInProgress:true
        })
    console.log(this.state.dateEnd)

        fetch(localStorage.ClientApiURL + '/Affaires?statut=5&date_debut='+moment(this.state.dateStart).format('Y-MM-DD 00:00:00')+'&date_fin='+moment(this.state.dateEnd).format('Y-MM-DD 23:59:59'), {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((json) => {
            var leadsHway = lodash.filter(json, (lead) => {
              return lead.CodeAffaireSource !== null && lead.CodeAffaireSource.startsWith("HMF");
            });
            console.log(leadsHway)
            this.setState({
              leads: leadsHway,
              fetchInProgress:false
            })
        })
        .catch((error) => {
          console.log(error)
        });
    }

    handleclickAffaire(IDProspect) {
      browserHistory.push('/prospect/' + IDProspect);
    }

    downloadHway(lead){
      window.open('https://'+localStorage.WebGp+'/ImagesMaterial/'+localStorage.NomGp+'/ContractHway/contract_'+lead.CodeAffaireSource+'.pdf')
    }

    onChangeDate(start, end, datePeriode) {
      if(!datePeriode)
        datePeriode = this.state.datePeriode

    
      this.setState({
        dateStart: start,
        dateEnd: end,
        datePeriode: datePeriode,
      }, () => {
        this.fetchAffaires();
      })
    }

    render(){
        return(
            
            <div className="container">
                <h2>
                    <i className="icon-trophy icons"></i> Hway affaires gagnées
                </h2>
                <hr/>
                <FilterDate onChangeDate={this.onChangeDate.bind(this)} className='pull-left' datePeriode={this.state.datePeriode} dateStart={this.state.dateStart} dateEnd={this.state.dateEnd}/>
                {
                    this.state.fetchInProgress === true ?
                    <Loader style={{ marginTop: 150 }} />
                :
                <div className="row">
                    
                    <table className="table table-eseller-lead table-striped table-hover table-condensed">
                      <thead>
                        <tr>
                          <th>Client</th>
                          <th>LeadNo</th>
                          <th>Date de la commande</th>
                          <th className='text-center'>PDF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.leads.map((lead, i) =>
                          <tr key={i} className="tr-link">
                            <td>
                              <a onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)}>{lead.Prospect.Nom} {lead.Prospect.Prenom}</a>
                            </td>
                            <td>{lead.CodeAffaireSource}</td>
                            <td>{moment(lead.DateHFin).format('L')}</td>
                            <td className='text-center'>
                                <Tooltip trigger={['hover']} overlay={<div>Télécharger le PDF</div>}>
                                    <i className="fa fa-download" aria-hidden="true" onClick={() => { this.downloadHway(lead) }}></i>
                                </Tooltip>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                </div>
                }
                
            </div>
        )
    }
}
export default Hway;
