import React, { Component } from 'react';

import SkyLight from 'react-skylight';

class ModalAdherent extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount(){

    }

    show() {
        this.refs.modalAdherent.show();
    }

    hide() {
        this.refs.modalAdherent.hide();
    }

    render() {
        return (
            <SkyLight hideOnOverlayClicked ref="modalAdherent"
                dialogStyles={{ padding: 0, width: '50%', maxWidth: '800px', minWidth: '300px', height: 'auto', minHeight:'500px', left: '0', right: '0', top: '10%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                title="Liste des groupements"
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}
                >
                <div className="inner-large-content" style={{ height: '100%', padding: '20px' }}>
                    <b>Adhérent : {this.props.prospect.Civilite ? this.props.prospect.Civilite+' ' : ''}{this.props.prospect.Nom}</b><br/><br/>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table-striped light-table">
                                <thead>
                                    <tr>
                                        <th>Ref groupement</th>
                                        <th>Groupement</th>
                                        <th className='text-right'>Nb parts</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.prospect.Groupements.map((g) => {
                                        return (<tr key={g.IDClient}>
                                            <td>{g.IDClient}</td>
                                            <td>
                                                <a href={"/prospect/"+g.IDClient}>{g.Nom}</a>
                                            </td>
                                            <td className='text-right'>{g.NbParts} %</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </SkyLight>
        );
    }
}

export default ModalAdherent;
