import React, {Component} from 'react';
import {browserHistory} from 'react-router'

import Globals from '../Globals';
import {Link} from 'react-router';

import moment from 'moment'

import Loader from '../Components/Loader';
import BadgeDiffDay from '../Components/BadgeDiffDay';
import PastilleInteret from '../Components/PastilleInteret';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowComment from '../Components/TableRowComment';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import WelcomeProMail from '../Components/WelcomeProMail';

import FormToDoRapport from '../LeadCreate/FormToDoRapport.js'

import FormRelance from '../LeadCreate/FormRelance.js'
import FormFinancement from '../LeadCreate/FormFinancement';

import SweetAlert from 'react-bootstrap-sweetalert';

import SkyLight from 'react-skylight';
import Scheduler from '../Pages/Scheduler.js'
import Dictionary from '../Dictionnary';
import LibAffaire from '../Lib/Affaire';
import { WorkflowBDC } from '../Enums/WorkflowBDC';

var lodash = require('lodash')

class Rapport extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
            rapport: null,
            rapports: null,
            prospect: null,
            affaire: null,
            affaireChange: false,
            isHway: false,
            checked: [],

            fetchCreateInProgress: false,
            successCreate: false,
            errorCreate: false,
            errorCreateMessage: '',

            action: 'continuer',
            todoEtape: 0,
            todoEtapeText: '',
            todoAction: '',
            todoDate: moment(),
            todoHeure: null,
            todoHeureFin: null,
            todoNiveauInteret: 4,
            todoTransfertToVendeur: false,
            todoVendeur: 0,
            todoComment: '',
            todoRaison: '',
            typeCloture: '',

            relance: 0,
            relanceCommentaire: '',
            relanceDate: moment(),
            relanceAction: '',
            todoDateHeureFin: null,
            relanceHeure: null,

            todoTransfertToChefVente: false,
            todoChefVente: 0,

            workflowBDC: null,
            utilisateursTransfert: [],
            fetchingUtilisateurs: false,
            profilSelectedUtilisateurTransfert: null,

            transfertToPotCommunVendeurs: false,

            relanceMonth: null,

            salesmanAssignmentHway: "",
            assignmentHwayLoading: false,
            errorHway: false,
            successHway: false,
            successDescriptionHway: "",
            errorDescriptionHway: "",
            tokenHway: ""
        }
    }

    componentDidMount() {
        this.fetchRapport();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params.IDLead !== this.props.params.IDLead) {
            this.fetchRapport();
        }
    }

    fetchRapport() {

        fetch(localStorage.ClientApiURL + '/Leads/' + this.props.params.IDLead, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                console.log(json)
                this.setState({
                    rapport: json,
                }, () => {
                    this.fetchProspect();
                    if (localStorage.user_profil === 'BDC') {
                        this.fetchWorkflow();
                    } else if (localStorage.user_profil === "ChefDesVentes") {
                        this.fetchManagedVendeurs();
                    }
                    this.fetchAffaire();
                    this.fetchRapports();
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    //Récupère le workflow BDC du couple origine/site, ou du groupe par défaut
    fetchWorkflow() {
        fetch(localStorage.ClientApiURL + '/workflowsLeads/workflowBDC?IDSite=' + this.state.rapport.Site.IDSite + '&IDOrigine=' + this.state.rapport.Affaire.OrigineAction.IDOrigineAction, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                if (json.WorkflowBDC) {
                    this.setState({
                        workflowBDC: json.WorkflowBDC
                    }, () => {
                        if (this.state.workflowBDC != WorkflowBDC.BdcToPotCommunVendeurs) {
                            this.fetchUtilisateursWorkflowBDC()
                        }
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    //Récupère les utilisateurs à qui le bdc peut redistribuer, en fonction workflow configuré pour le couple origine/site et du type d'affaire (filtrage)
    fetchUtilisateursWorkflowBDC() {
        this.setState({
            fetchingUtilisateurs: true
        })
        fetch(localStorage.ClientApiURL + '/workflowsLeads/workflowBDC/utilisateurs?IDSite=' + this.state.rapport.Site.IDSite + '&IDOrigine=' + this.state.rapport.Affaire.OrigineAction.IDOrigineAction + '&affaireType=' + this.state.rapport.Affaire.Type, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    utilisateursTransfert: json,
                    fetchingUtilisateurs: false,
                })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchProspect() {
        fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.rapport.Prospect.IDProspect, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    prospect: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchRapports() {
        if (!this.state.rapport.Affaire.IDAffaire) {
            this.setState({
                rapports: [this.state.rapport]
            });
            return;
        }

        fetch(localStorage.ClientApiURL + '/affaires/' + this.state.rapport.Affaire.IDAffaire + '/rapports', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    rapports: json,
                });

            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchOpenAffaire(IDAffaire) {
        fetch(localStorage.ClientApiURL + '/affaires/' + IDAffaire + '/open', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchAffaire() {
        if (this.state.rapport.Affaire.IDAffaire) {
            fetch(localStorage.ClientApiURL + '/affaires/' + this.state.rapport.Affaire.IDAffaire, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKey': Globals.API_KEY,
                    'IDClient': localStorage.user_IDClient,
                    'Login': localStorage.user_login,
                    'Password': localStorage.user_password
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(json => {

                    if (!json.DateHOuvertureLead) {
                        this.fetchOpenAffaire(this.state.rapport.Affaire.IDAffaire);
                    }

                    this.setState({
                        affaire: json,
                    }, () => {
                        this.determineIsHway()
                    });

                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }

    determineIsHway() {
        if (this.state.affaire.CodeAffaireSource !== null && this.state.affaire.CodeAffaireSource.startsWith("HMF")) {
            this.setState({
                isHway: true,
            })
        }
    }

    async updateAffaire() {
        console.log(this.state.affaire)
        var updated = await LibAffaire.update(this.state.affaire);

        if (!updated) {
            this.setState({
                fetchCreateInProgress: false,
                errorCreate: true,
                errorCreateMessage: "Erreur lors de la mise à jour de l'affaire",
            });
        }
    }

    reportAffaire() {

        // TODO : obtain durée report
        var dateReport = moment().add(this.state.relanceMonth, 'month');

        let method = 'traiter_lead';

        let body = {
            IDLead: this.state.rapport.IDLead,
            "Affaire": {
                "IDAffaire": this.state.rapport.Affaire.IDAffaire,
            },
            "ARedistribuer": this.state.todoTransfertToChefVente ? true : false,
            "Campagne": (this.state.rapport.Campagne ? {"IDCampagne": this.state.rapport.Campagne.IDCampagne,} : null),
            "ReportAffaire": 1,
            Site: {
                IDSite: this.state.rapport.Site.IDSite,
            },
            Prospect: {
                IDProspect: this.state.rapport.Prospect.IDProspect,
            },
            "SuiviPar": {
                "IDUtilisateur": localStorage.user_IDUtilisateur,
            },
            ActionFaite_DO: {
                "DateHLead": moment().format(),
                "TypeContact": {
                    "Description": "eSeller Lead",
                },
                Commentaire: this.state.todoComment,
                "OrigineAction": {
                    "IDOrigineAction": this.state.rapport.Affaire.OrigineAction ? this.state.rapport.Affaire.OrigineAction.IDOrigineAction : null,
                },
            },
            "ActionAFaire_TODO": {
                "ActionAFaire": {
                    "Description": "Téléphoner",
                },
                "Etape": {
                    "IDEtape": 1,
                },
                "NatureActionAFaire": "Téléphone",
                "NiveauInteret": {
                    "IDNiveauInteret": 1,
                },
                "DateHRelance": dateReport.format(),
                "DateHProchaineEtape": dateReport.format(),
                "Etat": "EnCours",
            },
        };

        body = JSON.stringify(body);

        console.log(body)

        fetch(localStorage.ClientApiURL + '/Leads/' + method, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: body,
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        this.setState({
                            fetchCreateInProgress: false,
                            errorCreate: true,
                            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                        });
                    });

                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    fetchCreateInProgress: false,
                    successCreate: true,
                    errorCreate: false,
                    errorCreateMessage: '',
                });
            })
            .catch((error) => {
                console.warn(error);
            });

    }

    getHwayToken() {
        if(this.state.salesmanAssignmentHway === ""){
            alert("Veuillez sélectionner le vendeur à assigner")
            return;
        }

        this.setState({
            assignmentHwayLoading: true,
            errorHway: false,
            errorDescriptionHway: "",
            successHway: false,
        });

        fetch(
            localStorage.urlWally +"/users/login",
            {
                method: "POST",
                headers:{
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: localStorage.usernameWally,
                    password: localStorage.passwordWally
                })
            }
        )
            .then((response) => {
                response.json().then((value) => {
                    if(value.status !== "failed"){
                        this.setState({
                            tokenHway: value.token
                        }, () => {
                            this.affecteVendeurHway(this.state.salesmanAssignmentHway)
                        });
                    }else{
                        this.setState({
                            errorHway: true,
                            errorDescriptionHway: value.error,
                        });
                    }
                });
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    affecteVendeurHway(salesmanId) {
        let utilisateur = lodash.find(this.state.utilisateursTransfert, function (utilisateur) {
             return utilisateur.IDUtilisateur == salesmanId;
         });
         let nom = utilisateur.Nom.split(" ")
         let salesmanFirstName = nom[1]
         let salesmanLastName = nom[0]

        fetch(
            localStorage.urlWally +"/hway/leads/assign",
            {
                method: "POST",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + this.state.tokenHway
                },
                body: JSON.stringify({
                    "leadNumber": this.state.affaire.CodeAffaireSource,
                    "showroom": this.state.rapport.Site.CodeConstructeur,
                    "salesman": salesmanFirstName + " " + salesmanLastName
                })
            }
        )
            .then((response) => {
                response.json().then((value) => {
                    if(value.status !== "failed"){
                        this.setState({
                            successHway: true,
                            successDescriptionHway: value.payload.details.digitalCode,
                        }, () => {
                            this.setState({
                                assignmentHwayLoading: false,
                            });
                        });
                    }else{
                        this.setState({
                            errorHway: true,
                            errorDescriptionHway: value.payload,
                        }, () => {
                            this.setState({
                                assignmentHwayLoading: false,
                            });
                        });
                    }
                });
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({
            fetchCreateInProgress: true,
        });

        if (this.state.affaireChange === true) {
            await this.updateAffaire();
        }

        if (this.state.action === 'aucune') {
            this.fermerRapport();
            return;
        }

        if (this.state.action === 'reporter') {
            this.reportAffaire();
            return;
        }

        if (this.state.action === 'cloturer' && this.state.typeCloture === 'AffaireGagnee') {
            this.submitGagnee();
            return;
        }

        let method = 'traiter_lead';

        let date_relance = this.state.todoDate;
        if (this.state.todoHeure) {
            date_relance.set('hour', this.state.todoHeure.get('hour')).set('minute', this.state.todoHeure.get('minute'));
        }

        let date_relance_fin = null;
        if (this.state.todoDateHeureFin) {
            date_relance_fin = this.state.todoDateHeureFin;
        } else if (this.state.todoHeureFin) {
            date_relance_fin = moment(date_relance);
            date_relance_fin.set('hour', this.state.todoHeureFin.get('hour')).set('minute', this.state.todoHeureFin.get('minute'));
        }

        let IDUtilisateur = localStorage.user_IDUtilisateur;

        if (this.state.todoTransfertToVendeur) {
            IDUtilisateur = this.state.todoVendeur;
        } else if (this.state.todoTransfertToChefVente) {
            IDUtilisateur = this.state.todoChefVente;
        } else if (this.state.transfertToPotCommunVendeurs) {
            IDUtilisateur = 0;
        }

        let body = {
            IDLead: this.state.rapport.IDLead,
            "Affaire": {
                "IDAffaire": this.state.rapport.Affaire.IDAffaire,
            },
            "ARedistribuer": this.state.todoTransfertToChefVente ? true : false,
            "Campagne": (this.state.rapport.Campagne ? {"IDCampagne": this.state.rapport.Campagne.IDCampagne,} : null),
            Site: {
                IDSite: this.state.rapport.Site.IDSite,
            },
            Prospect: {
                IDProspect: this.state.rapport.Prospect.IDProspect,
            },
            "SuiviPar": {
                "IDUtilisateur": IDUtilisateur,
            },
            ActionFaite_DO: {
                "DateHLead": moment().format(),
                "TypeContact": {
                    "Description": "eSeller Lead",
                },
                CanalOrigine: this.state.rapport.ActionFaite_DO.CanalOrigine,
                Commentaire: this.state.todoComment,
                "OrigineAction": {
                    "IDOrigineAction": this.state.rapport.Affaire.OrigineAction ? this.state.rapport.Affaire.OrigineAction.IDOrigineAction : null,
                },
            },
            "ActionAFaire_TODO": {
                "Etape": null,
                "ActionAFaire": {
                    "Description": this.state.todoAction ? this.state.todoAction : this.state.todoEtapeText,
                },
                "NatureActionAFaire": "Téléphone",
                "NiveauInteret": {
                    "IDNiveauInteret": this.state.todoNiveauInteret,
                },
                "EstDateLimite": true,
                "DateHRelance": (this.state.action === 'cloturer') ? null : date_relance.format(),
                "DateHFinRelance": date_relance_fin ? date_relance_fin.format() : null,
                "DateHProchaineEtape": (this.state.action === 'cloturer') ? null : date_relance.format(),
                "Etat": (this.state.action === 'continuer') ? "EnCours" : "Termine",
                "Cloturer": null
            },
            "IDLeadsATerminer": this.state.checked.length ? this.state.checked : null,
        };

        if (this.state.action === 'cloturer') {
            body.ActionAFaire_TODO.Cloturer = {
                "TypeCloture": 'AffairePerdue',
                "AffaireRaisonStop": this.state.todoRaison,
            }
        }

        if (!this.state.todoEtape && (this.state.rapport.ActionAFaire_TODO.Etape && this.state.rapport.ActionAFaire_TODO.Etape.IDEtape)) {
            body.ActionAFaire_TODO.Etape = {
                "IDEtape": this.state.rapport.ActionAFaire_TODO.Etape.IDEtape
            };
        } else if (this.state.todoEtape) {
            body.ActionAFaire_TODO.Etape = {
                "IDEtape": this.state.todoEtape
            };
        }

        if (this.state.transfertToPotCommunVendeurs) {
            body.PotCommunVendeurs = true;
        }

        body = JSON.stringify(body);

        console.log(body)

        fetch(localStorage.ClientApiURL + '/Leads/' + method, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: body,
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        this.setState({
                            fetchCreateInProgress: false,
                            errorCreate: true,
                            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                        });
                    });

                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                localStorage.refreshHeaderCounters = "1";
                if (!this.state.relance) {
                    this.setState({
                        fetchCreateInProgress: false,
                        successCreate: true,
                        errorCreate: false,
                        errorCreateMessage: '',
                    });
                    return;
                }

                /****** S'il y a une relance à créer (case "Me créer une alerte" cochée) *******/
                let date_relance = this.state.relanceDate;
                if (this.state.relanceHeure) {
                    date_relance.set('hour', this.state.relanceHeure.get('hour')).set('minute', this.state.relanceHeure.get('minute'));
                }

                let body = {
                    IDLead: this.state.rapport.IDLead,
                    IDAffaire: this.state.rapport.Affaire.IDAffaire,
                    Site: {
                        IDSite: this.state.rapport.Site.IDSite,
                    },
                    Prospect: {
                        IDProspect: this.state.rapport.Prospect.IDProspect,
                    },
                    "OrigineAction": {
                        "IDOrigineAction": this.state.rapport.OrigineAction ? this.state.rapport.OrigineAction.IDOrigineAction : null,
                    },
                    "SuiviPar": {
                        "IDUtilisateur": localStorage.user_IDUtilisateur,
                    },
                    ActionFaite_DO: {
                        "DateHLead": moment().format(),
                        "TypeContact": {
                            "Description": "eSeller Lead",
                        },
                        CanalOrigine: this.state.rapport.ActionFaite_DO.CanalOrigine,
                        Commentaire: this.state.relanceCommentaire,
                    },
                    "ActionAFaire_TODO": {
                        "Etape": null,
                        "ActionAFaire": {
                            "Description": this.state.relanceAction ? this.state.relanceAction : this.state.todoEtapeText,
                        },
                        "NatureActionAFaire": "Téléphone",
                        "NiveauInteret": {
                            "IDNiveauInteret": this.state.todoNiveauInteret,
                        },
                        "EstDateLimite": true,
                        "DateHRelance": date_relance.format(),
                        "DateHProchaineEtape": date_relance.format(),
                        "Etat": "EnCours",
                        "Cloturer": null
                    }
                };

                if (!this.state.todoEtape && (this.state.rapport.ActionAFaire_TODO.Etape && this.state.rapport.ActionAFaire_TODO.Etape.IDEtape)) {
                    body.ActionAFaire_TODO.Etape = {
                        "IDEtape": this.state.rapport.ActionAFaire_TODO.Etape.IDEtape
                    };
                } else if (this.state.todoEtape) {
                    body.ActionAFaire_TODO.Etape = {
                        "IDEtape": this.state.todoEtape
                    };
                }

                body = JSON.stringify(body);

                fetch(localStorage.ClientApiURL + '/Leads/' + method, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'APIKey': Globals.API_KEY,
                        'IDClient': localStorage.user_IDClient,
                        'Login': localStorage.user_login,
                        'Password': localStorage.user_password
                    },
                    body: body,
                })
                    .then((response) => {
                        if (!response.ok) {
                            response.json().then((value) => {
                                this.setState({
                                    fetchCreateInProgress: false,
                                    errorCreate: true,
                                    errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                                });
                            });

                            throw Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(json => {
                        this.setState({
                            fetchCreateInProgress: false,
                            successCreate: true,
                            errorCreate: false,
                            errorCreateMessage: '',
                        });
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
                /************ Fin création relance **********/
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    fermerRapport() {

        var body = this.state.rapport;
        body.ActionAFaire_TODO.Etat = 'Termine';
        body.ActionFaite_DO.Commentaire = this.state.todoComment;
        body.Historiques = null;

        body = JSON.stringify(body);

        fetch(localStorage.ClientApiURL + '/Leads/' + this.state.rapport.IDLead, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: body,
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        this.setState({
                            fetchCreateInProgress: false,
                            errorCreate: true,
                            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                        });
                    });

                    throw Error(response.statusText);
                }

                this.setState({
                    fetchCreateInProgress: false,
                    successCreate: true,
                    errorCreate: false,
                    errorCreateMessage: '',
                });

                return response;
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    async submitGagnee() {
        this.setState({
            fetchCreateInProgress: true
        });

        var affaire = this.state.rapport.Affaire;
        affaire.Statut = 'Gagnee';

        console.log(affaire)

        var affaireCloturee = await fetch(localStorage.ClientApiURL + '/Affaires/' + this.state.affaire.IDAffaire, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: JSON.stringify(affaire),
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        this.setState({
                            fetchCreateInProgress: false,
                            errorCreate: true,
                            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                        });
                    });

                    throw Error(response.statusText);
                }

                return response;
            })
            .catch((error) => {
                console.warn(error);
            });

        if (affaireCloturee) {

            var lead = this.state.rapport;

            lead.ActionAFaire_TODO.Etat = 'Termine';
            lead.ActionAFaire_TODO.ActionAFaire = null;
            lead.ActionAFaire_TODO.Etape = null;
            lead.ActionFaite_DO.Commentaire = "Affaire gagnée";

            lead.ActionAFaire_TODO.Cloturer = {
                "TypeCloture": null,
                "AffaireRaisonStop": null,
                "AutreRaisonStop": "Affaire gagnée"
            }

            console.log(lead)

            await fetch(localStorage.ClientApiURL + '/Leads/' + lead.IDLead, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKey': Globals.API_KEY,
                    'IDClient': localStorage.user_IDClient,
                    'Login': localStorage.user_login,
                    'Password': localStorage.user_password
                },
                body: JSON.stringify(lead),
            })
                .then((response) => {
                    if (!response.ok) {
                        response.json().then((value) => {
                            this.setState({
                                fetchCreateInProgress: false,
                                errorCreate: true,
                                errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                            });
                        })
                        throw Error(response.statusText);
                    }

                    this.setState({
                        fetchCreateInProgress: false,
                        successCreate: true,
                        errorCreate: false,
                        errorCreateMessage: '',
                    });
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    }

    fetchManagedVendeurs() {
        fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + this.state.rapport.Site.IDSite, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                var utilisateurs = lodash.filter(json, (utilisateur) => {
                    if (!this.state.rapport.Affaire || !this.state.rapport.Affaire.OrigineAction || !this.state.rapport.Affaire.Type) {
                        return true;
                    }

                    if (localStorage.IsVDL === 'true') {
                        return true;
                    }

                    if (this.state.rapport.Affaire.Type === "VN") {
                        return utilisateur.EstTypeVN;
                    } else if (this.state.rapport.Affaire.Type === "VO") {
                        return utilisateur.EstTypeVO;
                    }
                });

                console.log("utilisateurs:", utilisateurs)

                utilisateurs = lodash.orderBy(utilisateurs, 'Nom');

                this.setState({
                    utilisateursTransfert: utilisateurs,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    // Est appelé lors du changement de valeur dans les composants enfants
    changeValue(attr, value) {
        this.setState({
            [attr]: value
        }, () => {
            if (attr === 'action' && value === 'cloturer') {
                let items = [];

                this.state.rapports.map((rapport, i) => {
                    items.push(rapport.IDLead);
                    return rapport;
                });

                this.setState({
                    checked: items
                });
            }
        });
    }

    hideAlert() {
        this.setState({
            errorCreate: false,
            errorCreateMessage: '',
        });
    }

    propageSuccess() {
        browserHistory.push('/prospect/' + this.state.prospect.IDProspect);
    }

    isChecked(IDLead) {

        if (IDLead === this.props.params.IDLead)
            return true;

        if (this.state.checked.indexOf(IDLead) >= 0) {
            return true;
        }

        return false;
    }

    itemToggle(IDLead, checked) {
        var items = this.state.checked;

        if (checked) {
            items.push(IDLead)
        } else {
            items = lodash.without(this.state.checked, IDLead)
        }

        this.setState({
            checked: items
        });
    }

    changeToVendeur(event) {
        this.setState({
            todoTransfertToVendeur: event.target.checked,
            todoTransfertToChefVente: false,
            transfertToPotCommunVendeurs: false,
        })
    }

    changeToChefVente(event) {
        this.setState({
            todoTransfertToVendeur: false,
            todoTransfertToChefVente: event.target.checked,
            transfertToPotCommunVendeurs: false,
        })
    }

    changeToPotCommunVendeurs(event) {
        this.setState({
            todoTransfertToVendeur: false,
            todoTransfertToChefVente: false,
            transfertToPotCommunVendeurs: event.target.checked,
        })
    }

    onChangeTransfertTo(event) {
        var utilisateur = lodash.find(this.state.utilisateursTransfert, (u) => u.IDUtilisateur == event.target.value);
        var profil = utilisateur ? utilisateur.TypeProfilLead : null;
        this.setState({
            todoVendeur: profil == "Vendeur" ? event.target.value : 0,
            todoChefVente: profil == "ChefDesVentes" ? event.target.value : 0,
            todoTransfertToVendeur: profil == "Vendeur",
            todoTransfertToChefVente: profil == "ChefDesVentes",
            profilSelectedUtilisateurTransfert: profil,
            transfertToPotCommunVendeurs: false,
        })
    }

    onChangeSalesmanAssignmentHway(event) {
        this.setState({
            salesmanAssignmentHway: event.target.value,
            todoVendeur: event.target.value,
        })
    }

    openCalendar() {
        if (this.refs.modalCalendar) {
            this.refs.modalCalendar.show();
        }
    }

    onSelectCalendar(start, end, resource) {
        this.refs.modalCalendar.hide();
        this.setState({
            todoDate: moment(start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
            todoHeure: start,
            todoDateHeureFin: end,
            todoVendeur: resource ? resource : 0,
        })
    }

    onChangeDescriptionAffaire(event) {
        var affaire = this.state.affaire;
        affaire.Description = event.target.value;

        this.setState({
            affaireChange: true,
            affaire: affaire,
        })
    }

    onChangeFinancement(affaire) {
        //console.log(affaire)
        this.modalFinancement.hide();
        this.setState({
            affaireChange: true,
            affaire: affaire
        })
    }

    canSubmit() {
        return !this.state.fetchCreateInProgress && !(this.state.action === "cloturer" && this.state.typeCloture === "");
    }

    renderTransfertToVendeur() {
        return (
            <>
                <div>
                    {parseInt(this.state.rapport.SuiviPar.IDUtilisateur, 10) !== parseInt(localStorage.user_IDUtilisateur, 10) ?
                        <div className="form-group" style={{marginBottom: '0px'}}>
                            <label className="col-md-4">Vendeur</label>
                            <div className="col-md-6">
                                <p className="form-control-static">{this.state.rapport.SuiviPar.Nom}</p>
                            </div>
                        </div>
                        : null}

                    <div className="form-group" style={{marginBottom: '0px'}}>
                        <div className="col-md-10 col-md-offset-1">
                            <input type='checkbox' id='toVendeur' onChange={this.changeToVendeur.bind(this)}
                                   checked={this.state.todoTransfertToVendeur}/>
                            <label className="label-box" htmlFor="toVendeur">Transférer à un vendeur</label>
                        </div>
                    </div>

                    {this.state.todoTransfertToVendeur ?
                        this.state.utilisateursTransfert.length ?
                            <div>
                                <div className="form-group" style={{marginBottom: '0px'}}>
                                    <div className="col-md-10 col-md-offset-1">
                                        <select className="form-control" id="vendeur" name="vendeur"
                                                onChange={this.onChangeTransfertTo.bind(this)}
                                                value={this.state.todoVendeur || this.state.todoChefVente}
                                                required={this.state.todoTransfertToVendeur}>
                                            <option></option>
                                            {this.state.utilisateursTransfert.map((utilisateur, i) => {
                                                return <option key={i}
                                                               value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group" style={{marginBottom: '25px'}}>
                                    <div className='col-md-10 col-md-offset-1'>
                                        <a className='btn btn-default' onClick={this.openCalendar.bind(this)}
                                           style={{width: '100%', fontSize: '12px', padding: '6px 0px 4px 0px'}}>Calendrier
                                            vendeurs</a>
                                    </div>
                                </div>
                            </div>
                            : !this.state.utilisateursTransfert.length ?
                                <div>
                                    <div className="form-group" style={{marginBottom: '0px'}}>
                                        <div className="col-md-10 col-md-offset-1" style={{textAlign: 'center'}}>
                                            {this.state.fetchingUtilisateurs ?
                                                <label>Récupération des utilisateurs...</label>
                                                :
                                                <label className="warning">[Aucun vendeur paramétré]</label>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                        : null}
                </div>
            </>
        );
    }

    renderTransfertToCDV() {
        return (
            <>
                <div className="form-group" style={{marginBottom: '0px'}}>
                    <div className="col-md-10 col-md-offset-1">
                        <input type='checkbox' id='toChefVente' onChange={this.changeToChefVente.bind(this)}
                               checked={this.state.todoTransfertToChefVente}/>
                        <label className="label-box" htmlFor="toChefVente">Transférer à un chef des ventes</label>
                    </div>
                </div>

                {this.state.todoTransfertToChefVente ?
                    this.state.utilisateursTransfert.length ?
                        <div>
                            <div className="form-group" style={{marginBottom: '0px'}}>
                                <div className="col-md-10 col-md-offset-1">
                                    <select className="form-control" id="vendeur" name="vendeur"
                                            onChange={this.onChangeTransfertTo.bind(this)}
                                            value={this.state.todoChefVente}
                                            required={this.state.todoTransfertToChefVente}>
                                        <option></option>
                                        {this.state.utilisateursTransfert.map((utilisateur, i) => {
                                            return <option key={i}
                                                           value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        : !this.state.utilisateursTransfert.length ?
                            <div>
                                <div className="form-group" style={{marginBottom: '0px'}}>
                                    <div className="col-md-10 col-md-offset-1" style={{textAlign: 'center'}}>
                                        {this.state.fetchingUtilisateurs ?
                                            <label>Récupération des utilisateurs...</label>
                                            :
                                            <label className="warning">[Aucun chef des ventes paramétré]</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            : null
                    : null}
            </>
        )
    }

    renderTransfertToCDVOuVendeur() {
        const vendeurs = lodash.filter(this.state.utilisateursTransfert, (u) => u.TypeProfilLead === "Vendeur");
        const chefsDesVentes = lodash.filter(this.state.utilisateursTransfert, (u) => u.TypeProfilLead === "ChefDesVentes");
        return (
            <>
                <div>
                    {parseInt(this.state.rapport.SuiviPar.IDUtilisateur, 10) !== parseInt(localStorage.user_IDUtilisateur, 10) ?
                        <div className="form-group" style={{marginBottom: '0px'}}>
                            <label className="col-md-4">Vendeur</label>
                            <div className="col-md-6">
                                <p className="form-control-static">{this.state.rapport.SuiviPar.Nom}</p>
                            </div>
                        </div>
                        : null}

                    <div className="form-group" style={{marginBottom: '0px'}}>
                        <div className="col-md-10 col-md-offset-1">
                            <input type='checkbox' id='toVendeur' onChange={this.changeToVendeur.bind(this)}
                                   checked={this.state.todoTransfertToVendeur || this.state.todoTransfertToChefVente}/>
                            <label className="label-box" htmlFor="toVendeur">Transférer à un vendeur ou un chef des
                                ventes</label>
                        </div>
                    </div>

                    {this.state.todoTransfertToVendeur || this.state.todoTransfertToChefVente ?
                        this.state.utilisateursTransfert.length ?
                            <div>
                                <div className="form-group" style={{marginBottom: '0px'}}>
                                    <div className="col-md-10 col-md-offset-1">
                                        <select className="form-control" id="vendeur" name="vendeur"
                                                onChange={this.onChangeTransfertTo.bind(this)}
                                                value={this.state.todoVendeur || this.state.todoChefVente}
                                                required={this.state.todoTransfertToVendeur}>
                                            <option></option>
                                            <optgroup label="Vendeurs">
                                                {vendeurs.map((utilisateur, i) => {
                                                    return <option key={i}
                                                                   value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                                })}
                                            </optgroup>
                                            <optgroup label="Chef des ventes">
                                                {chefsDesVentes.map((utilisateur, i) => {
                                                    return <option key={i}
                                                                   value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                                })}
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>

                                {this.state.profilSelectedUtilisateurTransfert == "Vendeur" ?
                                    <div className="form-group" style={{marginBottom: '25px'}}>
                                        <div className='col-md-10 col-md-offset-1'>
                                            <a className='btn btn-default' onClick={this.openCalendar.bind(this)}
                                               style={{width: '100%', fontSize: '12px', padding: '6px 0px 4px 0px'}}>Calendrier
                                                vendeurs</a>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            : !this.state.utilisateursTransfert.length ?
                                <div>
                                    <div className="form-group" style={{marginBottom: '0px'}}>
                                        <div className="col-md-10 col-md-offset-1" style={{textAlign: 'center'}}>
                                            {this.state.fetchingUtilisateurs ?
                                                <label>Récupération des utilisateurs...</label>
                                                :
                                                <label className="warning">[Aucun vendeur ou chef des ventes
                                                    paramétré]</label>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                        : null}
                </div>
            </>
        );
    }

    renderTransfertToPotCommunVendeurs() {
        return (
            <div className="form-group" style={{marginBottom: '0px'}}>
                <div className="col-md-10 col-md-offset-1">
                    <input type='checkbox' id='toPotCommunVendeur' onChange={this.changeToPotCommunVendeurs.bind(this)}
                           checked={this.state.transfertToPotCommunVendeurs}/>
                    <label className="label-box" htmlFor="toPotCommunVendeur">Transférer au pot commun Vendeurs</label>
                </div>
            </div>
        )
    }

    renderTransfertForm() {
        if(localStorage.user_profil == "BDC") {
            switch(this.state.workflowBDC) {
                case WorkflowBDC.BdcToChefsVentes:
                    return this.renderTransfertToCDV()
                case WorkflowBDC.BdcToVendeurs:
                    return this.renderTransfertToVendeur()
                case WorkflowBDC.BdcToChefsVentesVendeurs:
                    return this.renderTransfertToCDVOuVendeur();
                case WorkflowBDC.BdcToPotCommunVendeurs:
                    return this.renderTransfertToPotCommunVendeurs()
                case WorkflowBDC.BdcToChefsVentesVendeursPotCommunVendeurs:
                    return [
                        this.renderTransfertToCDVOuVendeur(),
                        this.renderTransfertToPotCommunVendeurs()
                    ]
                default:
                    return <div>WorkflowBDC inexistant : {this.state.workflowBDC}</div>
            }
        }
        else if(localStorage.user_profil == "ChefDesVentes") {
            return this.renderTransfertToVendeur()
        }
        
        return null;
    }

    render() {

        //console.log(this.state)

        if (!this.state.rapport || !this.state.affaire || !this.state.prospect) {
            return <div className='container-fluid'><Loader style={{marginTop: 50}}/></div>
        }

        return (
            <div className='container-fluid'>

                <SkyLight hideOnOverlayClicked ref="modalCalendar" dialogStyles={{
                    width: '90%',
                    left: '5%',
                    marginLeft: '0%',
                    height: '90%',
                    top: '5%',
                    marginTop: '0%',
                    overflow: 'scroll',
                    zIndex: 9999
                }}>
                    <div className='inner-large-content'>
                        <Scheduler
                            IDPlaque={this.state.rapport.Site.Plaque.IDPlaque}
                            IDSite={this.state.rapport.Site.IDSite}
                            onSelectCalendar={this.onSelectCalendar.bind(this)}
                            typeLead={this.state.affaire.OrigineAction ? this.state.affaire.Type : null}
                            vendeurs={lodash.filter(this.state.utilisateursTransfert, u => u.TypeProfilLead === "Vendeur")}/>
                    </div>
                </SkyLight>

                {this.state.successCreate && !this.state.errorHway ?
                    <SweetAlert success title='' onConfirm={() => this.propageSuccess()}>
                        Traitement du lead avec succès !
                    </SweetAlert>
                    : null}

                {this.state.errorCreate ?
                    <SweetAlert error title="Une erreur s'est produite" onConfirm={() => this.hideAlert()}>
                        {this.state.errorCreateMessage}
                    </SweetAlert>
                    : null}

                <div className='row'>

                    <div className='col-md-12'>
                        <h2 className='title'><img src="/img/RAPPEL DU LEAD.svg" role='presentation'/> Rappel du lead
                        </h2>
                    </div>

                    <div className='col-md-12' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <div className="panel-group" id="accordeon">
                            <div className='panel panel-default'>
                                <div className="panel-heading" role="tab" id="rappelsHeading">
                                    <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse"
                                           data-parent="#accordeon" href="#collapseRappels" aria-expanded="true"
                                           aria-controls="collapseRappels">
                                            <div className="table-collapse">
                                                <div className='col col-sm-1 col-md-1 col-lg-1'>
                                                    <strong>{this.state.affaire ? ((this.state.affaire.OrigineAction) ? Dictionary.getUCFirst(this.state.affaire.Type) : null) : null}</strong>
                                                </div>
                                                <div className='col col-sm-2 col-md-2 col-lg-2'>
                                                    <strong>Date
                                                        :</strong> {moment(this.state.rapport.InfoCreation.DateH).format('DD/MM/Y')}
                                                </div>
                                                <div className='col col-sm-2 col-md-2 col-lg-2'>
                                                    <strong>Site :</strong> {this.state.rapport.Site.Code}
                                                </div>
                                                <div className='col col-sm-2 col-md-2 col-lg-2'>
                                                    <strong>Origine
                                                        :</strong> {this.state.rapport.ActionFaite_DO.OrigineAction ? this.state.rapport.ActionFaite_DO.OrigineAction.Famille + " / " + this.state.rapport.ActionFaite_DO.OrigineAction.SousFamille + " / " + this.state.rapport.ActionFaite_DO.OrigineAction.Origine : null}
                                                </div>

                                                <div className='col col-sm-1 col-md-1 col-lg-1'>
                                                    <strong>Int. :</strong> <PastilleInteret
                                                    niveau={this.state.rapport.ActionAFaire_TODO.NiveauInteret.Niveau}/>
                                                </div>
                                                <div className='col col-sm-2 col-md-2 col-lg-2'>
                                                    <strong>Etape
                                                        :</strong> {this.state.rapport.ActionAFaire_TODO.Etape ? this.state.rapport.ActionAFaire_TODO.Etape.Description : null}
                                                </div>
                                                <div className='col col-sm-2 col-md-2 col-lg-2'
                                                     style={{paddingLeft: 0}}>
                                                    <strong>Relance :</strong>&nbsp;

                                                    <TableRowDateRelance
                                                        date={this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape}/>

                                                    {this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape ?
                                                        <BadgeDiffDay
                                                            date={this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape}/>
                                                        : null}
                                                </div>
                                            </div>
                                        </a>
                                    </h4>
                                </div>

                                <div id="collapseRappels" className="panel-collapse collapse in" role="tabpanel"
                                     aria-labelledby="rappelsHeading" aria-expanded="true">
                                    {this.state.rapports && this.state.rapports.length ?
                                        <table
                                            className='table table-eseller-lead table-striped table-hover table-condensed'>
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Site</th>
                                                <th>Suivi</th>
                                                <th>Date</th>
                                                <th>Intérêt</th>
                                                <th>Origine</th>
                                                <th>Campagne</th>
                                                <th>Type contact</th>
                                                <th>Relance</th>
                                                <th>Action à faire</th>
                                                <th className='text-center'>Commentaire</th>
                                                <th className='text-center'>Etat</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.rapports.map((rapport, j) => {
                                                return <tr key={j}>
                                                    <td className='text-center'>
                                                        {(rapport.ActionAFaire_TODO.Etat !== "Termine") ?
                                                            <div className="checkbox-form active">
                                                                <input type="checkbox" name={"lead_" + rapport.IDLead}
                                                                       id={"lead_" + rapport.IDLead}
                                                                       checked={this.isChecked(rapport.IDLead)}
                                                                       disabled={(rapport.IDLead === this.state.rapport.IDLead) || this.state.action === 'cloturer'}
                                                                       onClick={this.itemToggle.bind(this, rapport.IDLead, !this.isChecked(rapport.IDLead))}/>
                                                                <label className="label-box"
                                                                       htmlFor={"lead_" + rapport.IDLead}></label>
                                                            </div>
                                                            : null}
                                                    </td>
                                                    <td>{rapport.Site.Code}</td>
                                                    <td>
                                                        {rapport.PremiereActionEntrante && !rapport.InfoCreation.Utilisateur.EstPasserelle ?
                                                            <PastilleVendeur
                                                                utilisateur={rapport.InfoCreation.Utilisateur}/>
                                                            :
                                                            <PastilleVendeur utilisateur={rapport.SuiviPar}/>
                                                        }
                                                    </td>
                                                    <td>{moment(rapport.InfoCreation.DateH).format('DD/MM/Y')}</td>
                                                    <td>
                                                        <PastilleInteret
                                                            niveau={rapport.ActionAFaire_TODO.NiveauInteret.Niveau}/>
                                                    </td>
                                                    <td>
                                                        {rapport.ActionFaite_DO.OrigineAction ? rapport.ActionFaite_DO.OrigineAction.Origine : null}
                                                    </td>
                                                    <td>{rapport.Campagne ? rapport.Campagne.Nom : null}</td>
                                                    <td>{rapport.ActionFaite_DO.TypeContact.Description}</td>
                                                    <td>
                                                        <TableRowDateRelance
                                                            date={rapport.ActionAFaire_TODO.DateHProchaineEtape}/>
                                                    </td>
                                                    <td>{rapport.ActionAFaire_TODO.ActionAFaire ? rapport.ActionAFaire_TODO.ActionAFaire.Description : null}</td>

                                                    <td className='text-center'  style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                        <TableRowComment comment={rapport.ActionFaite_DO.Commentaire}/>
                                                        <WelcomeProMail rapport={rapport}/>
                                                        {LibAffaire.showOngletConstructeur(rapport.Affaire.CodeAffaireSource, rapport.ActionFaite_DO.Commentaire) &&
                                                            <a href={LibAffaire.getUrlOngletConstructeur(rapport.Affaire.CodeAffaireSource, rapport.ActionFaite_DO.Commentaire)} target="_blank" style={{ marginLeft: "16px", marginRight: "16px" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>
                                                            </a>
                                                            }
                                                    </td>
                                                    <td className='text-center'>
                                                        {rapport.ActionAFaire_TODO.Etat === "Termine" ?
                                                            <img src="/img/TABLER ETAT.svg" role='presentation'/> :
                                                            <Link
                                                                to={'/rapport' + (!rapport.Affaire.OrigineAction || rapport.Affaire.Type === 'VN' || rapport.Affaire.Type === 'VO' || rapport.Affaire.Type === "VDL" ? '' : 'Atelier') + '/' + rapport.IDLead}>
                                                                A traiter
                                                            </Link>
                                                        }
                                                    </td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                        : null}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-md-12' style={{marginBottom: '20px'}}>
                        <h2><img src="/img/PROSPECT.svg" role='presentation'/> Prospect</h2>

                        <div className="rapport-details">
                            <Link to={'/prospect/' + this.state.prospect.IDProspect}>
                                {this.state.prospect.Nom}, {this.state.prospect.Prenom}, {this.state.prospect.Adresse}, {this.state.prospect.CodePostal}, {this.state.prospect.Ville}, {this.state.prospect.Telephones ? this.state.prospect.Telephones.map((phone, i) => {
                                return phone.Numero + ' '
                            }) : null}, {this.state.prospect.Mail}
                            </Link>
                        </div>
                    </div>
                    {
                        (this.state.isHway && this.state.rapport.PremiereActionEntrante ) &&
                        <div className='col-md-12'
                             style={{border: "solid", marginBottom: '20px', display: "flex", alignContent: "center"}}>
                            <div className={"col-md-1"}>
                                <img style={{width: "150px", height: "82px"}} src="/img/logo_hyundai.png"
                                     role="presentation"/>
                            </div>
                            <div className={"col-md-4"} style={{marginBottom: "auto", marginTop: "auto"}}>
                                <div className="form-group" style={{marginBottom: '0px'}}>
                                    <div className="col-md-10 col-md-offset-1">
                                        <select disabled={this.state.assignmentHwayLoading} className="form-control"
                                                id="vendeurHway" name="vendeurHway"
                                                onChange={this.onChangeSalesmanAssignmentHway.bind(this)}
                                                value={this.state.salesmanAssignmentHway}
                                                required={this.state.salesmanAssignmentHway}>
                                            <option
                                                value="">Choisir un vendeur pour affectation dans HWAY...
                                            </option>
                                            {this.state.utilisateursTransfert.map((utilisateur, i) => {
                                                return <option key={i}
                                                               value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-2"} style={{marginBottom: "auto", marginTop: "auto"}}>
                                <div className="form-group">
                                    <div className='col-md-10 col-md-offset-1'>
                                        <a className='btn btn-default' disabled={this.state.assignmentHwayLoading}
                                           onClick={this.getHwayToken.bind(this)}
                                           style={{width: '100%', fontSize: '12px', padding: '6px 0px 4px 0px'}}>
                                            {
                                                this.state.assignmentHwayLoading ?
                                                    <i className="fa fa-refresh fa-spin fa-3x fa-fw text-white"
                                                       style={{fontSize: 20}}></i>
                                                    :
                                                    <span>Affecter dans HWAY</span>
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                            { this.state.errorHway &&
                                <div className={"col-md-5"} style={{marginBottom: "auto", marginTop: "auto"}}>
                                    <div className="bg-danger row text-center" style={{borderRadius:"25px",paddingBottom: "5px", paddingTop: "5px"}}>
                                        <span className="col-md-2 glyphicon glyphicon-warning-sign"></span>
                                        <p className="col-md-10" style={{marginBottom:"0px",paddingLeft:"0px",textAlign:"start"}}>{this.state.errorDescriptionHway}</p>
                                    </div>
                                </div>
                            }
                            { this.state.successHway &&
                                <div className={"col-md-5"} style={{marginBottom: "auto", marginTop: "auto"}}>
                                    <div className="bg-success row text-center" style={{borderRadius:"25px",paddingBottom: "5px", paddingTop: "5px"}}>
                                        <span className="col-md-1 glyphicon glyphicon-ok"></span>
                                        <p className="col-md-11" style={{marginBottom:"0px",paddingLeft:"0px",textAlign:"start"}}>Lead assigné avec succès! Call tracking : {this.state.successDescriptionHway}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <form className="form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className='col-md-4 no-padd-left' style={{marginBottom: '55px'}}>
                                <FormToDoRapport
                                    displayNextActionType={true}
                                    nextActionType={this.state.action}
                                    onChangeValue={this.changeValue.bind(this)}
                                    todoNiveauInteret={this.state.todoNiveauInteret}
                                    todoDate={this.state.todoDate}
                                    todoHeure={this.state.todoHeure}
                                    todoHeureFin={this.state.todoHeureFin}
                                    todoTransfertToVendeur={this.state.todoTransfertToVendeur}
                                    todoVendeur={this.state.todoVendeur}
                                    site={this.state.rapport.Site.IDSite}
                                    todoEtape={this.state.todoEtape}
                                    todoEtapeText={this.state.todoEtapeText}
                                    todoAction={this.state.todoAction}
                                    todoRaison={this.state.todoRaison}
                                    todoComment={this.state.todoComment}
                                    typeActive={this.state.affaire.OrigineAction ? this.state.affaire.Type : null}
                                    displayImageTitle={true}
                                    transfertVendeurLabel={true}
                                    relanceMonth={this.state.relanceMonth}
                                    currentVendeur={(parseInt(this.state.rapport.SuiviPar.IDUtilisateur, 10) !== parseInt(localStorage.user_IDUtilisateur, 10)) ? this.state.rapport.SuiviPar.Nom : null}
                                    typeCloture={this.state.typeCloture}
                                    transfertToPotCommunVendeurs={this.state.transfertToPotCommunVendeurs}
                                />

                                {this.state.action === 'continuer' && (this.state.todoEtape == 4 || this.state.todoEtape == 5) ?  /* Financement */
                                    <div className='row'>
                                        <div className="col-md-10 col-md-offset-1">
                                            <button type="button" onClick={() => this.modalFinancement.show()}
                                                    className="text-center btn btn-default btn-xs" style={{
                                                lineHeight: 'inherit',
                                                padding: '5 10 5 10',
                                                height: 'auto'
                                            }}>
                                                Financement
                                            </button>
                                            <SkyLight hideOnOverlayClicked ref={ref => this.modalFinancement = ref}
                                                      dialogStyles={{
                                                          padding: 0,
                                                          width: '60%',
                                                          maxWidth: '800px',
                                                          minWidth: '300px',
                                                          height: 'auto',
                                                          minHeight: '400px',
                                                          left: '0',
                                                          right: '0',
                                                          top: '15%',
                                                          margin: 'auto',
                                                          zIndex: 9999,
                                                          background: "#efefef"
                                                      }}
                                                      title="Financement"
                                                      titleStyle={{padding: 10, margin: 0, color: 'white'}}
                                                      closeButtonStyle={{color: 'white'}}>
                                                <FormFinancement
                                                    affaire={this.state.affaire}
                                                    onSave={this.onChangeFinancement.bind(this)}
                                                />
                                            </SkyLight>
                                        </div>
                                    </div>
                                    : null}
                            </div>

                            {/********* Transfert du lead *************/}
                            <div className="col-md-4">
                                {this.state.action === "continuer" ?
                                    <>
                                        {this.renderTransfertForm()}
                                        {this.state.action === 'continuer' && (this.state.todoTransfertToVendeur || this.state.todoTransfertToChefVente) ?
                                            <FormRelance
                                                onChangeValue={this.changeValue.bind(this)}
                                                relance={this.state.relance}
                                                relanceAction={this.state.relanceAction}
                                                relanceDate={this.state.relanceDate}
                                                relanceHeure={this.state.relanceHeure}
                                                relanceCommentaire={this.state.relanceCommentaire}
                                                displayImageTitle={true}
                                                showTitle={true}
                                            />
                                            : null}
                                    </>
                                    : null}
                            </div>
                            {/******* Fin transfert du lead ********/}

                            {this.state.affaire && this.state.affaire.Description ?
                                <div className='col-md-4'>
                                    <h2 style={{ flex: 1, justifyContent: 'space-around', alignItems: 'center', display: 'flex' }}>
                                       <div> 
                                        <img
                                        src={localStorage.IsAgri === 'true' ? "/img/AFFAIRE MO.svg" : "/img/AFFAIRE VO.svg"}
                                        role='presentation'/> Affaire {this.state.affaire ? Dictionary.getUCFirst(this.state.affaire.Type) : null}
                                        </div>
                                        {LibAffaire.showOngletConstructeur(this.state.affaire.CodeAffaireSource, this.state.affaire.Description) &&
                                            <a href={LibAffaire.getUrlOngletConstructeur(this.state.affaire.CodeAffaireSource, this.state.affaire.Description)} target="_blank" style={{ marginLeft: "16px", marginRight: "16px" }}>
                                                <img style={{height:"25px"}}
                                                    src={`/img/${LibAffaire.getImageConstructeur(this.state.affaire.CodeAffaireSource)}`}
                                                    role='presentation'/>
                                            </a>
                                        }
                                    </h2>
                                    <div className='row'>
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="form-group" style={{marginLeft: '2px', marginRight: 0}}>
                                                <textarea className="form-control"
                                                          style={{height: this.state.affaire.Financement ? '380px' : '230px'}}
                                                          value={this.state.affaire ? this.state.affaire.Description.replace(/(?:\\[rn])+/g, '\n') : null}
                                                          onChange={this.onChangeDescriptionAffaire.bind(this)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="col-md-12">
                                <div className="center-div bigger-bottom">
                                    <button type="submit" className="text-center btn btn-default submit-vn"
                                            disabled={!this.canSubmit()}>
                                        {this.state.fetchCreateInProgress ?
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                            : null}
                                        Valider
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Rapport;
