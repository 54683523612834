import React, {Component} from 'react';

import moment from 'moment'

import InputTime from '../Components/InputTime';
var Datetime = require('react-datetime');

class FormAtelier extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      type: 'RDV',
      urgent: false,
      comment: this.props.defaultComment,
      panneAssistance: false,
      vehiculeCourtoisie: false,
      kilometrage:'',
      dateArrivee: null,
      dateRestitution: null,
      rdvConfirm: false,
    }
  }

  componentDidMount() {
    this.props.changeValue('commentAtelier', this.generateComment());
  }

  generateTypeDemande(type) {
    var valret = '';

    switch (type) {
      case 'RDV':
        valret = 'Prise de RDV';
        break;
      case 'SUIVI':
        valret = 'Suivi travaux';
        break;
      case 'RECLAMATION':
        valret = 'Réclamations';
        break;
      case 'DEVIS':
        valret = 'Demande de devis';
        break;
      case 'DEPANNAGE':
        valret = 'Demande de dépannage';
        break;
      case 'INFOS':
        valret = "Demande d'infos";
        break;
      case 'MECAPLANNING':
        valret = "Mécaplanning";
        break;
      default:
    }

    return valret;
  }

  generateComment() {
    let comment = '';
    
    comment += 'Type de demande : ' + this.generateTypeDemande(this.state.type) + '\n';
    comment += 'Demande urgente : ' + (this.state.urgent ? 'Oui' : 'Non') + '\n';

    if (this.state.type === 'RDV') {
      comment += 'Panne assistance : ' + (this.state.panneAssistance ? 'Oui' : 'Non') + '\n';
      comment += 'Véhicule de courtoisie : ' + (this.state.vehiculeCourtoisie ? 'Oui' : 'Non') + '\n';
      comment += 'Date / Heure d\'arrivée : ' + (this.state.dateArrivee ? moment(this.state.dateArrivee).format('DD/MM/YY HH:mm') : '') + '\n';
      comment += 'RDV Confirmé : ' + (this.state.rdvConfirm ? 'Oui' : 'Non') + '\n';
      if(this.state.kilometrage)
        comment += 'Kilométrage : '+this.state.kilometrage+'\n';
      comment += 'Date / Heure restitution souhaitée : ' + (this.state.dateRestitution ? moment(this.state.dateRestitution).format('DD/MM/YY HH:mm') : '') + '\n';

      var interventions = [];
      if (this.state.interventionPneu) {
        interventions.push('Changement des pneus');
      }
      if (this.state.interventionVidange) {
        interventions.push('Vidange');
      }
      if (this.state.interventionCarrosserie) {
        interventions.push('Carrosserie');
      }
      if (this.state.interventionRevision) {
        interventions.push('Révision constructeur');
      }
      if (this.state.interventionAmortisseur) {
        interventions.push('Amortisseurs');
      }
      if (this.state.interventionPlaquette) {
        interventions.push('Plaquettes de frein');
      }
      if (this.state.interventionClimatisation) {
        interventions.push('Climatisation');
      }
      if (this.state.interventionDiagnostic) {
        interventions.push('Diagnostic');
      }
      if (this.state.interventionCampagneRappel) {
        interventions.push('Campagne de rappel');
      }
      comment += 'Intervention : ' + interventions.join(', ') + '\n';
    }

    comment += 'Commentaire :\n' + this.state.comment + '\n';

    return comment;
  }

  updateChangeValue(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      if( name !== 'mecaplanningrdv' ){
        this.props.changeValue('commentAtelier', this.generateComment());
      }
    });
  }

  handleClickType(e) {
    this.setState({
      type: e.target.value,
    }, () => {
      this.props.changeValue('commentAtelier', this.generateComment());
    });
  }

  changeDateArrivee(value) {
    var dateArrivee = this.state.dateArrivee ? this.state.dateArrivee : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    value.set('hour', dateArrivee.get('hour')).set('minute', dateArrivee.get('minute'));
    console.log(value)

    this.setState({
      dateArrivee: value
    }, () => {
      this.props.changeValue('commentAtelier', this.generateComment());
    })
  }

  changeHeureArrivee(value) {
    var dateArrivee = this.state.dateArrivee ? this.state.dateArrivee : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dateArrivee.set('hour', value.get('hour')).set('minute', value.get('minute'));

    this.setState({
      dateArrivee: dateArrivee
    }, () => {
      this.props.changeValue('commentAtelier', this.generateComment());
    })
  }

  changeDateRestitution(value) {
    var dateRestitution = this.state.dateRestitution ? this.state.dateRestitution : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    value.set('hour', dateRestitution.get('hour')).set('minute', dateRestitution.get('minute'));

    this.setState({
      dateRestitution: value
    }, () => {
      this.props.changeValue('commentAtelier', this.generateComment());
    })
  }

  changeHeureRestitution(value) {
    var dateRestitution = this.state.dateRestitution ? this.state.dateRestitution : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dateRestitution.set('hour', value.get('hour')).set('minute', value.get('minute'));

    this.setState({
      dateRestitution: dateRestitution
    }, () => {
      this.props.changeValue('commentAtelier', this.generateComment());
    })
  }

  isValidDateArrivee(event) {
    return (event >= moment().startOf('day')) ? true : false;
  }

  isValidDateRestitution(event) {
    if (this.state.dateArrivee) {
      return (event >= moment(this.state.dateArrivee).startOf('day'));
    }

    return (event >= moment().startOf('day'));
  }

  render() {

    return (
      <div className="panel panel-default">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-atelier">
            <h2>
              <i className="fa fa-bell" aria-hidden="true"></i>&nbsp; Atelier
            </h2>
          </a>
        </li>
      </ul>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="form-group">
              <div className="col-md-12">
                <div className="btn-group flex-box">
                  <button className={this.state.type === 'RDV'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} type="button" value="RDV" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('RDV')}</button>
                  <button className={this.state.type === 'DEVIS'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="DEVIS" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('DEVIS')}</button>
                  <button className={this.state.type === 'DEPANNAGE'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="DEPANNAGE" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('DEPANNAGE')}</button>
                  <button className={this.state.type === 'SUIVI'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} type="button" value="SUIVI" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('SUIVI')}</button>
                  <button className={this.state.type === 'RECLAMATION'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="RECLAMATION" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('RECLAMATION')}</button>
                  <button className={this.state.type === 'INFOS'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="INFOS" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('INFOS')}</button>
                    {/*<button className={this.state.type === 'MECAPLANNING'
                      ? 'btn btn-default active btn-group-xs'
    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="MECAPLANNING" onClick={this.handleClickType.bind(this)} style={{ textTransform: 'inherit', fontSize: '15px' }}>{this.generateTypeDemande('MECAPLANNING')}</button>*/}
                </div>
              </div>
            </div>
          </div>

          {this.state.type === 'MECAPLANNING' ?
            this.props.lockProspectMecaplanning ?
              <div className="col-md-12 col-xs-12"><h5>Veuillez renseigner le Nom, Prénom et téléphone privé du prospect afin de continuer</h5></div>
            :
              this.props.site ?
                this.props.UrlMecaplanning ?
                  <div className="col-md-12 col-xs-12">
                    <div className="col-md-4 col-xs-4">
                      <iframe src={this.props.UrlMecaplanning} width="320px" height="510px" style={{ border: 'none'}} ></iframe>
                    </div>
                    <div className="col-md-8 col-ws-8" style={{ marginTop: '50px' }}>
                      <input type="checkbox" name="mecaplanningrdv" id="mecaplanningrdv" onChange={evt => this.updateChangeValue(evt)} checked={this.state.mecaplanningrdv} />
                      <label className="label-box" htmlFor="mecaplanningrdv" style={{ paddingLeft: '15px' }}> Rendez-vous pris </label>
                    </div>
                  </div>
                : <div className="col-md-12 col-xs-12"><h5>URL mécaplanning en attente...</h5></div>
              : <div className="col-md-12 col-xs-12"><h5>Veuillez sélectionner un site afin de continuer.</h5></div>


          :null}


          {this.state.type === 'RDV' ?
            <div className="col-md-4 col-xs-12">
              <h5>Intervention(s) :</h5>
              <div className="form-group checkbox-lines">
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionPneu" id="interventionPneu" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionPneu} />
                  <label className="label-box" htmlFor="interventionPneu"> Changement des pneus </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionVidange" id="interventionVidange" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionVidange} />
                  <label className="label-box" htmlFor="interventionVidange"> Vidange </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionCarrosserie" id="interventionCarrosserie" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionCarrosserie} />
                  <label className="label-box" htmlFor="interventionCarrosserie"> Carrosserie </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionRevision" id="interventionRevision" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionRevision} />
                  <label className="label-box" htmlFor="interventionRevision"> Révision constructeur </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionAmortisseur" id="interventionAmortisseur" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionAmortisseur} />
                  <label className="label-box" htmlFor="interventionAmortisseur"> Amortisseurs </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionPlaquette" id="interventionPlaquette" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionPlaquette} />
                  <label className="label-box" htmlFor="interventionPlaquette"> Plaquettes de frein </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionClimatisation" id="interventionClimatisation" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionClimatisation} />
                  <label className="label-box" htmlFor="interventionClimatisation"> Climatisation </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionDiagnostic" id="interventionDiagnostic" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionDiagnostic} />
                  <label className="label-box" htmlFor="interventionDiagnostic"> Diagnostic </label>
                </div>
                <div className="col-md-12 col-xs-12">
                  <input type="checkbox" name="interventionCampagneRappel" id="interventionCampagneRappel" onChange={evt => this.updateChangeValue(evt)} checked={this.state.interventionCampagneRappel} />
                  <label className="label-box" htmlFor="interventionCampagneRappel"> Campagne de rappel </label>
                </div>
              </div>
            </div>
          : null}


          <div className="col-md-8 col-xs-12">
            <div className="form-group checkbox-lines">
              {this.state.type !== 'MECAPLANNING' ?
              <div className="col-md-4 col-xs-12">
                <input type="checkbox" name="urgent" id="urgent" onChange={evt => this.updateChangeValue(evt)} checked={this.state.urgent} />
                <label className="label-box" htmlFor="urgent"> Demande urgente </label>
              </div> : null}

              {this.state.type === 'RDV' ?
                <div className="col-md-4 col-xs-12">
                  <input type="checkbox" name="panneAssistance" id="panneAssistance" onChange={evt => this.updateChangeValue(evt)} checked={this.state.panneAssistance}/>
                  <label className="label-box" htmlFor="panneAssistance"> Panne assistance </label>
                </div>
              : null}

              {this.state.type === 'RDV' ?
                <div className="col-md-4 col-xs-12">
                  <input type="checkbox" name="vehiculeCourtoisie" id="vehiculeCourtoisie" onChange={evt => this.updateChangeValue(evt)} checked={this.state.vehiculeCourtoisie}/>
                  <label className="label-box" htmlFor="vehiculeCourtoisie"> Véhicule de courtoisie </label>
                </div>
              : null}
            </div>
          </div>

          {this.state.type === 'RDV' ?
            <div className="col-md-8 col-xs-12">
              <div className="col-md-12 col-xs-12">
                <div className="form-group">
                  <label className="control-label">Kilométrage</label>
                  <input type="text" style={{display:'inline',width:'8em',marginLeft:'1em'}} pattern="[0-9]{0,10}" className="form-control d-inline" name="kilometrage" id="kilometrage" onChange={evt => this.updateChangeValue(evt)} value={this.state.kilometrage} />
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <h5>Date et heure d'arrivée :</h5>
                <div className="form-group datepicker-center">
                  <div className="col-md-7">
                    <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} isValidDate={this.isValidDateArrivee.bind(this)} open={false} onChange={this.changeDateArrivee.bind(this)} value={this.state.dateArrivee} />
                  </div>
                  <div className="col-md-3">
                    <i className="icon-calendar icons" style={{ paddingTop: '4px', display: 'block' }}></i>
                  </div>
                </div>
                <div className="form-group datepicker-center">
                  <div className="col-md-7">
                    <InputTime value={this.state.dateArrivee} onChange={this.changeHeureArrivee.bind(this)} SelectStyle={{paddingLeft: '3px'}} />
                  </div>
                  <div className="col-md-3">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>
                
                {this.state.dateArrivee ?
                  <div className="form-group">
                    <div className="col-md-12 col-xs-12">
                      <input type="checkbox" name="RDVConfirm" id="RDVConfirm" onChange={evt => this.setState({rdvConfirm: evt.target.checked}, () => this.generateComment())} checked={this.state.rdvConfirm} />
                      <label className="label-box" htmlFor="RDVConfirm">RDV Confirmé</label>
                    </div>
                  </div>
                : null}
              </div>

              <div className="col-md-6 col-xs-12">
                <h5>Date et heure de restitution souhaitée :</h5>
                <div className="form-group datepicker-center">
                  <div className="col-md-7">
                    <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} open={false} isValidDate={this.isValidDateRestitution.bind(this)} onChange={this.changeDateRestitution.bind(this)} value={this.state.dateRestitution} />
                  </div>
                  <div className="col-md-3">
                    <i className="icon-calendar icons" style={{ paddingTop: '4px', display: 'block' }}></i>
                  </div>
                </div>
                <div className="form-group datepicker-center">
                  <div className="col-md-7">
                    <InputTime value={this.state.dateRestitution} onChange={this.changeHeureRestitution.bind(this)} SelectStyle={{paddingLeft: '3px'}} />
                  </div>
                  <div className="col-md-3">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>
              </div>
            </div>
          : null}

          {this.state.type !== 'MECAPLANNING' ?
          <div className="col-md-12">
            <div className="form-group">
              <div className="col-md-12">
                  <textarea  name="comment" id="comment" style={{ width: '100%', height: '80px' }} onChange={evt => this.updateChangeValue(evt)} placeholder="Commentaire" value={this.state.comment}></textarea>
              </div>
            </div>
          </div>
          : null}
        </div>

      </div>
    );
  }
}

export default FormAtelier;
