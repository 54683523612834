import React, { Component } from 'react';

import { browserHistory } from 'react-router';

import Loader from '../Components/Loader';
import Globals from '../Globals';

class Vehicules extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vehicules: [],
      search: '',
      type: 'vn',
      vd: false,
      fetchInProgress: false,
      hasSearched: false,
      page: 0,
      page_size: 15,
      nextPageAvailable: false,
    }
  }

  componentDidMount() {
    var search = this.props.location.query.search

    if (search !== undefined && search !== "") {
      this.setState({
        search: search,
        vo: this.props.location.query.type === 'vo' ? true : false,
        vn: this.props.location.query.type === 'vn' ? true : false,
        vd: this.props.location.query.vd === 'true',
      }, () => {
        this.fetchVehicules();
      })
    }

  }

  changeUrl() {
    if (window.history.replaceState) {
       window.history.replaceState({}, null, '/vehicule?search=' + this.state.search+'&type='+this.state.type+'&vd='+this.state.vd);
    }
  }

  fetchVehicules() {

    this.setState({
      fetchInProgress: true
    })

    fetch(localStorage.ClientApiURL + '/Vehicules?stock_dispo=true&page='+this.state.page+'&page_size='+this.state.page_size+'&vehicule='+this.state.search+'&vn='+(this.state.type === 'vn')+'&vo='+(this.state.type === 'vo')+'&vd='+this.state.vd, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        vehicules: json.Vehicules,
        nextPageAvailable: (json.Page <= (json.Count/json.PageSize)),
      }, () => {
        this.setState({ fetchInProgress: false, hasSearched: true, })
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchInProgress: false })
    });
  }

  onClickNext() {
    this.setState({page: this.state.page+1}, () => {
      this.fetchVehicules();
    });
  }

  onClickPrevious() {
    this.setState({page: this.state.page-1}, () => {
      this.fetchVehicules();
    });
  }

  handleclickVehicule(IDVehicule) {
    browserHistory.push('/vehicule/' + IDVehicule);
  }

  onSubmit(ev) {
    ev.preventDefault();

    this.changeUrl();
    this.fetchVehicules();
  }

  handleChangeSearch(event) {
    this.setState({search: event.target.value});
  }

  disabledButton() {
    if (this.state.fetchInProgress)
      return true;

    return false;
  }

  render() {

    return (
      <div className="container-fluid">
        <div className="row">

          <div className="col-md-12" style={{ marginBottom: '60px' }}>

            <h2 className='title' style={{ marginBottom: 16 }}>
							<img src="img/Zoomer.svg" role="presentation" /> Rechercher un véhicule
						</h2>

            <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>

              <div className="form-group">
                <div className="col-md-12">
                  <input type="text" autoFocus={true} className="form-control size-xs" placeholder="Marque, Modèle, ..." value={this.state.search} onChange={this.handleChangeSearch.bind(this)} style={{ width: 400, display: 'inline-block' }} />

                  <div className="form-stock">
                    <input type="radio" name='type' id="vn"  checked={this.state.type === 'vn'}  onClick={(ev) => {this.setState({type: 'vn'}); }} />
                    <label className="label-radio radio-A" htmlFor="vn">
                      Neuf
                    </label>

                    <input type="radio" name='type' id="vo" checked={this.state.type === 'vo'}  onClick={(ev) => {this.setState({type: 'vo'}); }} />
                    <label className="label-radio radio-A" htmlFor="vo">
                      Occasion
                    </label>

                    <input type='checkbox' id='vd' checked={this.state.vd} onClick={(ev) => {this.setState({vd: ev.target.checked}); }} />
  									<label className="label-box" htmlFor="vd">Direction</label>
                  </div>


                  <button className="btn btn-default" disabled={this.disabledButton()} style={{ display: 'inline-block', marginLeft: 30 }}>Rechercher</button>
                </div>
              </div>

            </form>

          </div>

          {!this.state.fetchInProgress && this.state.vehicules.length ?
            <table className="table table-eseller-lead table-striped table-hover table-condensed">
              <thead>
                <tr>
                  <th>Ref. DMS</th>
                  <th>Véhicule</th>
                  <th>Type</th>
                  <th>Energie</th>
                  <th>Année</th>
                  <th className='text-center'>Kilométrage</th>
                  <th>Prix</th>
                  <th>Promo</th>
                  <th>Lieu</th>
                </tr>
              </thead>
              <tbody>
                {this.state.vehicules.map((vehicule, i) => {
                  return <tr onClick={this.handleclickVehicule.bind(this, vehicule.IDVehicule)} key={i}>
                    <td>{vehicule.RefDMS}</td>
                    <td>{vehicule.Marque} {vehicule.Modele} {vehicule.Version}</td>
                    <td>{vehicule.Type}</td>
                    <td>{vehicule.Moteur.Energie}</td>
                    <td>{vehicule.Annee}</td>
                    <td className='text-center'>{parseInt(vehicule.Kilometrage, 10).toLocaleString("fr-FR")}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                })}
              </tbody>
            </table>
          : null }

          {!this.state.fetchInProgress && this.state.hasSearched && !this.state.vehicules.length ?
            <div className="text-center"><em>Aucun résultat</em></div>
          : null }

          {!this.state.fetchInProgress && this.state.vehicules.length ?
              <div className="col-md-12">
                <div className="pager">
                  {this.state.page !== 0 ? <a className="pull-left" onClick={this.onClickPrevious.bind(this)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Page précédente</a> : ''}
                  {this.state.nextPageAvailable ? <a className="pull-right" onClick={this.onClickNext.bind(this)}>Page suivante <i className="fa fa-arrow-right" aria-hidden="true"></i></a> : ''}
                </div>
              </div>
          : null }

          {this.state.fetchInProgress ?
            <div className='col-md-12'>
              <Loader style={{ marginTop: 50 }} />
            </div>
          : null }

        </div>
      </div>
    );
  }
}

export default Vehicules;
