import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class ResumeFile extends Component {

  render() {
    return (
      <div className='col-md-12' style={{ marginBottom: '30px' }}>
        <h3>Type de fichier</h3>

        <div className='col-md-4'>
          <u>Type</u> : Constructeur
        </div>
        <div className='col-md-4'>
          <u>Marque</u> : Nissan
        </div>
        <div className='col-md-4'>
          <u>Finalité</u> : APV
        </div>

      </div>
    );
  }
}

export default ResumeFile;
