import React, { Component } from 'react';

import Globals from '../Globals';
import moment from 'moment';
var lodash = require('lodash');


class SendMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mails: [],
            tels: [],

            message: "",
            emailSubject:"",
            selectedChannel: "",
            selectedPhone: "",
            selectedEmail: "",
            selectedLead: "",
            attachments: [],

            smsLength: 0,
            sendingMessage: false,
            sendingMessageResult: "",
        };

        this.singleSMSLength = 160;
        this.acceptFileTypes = ['.doc', '.docx', '.xls', '.xlsx', '.pdf', '.jpeg', '.jpg', '.png', '.txt'];
        this.nbMaxAttachments = 2;
        this.maxSizeAttachments = 5; //En Mb
    }

    componentDidMount() {
        this.loadPhonesAndMails();
        //console.log(this.props.prospect)
    }

    calcSMSLength() {
        var message = this.state.message;
        this.setState({
            smsLength: message.replace(/[€\[\]\^\{\}~\|\\]/g, "xx").length
        });
    }

    canSubmit() {
        if (this.state.message.trim() === "" || this.state.sendingMessage) return false;
        if (this.state.selectedChannel === "sms")
            return (this.state.selectedPhone !== "");
        else if (this.state.selectedChannel === "email")
            return (this.state.selectedEmail !== "" && this.state.emailSubject.trim() !== "");
        else
            return false;
    }

    changeValue(target) {
        this.setState({
            [target.name]: target.value
        }, function () {
            if (target.name === "message") this.calcSMSLength();
        });
    }

    loadPhonesAndMails() {
        var mails = [];
        if (this.props.prospect.Mail && this.props.prospect.Mail !== "") mails.push({ type: "Mail", value: this.props.prospect.Mail });
        if (this.props.prospect.MailPro && this.props.prospect.MailPro !== "") mails.push({ type: "MailPro", value: this.props.prospect.MailPro });
        var tels = [];
        lodash.forEach(this.props.prospect.Telephones, function (tel) {
            var numstart = tel.Numero.substr(0, 2);
            if (tel.Numero && tel.Numero !== "" && (numstart === "06" || numstart === "07"))tels.push({ type: tel.Type, value: tel.Numero });
        });
        this.setState({
            mails: mails,
            tels: tels,
            message: "",
            emailSubject:"",
            selectedChannel: "",
            selectedPhone: "",
            selectedEmail: "",
            selectedLead: "",
            attachments:[],
        });
    }

    selectChannel(channel) {
        var selectedPhone = "";
        var selectedEmail = "";
        if (channel === "sms" && this.state.tels.length === 1)
            selectedPhone = this.state.tels[0].type;
        else if (channel === "email" && this.state.mails.length === 1)
            selectedEmail = this.state.mails[0].type;
        this.setState({
            selectedChannel: channel,
            selectedPhone: selectedPhone,
            selectedEmail: selectedEmail,
        });
    }

    openFileBrowser(e) {
        if(this.state.attachments.length === this.nbMaxAttachments){
            alert("Vous ne pouvez pas ajouter plus de "+this.nbMaxAttachments+" fichier"+(this.nbMaxAttachments > 1?"s":""));
            e.preventDefault();
            return false;
        }
    }

    addAttachment(target){
        var file = target.files[0];
        if(this.acceptFileTypes.indexOf('.'+file.name.split('.').pop().toLowerCase()) === -1){
            alert("Type de fichier non-autorisé");
            return false;
        }
        var totalSize = 0;
        lodash.forEach(this.state.attachments, function(a){
            totalSize += a.size;
        });
        if(totalSize + (file.size/1000000) > this.maxSizeAttachments){
            alert("Taille maximum des pièces jointes dépassée ("+this.maxSizeAttachments+" Mb)");
            return false;
        }
        var reader = new FileReader();
        var this_ = this;
        reader.onloadend = function(e) {
            //console.log(e.target.result);
            var attachments = this_.state.attachments;
            attachments.push({
                content: e.target.result,
                name: file.name,
                size: file.size/1000000
            })
            this_.setState({
                attachments: attachments
            });
        };
        reader.readAsDataURL(file);
    }

    removeAttachment(idx){
        var attachments = this.state.attachments;
        lodash.remove(attachments, (a, i) => {
            return i === idx;
        });
        this.setState({
            attachments:attachments,
        });
    }

    sendMessage() {
        this.setState({
            sendingMessage: true,
            sendingMessageResult: "",
        });
        fetch(localStorage.ClientApiURL + '/prospects/'+this.props.prospect.IDProspect+'/sendmessage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: JSON.stringify({
                type: this.state.selectedChannel,
                phoneType: this.state.selectedPhone,
                emailType: this.state.selectedEmail,
                text: this.state.message,
                mailSubject:this.state.emailSubject,
                idAffaire: this.state.selectedLead,
                attachments: this.state.selectedChannel === "email" ? this.state.attachments : null,
                idPlaque: this.props.IDPlaque,
            }),
        })
            .then((response) => {
                //console.log(response);
                if (!response.ok) {
                    this.setState({
                        sendingMessage: false,
                        sendingMessageResult: "ko",
                    });
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                //console.log(json);
                this.setState({
                    sendingMessage: false,
                    sendingMessageResult: "ok",
                });
                if(this.props.onMessageSent)
                    this.props.onMessageSent();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    sendingMessage: false,
                    sendingMessageResult: "ko",
                });
            });
    }

    render() {
        return (
                <div className="inner-large-content" style={{ height: '100%', padding: '20px' }}>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                Canal
                                <select className="form-control" onChange={(e) => this.selectChannel(e.target.value)}>
                                    <option value="">Choisir le canal</option>
                                    {this.state.tels.length > 0 ? <option value="sms">SMS</option> : null}
                                    {this.state.mails.length > 0 ? <option value="email">Email</option> : null}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                {this.state.selectedChannel === "email" ? "Email" : "Numéro"}
                                {this.state.selectedChannel === "sms" ?
                                    <select name="selectedPhone" className="form-control" onChange={(e) => this.changeValue(e.target)}>
                                        {this.state.tels.length > 1 ? <option value="">Choisir le numéro</option> : null}
                                        {this.state.tels.map((tel, key) => { return (<option value={tel.type} key={"sms" + key}>{tel.value}</option>) })}
                                    </select>
                                    :
                                    this.state.selectedChannel === "email" ?
                                        <select name="selectedEmail" className="form-control" onChange={(e) => this.changeValue(e.target)}>
                                            {this.state.mails.length > 1 ? <option value="">Choisir l'email</option> : null}
                                            {this.state.mails.map((mail, key) => { return (<option value={mail.type} key={"email" + key}>{mail.value}</option>) })}
                                        </select>
                                        :
                                        <select className="form-control">
                                            <option value="">-</option>
                                        </select>
                                }
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                Affaire
                                <select name="selectedLead" className="form-control" onChange={(e) => this.changeValue(e.target)}>
                                    <option value=''>-</option>
                                    {this.props.affaires && this.props.affaires.length ? this.props.affaires.reverse().map((a) => {
                                        return <option key={a.IDAffaire} value={a.IDAffaire}>Lead {a.Type ? a.Type : ''} du {moment(a.DateDebut).format('DD/MM/YYYY')}</option>
                                    }) : null}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div className="form-group">
                                {this.state.selectedChannel === "email" ?
                                    <div style={{marginBottom:'15px'}}>
                                        Sujet 
                                        <input type="text" name="emailSubject" onChange={(e) => this.changeValue(e.target)} className="form-control"/>
                                    </div>
                                :null}
                                Votre message
                                <textarea name="message" className="form-control" style={{ height: '200px' }} onChange={(e) => this.changeValue(e.target)}>
                                </textarea>
                                {this.state.selectedChannel === "sms" ?
                                    <div className={this.state.smsLength > 160 ? "text-danger" : ""} style={{ fontSize: '12px' }}>
                                        {this.state.smsLength} caractère{this.state.smsLength > 1 ? "s" : ""} | {Math.ceil(this.state.smsLength / this.singleSMSLength)} SMS
                                    </div>
                                :null}
                                {this.state.selectedChannel === "email" ?
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <label type="button" className="btn btn-primary" style={{marginTop:'5px', lineHeight:'12px', verticalAlign:'baseline', marginRight:'5px'}} onClick={(e) => this.openFileBrowser(e)}>
                                                Ajouter un fichier <input type="file" style={{display:'none'}} onChange={(e) => this.addAttachment(e.target)} accept={this.acceptFileTypes.join(',')}/>
                                            </label>
                                            {this.state.attachments.map((attachment, idx) => {
                                                return (<div key={"attachment"+idx} style={{display:'inline-block', background:'#ccc', padding:'3px', marginRight:'5px', marginTop:'5px'}}>{attachment.name}
                                                            <a className="glyphicon glyphicon-remove" style={{marginLeft:'5px'}} onClick={()=> this.removeAttachment(idx)}></a>
                                                        </div>);
                                            })}
                                        </div>
                                    </div>
                                :null}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 text-right">
                            {this.state.sendingMessage ?
                                <span style={{ marginRight: '15px' }}>
                                    <i className="fa fa-refresh fa-spin fa-fw" aria-hidden="true"></i>&nbsp;Envoi du message en cours
                                </span>
                                :
                                this.state.sendingMessageResult !== "" ?
                                    this.state.sendingMessageResult === "ko" ?
                                        <span className="text-danger" style={{ marginRight: '15px' }}>
                                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Echec de l'envoi du message
                                </span>
                                        :
                                        this.state.sendingMessageResult === "ok" ?
                                            <span className="text-success" style={{ marginRight: '15px' }}>
                                                <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Message envoyé
                                </span>
                                            : null
                                    : null
                            }
                            {this.state.sendingMessageResult === "" ?
                                <button type="button" className="btn btn-default" disabled={!this.canSubmit()} onClick={() => this.sendMessage()}>Envoyer</button>
                            :!this.props.iframe ?
                                <button type="button" className="btn btn-default" onClick={() => this.props.onClickClose()}>Fermer</button>
                            :
                                null
                            }
                            </div>
                    </div>
                </div>
        );
    }
}

export default SendMessage;
