import React, { Component } from 'react';

import moment from 'moment'

import OffreCompoment from '../Offre';

import SkyLight from 'react-skylight';
import Tooltip from "rc-tooltip";

class ListOffres extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      seletedOffre: null,
    }
  }

  displayStatus(status) {

    if (status === 'OptionDeVente')
      return 'Option de vente';
    else if (status === 'CommandeValidee')
        return 'Commande validée';
    else if (status === 'CommandeAnnulee')
        return 'Commande annulée';

    return status;
  }

  render() {

    if (!this.props.offres) {
      return null;
    }

    var offres = this.props.offres;
    offres.sort(function(a, b) {
      if (moment(a.Date) > moment(b.Date))
        return -1;
      return 1;
    });

    return (
      <div>
        <SkyLight hideOnOverlayClicked ref="modalDisplayOffre" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
          <div className='inner-large-content'>
            <OffreCompoment IDOffre={this.state.seletedOffre} />
          </div>
        </SkyLight>

        {offres.length ?
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <thead>
              <tr>
                <th>Suivi</th>
                <th>Numéro offre</th>
                <th>Status</th>
                <th>Date</th>
                <th>Validité</th>
                <th>Véhicule</th>
                <th>Reprise</th>
              </tr>
            </thead>
            <tbody>
              {offres.map((offre, j) => {
                return <tr key={j} onClick={(e) => {
                  this.setState({
                    seletedOffre: offre.IDOffre,
                  }, () => {
                    this.refs.modalDisplayOffre.show()
                  })
                }}>
                  <td>{offre.SuiviPar.Nom}</td>
                  <td>{offre.NumOffre}</td>
                  <td>{offre.Statut}</td>
                  <td>{moment(offre.Date).format('DD/MM/Y')}</td>
                  <td>
                    {offre.Statut === 'Offre' ? moment(offre.DateValidite).format('DD/MM/Y') : null}
                  </td>
                  <td>
                    {offre.Vehicules.map((vehicule, i) => {
                      return <div key={i}>{vehicule.Designation}</div>;
                    })}
                  </td>
                  <td>
                    {offre.Reprises.length > 0 ?
                      <Tooltip trigger={['hover']} overlay={<div>{offre.Reprises.map((reprise, i) => {
                        return <div key={i}>{reprise}</div>;
                      })}</div>}>
                        <img src="/img/TABLER ETAT.svg" role='presentation' />
                      </Tooltip>
                    : null}
                    {}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        : null}
      </div>
    );
  }
}

export default ListOffres;
