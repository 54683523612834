import React, { Component } from 'react';
import { Link } from 'react-router';

import FilterSocieteSite from './../Leads/FilterSocieteSite';
import FilterOrigine from './../Leads/FilterOrigine';
import FilterVendeur from './FilterVendeur';

import Tooltip from "rc-tooltip";

class Sidebar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showFilters: true,
    }
  }

  onClick(event) {
    this.props.showList();
    if (event.currentTarget.href === window.location.href) {
      this.setState({ showFilters: !this.state.showFilters });
    }
    else if(window.location.pathname.indexOf("AffairesEncours") !== -1){
      this.props.onChangeFilters("reset");
    }
  }

  render() {

    var currentLocation = window.location.pathname;

    return (
      <div  className="container-fluid">
        <div className="row">
          <div className="col-md-12">

            {this.props.typesDemandesSidebar.map((type, index) => {
              return (
              <span key={index}>
                <Link to={'/leadsAccessoires/AffairesEncours/'+encodeURI(type.name)} className={"btn linkToList " + (this.state.showFilters ? 'withFilters' : '') + " "+type.class} activeClassName="active" onClick={this.onClick.bind(this)}>
                  <div className="row">
                    <div className="col-xs-8">{type.name}</div>
                    <div className="col-xs-4 compteurs">
                      <div className="row">
                        <div className="col-xs-3" style={{padding:0}}>
                          <Tooltip trigger={['hover']} placement='left' overlay="En retard">
                            <i className="icons icon-clock"></i>
                          </Tooltip>
                        </div>
                        <div className="col-xs-2 text-center" style={{padding:0}}>
                          {this.props.nbLeadsEnCours[type.name+"_EnRetard"] ? this.props.nbLeadsEnCours[type.name+"_EnRetard"] : 0}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-3" style={{padding:0}}>
                          <Tooltip trigger={['hover']} placement='left' overlay="A venir">
                            <i className="icons icon-calendar"></i>
                          </Tooltip>
                        </div>
                        <div className="col-xs-2 text-center" style={{padding:0}}>
                          {this.props.nbLeadsEnCours[type.name+"_AVenir"] ? this.props.nbLeadsEnCours[type.name+"_AVenir"] : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                {currentLocation === '/leadsAccessoires/AffairesEncours/'+encodeURI(type.name) && this.state.showFilters ?
                <div>
                  <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                  <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                </div>
                :null}
                <br/><br/>
              </span>
              );
            })}

            <Link to={'/leadsAccessoires/AffairesGagnees'} className={"btn btn-success linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Leads transformés <span></span>
            </Link>

            {currentLocation === '/leadsAccessoires/AffairesGagnees' && this.state.showFilters ?
              <div>
                {/*<FilterTypeDemande types={this.props.typesDemandes} onChange={this.props.onChangeFilters} />*/}
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} />
              </div>
            : null}

            <br /><br />
            

            <Link to={'/leadsAccessoires/AffairesCloturees'} className={"btn btn-danger linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Leads non transformés <span></span>
            </Link>

            {currentLocation === '/leadsAccessoires/AffairesCloturees' && this.state.showFilters ?
              <div>
                {/*<FilterTypeDemande types={this.props.typesDemandes} onChange={this.props.onChangeFilters} />*/}
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} />
              </div>
            : null}

            <br /><br />
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
