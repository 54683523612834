import React, { Component } from 'react';


class IconCanal extends Component {
  render() {

    var _return = null;

    switch(this.props.canal){
      case 'Téléphone':
        _return = <i className="fa fa-phone" aria-hidden="true"></i>
        break;
      case 'Email':
        _return = <i className="fa fa-envelope" aria-hidden="true"></i>
        break;
      case 'Face à face':
        _return = <i className="fa fa-user" aria-hidden="true" title="Face à face"></i>
        break;
      default:
        if (!this.props.canal) {
          _return = <span />
        }
        else {
          _return = <span>{this.props.canal} -</span>
        }
        break;
    }

    return _return;
  }
}

export default IconCanal;
