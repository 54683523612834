import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import ResumeCampagne from './ResumeCampagne';

import FormCustomFile from './FormCustomFile';

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class FormStep2 extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      displayFormConstructeur: false,
      displayFormCustomFile: false,
    }
  }

  render() {
    return (
    <div className='row' style={this.props.style}>

      <ResumeCampagne campagne={this.props.campagne} />

      <div className='col-md-12'>
        <h3>Type de fichier</h3>

        <br />

        <div className='col-md-4 text-center'>
          <div style={{ cursor: 'pointer', width: '200px', height: '120px', backgroundColor: '#9A0059', margin: 'auto', color: 'white', fontWeight: '500', fontSize: '17px', lineHeight: '120px', }} onClick={() => {
            this.setState({ displayFormConstructeur: true, })
          }}>
            Constructeur
          </div>
        </div>
        <div className='col-md-4 text-center'>
          <div style={{ width: '200px', height: '120px', backgroundColor: '#9A0059', margin: 'auto', color: 'white', fontWeight: '500', fontSize: '17px', lineHeight: '120px', opacity: '0.4' }} onClick={() => {

          }}>
            Partenaire
          </div>
        </div>
        <div className='col-md-4 text-center'>
          <div style={{ cursor: 'pointer', width: '200px', height: '120px', backgroundColor: '#9A0059', margin: 'auto', color: 'white', fontWeight: '500', fontSize: '17px', lineHeight: '120px', }} onClick={() => {
            this.setState({ displayFormCustomFile: true, })
          }}>
            Personnalisé
          </div>
        </div>

      </div>

      {this.state.displayFormCustomFile ?
        <FormCustomFile />
      : null}

      {this.state.displayFormConstructeur ?
        <div className='col-md-12' style={{ marginTop: '45px' }}>
          <div className='col-md-4'>
            <div className="form-group">
              <label htmlFor="marque" className="col-sm-3 control-label">Marque</label>
              <div className="col-sm-6">
                <select className="form-group" style={{ width: '100%' }}>
                  <option value='Nissan' key='0'>Nissan</option>
                </select>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            <div className="form-group">
              <label htmlFor="type" className="col-sm-3 control-label">Finalité</label>
              <div className="col-sm-6">
                <select className="form-group" style={{ width: '100%' }}>
                  <option value='APV' key='0'>APV</option>
                </select>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            <div className="form-group">
              <label htmlFor="type" className="col-sm-3 control-label">Fichier</label>
              <div className="col-sm-6">
                <input type='file' style={{ border: 'none' }} />
              </div>
            </div>
          </div>

          <div className='col-md-12 text-center'>
            <button className='btn btn-default' onClick={() => {
              this.props.goToStep3(this.state.campagne);
            }}>Valider</button>
          </div>
        </div>
      : null}

    </div>
    );
  }
}

export default FormStep2;
