import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class FormCreate extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      saveInProgress: false,
      societes: [],
      campagne: {
        Code: '',
        Nom: '',
        Description: '',
        Marque: '',
        Societe: {
          IDPlaque: '',
        },
        Site: {
          IDSite: '',
        },
        OrigineAction: {
          IDOrigineAction: '',
        },
        DateDebutCampagne: '',
        DateFinCampagne: '',
        Statut: 1,
      }
    }
  }

  componentDidMount() {
    this.fetchSocietes();
    this.fetchOrigines();
  }

  fetchSocietes() {
    fetch(localStorage.ClientApiURL + '/Sites' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var societes = lodash.sortBy(json, (s) => {
        return s.Plaque.Nom + ' -  '+ s.Nom;
      })

      this.setState({
        societes: societes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchOrigines() {
    fetch(localStorage.ClientApiURL + '/LeadOrigineActions' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var origines = lodash.filter(json, (o) => {
        if (o.EstInactif)
          return false;

        if (!o.EstLead)
          return false;

        if (!o.Type || o.Type === '')
          return false;

        if (o.Famille.toLowerCase() != 'campagne')
          return false;

        return true;
      })

      origines = lodash.sortBy(origines, (o) => {
        return o.Type + ' - ' + o.Famille + ' - ' + o.SousFamille + ' - ' + o.Origine;
      })

      this.setState({
        origines: origines,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeCampagne(event) {
    var campagne = this.state.campagne;

    if (event.target.name == 'IDSite') {
      campagne.Site.IDSite = event.target.value;

      var indexSite = lodash.findIndex(this.state.societes, (s) => {
        return parseInt(s.IDSite, 10) === parseInt(event.target.value, 10)
      })

      campagne.Societe.IDPlaque = this.state.societes[indexSite].Plaque.IDPlaque;
    }
    else if (event.target.name == 'IDOrigineAction') {
      campagne.OrigineAction.IDOrigineAction = event.target.value;
    }
    else {
      campagne[event.target.name] = event.target.value;
    }

    console.log(campagne)

    this.setState({
      campagne: campagne,
    })
  }

  createCampagne(event) {

    event.preventDefault();

    this.setState({
      saveInProgress: true,
    })

    var campagne = JSON.stringify(this.state.campagne);
    console.log(campagne);

    fetch(localStorage.ClientApiURL + '/Campagnes' , {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: campagne,
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      browserHistory.push('/campagnes/' + json.IDCampagne);
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeFile(ev) {
    let files = ev.target.files;
    let reader = new FileReader();
    let file = files[0];
    var that = this;

    var campagne = this.state.campagne;
    reader.onload = function(e) {
        campagne.File = reader.result;
        that.setState({
          campagne : campagne
        });
    }
    reader.readAsDataURL(file);
  }

  render() {
    return (
    <form onSubmit={this.createCampagne.bind(this)}>
      <div className='row' style={this.props.style}>

        <div>
          <div className='col-md-6'>
            <div className="form-group">
              <label htmlFor="code" className="col-sm-3 control-label">Code</label>
              <div className="col-sm-4">
                <input type="text" className="form-control" name="Code" value={this.state.campagne.Code} onChange={this.onChangeCampagne.bind(this)} required={true} />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="IDSite" className="col-sm-3 control-label">Société / Site</label>
              <div className="col-sm-6">
                {this.state.societes ?
                  <select className="form-control" name="IDSite" value={this.state.campagne.Societe.IDSite} style={{ width: '100%' }} onChange={this.onChangeCampagne.bind(this)}>
                    <option value='' key='0'></option>
                    {this.state.societes.map((societe) => {
                      return <option value={societe.IDSite} key={societe.IDSite}>{societe.Plaque.Nom} - {societe.Nom}</option>
                    })}
                  </select>
                : null}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="Nom" className="col-sm-3 control-label">Libellé</label>
              <div className="col-sm-6">
                <input type="text" className="form-control" name="Nom" value={this.state.campagne.Nom} onChange={this.onChangeCampagne.bind(this)} required={true} />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="Marque" className="col-sm-3 control-label">Marque</label>
              <div className="col-sm-6">
              <select className="form-control" name="Marque" value={this.state.campagne.Marque} style={{ width: '100%' }} onChange={this.onChangeCampagne.bind(this)}>
                <option value='' key='0'></option>
                <option value='Nissan' key='1'>Nissan</option>
              </select>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="IDOrigineAction" className="col-sm-3 control-label">Origine</label>
              <div className="col-sm-6">

                  <select className="form-control" name="IDOrigineAction" value={this.state.campagne.OrigineAction.IDOrigineAction} style={{ width: '100%' }} onChange={this.onChangeCampagne.bind(this)} required={true}>
                    <option value='' key='0'></option>
                    {this.state.origines ?
                      this.state.origines.map((origine) => {
                        return <option value={origine.IDOrigineAction} key={origine.IDOrigineAction}>{origine.Type} - {origine.Famille} / {origine.SousFamille} / {origine.Origine}</option>
                      })
                    : null}
                  </select>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className="form-group">
              <label htmlFor="DateDebutCampagne" className="col-sm-3 control-label">Date de début</label>
              <div className="col-sm-3">
                <Datetime timeFormat={false} closeOnSelect={true} inputProps={{required: true}} dateFormat="DD/MM/YYYY" name='DateDebutCampagne' value={this.state.campagne.DateDebutCampagne ? moment(this.state.campagne.DateDebutCampagne) : null} onChange={(ev) => {
                  var campagne = this.state.campagne;
                  campagne.DateDebutCampagne = moment(ev);
                  this.setState({campagne: campagne});
                }} />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="DateFinCampagne" className="col-sm-3 control-label">Date de fin</label>
              <div className="col-sm-3">
                <Datetime timeFormat={false} closeOnSelect={true} inputProps={{required: true}} dateFormat="DD/MM/YYYY" name='DateFinCampagne' value={this.state.campagne.DateFinCampagne ? moment(this.state.campagne.DateFinCampagne) : null} onChange={(ev) => {
                  var campagne = this.state.campagne;
                  campagne.DateFinCampagne = moment(ev);
                  this.setState({campagne: campagne});
                }} />
              </div>
            </div>

            <div className="form-group clearfix">
              <label htmlFor="Description" className="col-sm-3 control-label">Description</label>
              <div className="col-sm-6">
                <textarea name='Description' style={{ width: '100%', }} value={this.state.Description} onChange={this.onChangeCampagne.bind(this)} />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="file" className="col-sm-3 control-label">Communication</label>
              <div className="col-sm-6">
                <input type="file" name="file" className="form-control" value={this.state.file} onChange={this.onChangeFile.bind(this)} style={{border: 'none'}} accept=".pdf" />
              </div>
            </div>

            <div className="form-group" style={{ marginTop: '15px' }}>
              <div className="col-sm-9">
                <button className='btn btn-default pull-right' disabled={this.state.saveInProgress}>
                  {this.state.saveInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Valider
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </form>
    );
  }
}

export default FormCreate;
