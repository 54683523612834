import React, { Component } from 'react';
import Tooltip from "rc-tooltip";
import {IsCrawlHway} from '../Utils'

var lodash = require('lodash')

class HwayContract extends Component {

  
    downloadHway(){
      window.open('https://'+localStorage.WebGp+'/ImagesMaterial/'+localStorage.NomGp+'/ContractHway/contract_'+this.props.rapport.Affaire.CodeAffaireSource+'.pdf')
    }

    render() {
      if(this.props.rapport.ActionAFaire_TODO.Etape === null || this.props.rapport.ActionAFaire_TODO.Etape.Description != "Commande" ||this.props.rapport.Affaire === null || !IsCrawlHway() || this.props.rapport.Affaire.CodeAffaireSource === null || !this.props.rapport.Affaire.CodeAffaireSource.startsWith("HMF")){
        return null;
      }
      return (<div
        style={!this.props.rapport.Description && !this.props.rapport.ActionFaite_DO.Commentaire ? { display: 'inline-block', fontSize: '14px' } : { display: 'inline-block', marginLeft: '5px', fontSize: '14px' }}>

          <Tooltip trigger={['hover']} overlay={<div>Télécharger le PDF</div>}>
              <i className="fa fa-download" aria-hidden="true" onClick={() => { this.downloadHway() }}></i>
          </Tooltip>
      </div>);
    }
}

export default HwayContract;
