import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';
import moment from 'moment'

import OffreCompoment from '../Components/Offre';

class Offre extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <OffreCompoment IDOffre={this.props.params.id} />
    )
  }
}

export default Offre;
