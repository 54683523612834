import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../Components/Loader';
import Globals from '../Globals';

import { Link } from 'react-router';

var lodash = require('lodash')


class Holidays extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchInProgress: true,
      utilisateurs: [],
      filter: '',
    }
  }

  componentDidMount() {
    this.fetchutilisateurs();
  }

  fetchutilisateurs() {
    fetch(localStorage.ClientApiURL + '/utilisateurs/', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var utilisateurs = lodash.sortBy(json, (utilisateur) => {
        return utilisateur.Nom;
      })

      utilisateurs = lodash.filter(utilisateurs, (utilisateur) => {
        return !utilisateur.EstPasserelle;
      })

      this.setState({
        utilisateurs: utilisateurs,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  goToPeople(IDPeople) {
    browserHistory.push('/holidays/' + IDPeople);
  }

  onChangeFilter(event) {
    this.setState({
      filter: event.target.value,
    })
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div className='pull-left'>
                <h1>Gestion des absences</h1>
              </div>

              <div className='form-group form-group-sm pull-right'>
                {!this.state.fetchInProgress ?
                  <input type="search" className='form-control' value={this.state.filter} onChange={this.onChangeFilter.bind(this)} placeholder='Filtre sur le nom du vendeur' style={{ width: '200px', border: '1px solid rgb(204, 204, 204)', marginRight: '5px', marginTop: '24px' }} />
                : null}
              </div>

              <div className='clearfix'></div>

            </div>

            <br />

            {this.state.fetchInProgress ?
              <Loader style={{ marginTop: 150 }} />
            :
              <div>

                <table className='table table-eseller-lead table-striped'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom</th>
                      <th>Profil</th>
                      <th>Actif</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.utilisateurs.map((utilisateur, i) => {

                      if (this.state.filter) {
                        if (utilisateur.Nom.toLowerCase().search(this.state.filter.toLowerCase()) === -1) {
                          return false;
                        }
                      }

                      if (utilisateur.EstPasserelle)
                        return false;

                      return (<tr key={i} onClick={this.goToPeople.bind(this, utilisateur.IDUtilisateur)}>
                        <td>{utilisateur.IDUtilisateur}</td>
                        <td>{utilisateur.Nom}</td>
                        <td>{utilisateur.Profil ? utilisateur.Profil.Description : ''}</td>
                        <td>{utilisateur.EstActif ? 'Oui' : 'Non'}</td>
                        <td></td>
                      </tr>)
                    })}

                  </tbody>
                </table>
              </div>
            }

          </div>
        </div>
      </div>
    );
  }
}

export default Holidays;
