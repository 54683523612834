import React, {Component} from 'react';
import Globals from '../Globals';

var lodash = require('lodash')

class FormSocieteSiteAtelier extends Component {

  constructor(props) {
    super(props);

    this.state = {
      marques: [],
      societes: [],
      sites: [],
      _plaques: [],
      userSites: [],
    }
  }

  componentWillMount() {
    if(this.props.origin === "CallWin")
      this.fetchSocietesSites();
    else
      this.fetchUserSocietesSites();
  }

  fetchUserSocietesSites(){
    fetch(localStorage.ClientApiURL + '/Utilisateurs/'+localStorage.user_IDUtilisateur, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        userSites: lodash.map(json.Sites, 'IDSite'),
      }, this.fetchSocietesSites);
    })
    .catch((error) => {
        console.log(error)
    });
  }

  fetchSocietesSites() {
    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var societes = [];
      var sites = [];

      var marques = [];

      lodash.map(json, (plaque) => {
        var sites = lodash.filter(plaque.Sites, (site) => {
          return site.Est_eSellerLeadActif === true && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1);
        });

        if (!sites.length) {
          return;
        }

        plaque.Sites.map((site) => {
          return site.Marques.map((marque) => {
            marques.push(marque.Description);
            return marque;
          });
        });

        return;
      });


      marques = lodash.uniq(marques).sort();

      json.map((plaque) => {
        return societes[plaque.IDPlaque] = plaque.Nom;
      })

      json.map((plaque) => {
        var index = societes.indexOf(plaque.Nom);
        sites[index] = [];
        return plaque.Sites.map((site) => {
          if(site.Est_eSellerLeadActif === true && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1)){
            return sites[index][site.IDSite] = site.Libelle ? site.Libelle : site.Nom;
          }
          return null;
        })
      })

      this.setState({
        marques: marques,
        societes: societes,
        sites: sites,
        _plaques: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }
  handleClickCanal(event) {
    this.props.onChangeValue('canal', event.target.value);
  }

  handleChangeSociete(event) {
    this.props.onChangeValue('societe', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('site', 0);
    this.props.onChangeValue('todoToVendeur', false);
  }

  handleChangeSite(event) {
    this.props.onChangeValue('site', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('todoToVendeur', false);
  }

  generateAll(){
    this.fetchSocietesSites();
  }

  handleChangeMarque(event) {
    this.props.onChangeValue('marque', event.target.value);
    this.props.onChangeValue('societe', 0);
    this.props.onChangeValue('site', 0);
  }

  render() {
    return (
      <div style={{ marginBottom: '55px' }}>
        <h3>Société / Site</h3>

        {this.props.typeActive === 'VN' ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeMarque.bind(this)} id="leadMarque" name="leadMarque" value={this.props.marque} required="required">
                <option key="0">Marque</option>
                {this.state.marques.map((marque, id) => {
                  return <option key={id} value={marque}>{ marque }</option>;
                })}
              </select>
            </div>
          </div>
        : null}

        {((this.props.typeActive === 'VN' && this.props.marque) || (this.props.typeActive !== 'VN')) ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeSociete.bind(this)} id="leadSociete" name="leadSociete" value={this.props.societe} required="required">
                <option key="0">Société</option>
                {this.state._plaques.map((societe, id) => {
                  return this.state.sites[societe.IDPlaque].length ? <option key={societe.IDPlaque} value={societe.IDPlaque}>{ societe.Nom }</option> : null;
                })}
              </select>
            </div>
          </div>
        : null}

        {(this.props.societe || this.props.societe == 0) && this.props.societe !== "Société" ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="leadSite" name="leadSite" value={this.props.site} required="required">
                <option value="" key="0">Site</option>
                {this.state._plaques.map((societe, id) => {
                  if (parseInt(societe.IDPlaque, 10) === parseInt(this.props.societe, 10)) {
                    return societe.Sites.map((site) => {
                      if((this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1))
                        return <option key={site.IDSite} value={site.IDSite} >{ site.Libelle ? site.Libelle : site.Nom }</option>;
                    })
                  }
                  return null;
                })}
              </select>
            </div>
          </div>
        : null }

      </div>
    );
  }
}

export default FormSocieteSiteAtelier;
