import React, { Component } from 'react';
import ListInterlocuteurs from './ListInterlocuteurs';
import Globals from '../../Globals';
import Tooltip from "rc-tooltip";
import InputMask from 'react-input-mask';
var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    var defaultMobile = '';
    var defaultPrive = '';

    if (this.props.defaultPhone) {
      if (this.props.defaultPhone.substr(0,2) === '06' || this.props.defaultPhone.substr(0,2) === '07') {
        defaultMobile = this.props.defaultPhone;
      }
      else {
        defaultPrive = this.props.defaultPhone;
      }
    }

    this.state = {
      TypeClient: 'Particulier',
      Civilite: '',
      Nom: this.props.defaultName ? this.props.defaultName.toUpperCase() : '',
      Prenom: '',
      Adresse: '',
      CodePostal: '',
      Ville: '',
      Mail: this.props.defaultEmail,
      MailPro : '',
      Mobile: defaultMobile,
      Prive: defaultPrive,
      Fax: '',
      Professionnel : '',
      SIRET: '',
      IDFidelite: null,
      CommentaireFidelite: '',
      IDCategorieClientAgri: null,

      _fidelites: null,
      _categoriesClientAgri:null,
      _libFidelite: '',
      _telsInitialValidation: {
        Mobile: true,
        Prive: true,
        Professionnel: true,
        Fax: true
      }
    }
  }

  componentDidMount() {
    this.fetchFidelites();
    if(localStorage.IsAgri === 'true')
      this.fetchCategoriesClientAgri();

    this.checkInitialTelsAreValid();
  }

  checkInitialTelsAreValid() {
    console.log("Check init tels are valid")
    const formatTelFR = localStorage.eSellerLeadFormatTelFR == 'true';
    const telMobile = this.state.Mobile;
    const telPrive = this.state.Prive;
    const telPro = this.state.Professionnel;
    const fax = this.state.Fax;

    var telsInitialValidation = {
      Mobile: !telMobile || this.isValidTel(telMobile, true, formatTelFR),
      Prive: !telPrive || this.isValidTel(telPrive, false, formatTelFR),
      Professionnel: !telPro || this.isValidTel(telPro, false, formatTelFR),
      Fax:  !fax || this.isValidTel(fax, false, formatTelFR),
    }

    this.setState({
      telsInitialValidation: telsInitialValidation
    });
  }

  isValidTel(tel, isMobile, formatTelFR) {
    var regex;
    if(formatTelFR){
      if(isMobile) {
        regex = /0(6|7)(?:[ .-]?[0-9]{2}){4}/;
      }
      else {
        regex = /0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}/;
      }
    }
    else {
      regex = /[0-9.()\- ]{2,14}/;
    }

    return regex.test(tel);
  }

  fetchFidelites() {
    fetch(localStorage.ClientApiURL + '/Fidelites', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var IDFidelite = this.state.IDFidelite
      var selectedFidelite = lodash.find(json, function(f) {
        return f.IDFidelite === IDFidelite;
      });
      this.setState({
        _fidelites: json,
        _libFidelite: selectedFidelite ? selectedFidelite.Descr : '',
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchCategoriesClientAgri() {
    fetch(localStorage.ClientApiURL + '/agri/CategoriesClientAgri', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        _categoriesClientAgri: json,
      }); 
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(attr, event) {
    var value = event.target.value

    if (attr === 'Prenom') {
      value = value.replace(/[^ -]+/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}); // uppercase all first letter of words
    }

    if (attr === 'Nom') {
      value = value.toUpperCase();
    }

    if (attr === 'CodePostal' && (value && value.length === 5)) {

      var url;
      var headers = {};
      if(!localStorage.CodePays || localStorage.CodePays === "undefined" || localStorage.CodePays === "FR") //France
        url = 'https://geo.api.gouv.fr/communes?codePostal='+value+'&fields=nom&format=json';
      else { //Autres pays 
        url = localStorage.ClientApiURL+'/communes?code_pays='+localStorage.CodePays+'&code_postal='+value;
        headers = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
          },
        }
      }

      fetch(url, headers)
      .then((response) => {
        return response.json();
      })
      .then((json) => {

        // Si pas de ville renseigné par l'utilisateur
        if (!this.state.Ville) {
          // Si une seule ville disponible pour le code postal
          if (json.length === 1) {
            this.setState({
              Ville: json[0].nom,
            }, () => {
              this.props.onChange(this.getProspectState());
            })
          }
          // Si plusieurs villes disponible pour le code postal
          else if (json.length > 1) {
            this.setState({
              cp_villes: json,
              displaySelectVille: true,
            })
          }
        }
      })
    }

    if (attr === 'Ville' && this.state.displaySelectVille) {
      this.setState({
        displaySelectVille: false,
        cp_villes: null,
      })
    }

    this.setState({
      [attr]: value,
    }, () => {
      this.props.onChange(this.getProspectState());
    })

    if (attr === 'TypeClient') {
      this.setState({
        Civilite: ''
      }, () => {
        this.props.onChange(this.getProspectState());
      });
    }

    if(attr === 'IDFidelite'){
      var selectedFidelite = lodash.find(this.state._fidelites, function(f) {
        return f.IDFidelite === parseInt(value, 10);
      });

      this.setState({
        _libFidelite: selectedFidelite ? selectedFidelite.Descr : '',
        CommentaireFidelite: selectedFidelite && selectedFidelite.Descr.toLowerCase() === "oui" ? this.state.CommentaireFidelite : ''
      })
    }
  }

  getProspectState(){
    /* Les propriétés commençant par '_' dans this.state ne font pas parties des données du prospect */
    return lodash.pickBy(this.state, function(value, name){
      return name.substr(0, 1) !== '_';
    });
  }

  submitInterlocuteurs(IDProspect){
    this.refs.listInterlocuteurs.submitInterlocuteurs(IDProspect);
  }

  render() {

    const formatTelFR = localStorage.eSellerLeadFormatTelFR == 'true';

    return (
    <div className="form-prospect">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-civilite">

          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Type</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12 flex-box text-nowrap">
              <div style={{ marginRight: '15px' }}>
                <input type="radio" name="TypeClient" id="Particulier" value="Particulier" checked={this.state.TypeClient === 'Particulier'} onChange={this.onChange.bind(this, 'TypeClient')} />
                <label className="label-radio radio-A" htmlFor="Particulier">Particulier</label>
              </div>
              <div>
                <input type="radio" name="TypeClient" id="Societe" value="Societe" checked={this.state.TypeClient === 'Societe'} onChange={this.onChange.bind(this, 'TypeClient')}   />
                <label className="label-radio radio-A" htmlFor="Societe">Société</label>
              </div>
            </div>
          </div>

          {localStorage.IsAgri === 'true' && this.state.TypeClient === 'Societe' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Catégorie</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <select className="form-control" name='IDCategorieClientAgri' onChange={this.onChange.bind(this, 'IDCategorieClientAgri')} value={this.state.IDCategorieClientAgri ? this.state.IDCategorieClientAgri : ''}>
                <option value=""></option>
                {this.state._categoriesClientAgri.map((categ) => {
                  return <option key={categ.IDCategorieClient} value={categ.IDCategorieClient}>{categ.Libelle}</option>
                })}
              </select>
            </div>
          </div>
        : null}

          <div className="form-group">
            {this.state.TypeClient === 'Societe' 
              ? <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right text-nowrap">Forme juridique</label>
              : <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Civilité</label>
            }
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <select className="form-control" value={this.state.Civilite} onChange={this.onChange.bind(this, 'Civilite')} required='required'>
                <option></option>
                {this.state.TypeClient === 'Particulier' ?
                  [<option key='TypeClient-Madame' value="Madame">Madame</option>,
                  <option key='TypeClient-Monsieur' value="Monsieur">Monsieur</option>]
                :
                [<option key='TypeClient-EURL' value="eurl">EURL</option>,
                <option key='TypeClient-SA' value="sa">SA</option>,
                <option key='TypeClient-SARL' value="sarl">SARL</option>,
                <option key='TypeClient-SAS' value="sas">SAS</option>,
                <option key='TypeClient-Autre' value="autre">Autre</option>]
                }
              </select>
            </div>
          </div>

          <div className="form-group">
            {this.state.TypeClient === 'Societe' 
              ? <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right text-nowrap">Raison sociale</label>
              : <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Nom</label>
            }
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="text" autoComplete="off" className="form-control" value={this.state.Nom} onChange={this.onChange.bind(this, 'Nom')}  required='required' />
            </div>
          </div>

          {this.state.TypeClient === 'Particulier' ?
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Prenom</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <input type="text" autoComplete="off" className="form-control" value={this.state.Prenom} onChange={this.onChange.bind(this, 'Prenom')} />
              </div>
            </div>
          : null}

          {this.state.TypeClient === 'Societe' ?
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">{localStorage.CodeLangue === "fr-MA" ? "N° RC" : "SIRET"}</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <input type="text" className='form-control' value={this.state.SIRET} name='SIRET' onChange={this.onChange.bind(this, 'SIRET')} minLength={localStorage.CodeLangue === "fr-MA" ? 6 : 14} maxLength={localStorage.CodeLangue === "fr-MA" ? 6 : 14} required={localStorage.IsNissan === "true"} />
              </div>
            </div>
          : null}

        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

          <div className="form-group">
            <label className="col-sm-3 control-label">Mobile</label>
            <div className="col-sm-9">
              <InputMask mask={formatTelFR ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control" value={this.state.Mobile} onChange={this.onChange.bind(this, 'Mobile')} pattern={formatTelFR ? "0(6|7)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}"} />
              {this.state.Mobile && !this.isValidTel(this.state.Mobile, true, formatTelFR) ? 
                <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                  <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                    <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                  </div>
                </Tooltip>
              : null}
            </div>
          </div>

          {this.state.TypeClient !== 'Societe' ?
            <div className="form-group">
              <label className="col-sm-3 control-label">Tél. Privé</label>
              <div className="col-sm-9">
                <InputMask mask={formatTelFR ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control"  value={this.state.Prive} onChange={this.onChange.bind(this, 'Prive')} pattern={formatTelFR ? "0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}"} />
                {this.state.Prive && !this.isValidTel(this.state.Prive, false, formatTelFR) ? 
                  <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                    <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                      <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                    </div>
                  </Tooltip>
                : null}
              </div>
            </div>
          : null}

          <div className="form-group">
            <label className="col-sm-3 control-label">Tél. Pro</label>
            <div className="col-sm-9">
              <InputMask mask={formatTelFR ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control"  value={this.state.Professionnel} onChange={this.onChange.bind(this, 'Professionnel')} pattern={formatTelFR ? "0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}"} />
              {this.state.Professionnel && !this.isValidTel(this.state.Professionnel, false, formatTelFR) ? 
                <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                  <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                    <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                  </div>
                </Tooltip>
              : null}
            </div>
          </div>

          {this.state.TypeClient === 'Societe' ?
            <div className="form-group">
              <label className="col-sm-3 control-label">Fax</label>
              <div className="col-sm-9">
                <InputMask mask={formatTelFR ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control"  value={this.state.Fax} onChange={this.onChange.bind(this, 'Fax')} pattern={formatTelFR ? "0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}"} />
                {this.state.Fax && !this.isValidTel(this.state.Fax, false, formatTelFR) ? 
                  <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                    <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                      <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                    </div>
                  </Tooltip>
                : null}
              </div>
            </div>
          : null}

          {this.state.TypeClient === 'Particulier' ?
            <div>
              <div className="form-group">
                <label className="col-sm-3 control-label">Mail</label>
                <div className="col-sm-9">
                  <input type="email" autoComplete="off" className="form-control" value={this.state.Mail} onChange={this.onChange.bind(this, 'Mail')} minLength="7" />
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-3 control-label">Mail Pro</label>
                <div className="col-sm-9">
                  <input type="email" autoComplete="off" className="form-control" value={this.state.MailPro} onChange={this.onChange.bind(this, 'MailPro')} minLength="7" />
                </div>
              </div>
            </div>
          :
            <div className="form-group">
              <label className="col-sm-3 control-label">Mail Pro</label>
              <div className="col-sm-9">
                <input type="email" autoComplete="off" className="form-control" value={this.state.Mail} onChange={this.onChange.bind(this, 'Mail')} minLength="7" />
              </div>
            </div>
          }

        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

          <div className="form-group">
            <label className="col-sm-3 control-label">Adresse</label>
            <div className="col-sm-9">
              <textarea className="form-control" value={this.state.Adresse} onChange={this.onChange.bind(this, 'Adresse')} />
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-3 col-md-3 control-label">CP / Ville</label>
            <div className="col-md-3">
              <InputMask mask="99999" maskChar=""  type="text" autoComplete="off" className="form-control" value={this.state.CodePostal} onChange={this.onChange.bind(this, 'CodePostal')} pattern="[0-9]{5}" style={{ paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }} />
            </div>
            <div className="col-md-6" style={{ paddingLeft: '0px' }}>
              <input type="text" autoComplete="off" className="form-control" value={this.state.Ville} onChange={this.onChange.bind(this, 'Ville')} />
              {this.state.displaySelectVille ?
                <select className='form-control' style={{ marginTop: '2px' }} onChange={(e) => {
                  this.setState({
                    Ville: e.target.value,
                    displaySelectVille: false,
                    cp_villes: null,
                  }, () => {
                    this.props.onChange(this.getProspectState());
                  })
                }}>
                  <option key='0'>Liste des villes</option>
                  {this.state.cp_villes.map((ville, i) => {
                    return <option key={ville.nom}>{ville.nom}</option>
                  })}
                </select>
              : null}
            </div>
          </div>

          {this.state._fidelites && this.state._fidelites.length ?
          <div className="form-group">
            <label className="col-sm-3 control-label" style={{whiteSpace:'nowrap'}}>Carte de fidélité</label>
            <div className="col-sm-9">
              <select className="form-control" value={this.state.IDFidelite?this.state.IDFidelite:''} onChange={(e) => this.onChange('IDFidelite', e)}>
                <option></option>          
                {this.state._fidelites.map((f) => {
                    return <option value={f.IDFidelite} key={"fidelite-"+f.IDFidelite}>{f.Descr}</option>
                  })}
              </select>
            </div>
          </div>:null}
          
          {this.state._fidelites && this.state._libFidelite.toLowerCase() === "oui" ? 
          <div className="form-group">
            <label className="col-sm-3 control-label" style={{whiteSpace:'nowrap'}}>Info fidélité</label>
            <div className="col-sm-9">
              <input type='text' autoComplete='off' className="form-control" value={this.state.CommentaireFidelite} onChange={this.onChange.bind(this, 'CommentaireFidelite')} /> 
            </div>
          </div>:null}

        </div>

        {this.state.TypeClient === 'Societe' ?
          <div className="col-xs-12" style={{marginBottom:'25px', paddingLeft:'25px'}}>
            <ListInterlocuteurs ref="listInterlocuteurs" IDProspect={null}/>
          </div>
        :
          <div className="col-xs-12" style={{marginBottom:'25px'}}></div>
        }

      </div>
    </div>

    );
  }
}

export default Form;