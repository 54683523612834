import React, {Component} from 'react';

class FormTypeAppelSortant extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  handleClickType(event) {
    this.props.onChangeValue('typeAppelSortant', event.target.value);
    this.props.onChangeValue('type', event.target.value);
  }

  render() {

    return (
      <div className="row">
        <div className="col-md-4 col-xs-12 col-affaire" style={{ marginRight: '10%'}}>
          <div className="form-group">
            <label className="col-lg-5 col-md-5 col-sm-5 col-xs-12 control-label">Choix de l'intérêt</label>
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
              <div className="btn-group flex-box">
                <button className={this.props.typeAppelSortant === 'VN'
                  ? 'btn btn-default active btn-group-xs'
                  : 'btn btn-default btn-group-xs'} type="button" value="VN" onClick={this.handleClickType.bind(this)}>VN</button>
                <button className={this.props.typeAppelSortant === 'VO'
                  ? 'btn btn-default active btn-group-xs'
                  : 'btn btn-default btn-group-xs'} type="button" value="VO" onClick={this.handleClickType.bind(this)}>VO</button>
                <button key="typeAelier" className={this.props.typeAppelSortant === 'Atelier'
                  ? 'btn btn-default active btn-group-xs'
                  : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Atelier" onClick={this.handleClickType.bind(this)}>Atelier</button>
                <button key="typeAutres" className={this.props.typeAppelSortant === 'Autres'
                  ? 'btn btn-default active btn-group-xs'
                  : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Autres" onClick={this.handleClickType.bind(this)}>Autres</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xs-12 " style={{ marginTop: '21px', fontSize:'12px' }}>
          <label className="col-lg-2 col-md-2 col-sm-2 col-xs-12">Origine : </label>
          <span style={{ fontWeight: 'normal'}}>
            {this.props.selectedOrigine ? 
              this.props.selectedOrigine.Famille+" / "+this.props.selectedOrigine.SousFamille+" / "+this.props.selectedOrigine.Origine
            :null}
          </span>
        </div>
      </div>
    );
  }
}

export default FormTypeAppelSortant;
