import React, {Component} from 'react';
import Globals from '../Globals';

import moment from 'moment'
var Datetime = require('react-datetime');
var lodash = require('lodash')

class FormToDoCreateAtelierAutres extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      _utilisateurs: [],
      utilisateurs: [],
      selected:null,
    }
  }

  componentWillMount() {
    this.fetchutilisateurs();
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.site !== this.props.site) {
      this.fetchutilisateurs();
    }
  }

  fetchutilisateursBySite() {
    fetch(localStorage.ClientApiURL + '/Utilisateurs?EstActif=true&LoadDetailDroitProfil=false&LoadPlaqueMarques=false', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var utilisateurs = lodash.filter(json, (utilisateur) => {
        return lodash.find(utilisateur.Sites, (site, i) => {

          if (parseInt(site.IDSite, 10) !== parseInt(this.props.site, 10)) {
            return false;
          }

          if (this.props.typeActive){
            if(this.props.typeActive === "Atelier")
              return utilisateur.EstTypeConseiller;
            else if(this.props.typeActive === "Autres")
              return utilisateur.EstTypeAutre;
          }

          return false;
        });
      });
      utilisateurs = lodash.orderBy(utilisateurs, 'Nom');

      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchutilisateurs() {

    if (localStorage.user_IDUtilisateur == '-1' && localStorage.bypass_iFrame == 'true') {
      this.fetchutilisateursBySite();
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + this.props.site , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var utilisateurs = lodash.orderBy(json, 'Nom');
      
      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeVendeur(event){
    this.props.onChangeValue('todoVendeur', event.target.value);
  }

  changeToVendeur(event) {
    this.props.onChangeValue('todoToVendeur', event.target.checked);
  }

  changeVendeur(event) {
    this.props.onChangeValue('todoVendeur', event.target.value);
  }

  getAllUsers(){
    var utilisateurs = lodash.filter(this.state.utilisateurs, (utilisateur) => {
      if (this.props.typeActive){
        if(this.props.typeActive === "Atelier")
          return utilisateur.EstTypeConseiller;
        else if(this.props.typeActive === "Autres")
          return utilisateur.EstTypeAutre;
      }
    });
    return utilisateurs;
  }

  render() {
    return (
      <div style={{ marginBottom: '55px' }}>

        <div className='row'>
          <div className="col-md-10 col-md-offset-1">
            {this.getAllUsers().length ?
              <div>
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <div className="col-md-12" style={{ textAlign: 'left' }}>
                    <input type='checkbox' id='toVendeur' onChange={this.changeToVendeur.bind(this)} checked={this.props.todoToVendeur} />
                    <label className="label-box" htmlFor="toVendeur">Affecter à un conseiller service</label>
                  </div>
                </div>
              </div>
              :
              <div>
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <label className="warning">[Aucun conseiller service paramétré]</label>
                  </div>
                </div>
              </div>
              }

              {this.props.todoToVendeur ?
                <div>
                  <div className="form-group">
                    <div className="col-md-12">
                      <select className="form-control" id="vendeur" name="vendeur" onChange={this.changeVendeur.bind(this)} /*defaultValue={this.props.todoVendeur}*/ value={this.props.todoVendeur}>
                        <option value="">Sélectionner le conseiller service</option>
                        {this.getAllUsers().map((utilisateur, i) => {
                            return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              : null}
            </div>
        </div>

      </div>
    );
  }
}

export default FormToDoCreateAtelierAutres;
