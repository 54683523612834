import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import moment from 'moment'

import Select from 'react-select';
import 'react-select/dist/react-select.css';

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class FormAffectation extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      operateurs: [],
      peoples: '',
      nbJour: 10,
      saveInProgress: false,
    }
  }

  componentDidMount() {
    this.fetchOperateurs();
  }

  fetchOperateurs() {
    fetch(localStorage.ClientApiURL + '/Utilisateurs?EstActif=true&LoadDetailDroitProfil=false&LoadPlaqueMarques=false&IDSite=' + this.props.campagne.Site.IDSite, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(json => {
          if(this.props.campagne.OrigineAction.Type == "Atelier"){
            var jsonFilter = lodash.filter(json, function(j) {return j.AccesLeadAPV; });
          }else{
            var jsonFilter = lodash.filter(json, function(j) {return j.TypeProfilLead == "BDC"; });
          }

          var list = lodash.map(jsonFilter, (j) => {
            return {
              label: j.Nom,
              value: j.IDUtilisateur,
            };
          })

          this.setState({
            operateurs: list,
          });
        })
        .catch((error) => {
          console.log(error)
        });
  }

  onChangeNbJour(event) {
    this.setState({
      nbJour: event.target.value,
    })
  }

  onChangePeoples(selectedOption) {
    console.log(selectedOption)
    this.setState({
      peoples: selectedOption,
    })
  }

  generateAppelSortant(event) {
    event.preventDefault();

    this.setState({
      saveInProgress: true,
    })

    var body = {
      peoples: lodash.map(this.state.peoples, (p) => { return p.value }),
      nbJour: this.state.nbJour,
    }

    body = JSON.stringify(body);

    console.log(body);

    fetch(localStorage.ClientApiURL + '/campagnes/'+this.props.campagne.IDCampagne+'/rapports/generate' , {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          browserHistory.push('/campagnes');
        })
        .catch((error) => {
          console.log(error)
        });
  }

  render() {
    console.log(this.state.peoples)
    return (
        <div className='row' style={this.props.style}>

          <div className='col-md-12'>
            <h3>Appels sortants</h3>

            <br />

            <form onSubmit={this.generateAppelSortant.bind(this)}>
              <div className='row' style={this.props.style}>

                <div className='clearfix'>
                  <div className='col-md-6'>
                    <div className="form-group">
                      <label htmlFor="peoples" className="col-sm-3 control-label">Opérateurs</label>
                      <div className="col-sm-4">
                        <Select
                            closeOnSelect={false}
                            multi
                            onChange={this.onChangePeoples.bind(this)}
                            options={this.state.operateurs}
                            value={this.state.peoples}
                            placeholder='Sélectionner'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className="form-group">
                      <label htmlFor="nbJour" className="col-sm-3 control-label">Nombre d'appel par jour et par opérateur</label>
                      <div className="col-sm-3">
                        <input type="text" className="form-control" name="nbJour" value={this.state.nbJour} onChange={this.onChangeNbJour.bind(this)} required={true} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 text-center'>
                  <div className="form-group" style={{ marginTop: '15px' }}>
                    <button className='btn btn-default' disabled={this.state.saveInProgress || (!this.state.peoples || !this.state.peoples.length)}>
                      {this.state.saveInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Générer les appels sortants
                    </button>
                  </div>
                </div>

              </div>
            </form>

          </div>

        </div>
    );
  }
}

export default FormAffectation;
