import React, { Component } from 'react';

import { Link } from 'react-router';
import moment from 'moment';

var lodash = require('lodash')


class FiltreRapports extends Component {

  constructor(props) {
    super(props);
    moment.locale('fr');
  }

  render() {
      if (!this.props.rapports) {
        return null;
      }

      var rapports = this.props.rapports;
      rapports.sort(function(a, b) {
        if (moment(a.InfoCreation.DateH) > moment(b.InfoCreation.DateH))
          return -1;
        return 1;
      });

      return(
        <div className='form-group form-stock'>
          <label className='control-label' style={{ marginLeft: '10px' }}>Filtre : </label>
          <select className='form-control' value={this.props.filter} style={{ width: '250px', display: 'inline-block', marginLeft: '10px' }} onChange={(e) => this.props.onChange(e.target.value)}>
            <option value='0'>Aucun</option>
            {this.props.affaires.length ? this.props.affaires.map((a) => {
              return <option key={a.IDAffaire} value={a.IDAffaire}>Lead {a.Type ? a.Type : ''} du {moment(a.DateDebut).format('DD/MM/YYYY')}</option>
            }) : null}

            {lodash.filter(rapports, (r) => {
                return r.EstAtelier;
              }).length ?
              <option value='atelier'>Rapports Atelier</option>
            : null}

            {lodash.filter(rapports, (r) => {
              console.log(r)
                return r.EstAppelSortant;
              }).length ?
              <option value='appelSortant'>Rapports Appel Sortant</option>
            : null}
          </select>
        </div>
      );
  }

}

export default FiltreRapports;
