import React, { Component } from 'react';

import moment from 'moment'
import Loader from '../Components/Loader';
import Globals from '../Globals';

import Tooltip from "rc-tooltip";

class VehiculeHistoriqueAtelier extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      vehicule: null,
      historique: null,
    }
  }

  componentDidMount() {
    this.fetchVehicule()
    this.fetchVehiculeAtelier()
  }

  fetchVehicule() {
    fetch(localStorage.ClientApiURL + '/Vehicules/' + this.props.IDVehicule, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        vehicule: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchVehiculeAtelier() {
    fetch(localStorage.ClientApiURL + '/Vehicules/' + this.props.IDVehicule+'/historiquesateliers', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      json.sort(function(a, b) {
        if (moment(a.Date) > moment(b.Date))
          return -1;
        return 1;
      });

      this.setState({
        historique: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.vehicule || !this.state.historique) {
      return <Loader />;
    }

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <h3>Véhicule</h3>
            {this.state.vehicule.Marque} {this.state.vehicule.Modele} {this.state.vehicule.Immatriculation} {this.state.vehicule.VIN} {this.state.vehicule.Kilometrage}
          </div>
        </div>

        <br/>

        <div className='row'>
          <div className='col-md-12'>
            <h3>Historique Atelier</h3>

            <table className='table table-eseller-lead table-striped table-hover table-condensed'>
              <thead>
                <tr style={{ backgroundColor: '#9A0059' }}>
                  <th>Date</th>
                  <th>Véhicule</th>
                  <th>Immatriculation</th>
                  <th>Km</th>
                  <th>OR</th>
                  <th>Travaux</th>
                  <th>Type</th>
                  <th>Pièces</th>
                  <th>MO</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.historique.map((atelier, i) => {
                  return <tr key={i}>
                    <td>{moment(atelier.Date).format('DD/MM/Y')}</td>
                    <td>{atelier.Vehicule}</td>
                    <td>{atelier.Immatriculation}</td>
                    <td>{parseInt(atelier.Km, 10).toLocaleString("fr-FR")}</td>
                    <td>{atelier.NumOR}</td>
                    <td style={{ whiteSpace: 'pre-line' }}>{atelier.Travaux.replace(/<br ?\/?>/g, '\n')}</td>
                    <td className='text-center'>
                      <Tooltip trigger={['hover']} overlay={<div>{atelier.Type}</div>}>
                        <span>{atelier.Type ? atelier.Type.charAt(0).toUpperCase() : ''}</span>
                      </Tooltip>
                    </td>
                    <td className='text-right'>{atelier.TotalPiece.toLocaleString('fr-FR')}€</td>
                    <td className='text-right'>{atelier.TotalMO.toLocaleString('fr-FR')}€</td>
                    <td className='text-right'>{Math.round(atelier.TotalPiece+atelier.TotalMO)}€</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default VehiculeHistoriqueAtelier;
