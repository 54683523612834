import Globals from '../Globals';

const LibAffaire = {

  /**
   * Met à jour une affaire. Seulement les colonnes gérées par la méthode de l'API PATCH /affaires/{id} 
   * Pas de changement de Statut de cette façon
   * @param affaire Objet affaire tel que retourné par l'API
   * @return bool indiquant si l'API a retrounée une erreur ou pas (voir console)
   */
  async update(affaire) {
    try {
      const response = await fetch(localStorage.ClientApiURL + '/affaires/' + affaire.IDAffaire, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
        body: JSON.stringify(affaire)
      });
      if (response.ok) {
        return true;
      }
      else {
        const error = await response.json();
        console.log(error);
        return false;
      }
    }
    catch (error) {
      console.log(error);
      return false;
    }
  },

  /**
   * Récupère les types de financements existants
   */
  async getTypesFinancement() {
    var typesFinancement = [];

    try {
      const response = await fetch(localStorage.ClientApiURL + '/affaireTypesFinancement', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      });
      if (response.ok) {
        typesFinancement = await response.json();
        //console.log(typesFinancement);
      }
      else {
        const error = await response.json();
        console.log(error);
      }
    }
    catch (error) {
      console.log(error);
    }

    return typesFinancement;
  },

  getUrlOngletConstructeur(codeAffaire, commentaire) {

    let url = ""

    if (!codeAffaire.startsWith("HMF")) {
      if (codeAffaire.includes("|OP-")) {
        const items = codeAffaire.split("|OP-")
        return `https://www.customer360psa.com/s/opportunity/${items[1]}`
      }
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const match = commentaire.match(urlRegex);

    if (match) {
      url = match[match.length -1];
    }

    return url

  },

  showOngletConstructeur(codeAffaire, commentaire) {
    if (commentaire === "") return false

    return !!codeAffaire && !!commentaire && commentaire.includes("http")
  },
  getImageConstructeur(codeAffaire, comment) {
    if (codeAffaire.startsWith("HMF")) {
      return "HYMTF_BIG.svg"
    } else {
      return "STLA_BIG.svg"
    }
  }

}

export default LibAffaire;