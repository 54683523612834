import React, {Component} from 'react';
import { browserHistory } from 'react-router'

import Globals from '../Globals';
import { Link } from 'react-router';

import moment from 'moment'

import Loader from '../Components/Loader';
import BadgeDiffDay from '../Components/BadgeDiffDay';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowComment from '../Components/TableRowComment';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import WelcomeProMail from '../Components/WelcomeProMail';

import SweetAlert from 'react-bootstrap-sweetalert';

import FormRapportAccessoires from './FormRapportAccessoires';
import { MobileOnlyView, CustomView, isMobileOnly, isMobile } from 'react-device-detect';
var lodash = require('lodash')

class RapportAccessoires extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      rapport: null,
      rapports: null,
      prospect: null,
      affaire: null,
      affaireChange: false,

      checked: [],

      fetchCreateInProgress: false,
      successCreate: false,
      errorCreate: false,
      errorCreateMessage: '',

      todoEtape: 7,
      todoEtapeText: 'En cours',
      todoNiveauInteret: 4,

      formValues: {},
      transfertTo: 0,
    }
  }

  componentDidMount() {
    this.fetchRapport();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.IDLead !== this.props.IDLead) {
      this.fetchRapport();
    }
  }

  fetchRapport() {
    var IDLead = this.props.IDLead ? this.props.IDLead : this.props.params.IDLead;
    
    fetch(localStorage.ClientApiURL + '/Leads/' + IDLead, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      console.log(json)
      
      this.setState({
        rapport: json,
        todoEtape: json.ActionAFaire_TODO && json.ActionAFaire_TODO.Etape ? json.ActionAFaire_TODO.Etape.IDEtape : 7,
        todoEtapeText: json.ActionAFaire_TODO && json.ActionAFaire_TODO.Etape ? json.ActionAFaire_TODO.Etape.Description : 'En cours',
      }, () => {
        this.fetchProspect();
        this.fetchAffaire();
        this.fetchRapports();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchProspect() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.rapport.Prospect.IDProspect, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        prospect: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchRapports() {
    if (!this.state.rapport.Affaire.IDAffaire) {
      this.setState({
        rapports: [this.state.rapport]
      });
      return;
    }

    fetch(localStorage.ClientApiURL + '/affaires/' + this.state.rapport.Affaire.IDAffaire + '/rapports', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        rapports: json,
      });

    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchAffaire() {
    if (this.state.rapport.Affaire.IDAffaire) {
      fetch(localStorage.ClientApiURL + '/affaires/' + this.state.rapport.Affaire.IDAffaire, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        this.setState({
          affaire: json,
        });
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }

  leadIsSuivi(){
    //return this.state.rapport && this.state.rapport.SuiviPar && this.state.rapport.SuiviPar.IDUtilisateur !== 0;
    return this.state.rapport && this.state.rapport.InfoCreation.Utilisateur.IDUtilisateur;
  }

  canSubmit(){
    if(this.state.formValues.nonJoignable === true)
      return true;

    return this.state.formValues.devisEnvoye === false 
      || (this.state.formValues.devisEnvoye === true && this.state.formValues.poseSouhaitee === false)
      || (this.state.formValues.devisEnvoye === true && this.state.formValues.poseSouhaitee === true && this.state.formValues.rdvPris !== null);
  }

  getNewAffaireStatut(){
    if(this.state.formValues.nonJoignable === true)
      return null;

    if(this.state.formValues.devisEnvoye === true)
      return "Gagnee";
    else 
      return "Perdue";
  }

  generateComment(){
    var comment = "";
    if(this.state.formValues.nonJoignable === true)
      comment += "Non joignable le "+moment().format("DD/MM/YYYY HH:mm")+"\n";
    else {
      comment += "Devis envoyé : "+(this.state.formValues.devisEnvoye ? "Oui" : "Non")+"\n";
      comment += "Pose souhaitée : "+(this.state.formValues.poseSouhaitee ? "Oui" : "Non")+"\n";
      comment += "RDV Pris : "+(this.state.formValues.rdvPris ? "Oui" : "Non")+"\n";
      if(this.state.formValues.devisEnvoye && this.state.formValues.rdvPris && this.state.formValues.dateRdv){
        if(this.state.formValues.dateRdv.hour() > 0)
          comment += "Date RDV : "+this.state.formValues.dateRdv.format("DD/MM/YYYY HH:mm")+"\n";
        else
          comment += "Date RDV : "+this.state.formValues.dateRdv.format("DD/MM/YYYY")+"\n";
      }
    }
    comment += "Commentaire : "+this.state.formValues.commentaire;

    return comment;
  }

  updateAffaire() {
    var body = this.state.affaire;

    body = JSON.stringify(body);

    fetch(localStorage.ClientApiURL + '/Affaires/' + this.state.affaire.IDAffaire, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }

      return response;
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.fetchCreateInProgress)
      return false;

    this.setState({
      fetchCreateInProgress: true,
    });

    this.createRapport();

    localStorage.refreshHeaderCounters = "1";
  }

  createRapport(){
    var newAffaireStatus = this.getNewAffaireStatut();
    if(newAffaireStatus !== null){
      var affaire = this.state.affaire;
      affaire.Statut = newAffaireStatus;
    }

    var date_relance = null;
    if(this.state.formValues.nonJoignable === true){
      if(this.state.formValues.dateRelance)
        date_relance = this.state.formValues.dateRelance;
      else 
        date_relance = moment(new Date()).add(1,'days');
    }
    else 
      date_relance = this.state.formValues.dateRdv;

    let body = {
      IDLead: this.state.rapport.IDLead,
      "ARedistribuer": this.state.formValues.nonJoignable ? 1 : 0,
      "Affaire": {
        "IDAffaire": this.state.rapport.Affaire.IDAffaire,
      },
      "Campagne" : (this.state.rapport.Campagne ? { "IDCampagne":  this.state.rapport.Campagne.IDCampagne, } : null),
      Site: {
        IDSite: this.state.rapport.Site.IDSite,
      },
      Prospect: {
        IDProspect: this.state.rapport.Prospect.IDProspect,
      },
      "SuiviPar": {
        "IDUtilisateur": localStorage.user_IDUtilisateur,
      },
      ActionFaite_DO: {
        "DateHLead": moment().format(),
        "TypeContact": {
          "Description": "eSeller Lead",
        },
        CanalOrigine: this.state.rapport.ActionFaite_DO.CanalOrigine,
        Commentaire: this.generateComment(),
        "OrigineAction": {
          "IDOrigineAction": this.state.rapport.Affaire.OrigineAction ? this.state.rapport.Affaire.OrigineAction.IDOrigineAction : null,
        },
      },
      "ActionAFaire_TODO": {
        "Etape": null,
        "ActionAFaire": {
          "Description": this.state.todoEtapeText,
        },
        "NatureActionAFaire": "Téléphone",
        "NiveauInteret": {
          "IDNiveauInteret": this.state.todoNiveauInteret,
        },
        "EstDateLimite": true,
        "DateHRelance": (newAffaireStatus !== null) ? null : date_relance.format(),
        "DateHFinRelance": null,
        "DateHProchaineEtape": (newAffaireStatus !== null) ? null : date_relance.format(),
        "Etat": (newAffaireStatus === null) ? "EnCours" : "Termine",
        "Cloturer": null
      },
      "IDLeadsATerminer": this.state.checked.length ? this.state.checked : null,
    };

    if (this.state.todoEtapeText) {
      body.ActionAFaire_TODO.Etape = {
        "IDEtape": this.state.todoEtape,
        "Description": this.state.todoEtapeText
      };
    }

    if (newAffaireStatus === "Perdue") {
      body.ActionAFaire_TODO.Cloturer = {
        "TypeCloture": 'AffairePerdue',
        "AffaireRaisonStop": 'Autre',
      }
    }
    else if(newAffaireStatus === "Gagnee"){
      body.ActionAFaire_TODO.Cloturer = {
        "TypeCloture": 'AffaireGagnee',
        "AffaireRaisonStop": 'Autre',
      }      
    }

    let method = 'traiter_lead';

    fetch(localStorage.ClientApiURL + '/Leads/' + method, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify(body),
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }
      else 
        return response.json();
    })
    .then(json => {

      if(newAffaireStatus === "Gagnee"){
        affaire.Statut = "Gagnee";
        this.setState({
          affaire: affaire
        }, () => {
          this.updateAffaire();
        });
      }

      this.setState({
        fetchCreateInProgress: false,
        successCreate: true,
        errorCreate: false,
        errorCreateMessage: '',
      });
      return;
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  transferRapport(){
    let body = {
      IDLead: this.state.rapport.IDLead,
      "ARedistribuer": 0,
      "Affaire": {
        "IDAffaire": this.state.rapport.Affaire.IDAffaire,
      },
      "Campagne" : (this.state.rapport.Campagne ? { "IDCampagne":  this.state.rapport.Campagne.IDCampagne, } : null),
      Site: {
        IDSite: this.state.rapport.Site.IDSite,
      },
      Prospect: {
        IDProspect: this.state.rapport.Prospect.IDProspect,
      },
      "SuiviPar": {
        "IDUtilisateur": 0,
      },
      ActionFaite_DO: {
        "DateHLead": moment().format(),
        "TypeContact": {
          "Description": "eSeller Lead",
        },
        CanalOrigine: this.state.rapport.ActionFaite_DO.CanalOrigine,
        Commentaire: this.state.rapport.Affaire.Description,
        "OrigineAction": {
          "IDOrigineAction": this.state.rapport.Affaire.OrigineAction ? this.state.rapport.Affaire.OrigineAction.IDOrigineAction : null,
        },
      },
      "ActionAFaire_TODO": {
        "Etape": null,
        "ActionAFaire": {
          "Description": this.state.todoEtapeText,
        },
        "NatureActionAFaire": "Téléphone",
        "NiveauInteret": {
          "IDNiveauInteret": this.state.todoNiveauInteret,
        },
        "EstDateLimite": true,
        "DateHRelance": this.state.rapport.ActionAFaire_TODO.DateHRelance,
        "DateHFinRelance": null,
        "DateHProchaineEtape": this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape,
        "Etat": "EnCours",
        "Cloturer": null
      },
      "IDLeadsATerminer": this.state.checked.length ? this.state.checked : null,
    };

    if (this.state.todoEtapeText) {
      body.ActionAFaire_TODO.Etape = {
        "IDEtape": this.state.todoEtape,
        "Description": this.state.todoEtapeText
      };
    }

    fetch(localStorage.ClientApiURL + '/leads/traiter_lead', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify(body),
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }

      this.setState({
        fetchCreateInProgress: false,
        successCreate: true,
        errorCreate: false,
        errorCreateMessage: '',
      });
    
      localStorage.refreshHeaderCounters = "1";
      return response;
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  hideAlert() {
    this.setState({
      errorCreate: false,
      errorCreateMessage: '',
    });
  }

  propageSuccess() {
    if(this.props.reloadLeads)
      this.props.reloadLeads();
    else 
      browserHistory.push('/prospect/' + this.state.prospect.IDProspect);
  }

  isChecked(IDLead) {

    if (IDLead === this.props.IDLead || IDLead === this.props.params.IDLead)
      return true;

    if (this.state.checked.indexOf(IDLead) >= 0) {
      return true;
    }

    return false;
  }

  itemToggle(IDLead, checked) {
    var items = this.state.checked;

    if (checked) {
      items.push(IDLead)
    }
    else {
      items = lodash.without(this.state.checked, IDLead)
    }

    this.setState({
      checked: items
    });
  }

  getTypeDemande() {
    return "Demande de devis";
  }

  poseSouhaitee(){
    if(this.state.affaire.Description && this.state.affaire.Description.match(/Pose souhaitée : Oui/))
      return true;
    else 
      return false;
  }

  onChangeFormValues(formValues){
    this.setState({
      formValues: formValues
    }, /*() => console.log(this.state.formValues)*/);
  }
  
  onChangeTransfert(IDUtilisateur){
    this.setState({
      transfertTo: IDUtilisateur
    })
  }

  render() {

    if (!this.state.rapport || !this.state.affaire || !this.state.prospect) {
      return <div className='container-fluid'><Loader style={{ marginTop: 50 }} /></div>
    }

    return (
      <div className='container-fluid rapport-atelier'>

        {this.state.successCreate ?
          <SweetAlert success title='' onConfirm={() => this.propageSuccess()}>
            Traitement du lead avec succès !
          </SweetAlert>
        : null}

        {this.state.errorCreate ?
          <SweetAlert error title="Une erreur s'est produite" onConfirm={() => this.hideAlert()}>
            {this.state.errorCreateMessage}
          </SweetAlert>
        : null}

        <div className='row'>

          <div className='col-md-12'>
            <h2 className='title'><img src="/img/RAPPEL DU LEAD.svg" role='presentation'/> Rappel du lead</h2>
          </div>

          <div className='col-md-12' style={{ paddingLeft: '0px', paddingRight: '0px' }}>

{/************************* Tél View **************************/}
            <MobileOnlyView>
              
              <div className="row" style={{marginTop:"15px", marginBottom:'15px'}}>
                <div className='col-xs-12'>
                  <strong>{this.state.affaire ? ((this.state.affaire.OrigineAction) ? this.state.affaire.Type : null) : null}</strong>
                </div>
                <div className='col-xs-12'>
                  <strong>Date :</strong> {moment(this.state.rapport.InfoCreation.DateH).format('DD/MM/Y')}
                </div>
                <div className='col-xs-12'>
                  <strong>Site :</strong> {this.state.rapport.Site.Code}
                </div>
                <div className='col-xs-12'>
                  <strong>Origine :</strong> {this.state.rapport.ActionFaite_DO.OrigineAction ? this.state.rapport.ActionFaite_DO.OrigineAction.Famille + " / "+ this.state.rapport.ActionFaite_DO.OrigineAction.SousFamille + " / " + this.state.rapport.ActionFaite_DO.OrigineAction.Origine: null}
                </div>
                <div className='col-xs-12'>
                  <strong>Relance :</strong>&nbsp;
                  <TableRowDateRelance date={this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                  {this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape ?
                    <BadgeDiffDay date={this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                  : null}
                </div>
              </div>

              {this.state.rapports && this.state.rapports.length ?
                  <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Site</th>
                        <th>Suivi</th>
                        <th>Date</th>
                        <th>Origine</th>
                        <th>Campagne</th>
                        <th>Type contact</th>
                        <th>Relance</th>
                        <th>Action à faire</th>
                        <th className='text-center'>Commentaire</th>
                        <th className='text-center'>Etat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rapports.map((rapport, j) => {
                        return <tr key={j}>
                          <td className='text-center'>
                            {(rapport.ActionAFaire_TODO.Etat !== "Termine") ?
                            <div className="checkbox-form active">
                              <input type="checkbox" name={"lead_"+rapport.IDLead} id={"lead_"+rapport.IDLead} checked={this.isChecked(rapport.IDLead)} disabled={(rapport.IDLead === this.state.rapport.IDLead)}   onClick={this.itemToggle.bind(this, rapport.IDLead, !this.isChecked(rapport.IDLead))}/>
                              <label className="label-box isMobile" htmlFor={"lead_"+rapport.IDLead}></label>
                            </div>
                            : null}
                          </td>
                          <td>{rapport.Site.Code}</td>
                          <td>
                            {!rapport.SuiviPar && !rapport.InfoCreation.Utilisateur.EstPasserelle ?
                              <PastilleVendeur utilisateur={rapport.InfoCreation.Utilisateur} />
                            :
                              <PastilleVendeur utilisateur={rapport.SuiviPar} />
                            }
                          </td>
                          <td>{moment(rapport.InfoCreation.DateH).format('DD/MM/Y')}</td>
                          <td>
                            {rapport.ActionFaite_DO.OrigineAction ? rapport.ActionFaite_DO.OrigineAction.Origine : null}
                          </td>
                          <td>{rapport.Campagne ? rapport.Campagne.Nom : null}</td>
                          <td>{rapport.ActionFaite_DO.TypeContact.Description}</td>
                          <td>
                            <TableRowDateRelance date={rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                          </td>
                          <td>{rapport.ActionAFaire_TODO.ActionAFaire ? rapport.ActionAFaire_TODO.ActionAFaire.Description : null}</td>
                          <td className='text-center'>
                            <TableRowComment comment={rapport.ActionFaite_DO.Commentaire} />
                            <WelcomeProMail rapport={rapport} />
                          </td>
                          <td className='text-center'>
                            {rapport.ActionAFaire_TODO.Etat === "Termine" ? <img src="/img/TABLER ETAT.svg" role='presentation'/> :
                              <Link to={'/rapportAccessoires/'+rapport.IDLead}>
                                A traiter
                              </Link>
                            }
                          </td>
                        </tr>
                      })}
                  </tbody>
                </table>
            : null}
            </MobileOnlyView>

{/************************ Desktop/Tablet View ***********************/}
            <CustomView condition={!isMobileOnly}>
              <div className="panel-group" id="accordeon">
                <div className='panel panel-default'>
                  <div className="panel-heading" role="tab" id="rappelsHeading">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordeon" href="#collapseRappels" aria-expanded="true" aria-controls="collapseRappels">
                        <div className="table-collapse">
                          <div className='col col-sm-1 col-md-1 col-lg-1'>
                            <strong>{this.state.affaire ? ((this.state.affaire.OrigineAction) ? this.state.affaire.Type : null) : null}</strong>
                          </div>
                          <div className='col col-sm-3 col-md-3 col-lg-3'>
                            <strong>Date :</strong> {moment(this.state.rapport.InfoCreation.DateH).format('DD/MM/Y')}
                          </div>
                          <div className='col col-sm-2 col-md-2 col-lg-2'>
                            <strong>Site :</strong> {this.state.rapport.Site.Code}
                          </div>
                          <div className='col col-sm-3 col-md-3 col-lg-3'>
                            <strong>Origine :</strong> {this.state.rapport.ActionFaite_DO.OrigineAction ? this.state.rapport.ActionFaite_DO.OrigineAction.Famille + " / "+ this.state.rapport.ActionFaite_DO.OrigineAction.SousFamille + " / " + this.state.rapport.ActionFaite_DO.OrigineAction.Origine: null}
                          </div>
                          <div className='col col-sm-3 col-md-3 col-lg-3' style={{ paddingLeft: 0, textAlign:'right' }}>
                            <strong>Relance :</strong>&nbsp;

                            <TableRowDateRelance date={this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                            <br/>
                            {this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape ?
                              <BadgeDiffDay date={this.state.rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                            : null}
                          </div>
                        </div>
                      </a>
                    </h4>
                  </div>

                  <div id="collapseRappels" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="rappelsHeading" aria-expanded="true">
                    {this.state.rapports && this.state.rapports.length ?
                        <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Site</th>
                              <th>Suivi</th>
                              <th>Date</th>
                              <th>Origine</th>
                              <th>Campagne</th>
                              <th>Type contact</th>
                              <th>Relance</th>
                              <th>Action à faire</th>
                              <th className='text-center'>Commentaire</th>
                              <th className='text-center'>Etat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.rapports.map((rapport, j) => {
                              return <tr key={j}>
                                <td className='text-center'>
                                  {(rapport.ActionAFaire_TODO.Etat !== "Termine") ?
                                  <div className="checkbox-form active">
                                    <input type="checkbox" name={"lead_"+rapport.IDLead} id={"lead_"+rapport.IDLead} checked={this.isChecked(rapport.IDLead)} disabled={(rapport.IDLead === this.state.rapport.IDLead)}   onClick={this.itemToggle.bind(this, rapport.IDLead, !this.isChecked(rapport.IDLead))}/>
                                    <label className={"label-box "+ (isMobile ? "isMobile" : "")} htmlFor={"lead_"+rapport.IDLead}></label>
                                  </div>
                                  : null}
                                </td>
                                <td>{rapport.Site.Code}</td>
                                <td>
                                  {!rapport.SuiviPar && !rapport.InfoCreation.Utilisateur.EstPasserelle ?
                                    <PastilleVendeur utilisateur={rapport.InfoCreation.Utilisateur} />
                                  :
                                    <PastilleVendeur utilisateur={rapport.SuiviPar} />
                                  }
                                </td>
                                <td>{moment(rapport.InfoCreation.DateH).format('DD/MM/Y')}</td>
                                <td>
                                  {rapport.ActionFaite_DO.OrigineAction ? rapport.ActionFaite_DO.OrigineAction.Origine : null}
                                </td>
                                <td>{rapport.Campagne ? rapport.Campagne.Nom : null}</td>
                                <td>{rapport.ActionFaite_DO.TypeContact.Description}</td>
                                <td>
                                  <TableRowDateRelance date={rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                                </td>
                                <td>{rapport.ActionAFaire_TODO.ActionAFaire ? rapport.ActionAFaire_TODO.ActionAFaire.Description : null}</td>
                                <td className='text-center'>
                                  <TableRowComment comment={rapport.ActionFaite_DO.Commentaire} />
                                  <WelcomeProMail rapport={rapport} />
                                </td>
                                <td className='text-center'>
                                  {rapport.ActionAFaire_TODO.Etat === "Termine" ? <img src="/img/TABLER ETAT.svg" role='presentation'/> :
                                    <Link to={'/rapportAccessoires/'+rapport.IDLead}>
                                      A traiter
                                    </Link>
                                  }
                                </td>
                              </tr>
                            })}
                        </tbody>
                      </table>
                  : null}
                  </div>
                </div>            
              </div>
            </CustomView>
          </div>

          <div className='col-lg-6 col-md-12 cadre'>
            <h2><img src="/img/PROSPECT.svg" role='presentation' /> Prospect</h2>

            <div className="rapport-details">
              <Link to={'/prospect/' + this.state.prospect.IDProspect}>
                {this.state.prospect.Nom}, {this.state.prospect.Prenom}, {this.state.prospect.Adresse}, {this.state.prospect.CodePostal}, {this.state.prospect.Ville}, {this.state.prospect.Telephones ? this.state.prospect.Telephones.map((phone, i) => {return phone.Numero+' '}) : null}, {this.state.prospect.Mail}
              </Link>
            </div>
          </div>

          {this.state.affaire && this.state.affaire.Description ?
              <div className='col-lg-6 col-md-6 col-xs-12 cadre'>
                <h2><i className="fa fa-square"></i> Affaire {this.state.affaire ? this.state.affaire.Type : null}</h2>
                <div className='row'>
                  <div className="col-md-10">
                    <div className="form-group" style={{ marginLeft: '2px', marginRight: 0, marginBottom:0 }}>
                      <textarea className="form-control" style={{ height: '150px' }} value={this.state.affaire ? this.state.affaire.Description.replace(/(?:\\[rn])+/g, '\n') : null} onChange={() => {return;}} />
                    </div>
                  </div>
                </div>
              </div>
          :                   
            <div className='col-md-6 col-sm-12'></div>
          }

          <div className='col-lg-12 visible-lg' style={{marginTop:'15px'}}>
            <hr />
          </div>

          <div className="col-lg-6 col-md-6 col-xs-12 cadre">
            <form className="form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
              <FormRapportAccessoires poseSouhaitee={this.poseSouhaitee()} onChangeValue={(values) => this.onChangeFormValues(values)}/>
              <button type="submit" className="text-center btn btn-default submit-vn" disabled={!this.canSubmit()}>
                {this.state.fetchCreateInProgress ?
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                : null}
                Valider
              </button>
            </form>
          </div>
          {/*
          {this.leadIsATraiter() ? 
          <div className="col-lg-6 col-md-6 col-xs-12 cadre">
            <h2><img src="/img/PROCHAINE ACTION Copy 2.svg" role='presentation' /> Transférer</h2>
            <button type="button" className="text-center btn btn-default" name="transferer" onClick={() => this.transferRapport()}>
              Transférer aux conseillers
            </button>
          </div>
              :null}
          */}

          <div className="col-xs-12">
            <br/><br/>
          </div>
        </div>
      </div>
    );
  }
}

export default RapportAccessoires;
