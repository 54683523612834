import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { browserHistory, Link } from 'react-router';
import Loader from '../Components/Loader';
import Globals from '../Globals';
import moment from 'moment';
import InfobulleDashboard from './InfobulleDashboard';
import Dictionary from '../Dictionnary';
moment.locale('fr');
var lodash = require('lodash')
const colors = ["#FBB03B", "#7ac943", "#38a9e0", "#e50d70", "#ff7800", "#909da9", "#ec5858"]; 
const colorsStatut = ["#ec5858", "#7ac943", "#38a9e0"];
const colorsOffresCommandes = ["#db6762","#0fa4d8","#7a8d97","#f99f4e","#f37036"]; //Commandes, Offres, Essais, Contacts

class DashboardVNVO extends Component {

  constructor(props) {

    super(props);

    this.state = {
      dataBusiness: null,
      reportingMensuel: null,
      sites: [],
      societes: [],
      typesLeads: [],
      objectifsVendeurs: [],
      reloadingReportingMensuel: false,
      reloadingDataBusiness: false,

      moisReporting: (new Date()).getMonth()+1,
      anneeReporting: (new Date()).getFullYear(),
      shiftReporting: 0,
      IDSocieteReporting: 0,
      IDSiteReporting: 0,
      IDVendeurReporting: -1,
      IDFamilleReporting: 0,
      IDSousFamilleReporting: 0,
      IDOrigineReporting: 0,
      IDCatClientReporting: 0,
      IDEtapeReporting: 0,

      typeIndicateursPerformance: localStorage.IsVDL === 'true' ? 'VDL' : 'VN',
    }

  }

  componentDidMount() {
    var dashboardPrefs = JSON.parse(localStorage.dashboardPrefs);

    var IDSiteDefault = 0, IDSocieteDefault = 0;
    var sites = [];
    var societes = [];
    if(this.props.sites.length) {
      IDSiteDefault = this.props.sites[0].IDSite;
      IDSocieteDefault = this.props.sites[0].Plaque.IDPlaque;

      for(let i in this.props.sites){
        if(this.props.sites[i].EstFictif || !this.props.sites[i].Est_eSellerLeadActif)
          continue;

        sites.push(this.props.sites[i]);

        if(!societes[this.props.sites[i].Plaque.IDPlaque])
          societes[this.props.sites[i].Plaque.IDPlaque] = this.props.sites[i].Plaque;
      }

      societes = lodash.sortBy(lodash.compact(societes), 'Nom');
      sites = lodash.sortBy(sites, (site) => {return site.Plaque.Nom+' '+(site.Libelle ? site.Libelle : site.Nom)});
    }

    this.setState({
      IDSiteReporting: dashboardPrefs.IDSiteReporting ? dashboardPrefs.IDSiteReporting : IDSiteDefault,
      IDSocieteReporting: dashboardPrefs.IDSocieteReporting ? dashboardPrefs.IDSocieteReporting : IDSocieteDefault,
      sites: sites,
      societes: societes
    }, () => {
      this.fetchDataBusiness();
      this.fetchReportingMensuel();
    });
  }

  fetchDataBusiness(){
    var url = localStorage.ClientApiURL + '/dashboard/statsBusinessVNVO?';
    if(this.state.IDSocieteReporting > 0)url += "&IDSociete="+this.state.IDSocieteReporting;
    if(this.state.IDSiteReporting > 0)url += "&IDSite="+this.state.IDSiteReporting;
    if(this.state.IDVendeurReporting > -1)url += "&IDVendeur="+this.state.IDVendeurReporting;
    if(this.state.IDFamilleReporting > 0)url += "&IDFamilleOrigine="+this.state.IDFamilleReporting;
    if(this.state.IDSousFamilleReporting > 0)url += "&IDSousFamilleOrigine="+this.state.IDSousFamilleReporting;
    if(this.state.IDOrigineReporting > 0)url += "&IDOrigine="+this.state.IDOrigineReporting;
    if(this.state.IDCatClientReporting > 0)url += "&IDCatClient="+this.state.IDCatClientReporting;
    if(this.state.IDEtapeReporting > 0)url += "&IDEtape="+this.state.IDEtapeReporting;

    fetch(url , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        console.log(json)
        
        var leadsParFamille = {
          VN: {
            "id": "VN",
            "total": 0
          },
          VO: {
            "id": "VO",
            "total": 0,
          },
          VDL: {
            "id": "VDL",
            "total": 0
          }
        };
        for(let i in json.leadsParFamille){
            leadsParFamille[json.leadsParFamille[i].Type][json.leadsParFamille[i].Famille] = json.leadsParFamille[i].Nb
            leadsParFamille[json.leadsParFamille[i].Type].total += json.leadsParFamille[i].Nb
        }
        json.leadsParFamille = leadsParFamille;
        
        json.nbLeadsParType = {
          VN: 0,
          VO: 0,
          VDL: 0
        };
        /************* Leads par étape *************/
        var leadsParEtape = {
          VN: {
            "A qualifier": {id: "A qualifier", label:"A qualifier", value:0},
            "Découverte": {id: "Découverte", label:"Découverte", value:0},
            "Essai": {id: "Essai", label:"Essai", value:0},
            "Offre": {id: "Offre", label:"Offre", value:0},
            "Négociation": {id: "Négociation", label:"Négociation", value:0},
            "Commande": {id: "Commande", label:"Commande", value:0},
          },
          VO: {
            "A qualifier": {id: "A qualifier", label:"A qualifier", value:0},
            "Découverte": {id: "Découverte", label:"Découverte", value:0},
            "Essai": {id: "Essai", label:"Essai", value:0},
            "Offre": {id: "Offre", label:"Offre", value:0},
            "Négociation": {id: "Négociation", label:"Négociation", value:0},
            "Commande": {id: "Commande", label:"Commande", value:0},           
          },
          VDL: {
            "A qualifier": {id: "A qualifier", label:"A qualifier", value:0},
            "Découverte": {id: "Découverte", label:"Découverte", value:0},
            "Essai": {id: "Essai", label:"Essai", value:0},
            "Offre": {id: "Offre", label:"Offre", value:0},
            "Négociation": {id: "Négociation", label:"Négociation", value:0},
            "Commande": {id: "Commande", label:"Commande", value:0},           
          }
        }
        lodash.each(json.leadsParEtape, (data) => {
          if(!leadsParEtape[data.Type][data.Etape]){
            leadsParEtape[data.Type][data.Etape] = {
              id: data.Etape,
              label: data.Etape,
              value: data.Nb
            }
          }
          else {
            leadsParEtape[data.Type][data.Etape].value = data.Nb;
          }
          json.nbLeadsParType[data.Type] += data.Nb;
        })
        json.leadsParEtape = leadsParEtape;

        /************** Leads par statut ***************/
        var leadsParStatut = {
          VN: {
            "En retard": {id: "En retard", label:"En retard", value:0},
            "Du jour": {id: "Du jour", label:"Du jour", value:0},
            "A venir": {id: "A venir", label:"A venir", value:0},
          },
          VO: {
            "En retard": {id: "En retard", label:"En retard", value:0},
            "Du jour": {id: "Du jour", label:"Du jour", value:0},
            "A venir": {id: "A venir", label:"A venir", value:0},
          },
          VDL: {
            "En retard": {id: "En retard", label:"En retard", value:0},
            "Du jour": {id: "Du jour", label:"Du jour", value:0},
            "A venir": {id: "A venir", label:"A venir", value:0},
          },
        };

        lodash.each(json.leadsParStatut, (data) => {
          leadsParStatut[data.Type][data.Statut].value = data.Nb;
        });
        json.leadsParStatut = leadsParStatut;

        this.setState({
          dataBusiness: json,
          reloadingDataBusiness: false,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  fetchReportingMensuel(){
      var datesReporting = this.getDatesReporting();
      var dateDebut = datesReporting[0];
      var dateFin = datesReporting[1];

      var url = localStorage.ClientApiURL + '/dashboard/reportingMensuelVNVO?dateDebut='+dateDebut+'&dateFin='+dateFin;
      if(this.state.IDSocieteReporting > 0)url += "&IDSociete="+this.state.IDSocieteReporting;
      if(this.state.IDSiteReporting > 0)url += "&IDSite="+this.state.IDSiteReporting;
      if(this.state.IDVendeurReporting > -1)url += "&IDVendeur="+this.state.IDVendeurReporting;
      if(this.state.IDFamilleReporting > 0)url += "&IDFamilleOrigine="+this.state.IDFamilleReporting;
      if(this.state.IDSousFamilleReporting > 0)url += "&IDSousFamilleOrigine="+this.state.IDSousFamilleReporting;
      if(this.state.IDOrigineReporting > 0)url += "&IDOrigine="+this.state.IDOrigineReporting;
      if(this.state.IDCatClientReporting > 0)url += "&IDCatClient="+this.state.IDCatClientReporting;

      fetch(url , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        console.log(json)
        
        /********** Affaires reçues par type *********/
        var affairesRecuesParType = {
          VN:0,
          VO:0,
          VDL:0,
          Tous:0
        };
        for(let i in json.affairesRecuesParType){
          affairesRecuesParType[json.affairesRecuesParType[i].Type] += json.affairesRecuesParType[i].Nb;
          affairesRecuesParType['Tous'] += json.affairesRecuesParType[i].Nb;
        }
        json.affairesRecuesParType = affairesRecuesParType;

        /********** Affaires terminées ************/
        var affairesParStatut = {
          VN: {
            "Gagnée": 0,
            "Perdue": 0,
            "En cours": 0,
          },
          VO: {
            "Gagnée": 0,
            "Perdue": 0,
            "En cours": 0,
          },
          VDL: {
            "Gagnée": 0,
            "Perdue": 0,
            "En cours": 0,
          },

        };
        for(let i in json.affairesParStatut){
          affairesParStatut[json.affairesParStatut[i].Type][json.affairesParStatut[i].Statut] += json.affairesParStatut[i].Nb;
        }
        json.affairesParStatut = affairesParStatut;

        /************ Affaires reportées ***********/
        var affairesReportees = {
          VN: 0,
          VO: 0,
          VDL:0,
        }
        for(let i in json.affairesReportees){
          affairesReportees[json.affairesReportees[i].Type] = json.affairesReportees[i].Nb;
          //On soustrait aux affaires en cours le nombre d'affaires reportées car les affaires reportées sont également en cours.
          //Ainsi sur le dashboard on a bien affaires en cours + gagnées + perdues + reportées = reçues. (si écart voir s'il n'y a pas d'autres status d'affaires utilisés (à validéer, fusionnée...))
          json.affairesParStatut[json.affairesReportees[i].Type]["En cours"] -= json.affairesReportees[i].Nb;
        }
        json.affairesReportees = affairesReportees;

        /********* Délai de traitement **********/
        var delaiTraitementMoyen = {
          VN: null,
          VO: null,
          VDL: null,
          Tous: null,
        }
        for(let i in json.delaiTraitementMoyen){
          delaiTraitementMoyen[json.delaiTraitementMoyen[i].Type] = json.delaiTraitementMoyen[i].Delai;
        }
        json.delaiTraitementMoyen = delaiTraitementMoyen

        /************ Offres et commandes leads transformés *************/
        var offresEtCommandesLeads = {
          VN: {Offres: 0, Commandes: 0},
          VO: {Offres: 0, Commandes: 0},
          VDL: {Offres: 0, Commandes: 0},
          Tous: {Offres: 0, Commandes: 0},
        }
        for(let i in json.offresEtCommandesLeads){
          var type = json.offresEtCommandesLeads[i].Type;
          if(localStorage.IsVDL === 'true')
            type = "VDL"

          offresEtCommandesLeads[type][json.offresEtCommandesLeads[i].Etape] = json.offresEtCommandesLeads[i].Nb;
          offresEtCommandesLeads['Tous'][json.offresEtCommandesLeads[i].Etape] += json.offresEtCommandesLeads[i].Nb;
        }
        json.offresEtCommandesLeads = offresEtCommandesLeads;

        /************* Taux de transformation *************/
        if(localStorage.ReportingTransformationCyclevente === "true"){
          json.tauxTransformation = {
            VN: affairesRecuesParType.VN > 0 ? Math.round((json.affairesParStatut.VN["Gagnée"]/affairesRecuesParType.VN * 1000))/10 : 0,
            VO: affairesRecuesParType.VO > 0 ? Math.round((json.affairesParStatut.VO["Gagnée"]/affairesRecuesParType.VO * 1000))/10 : 0,
            VDL: affairesRecuesParType.VDL > 0 ? Math.round((json.affairesParStatut.VDL["Gagnée"]/affairesRecuesParType.VDL * 1000))/10 : 0
          }
          if(json.typesLeads.length > 1){
            let totalAffairesRecues = affairesRecuesParType.VN + affairesRecuesParType.VO + affairesRecuesParType.VDL;
            if(totalAffairesRecues > 0)
              json.tauxTransformation.Tous = Math.round(((json.affairesParStatut.VN["Gagnée"]+json.affairesParStatut.VO["Gagnée"]+json.affairesParStatut.VDL["Gagnée"])/totalAffairesRecues * 1000))/10;
            else 
              json.tauxTransformation.Tous = 0
          }
        }
        else {
          json.tauxTransformation = {
            VN: affairesRecuesParType.VN > 0 ? Math.round((offresEtCommandesLeads.VN["Commandes"]/affairesRecuesParType.VN * 1000))/10 : 0,
            VO: affairesRecuesParType.VO > 0 ? Math.round((offresEtCommandesLeads.VO["Commandes"]/affairesRecuesParType.VO * 1000))/10 : 0,
            VDL: affairesRecuesParType.VDL > 0 ? Math.round((offresEtCommandesLeads.VDL["Commandes"]/affairesRecuesParType.VDL * 1000))/10 : 0
          }
          if(json.typesLeads.length > 1){
            let totalAffairesRecues = affairesRecuesParType.VN + affairesRecuesParType.VO + affairesRecuesParType.VDL;
            if(totalAffairesRecues > 0)
              json.tauxTransformation.Tous = Math.round(((offresEtCommandesLeads.VN["Commandes"]+offresEtCommandesLeads.VO["Commandes"]+offresEtCommandesLeads.VDL["Commandes"])/totalAffairesRecues * 1000))/10;
            else 
              json.tauxTransformation.Tous = 0
          }
        }

        /************* Offres et commandes créees sur la période sélectionnée ************/
        if(json.offresEtCommandes) {
          var offresEtCommandes = {
            Commandes: {
              VN: 0, VO: 0, VDL:0, Tous: 0
            },
            Offres: {
              VN: 0, VO: 0, VDL:0, Tous: 0
            },
            Essais: {
              VN: 0, VO: 0, VDL:0, Tous:0 
            },
            Leads: {
              VN: 0, VO: 0, VDL:0, Tous:0 
            },
            Contacts: {
              VN: 0, VO: 0, VDL:0, Tous:0
            }
            
          }
          for(let i in json.offresEtCommandes){
            var type = json.offresEtCommandes[i].Type;
            if(localStorage.IsVDL === 'true')
              type = "VDL"

            offresEtCommandes[json.offresEtCommandes[i].Etape][type] = json.offresEtCommandes[i].Nb;
            offresEtCommandes[json.offresEtCommandes[i].Etape]['Tous'] += json.offresEtCommandes[i].Nb;
          }

          if(localStorage.IsNissan === "true")
            delete offresEtCommandes["Leads"];

          json.offresEtCommandes = offresEtCommandes;
        }

        var objectifsVendeurs = json.objectifsVendeurs;
        if(objectifsVendeurs === undefined)
          objectifsVendeurs = [];
        delete json.objectifsVendeurs;

        this.setState({
          reportingMensuel: json,
          reloadingReportingMensuel: false,
          typesLeads: json.typesLeads,
          typeIndicateursPerformance: json.typesLeads.length > 1 ? 'Tous' : this.state.typeIndicateursPerformance,
          objectifsVendeurs: objectifsVendeurs
        }, () => {
          console.log(this.state.reportingMensuel);
          console.log(this.state.objectifsVendeurs);
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  /**
   * Retourne les dates du reporting "mensuel"
   * @return ["dateDebutReporting, "dateFinReporting"]
   */
  getDatesReporting() {
    if(this.state.shiftReporting < 0) { //Cumul année
      var dateDebut = moment().add(parseInt(this.state.shiftReporting) + 1, 'y').startOf('year').format('YYYY-MM-DD');
      var dateFin = moment().add(parseInt(this.state.shiftReporting) + 1, 'y').endOf('year').format('YYYY-MM-DD');
    }
    else {
      var date = moment(this.state.anneeReporting+'-'+this.state.moisReporting+'-01', 'YYYY-MM-DD');
      var dateDebut = date.startOf('month').format('YYYY-MM-DD');
      var dateFin = date.endOf('month').format('YYYY-MM-DD');
    }

    return [dateDebut, dateFin];
  }

  reloadData(){
    this.fetchDataBusiness();
    this.fetchReportingMensuel();
  }

  onChangeMoisReporting(event){
    let date = moment().startOf('month').subtract(event.target.value, 'month');
    this.setState({
      shiftReporting: event.target.value,
      moisReporting: date.month()+1,
      anneeReporting: date.year(),
      reloadingReportingMensuel: true,
    }, () => this.fetchReportingMensuel())
  }

  onChangeSiteReporting(event){
    var dashboardPrefs = JSON.parse(localStorage.dashboardPrefs);
    if(event.target.value > 0){
      dashboardPrefs.IDSiteReporting = event.target.value;

      var site = lodash.find(this.state.sites, (s) => {return s.IDSite == dashboardPrefs.IDSiteReporting});
      if(site)
        dashboardPrefs.IDSocieteReporting = site.Plaque.IDPlaque;
    }
    localStorage.dashboardPrefs = JSON.stringify(dashboardPrefs)

    this.setState({
      IDSiteReporting: event.target.value,
      IDSocieteReporting: site ? site.Plaque.IDPlaque : this.state.IDSocieteReporting,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeSocieteReporting(event){
    this.setState({
      IDSocieteReporting: event.target.value,
      IDSiteReporting: 0,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeVendeurReporting(event){
    this.setState({
      IDVendeurReporting: event.target.value,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeFamilleReporting(event){
    this.setState({
      IDFamilleReporting: event.target.value,
      IDSousFamilleReporting: 0,
      IDOrigineReporting: 0,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeSousFamilleReporting(event){
    this.setState({
      IDSousFamilleReporting: event.target.value,
      IDOrigineReporting: 0,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeOrigineReporting(event){
    this.setState({
      IDOrigineReporting: event.target.value,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeCatClientReporting(event){
    this.setState({
      IDCatClientReporting: event.target.value,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeEtapeReporting(event){
    this.setState({
      IDEtapeReporting: event.target.value,
      reloadingDataBusiness: true,
    }, () => this.fetchDataBusiness())
  }

  onChangeTypeIndicateursPerformance(event){
    this.setState({
      typeIndicateursPerformance: event.target.value
    })
  }

  formatDelai(delai){
    if(!delai)
      return "-";

    var jours = Math.floor(delai / 86400);
    delai = delai - 86400 * jours;
    var heures = Math.floor(delai / 3600);
    delai = delai - 3600 * heures;
    var minutes = Math.floor(delai / 60);
    delai = delai - 60 * minutes;
    var secondes = delai; 

    if(jours > 0)
      return jours+"j" + (heures > 0 ? " "+heures+"h" : "");
    if(heures > 0)
    {
      if(minutes > 0 && minutes < 10)minutes = "0"+minutes
      return heures+"h" + (minutes ? minutes : "");
    }
    if(minutes > 0)
      return <React.Fragment>{minutes} <span style={{fontSize:'1.7rem'}}> min</span></React.Fragment>;
    
    return <React.Fragment>{secondes} <span style={{fontSize:'1.7rem'}}> sec</span></React.Fragment>;
  }

  getFamilles() {
    var familles = [{value: "", label:"Toutes les familles"}];
    familles.push(...lodash.map(lodash.uniqBy(this.state.dataBusiness.origines, "IDFamille"), (o) => {return {"value": o.IDFamille, "label": o.Famille}}));
    return familles;
  }

  getSousFamilles() {
    var sousFamilles = [{value: "", label:"Toutes les sous-familles"}];

    if(this.state.IDFamilleReporting)
    {
      var origines = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDFamille == this.state.IDFamilleReporting);
      sousFamilles.push(...lodash.map(lodash.uniqBy(origines, "IDSousFamille"), (o) => {return {"value": o.IDSousFamille, "label": o.SousFamille}}));
    }

    return sousFamilles;
  }

  getOrigines() {
    var origines = [{value: "", label:"Toutes les origines"}];

    if(this.state.IDSousFamilleReporting)
    {
      var origines_ = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDFamille == this.state.IDFamilleReporting && o.IDSousFamille == this.state.IDSousFamilleReporting);
      origines.push(...lodash.map(origines_, (o) => {return {"value": o.IDOrigine, "label": o.Origine}}));
    }
  
    return origines;
  }

  getTitreLeadsParFamilles(){
    if(this.state.IDOrigineReporting)
    {
      let origine = lodash.find(this.state.dataBusiness.origines, o => o.IDOrigine == this.state.IDOrigineReporting);
      return `Leads "${origine.Famille}/${origine.SousFamille}/${origine.Origine}"`;
    }
    else if(this.state.IDSousFamilleReporting)
    {
      let sousFamille = lodash.find(this.state.dataBusiness.origines, o => o.IDSousFamille == this.state.IDSousFamilleReporting);
      return `Leads "${sousFamille.Famille}/${sousFamille.SousFamille}" par origines`;
    }
    else if(this.state.IDFamilleReporting)
    {
      let famille = lodash.find(this.state.dataBusiness.origines, o => o.IDFamille == this.state.IDFamilleReporting);
      return `Leads "${famille.Famille}" par sous-familles`;
    }
    else 
      return `Leads par familles`;
  }

  getObjectif(type, etape) {
    var objectif = null;

    if(type == "Tous") 
    {
      objectif = lodash.sumBy(this.state.objectifsVendeurs, 'Nb'+etape);
    }
    else 
    {
      objectif = lodash.find(this.state.objectifsVendeurs, {"Type": type});
      if(objectif)
        objectif = objectif['Nb'+etape];
    }

    return objectif;
  }

  goToLeadsPageFromDashbord(page, filters, addDates, e) {
    if(e) {
      e.preventDefault();
    }
    //Ajout des dates
    if(addDates) {
      var datesReporting = this.getDatesReporting();
      filters["dateStart"] = datesReporting[0].replaceAll("-", "");
      filters["dateEnd"] = datesReporting[1].replaceAll("-", "");
      filters["datePeriode"] = "months";
    }
    //Filtre par société/site
    if(this.state.IDSiteReporting > 0) {
      filters["sites"] = this.state.IDSiteReporting;
    }
    else if(this.state.IDSocieteReporting > 0) {
      filters["sites"] = lodash.map(lodash.filter(this.state.sites, (s) => s.Plaque.IDPlaque == this.state.IDSocieteReporting), (s) => s.IDSite);
    }
    //Filtre par vendeur
    if(this.state.IDVendeurReporting > -1) {
      let vendeur = lodash.find(this.state.reportingMensuel.vendeurs, (v) => v.IDPeople == this.state.IDVendeurReporting);
      if(vendeur) {
        filters["vendeurs"] = vendeur.Name;
      }
    }
    //Filtre par catégorie de client
    if(this.state.IDCatClientReporting > 0) {
      let categorieClient = lodash.find(this.state.dataBusiness.categoriesClient, (c) => c.IDCatClient == this.state.IDCatClientReporting);
      if(categorieClient) {
        filters["typesClient"] = categorieClient.Descr;
      }
    }
    //Filtre par origine/sous-famille/famille
    if(!filters["origines"]) {
      var originesReporting = [];
      if(this.state.IDOrigineReporting > 0) {
        originesReporting = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDOrigine == this.state.IDOrigineReporting);
      }
      else if(this.state.IDSousFamilleReporting > 0) {
        originesReporting = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDSousFamille == this.state.IDSousFamilleReporting);
      }
      else if(this.state.IDFamilleReporting > 0) {
        originesReporting = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDFamille == this.state.IDFamilleReporting);
      }
      if(originesReporting.length > 0) {
        filters["origines"] = originesReporting.map(o => o.Famille+"-"+o.SousFamille+"-"+o.Origine);
      }
    }
    //Filtre par étape
    if(!filters["etapes"]) {
      if(this.state.IDEtapeReporting > 0) {
        filters["etapes"] = lodash.find(this.state.dataBusiness.etapes, (e) => e.IDComRappEtapeLead == this.state.IDEtapeReporting).Description;
      }
    }
    
    var queryParams = [];
    for(var param in filters) {
      queryParams.push(param+"="+filters[param]);
    }
    browserHistory.push("/leads/"+page+"?"+queryParams.join("&")); 
  }

  goToLeads(filters, e) {
    var pageName = "AffairesEncoursVendeur"; //localStorage.user_profil === "BDC" ? "AffairesEncoursBDC" : "AffairesEncoursVendeur";
    this.goToLeadsPageFromDashbord(pageName, filters, false, e);
  }

  goToLeadsFromReportingMensuel(filters, e) {
    var pageName = "AffairesEncoursVendeur"; //localStorage.user_profil === "BDC" ? "AffairesEncoursBDC" : "AffairesEncoursVendeur";
    this.goToLeadsPageFromDashbord(pageName, filters, true, e);
  }

  goToAffairesGagnees(filters, e) {
    this.goToLeadsPageFromDashbord("AffairesGagnees", filters, true, e);
  }

  goToAffairesPerdues(filters, e) {
    this.goToLeadsPageFromDashbord("AffairesClotures", filters, true, e);
  }

  render() {

    if(!this.state.dataBusiness || !this.state.reportingMensuel){
      return (<div className='col-md-12'>
          <Loader style={{ marginTop: 150 }} />
        </div>);
    }

    const tauxTransformation = [
      {
        "id": "Taux",
        "label": "Taux de transformation",
        "value": this.state.reportingMensuel.tauxTransformation[this.state.typeIndicateursPerformance]
      },
      {
        "id": "Non transformés",
        "label": "Non transformés",
        "value": 100 - this.state.reportingMensuel.tauxTransformation[this.state.typeIndicateursPerformance]
      },
    ]

    if(this.state.reportingMensuel.offresEtCommandes){
      const tauxConcretisationValue = this.state.reportingMensuel.offresEtCommandes["Offres"][this.state.typeIndicateursPerformance] > 0 ? 
                                      Math.round((this.state.reportingMensuel.offresEtCommandes["Commandes"][this.state.typeIndicateursPerformance] / this.state.reportingMensuel.offresEtCommandes["Offres"][this.state.typeIndicateursPerformance])*1000) / 10
                                      :0;
      var tauxConcretisation = [
        {
          "id": "Taux",
          "label": "Offres concrétisées (%)",
          "value": tauxConcretisationValue
        },
        {
          "id": "Non transformés",
          "label": "Non concrétisées (%)",
          "value": 100 - tauxConcretisationValue
        },
      ]

      var maxValueOffresEtCommandes = Math.max(this.state.reportingMensuel.offresEtCommandes["Offres"][this.state.typeIndicateursPerformance], this.state.reportingMensuel.offresEtCommandes["Essais"][this.state.typeIndicateursPerformance], this.state.reportingMensuel.offresEtCommandes["Contacts"][this.state.typeIndicateursPerformance]);
      if(maxValueOffresEtCommandes < 100)
        maxValueOffresEtCommandes = 100;
      if(maxValueOffresEtCommandes < 200)
        maxValueOffresEtCommandes = 200;
      else if(maxValueOffresEtCommandes < 500)
        maxValueOffresEtCommandes = 500;
    }

    const familles = this.getFamilles();
    const sousFamilles = this.getSousFamilles();
    const origines = this.getOrigines();
    const sousFamillesDisabled = !this.state.IDFamilleReporting;
    const originesDisabled = !this.state.IDSousFamilleReporting;

    return (

      <React.Fragment>
        {/* Portefeuille business */}
        <div>
          <h2>Opportunités business</h2>
          <select style={{marginLeft:'1rem'}} value={this.state.IDSocieteReporting} onChange={this.onChangeSocieteReporting.bind(this)}>
            <option value={0}>Toutes les sociétés</option>
            {this.state.societes.map((soc, i) => {
              return <option key={i} value={soc.IDPlaque}>{soc.Nom}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDSiteReporting} onChange={this.onChangeSiteReporting.bind(this)}>
            <option value={0}>Tous les sites</option>
            {this.state.sites.map((site, i) => {
              if(this.state.IDSocieteReporting > 0 && site.Plaque.IDPlaque != this.state.IDSocieteReporting)
                return null;

              return <option key={i} value={site.IDSite}>{site.Plaque.Nom} {site.Libelle ? site.Libelle : site.Nom}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDVendeurReporting} onChange={this.onChangeVendeurReporting.bind(this)}>
            {this.state.IDVendeurReporting > -1 && !lodash.find(this.state.reportingMensuel.vendeurs, (v) => {return v.IDPeople == this.state.IDVendeurReporting}) ? 
              <option value=''></option>
            :null}
            <option value={-1}>Tous les vendeurs</option>
            {this.state.reportingMensuel.vendeurs.map((vendeur) => {
              return <option key={vendeur.IDPeople} value={vendeur.IDPeople}>{vendeur.Name}</option>
            })}
          </select>
          <br/>
          <select style={{marginLeft:'1rem'}} value={this.state.IDFamilleReporting} onChange={this.onChangeFamilleReporting.bind(this)}>
            {familles.map((famille, i) => {
              return <option key={i} value={famille.value}>{famille.label}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem', opacity: sousFamillesDisabled ? 0.5 : 1}} value={this.state.IDSousFamilleReporting} onChange={this.onChangeSousFamilleReporting.bind(this)} disabled={!this.state.IDFamilleReporting}>
            {sousFamilles.map((sousFamille, i) => {
              return <option key={i} value={sousFamille.value}>{sousFamille.label}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem', opacity: originesDisabled ? 0.5 : 1}} value={this.state.IDOrigineReporting} onChange={this.onChangeOrigineReporting.bind(this)} disabled={!this.state.IDSousFamilleReporting}>
            {origines.map((origine, i) => {
              return <option key={i} value={origine.value}>{origine.label}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDCatClientReporting} onChange={this.onChangeCatClientReporting.bind(this)}>
            <option value="">Toutes les catégories client</option>
            {this.state.dataBusiness.categoriesClient.map((categorie, i) => {
              return <option key={i} value={categorie.IDCatClient}>{categorie.Descr}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDEtapeReporting} onChange={this.onChangeEtapeReporting.bind(this)}>
            <option value="">Toutes les étapes</option>
            {this.state.dataBusiness.etapes.map((etape, i) => {
              return <option key={i} value={etape.IDComRappEtapeLead}>{etape.Description}</option>
            })}
          </select>

          <div style={{display:'flex', flex:1, flexDirection:'column', position:'relative'}}>
            {this.state.reloadingDataBusiness ? 
              <div style={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', zIndex:10, left:0, top:0, backgroundColor:'#fff', width:'100%', height:'100%', opacity:0.9}}>
                <Loader/>
              </div>
            :null}

            <div style={{display:'flex'}}>
              {/* Leads/Affaires en cours */}
              <div className="cadre" style={{width:'140px', height:'140px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
                <div className="title">Leads en cours</div>
                <div style={{display: 'flex', flex:1, flexDirection: 'column', justifyContent: 'center'}}>
                  <a className="big-value" href="#" onClick={this.goToLeads.bind(this, {})}>
                    {this.state.dataBusiness.nbLeadsEnCours}
                  </a>
                </div>
              </div>
              {/* Leads/affaires par famille */}
              <div className="cadre" style={{width:'auto', height:'140px', display:'flex', flex:1, flexDirection:'column', justifyContent:'space-between', alignItems:'start'}}>
                <div className="title">{this.getTitreLeadsParFamilles()}</div>
                <div style={{display:'flex', flexDirection:'column', flex:1, width:'100%', justifyContent:'space-evenly', paddingTop:'1rem'}}>
                  {this.state.typesLeads.map((type, i) => {
                    return (<div key={i} style={{display:'flex', flexDirection:'row'}}>
                      <div className="big-label text-center" style={{width:'50px'}}>{Dictionary.getUCFirst(type)}</div>
                      <div className="big-value text-center" style={{width:'100px'}}>
                        <a className="big-value" href="#" onClick={this.goToLeads.bind(this, {types: type})}>
                          {this.state.dataBusiness.leadsParFamille[type].total}
                        </a>
                      </div>
                      <div style={{height: '35px', display:'flex', flex:1, justifyContent:'center', alignItems:'center', verticalAlign:'middle'}}>
                        <ResponsiveBar
                          data={[this.state.dataBusiness.leadsParFamille[type]]}
                          indexBy="id"
                          keys={lodash.without(Object.keys(this.state.dataBusiness.leadsParFamille[type]), 'id', 'total')}
                          label={(d) => `${d.id} (${d.value})`}
                          labelSkipWidth={50}
                          layout="horizontal"
                          colors={colors}
                          axisTop={null}
                          axisRight={null}
                          enableGridY={false}
                          labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 10 ] ] }}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          tooltip={({ id, value, index, indexValue, color, data }) => (
                            <span>
                              <svg width={10} height={10}>
                                <rect width={10} height={10} style={{fill:color}}/>
                              </svg>
                              &nbsp;&nbsp;{id} - {Dictionary.getUCFirst(indexValue)} : {value}
                            </span>
                          )}
                          onClick={(data) => {
                            var filterBy = "Famille";
                            if(this.state.IDOrigineReporting > 0 || this.state.IDSousFamilleReporting > 0) {
                              filterBy = "Origine";
                            }
                            else if(this.state.IDFamilleReporting) {
                              filterBy = "SousFamille";
                            }
                            let origines = lodash.map(lodash.filter(this.state.dataBusiness.origines, {[filterBy]: data.id}), o => o.Famille+"-"+o.SousFamille+"-"+o.Origine);
                            this.goToLeads({types: data.indexValue, origines: origines.join(",")})
                          }}
                        />
                      </div>
                    </div>)
                  })}
                </div>
                {/* Fin Leads par famille */}
              </div>
            </div>

            <div style={{display:'flex', flexDirection:'row'}}>
              <div className="cadre" style={{display:'flex', flexDirection:"column", flexGrow:1}}>
                {/* Leads en cours par étape */}
                <div className="title">Leads en cours par étape</div>
                <div style={{display:'flex', flexGrow:1, flexShrink:1, justifyContent:'space-between', marginTop:'1em'}}>
                  {this.state.typesLeads.map((type, i) => {
                    return (<div key={i} style={{width: i == 0 ? '60%' : '40%', position:'relative', height:'150px'}}>
                      <ResponsivePie
                        data={lodash.values(this.state.dataBusiness.leadsParEtape[type])}
                        innerRadius={0.6}
                        colors={ colors }
                        enableRadialLabels={false}
                        slicesLabelsSkipAngle={0.1}
                        slicesLabelsTextColor="#000"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        legends={
                          i == 0 ? 
                          [{
                            anchor: 'left',
                            direction: 'column',
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#333',
                            itemsSpacing: 2,
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ],
                            onClick: (data) => {
                              if(this.state.dataBusiness.leadsParEtape[type][data.id] && this.state.dataBusiness.leadsParEtape[type][data.id].value > 0) {
                                this.goToLeads({etapes: data.id})
                              }
                            }
                          }]
                        :
                          []
                        }
                        onClick={(data) => {
                          this.goToLeads({types: type, etapes: data.id})
                        }}
                      />
                      <div className="pie-overlay">
                        <div className="big-label">{Dictionary.getUCFirst(type)}</div>
                        <a className="big-value" href="#" onClick={this.goToLeads.bind(this, {types: type})}>
                          {this.state.dataBusiness.nbLeadsParType[type]}
                        </a>
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className="cadre" style={{display:'flex', flexDirection:"column", flexGrow:1}}>
                {/* Leads en cours par statut */}
                <div className="title">Leads en cours par statut</div>
                <div style={{display:'flex', flexGrow:1, flexShrink:1, justifyContent:'space-between', marginTop:'1em'}}>
                  {this.state.typesLeads.map((type, i) => {
                    return (<div key={i} style={{width: i == 0 ? '60%' : '40%', position:'relative', height:'150px'}}>
                      <ResponsivePie
                        data={lodash.values(this.state.dataBusiness.leadsParStatut[type])}
                        innerRadius={0.6}
                        colors={ colorsStatut }
                        enableRadialLabels={false}
                        slicesLabelsSkipAngle={0.1}
                        slicesLabelsTextColor="#000"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        legends={
                          i == 0 ? 
                          [{
                            anchor: 'left',
                            direction: 'column',
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#333',
                            itemsSpacing: 2,
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ],
                            onClick: (data) => {
                              this.goToLeads({typePeriode: data.id == "A venir" ? "incoming" : "late"})
                            }
                          }]
                        :
                          []
                        }
                        onClick={(data) => {
                          this.goToLeads({types: type, typePeriode: data.id == "A venir" ? "incoming" : "late"})
                        }}
                      />
                      <div className="pie-overlay">
                        <div className="big-label">{Dictionary.getUCFirst(type)}</div>
                        <a className="big-value" href="#" onClick={this.goToLeads.bind(this, {types: type})}>
                          {this.state.dataBusiness.nbLeadsParType[type]}
                        </a>
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fin portefeuille business */}

        {/* Reporting mensuel */}
        <div style={{marginTop:'2rem'}}>
          <div>
            <h2>Reporting mensuel</h2>
            <select style={{marginLeft:'1rem'}} value={this.state.shiftReporting} onChange={this.onChangeMoisReporting.bind(this)}>
              <option value={-1}>Cumul {(new Date()).getFullYear()}</option>
              {lodash.range(0, 6).map((dec, i) => {
                let month = moment().startOf('month').subtract(dec, 'month');
                return <option key={i} value={dec}>{month.format('MMMM YYYY').toUpperCase()}</option>
              })}
              <option value={-2}>Cumul {(new Date()).getFullYear() - 1}</option>
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDSocieteReporting} onChange={this.onChangeSocieteReporting.bind(this)}>
              <option value={0}>Toutes les sociétés</option>
              {this.state.societes.map((soc, i) => {
                return <option key={i} value={soc.IDPlaque}>{soc.Nom}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDSiteReporting} onChange={this.onChangeSiteReporting.bind(this)}>
              <option value={0}>Tous les sites</option>
              {this.state.sites.map((site, i) => {
                if(this.state.IDSocieteReporting > 0 && site.Plaque.IDPlaque != this.state.IDSocieteReporting)
                  return null;

                return <option key={i} value={site.IDSite}>{site.Plaque.Nom} {site.Libelle ? site.Libelle : site.Nom}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDVendeurReporting} onChange={this.onChangeVendeurReporting.bind(this)}>
              {this.state.IDVendeurReporting > -1 && !lodash.find(this.state.reportingMensuel.vendeurs, (v) => {return v.IDPeople == this.state.IDVendeurReporting}) ? 
                <option value=''></option>
              :null}
              <option value={-1}>Tous les vendeurs</option>
              {this.state.reportingMensuel.vendeurs.map((vendeur) => {
                return <option key={vendeur.IDPeople} value={vendeur.IDPeople}>{vendeur.Name}</option>
              })}
            </select>
            <br/>
            <select style={{marginLeft:'1rem'}} value={this.state.IDFamilleReporting} onChange={this.onChangeFamilleReporting.bind(this)}>
              {familles.map((famille, i) => {
                return <option key={i} value={famille.value}>{famille.label}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem', opacity: sousFamillesDisabled ? 0.5 : 1}} value={this.state.IDSousFamilleReporting} onChange={this.onChangeSousFamilleReporting.bind(this)} disabled={!this.state.IDFamilleReporting}>
              {sousFamilles.map((sousFamille, i) => {
                return <option key={i} value={sousFamille.value}>{sousFamille.label}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem', opacity: originesDisabled ? 0.5 : 1}} value={this.state.IDOrigineReporting} onChange={this.onChangeOrigineReporting.bind(this)} disabled={!this.state.IDSousFamilleReporting}>
              {origines.map((origine, i) => {
                return <option key={i} value={origine.value}>{origine.label}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDCatClientReporting} onChange={this.onChangeCatClientReporting.bind(this)}>
              <option value="">Toutes les catégories client</option>
              {this.state.dataBusiness.categoriesClient.map((categorie, i) => {
                return <option key={i} value={categorie.IDCatClient}>{categorie.Descr}</option>
              })}
            </select>
          </div>
          <div style={{display:'flex', flex:1, flexDirection:'column', position:'relative'}}>
            {this.state.reloadingReportingMensuel ? 
              <div style={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', zIndex:10, left:0, top:0, backgroundColor:'#fff', width:'100%', height:'100%', opacity:0.9}}>
                <Loader/>
              </div>
            :null}
            
            <div style={{display:'flex', flexDirection:'row', flexGrow:1, flexShrink:1}}>
              <div style={{display:'flex', flexDirection:'column', flex:0.5}}>
                {/* Nombre d'affaires reçues */}
                <div className="cadre" style={{display:'flex', flexDirection:'column', flexGrow:0.4}}>
                  <div className="title">Nombre d'affaires reçues (sur la période sélectionnée)</div>
                  <div style={{display:'flex', flexGrow:1, flexShrink:1, flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', paddingTop:'1.5rem', paddingBottom:'1.5rem'}}>
                    {this.state.typesLeads.map((type, i) => {
                      return (<div key={i} style={{display: 'flex', flexGrow:1, flexDirection:'column', alignItems:'center'}}>
                        <div className="big-label">{Dictionary.getUCFirst(type)}</div>
                        <div className="big-value">{this.state.reportingMensuel.affairesRecuesParType[type]}</div>
                      </div>)
                    })}
                  </div>
                </div>
                {/* Fin nombre d'affaires reçues */}

                {/*Suivi des affaires */}
                <div className="cadre" style={{display:'flex', flexDirection:'column', flexGrow:0.6}}>
                  <div className="title">Suivi des affaires (créées sur la période sélectionnée)</div>
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', flexGrow:1}}>
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flexGrow:1, height:'100%', justifyContent:'space-evenly'}}>
                      <div className="big-label">&nbsp;</div>
                      {this.state.typesLeads.map((type, i) => {
                        return <div key={i} className="big-label">{Dictionary.getUCFirst(type)}</div>
                      })}
                    </div>
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flexGrow:1, height:'100%', justifyContent:'space-evenly'}}>
                      <div className="big-label">En cours</div>
                      {this.state.typesLeads.map((type, i) => {
                        return (
                          <a key={i} className="big-value" /*href="#" onClick={this.goToLeadsFromReportingMensuel.bind(this, {types: type})}*/>
                            {this.state.reportingMensuel.affairesParStatut[type]["En cours"]}
                          </a>
                        )
                      })}
                    </div>
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flexGrow:1, height:'100%', justifyContent:'space-evenly'}}>
                      <div className="big-label">Gagnées</div>
                      {this.state.typesLeads.map((type, i) => {
                        return (
                          <a key={i} className="big-value" /*href="#" onClick={(this.goToAffairesGagnees.bind(this, {types: type}))}*/>
                            {this.state.reportingMensuel.affairesParStatut[type]["Gagnée"]}
                          </a>
                        )
                      })}
                    </div>
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flexGrow:1, height:'100%', justifyContent:'space-evenly'}}>
                      <div className="big-label">Perdues</div>
                      {this.state.typesLeads.map((type, i) => {
                        return (
                          <a key={i} className="big-value" /*href="#" onClick={this.goToAffairesPerdues.bind(this, {types: type})}*/>
                            {this.state.reportingMensuel.affairesParStatut[type]["Perdue"]}
                          </a>
                        )
                      })}
                    </div>
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flexGrow:1, height:'100%', justifyContent:'space-evenly'}}>
                      <div className="big-label">Reportées</div>
                      {this.state.typesLeads.map((type, i) => {
                        return (
                          <a key={i} className="big-value" /*href="#" onClick={this.goToLeadsFromReportingMensuel.bind(this, {types: type, typePeriode: "reportes"})}*/>
                            {this.state.reportingMensuel.affairesReportees[type]}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/*Fin des affaires */}
              </div>

              {/* Indicateurs de performance */}
              <div className="cadre" style={{flex:0.5}}>
                <div style={{display:'flex', flexDirection:'row', flex:1, justifyContent:'space-between'}}>
                  <div className="title">Indicateurs de performance (sur la période sélectionnée)</div>
                  <select value={this.state.typeIndicateursPerformance} onChange={this.onChangeTypeIndicateursPerformance.bind(this)}>
                    {this.state.typesLeads.length > 1 ? 
                      <option value="Tous">Tous</option>
                    :null}
                    {this.state.typesLeads.map((type, i) => {
                      return <option key={i} value={type}>{Dictionary.getUCFirst(type)}</option>
                    })}
                  </select>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', flex:1, marginTop:'1rem'}}>
                  <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'stretch', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flex:1}}>
                      <div style={{margin:'1rem'}}>
                        Taux de transformation&nbsp;
                        <InfobulleDashboard>
                          Pourcentage des affaires reçues sur la période sélectionnée qui ont abouti à une commande
                        </InfobulleDashboard>
                      </div>
                      <div style={{height:'100px', width:'100px', position:'relative'}}>
                        <ResponsivePie
                          data={tauxTransformation}
                          innerRadius={0.85}
                          cornerRadius={4}
                          colors={['#ec5858','#d8d9d9']}
                          enableRadialLabels={false}
                          slicesLabelsSkipAngle={0}
                          slicesLabelsTextColor="#000"
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          enableSlicesLabels={false}
                          borderWidth={0}
                          borderColor={function(d){
                            if(d.data.id === "Taux")
                              return "#ec5858";
                            else 
                              return "rgba(0,0,0,0)";
                          }}
                        />
                        <div className="pie-overlay">
                          <div className="big-value">
                            {this.state.reportingMensuel.tauxTransformation[this.state.typeIndicateursPerformance]} %
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flex:1}}>
                      <div style={{margin:'1rem'}}>
                        Délai de traitement moyen&nbsp;
                        <InfobulleDashboard>
                          Délai moyen entre la réception d'un lead et la clôture de la première action entrante
                        </InfobulleDashboard>
                      </div>
                      <div style={{height:'110px', width:'110px', position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src="img/chrono.png" alt="@" style={{height:'100%'}}/>
                        <div className="pie-overlay" style={{paddingTop:'1.1rem'}}>
                          <div className="big-value">
                          {
                            this.formatDelai(this.state.reportingMensuel.delaiTraitementMoyen[this.state.typeIndicateursPerformance])
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'flex', flexDirection:'column', alignItems:'center', flexGrow:1, flexShrink:1, paddingTop:'3rem', paddingBottom:'2rem'}}>
                    <div style={{marginBottom:'1rem'}}>Nombre de leads transformés</div>
                    {localStorage.ReportingTransformationCyclevente === "true" ? 
                      <div style={{height:'66px', color:"#fff", paddingTop:"4px", paddingBottom:"2px", width:'123px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center', backgroundImage:'url("img/graph-ruban-rep-cycle-vente.png")', backgroundSize:'contains', backgroundRepeat:'no-repeat'}}>
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'85%'}}>
                            <div>{this.state.reportingMensuel.affairesRecuesParType[this.state.typeIndicateursPerformance]}</div>
                            <div style={{fontSize:'1.2rem'}}>Leads</div>
                          </div>
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'72%'}}>
                            <div>
                            {this.state.typeIndicateursPerformance === "Tous" ? 
                              lodash.reduce(this.state.typesLeads, (sum, typeLead) => sum + this.state.reportingMensuel.affairesParStatut[typeLead]["Gagnée"], 0)
                            :
                              this.state.reportingMensuel.affairesParStatut[this.state.typeIndicateursPerformance]["Gagnée"]}
                            </div>
                            <div style={{fontSize:'1.2rem'}}>Gagnés</div>
                          </div>
                      </div>
                    :
                      <div style={{height:'105px', color:"#fff", paddingTop:"4px", paddingBottom:"2px", width:'123px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center', backgroundImage:'url("img/graph-ruban.png")', backgroundSize:'contains', backgroundRepeat:'no-repeat'}}>
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'85%'}}>
                            <div>{this.state.reportingMensuel.affairesRecuesParType[this.state.typeIndicateursPerformance]}</div>
                            <div style={{fontSize:'1.2rem'}}>Leads</div>
                          </div>
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'75%'}}>
                            <div>{this.state.reportingMensuel.offresEtCommandesLeads[this.state.typeIndicateursPerformance].Offres}</div>
                            <div style={{fontSize:'1.2rem'}}>Offres</div>
                          </div>
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'58%'}}>
                            <div>{this.state.reportingMensuel.offresEtCommandesLeads[this.state.typeIndicateursPerformance].Commandes}</div>
                            <div style={{fontSize:'1.2rem'}}>Cdes</div>
                          </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              {/* Fin indicateurs de performance */}
            </div>

            {this.state.reportingMensuel.offresEtCommandes ?
            <div style={{display:'flex', flexDirection:'column'}}>
                {/* Nombre d'offres et de commandes */}
                <div className="cadre" style={{display:'flex', flexDirection:'column'}}>
                  <div style={{display:'flex', flexDirection:'row', flexGrow:1, flexShrink:1, justifyContent:'space-between'}}>
                    <div className="title">
                      Nombre d'essais, d'offres et de commandes {this.state.objectifsVendeurs.length ? "/ versus objectifs" : ""}&nbsp;
                      <InfobulleDashboard>
                        Créés sur la période sélectionnée, quelle que soit la date de création du lead
                      </InfobulleDashboard>
                    </div>
                    <select value={this.state.typeIndicateursPerformance} onChange={this.onChangeTypeIndicateursPerformance.bind(this)}>
                      {this.state.typesLeads.length > 1 ? 
                        <option value="Tous">Tous</option>
                      :null}
                      {this.state.typesLeads.map((type, i) => {
                        return <option key={i} value={type}>{Dictionary.getUCFirst(type)}</option>
                      })}
                    </select>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexGrow:1, flexShrink:1}}>
                    <div style={{display:'flex', height:'150px', flex:0.6}}>
                      <ResponsiveBar
                        data={lodash.map(this.state.reportingMensuel.offresEtCommandes, (data, etape) => {
                          var objectif = this.getObjectif(this.state.typeIndicateursPerformance, etape);
                          if(objectif) //S'il y a un objectif défini pour ce type, la longueur de la barre est en fonction du % complété de l'objectif
                          {
                            var pourcentObjectifRealise = Math.min(data[this.state.typeIndicateursPerformance] / objectif, 1);
                            var objectifRestant = Math.max(objectif - data[this.state.typeIndicateursPerformance], 0);
                            var value = pourcentObjectifRealise * maxValueOffresEtCommandes;
                            var valueGrey = (1 - pourcentObjectifRealise) * maxValueOffresEtCommandes;
                          }
                          else 
                          {
                            var value = data[this.state.typeIndicateursPerformance];
                            var valueGrey = maxValueOffresEtCommandes - value;
                            var objectifRestant = null;
                          }
                          return {
                            "id": etape, 
                            "value": value, 
                            "valueGrey": valueGrey,
                            "objectifRealise": data[this.state.typeIndicateursPerformance],
                            "objectifRestant": objectifRestant,
                            "objectif": objectif
                          }
                        })}
                        keys={['valueGrey', 'value', 'labelZone']}
                        colors={(d) => {
                          switch(d.id) {
                            case "valueGrey":
                              if(d.data.objectif)
                                return "#CCCCCC";
                              else 
                                return "transparent";
                            default: 
                              return colorsOffresCommandes[d.index];
                          }
                        }}
                        borderRadius={0}
                        padding={0.3}
                        layout="horizontal"
                        colorBy="index"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={{
                            tickSize: 0,
                            tickPadding: 15,
                        }}
                        margin={{ top: 0, right: 130, left: 150 }}
                        tooltip={({ id, value, index, indexValue, color, data }) => {
                          if(data.objectif)
                          {
                            var text = indexValue+" realisé"+(indexValue == "Offres" || indexValue == "Commandes" ? "e" : "")+(data.objectifRealise > 1 ? "s" : "")+" sur un objectif de ";
                            return (
                              <span>
                                {indexValue} ({Dictionary.getUCFirst(this.state.typeIndicateursPerformance)})<br/>
                                {data.objectifRealise} {text} {data.objectif} ({Math.min(Math.round(data.objectifRealise/data.objectif*1000)/10, 100)}%)
                              </span>
                            )
                          }
                          else 
                          {
                            if(id != "valueGrey")
                            {
                              return (
                                <span>
                                  <svg width={10} height={10}>
                                    <rect width={10} height={10} style={{fill:color}}/>
                                  </svg>
                                  &nbsp;&nbsp;{indexValue} ({Dictionary.getUCFirst(this.state.typeIndicateursPerformance)}) : {value}
                                </span>
                              )
                            }
                            else 
                              return null
                          }
                        }}
                        enableGridY={false}
                        label={(d) => {
                          if(d.id == "value")
                          {
                            if(d.data.objectif)
                            {
                              var percent = Math.min(Math.round(d.data.objectifRealise/d.data.objectif*1000)/10, 100);
                              return d.data.objectifRealise + " / " + d.data.objectif + " ("+percent+"%)";
                            }
                            else 
                            {
                              return d.value;
                            }
                          }
                          else if(d.id == "valueGrey" && d.data.objectif && d.data.objectifRealise === 0) //Cas où il y a un objectif complété à 0%, on affiche le label sur le grey car la partie colorée n'apparait pas
                          {
                            return d.data.objectifRealise + " / " + d.data.objectif + " (0%)"; 
                          }

                          return "-";
                        }}
                        labelFormat={(d) => {
                          if(d == "-")
                            return "";
                          
                          return <tspan fontSize={15} fontWeight={"500"} textAnchor={"start"} x={"1%"} fontFamily={"arial"}>{ d }</tspan>
                        }}
                        legends={[]}
                        animate={true}
                        reverse={true}
                        motionStiffness={1000}
                        motionDamping={50}
                        maxValue={maxValueOffresEtCommandes} 
                        theme={{
                          axis: {
                            ticks: {
                              text: {
                                fontSize: 18,
                                textAlign:'left',
                                fontWeight: 500,
                                fontFamily: 'Poppins'
                              }
                            }
                          },
                          
                        }}
                      />
                    </div>
                    {/*Taux de concrétisation */}
                    <div style={{display: 'flex', flexDirection:'column', alignItems:'start', flex:0.4, paddingBottom:10}}>
                      <div style={{display: 'flex', flexDirection:'column', alignItems:'center', marginLeft:'9em'}}>
                        <div style={{marginBottom:'1rem'}}>
                          Taux de concrétisation&nbsp;
                          <InfobulleDashboard>
                            Pourcentage des offres créées sur la période sélectionnée qui ont été transformées en commande
                          </InfobulleDashboard>
                        </div>
                        <div style={{height:'100px', width:'100px', position:'relative'}}>
                          <ResponsivePie
                            data={tauxConcretisation}
                            innerRadius={0.85}
                            cornerRadius={4}
                            colors={['#ec5858','#d8d9d9']}
                            enableRadialLabels={false}
                            slicesLabelsSkipAngle={0}
                            slicesLabelsTextColor="#000"
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            enableSlicesLabels={false}
                            borderWidth={0}
                            borderColor={function(d){
                              if(d.data.id === "Taux")
                                return "#ec5858";
                              else 
                                return "rgba(0,0,0,0)";
                            }}
                          />
                          <div className="pie-overlay">
                            <div className="big-value">
                              {lodash.find(tauxConcretisation, {"id":"Taux"})["value"]} %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin nombre d'offres et de commandes */}
              </div>
            :null}
          </div>
        </div>

        <div className='text-center'>
          {localStorage.IsNissan == "true" ?
            <a href="http://crm-agile.selectup.com/pdf/SMIT Lead_Nouveau Tableau de Bord_VFINALE_11062020.pdf" target="_blank">Télécharger la documentation</a>
          :
            <a href="http://crm-agile.selectup.com/pdf/Pr%C3%A9sentation%20du%20Dashboard%20eSeller%20lead%20Juin20.pdf" target="_blank">Télécharger la documentation</a>
          }
        </div>
        
        {/* Fin reporting mensuel */}
      </React.Fragment> 
    );
  }
}

export default DashboardVNVO;
