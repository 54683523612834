import React, {Component} from 'react';
import Globals from '../Globals';

var lodash = require('lodash')

class FormSocieteSiteVDL extends Component {

  constructor(props) {
    super(props);

    this.state = {
      societes: [],
      sites: [],
      userSites:[],
    }
  }

  componentWillMount() {
    if(this.props.origin === "CallWin")
      this.fetchSocietesSites();
    else 
      this.fetchUserSocietesSites();
    this.loadAffectation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.prospect !== prevProps.prospect) {
      this.loadAffectation();
    }
  }

  loadAffectation(){
    if(this.props.prospect && this.props.prospect.IDProspect && localStorage.bypass_iFrame !== "true"){
      if(this.props.prospect.SiteAffectation && this.props.prospect.SiteAffectation.EstVO === true){
        this.props.onChangeValue('societe', this.props.prospect.SiteAffectation.Plaque.IDPlaque);
        this.props.onChangeValue('site', this.props.prospect.SiteAffectation.IDSite);
      }
      else{
        this.props.onChangeValue('societe', 0);
        this.props.onChangeValue('site', 0);
      }
    }
  }

  fetchUserSocietesSites(){
    fetch(localStorage.ClientApiURL + '/Utilisateurs/'+localStorage.user_IDUtilisateur, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var sites = lodash.orderBy(json.Sites, ['Libelle','Nom']);
      var societes = lodash.map(sites, (site) => {
        return site.Plaque;
      });
      societes = lodash.orderBy(lodash.uniqBy(societes, 'IDPlaque'), 'Nom');

      this.setState({
        sites: sites,
        societes: societes
      });
    })
    .catch((error) => {
        console.log(error)
    });
  }

  fetchSocietesSites() {
    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var societes = [];
      var sites = [];

      societes = lodash.orderBy(json, 'Nom');

      lodash.each(societes, (soc) => {
        lodash.each(soc.Sites, (site) => {
          sites.push(site)
        })
      });

      sites = lodash.orderBy(lodash.uniqBy(sites, 'IDSite'), ['Libelle', 'Nom']);

      this.setState({
        societes: societes,
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleChangeSociete(event) {
    this.props.onChangeValue('societe', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('site', 0);
    this.props.onChangeValue('todoToVendeur', false);
  }

  handleChangeSite(event) {
    this.props.onChangeValue('site', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('todoToVendeur', false);
  }

  getSites(IDPlaque){
    return lodash.filter(this.state.sites, (site) => {return site.Plaque.IDPlaque === parseInt(IDPlaque)});
  }

  render() {
    return (
      <div >
        {this.props.location  && this.props.location === 'CallWin' ?
          <h2 style={{color:this.props.textColor, marginLeft: this.props.marginleft}}>
              <img src="/img/HOME Empty.svg" role='presentation'/> Société / Site
          </h2> :  <h3>Société / Site</h3>
        }

        <div className="form-group">
          <div className="col-md-8 col-md-offset-2">
            <select className="form-control" onChange={this.handleChangeSociete.bind(this)} id="leadSociete" name="leadSociete" value={this.props.societe} required="required">
              <option key="0" value=''>Société</option>
              {this.state.societes.map((societe) => {
                if(this.getSites(societe.IDPlaque).length)
                  return <option key={societe.IDPlaque} value={societe.IDPlaque}>{societe.Nom}</option>;
              })}
            </select>
          </div>
        </div>

        {this.props.societe && this.props.societe !== "Société" ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="leadSite" name="leadSite" value={this.props.site} required="required">
                <option value="" key="0">Site</option>
                {this.getSites(this.props.societe).map((site) => {
                  return <option key={site.IDSite} value={site.IDSite} >{ site.Libelle ? site.Libelle : site.Nom }</option>;
                })}
              </select>
            </div>
          </div>
        : null }

      </div>
    );
  }
}

export default FormSocieteSiteVDL;
