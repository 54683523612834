import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';

import { browserHistory } from 'react-router';

import moment from 'moment'

import InputTime from '../Components/InputTime';

import { Link } from 'react-router';
var Datetime = require('react-datetime');

class PeopleHolidaysAdd extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
            type: '',
            dateStart: null,
            dateEnd: null,
            types: [],
            utilisateur: null,
            fetchCreateInProgress: false,

            /** Gestion absences récurrentes */
            isAbsRecu:false,
            frequences:[],
            jour:null,
            creneau:null,
            frequence:null,

        }
    }

    componentDidMount() {
        this.fetchutilisateur();
        this.fetchTypes();
        this.fetchFrequences();
    }

    fetchTypes() {
        fetch(localStorage.ClientApiURL + '/PeopleHolidaysTypes' , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    types: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchutilisateur() {
        fetch(localStorage.ClientApiURL + '/utilisateurs/' + this.props.params.IDPeople , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    utilisateur: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    /**
     * Chargement de toutes les fréquences
     * */
    fetchFrequences(){
        fetch(localStorage.ClientApiURL + '/PeopleHolidays/frequences', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    frequences: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    changeType(event) {
        this.setState({
            type: event.target.value,
        })
    }

    changeDateStart(event) {

        var dateStart = event;
        if (!this.state.dateStart) {
            dateStart.set('hours', 8).set('minutes', 0).set('seconds', 0)
        }
        else {
            dateStart.set('hours', this.state.dateStart.get('hours')).set('minutes', this.state.dateStart.get('minutes')).set('seconds', 0)
        }

        this.setState({
            dateStart: dateStart,
        })
    }

    changeDateEnd(event) {

        var dateEnd = event;
        if (!this.state.dateEnd) {
            dateEnd.set('hours', 18).set('minutes', 0).set('seconds', 0)
        }
        else {
            dateEnd.set('hours', this.state.dateEnd.get('hours')).set('minutes', this.state.dateEnd.get('minutes')).set('seconds', 0)
        }

        this.setState({
            dateEnd: dateEnd,
        })
    }

    changeTimeStart(event) {

        var dateStart = event;
        if (this.state.dateStart) {
            dateStart.set('years', this.state.dateStart.get('years')).set('months', this.state.dateStart.get('months')).set('days', this.state.dateStart.get('days'))
        }

        this.setState({
            dateStart: dateStart,
        })
    }

    changeTimeEnd(event) {
        var dateEnd = event;
        if (this.state.dateEnd) {
            dateEnd.set('years', this.state.dateEnd.get('years')).set('months', this.state.dateEnd.get('months')).set('days', this.state.dateEnd.get('days'))
        }

        this.setState({
            dateEnd: dateEnd,
        })
    }

    isValidDateStart(event) {

        if (event < moment().startOf('day')) {
            return false;
        }

        if (!this.state.dateEnd) {
            return true
        }

        return event < this.state.dateEnd;
    }

    isValidDateEnd(event) {

        if (!this.state.dateStart) {
            return true
        }

        if (event.format('YYYY-MM-DD') === this.state.dateStart.format('YYYY-MM-DD')) {
            return true;
        }

        return event >= this.state.dateStart;
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            fetchCreateInProgress: true,
        });

        let body = {
            IDPeople: this.props.params.IDPeople,
            DateHStart: this.state.dateStart.utcOffset(2).format(),
            DateHEnd: this.state.dateEnd.utcOffset(2).format(),
            'IDPeopleHolidayType': this.state.type,

            EstRecurrent:this.state.isAbsRecu,
            Jour: this.state.jour,
            Creneau: this.state.creneau,
            Frequence:{'IDPeopleHolidayFrequence': this.state.frequence},
        };
        console.log(body);
        fetch(localStorage.ClientApiURL + '/PeopleHolidays', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                this.setState({
                    fetchCreateInProgress: false,
                });

                if (!response.ok) {
                    throw Error(response.statusText);
                }

                if (localStorage.bypass_iFrame == "true") {
                    browserHistory.push('/iframe/holidays/' + this.props.params.IDPeople);
                }
                else {
                    browserHistory.push('/holidays/' + this.props.params.IDPeople);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    handleCheckBoxAbsRecu(){
        this.setState({
            isAbsRecu:!this.state.isAbsRecu,
        })
    }
    render() {

        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>

                        <div>
                            <h1>Gestion des absences</h1>
                            {localStorage.bypass_iFrame == "true" ?
                                <Link className='btn btn-default pull-right' to={'/iframe/holidays/'+this.props.params.IDPeople}>Voir les absences</Link>
                                :
                                <Link className='btn btn-default pull-right' to={'/holidays/'+this.props.params.IDPeople}>Voir les absences</Link>
                            }
                        </div>

                        <br />

                        <h2>Ajout d'une absence</h2>

                        <form onSubmit={this.handleSubmit.bind(this)}>
                            {/*Partie gauche de la page*/}
                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className="col-md-3">Vendeur</label>
                                    <div className="col-md-7">
                                        <p className="form-control-static">{this.state.utilisateur ? this.state.utilisateur.Nom : null}</p>
                                    </div>
                                </div>

                                <div className="col-md-12" />

                                <div className="form-group">
                                    <label className="col-md-3">Type</label>
                                    <div className="col-md-6">
                                        <select className="form-control" id="type" name="type" onChange={this.changeType.bind(this)} value={this.state.type} required="required">
                                            <option></option>
                                            {this.state.types.map((type, i) => {
                                                return <option key={i} value={type.IDPeopleHolidayType}>{type.Libelle}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12" />

                                <div className="form-group">
                                    <label className="col-md-3">Date de début</label>
                                    <div className="col-md-3">
                                        <Datetime inputProps={{required: 'required'}} timeFormat={false} closeOnSelect={true} onChange={this.changeDateStart.bind(this)} value={this.state.dateStart} key='start-date' isValidDate={this.isValidDateStart.bind(this)} />
                                    </div>
                                    {!this.state.isAbsRecu ?
                                    <div className="col-md-3" style={{ paddingLeft: '0px' }}>
                                        <InputTime value={this.state.dateStart ? this.state.dateStart : moment().set('hours', 8).set('minutes', 0).set('seconds', 0)} onChange={this.changeTimeStart.bind(this)} />
                                    </div>
                                    :null}

                                    <div className="col-md-12" />

                                    <div className="form-group">
                                        <label className="col-md-3">Date de fin</label>
                                        <div className="col-md-3">
                                            <Datetime inputProps={{required: 'required'}} timeFormat={false} closeOnSelect={true} onChange={this.changeDateEnd.bind(this)} value={this.state.dateEnd} key='end-date' isValidDate={this.isValidDateEnd.bind(this)} />
                                        </div>
                                        {!this.state.isAbsRecu ?
                                        <div className="col-md-3" style={{ paddingLeft: '0px' }}>
                                            <InputTime value={this.state.dateEnd ? this.state.dateEnd : moment().set('hours', 18).set('minutes', 0).set('seconds', 0)} onChange={this.changeTimeEnd.bind(this)} />
                                        </div>
                                        :null}
                                    </div>
                                </div>

                                <div className="col-md-12" />

                                {/* Affichage absences récurrentes*/}
                                <div className="form-group">
                                    <div className="col-md-3">Récurrence</div>
                                    <div className="col-md-6" style={{paddingLeft:0}}>

                                        <div className="col-md-12" style={{ textAlign: 'left', marginLeft:0 }}>
                                            <input type='checkbox' id='checkRecurrente' onChange={this.handleCheckBoxAbsRecu.bind(this)} />
                                            <label className="label-box" htmlFor="checkRecurrente">Absence Récurrente </label>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/* Partie droite de la page*/}
                            {this.state.isAbsRecu ?
                                <div className={'col-md-6 form-group'} id={'gestionAbsRecu'}>
                                    <div className={ 'text-center col-md-7'} style={{marginBottom:'15px'}}><span >Spécificités de l'absence récurrente</span></div>

                                    <div className={'col-md-12'}/>
                                    <label className="col-md-3">Jour</label>
                                    <div className="col-md-5">
                                        <select className="form-control" id="jour" name="jour" onChange={event =>  this.setState({jour:event.target.value})} defaultValue={this.state.jour} required="required">
                                            <option key={-1}> </option>
                                            <option key={0} value={"0"}>Lundi</option>
                                            <option key={1} value={"1"}>Mardi</option>
                                            <option key={2} value={"2"}>Mercredi</option>
                                            <option key={3} value={"3"}>Jeudi</option>
                                            <option key={4} value={"4"}>Vendredi</option>
                                            <option key={5} value={"5"}>Samedi</option>
                                            <option key={6} value={"6"}>Dimanche</option>

                                        </select>


                                    </div>
                                    <div className={'col-md-12'}/>

                                    <label className="col-md-3">Créneau</label>
                                    <div className="col-md-5">
                                        <select className="form-control" id="jour" name="jour" onChange={event =>  this.setState({creneau:event.target.value})} defaultValue={this.state.creneau} required="required">
                                            <option key={-1}> </option>
                                            <option key={'M'} value={"M"}>Matin</option>
                                            <option key={'A'} value={"A"}>Après-midi</option>
                                            <option key={'J'} value={"J"}>Jour entier</option>

                                        </select>

                                    </div>

                                    <div className={'col-md-12'}/>

                                    <label className="col-md-3">Fréquence</label>
                                    <div className="col-md-5">
                                        {/* TODO charger les fréquences de la base */}
                                        <select className="form-control" id="jour" name="jour" onChange={event =>  this.setState({frequence:event.target.value})} defaultValue={this.state.frequence} required="required">
                                            <option key={-1}> </option>
                                            {this.state.frequences.map((frequence,index)=>{
                                                return <option key={frequence.IDPeopleHolidayFrequence} value={frequence.IDPeopleHolidayFrequence}>{frequence.Libelle}</option>
                                            })}
                                        </select>


                                    </div>
                                </div>
                                :null}

                            <div className="col-md-7">
                                <div className="col-md-8 col-md-offset-3">
                                    <button type="submit" className="text-center btn btn-default" disabled={this.state.fetchCreateInProgress}>{this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Ajouter</button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default PeopleHolidaysAdd;
