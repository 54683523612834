import React, {Component} from 'react';
import Globals from '../Globals';
import { Activite } from '../Enums/Activite';
var lodash = require('lodash');

class FormVO extends Component {

  constructor(props) {
    super(props);

    this.state = {
      marque : '',
      modele : '',
      budget : '',
      energie : '',
      boite : '',
      kmmax : '',
      agemax : '',
      comment: this.props.defaultComment,
      reprise : false,
      ventemarchand : false,
      financement : false,
      demandeessai : false,

      listeMarques:[],
      fetchingMarques: false,
      saisieMarqueMan: false,
      listeModeles:[],
      fetchingModeles: false,
      saisieModeleMan: false
    }

    this.marquesMoto = [
      "ACM",
      "AEON",
      "APOLLO MOTORS",
      "APRILIA",
      "ARCTIC CAT",
      "AWR",
      "AXR",
      "BAOTIAN",
      "BAROSSA",
      "BENELLI",
      "BETA",
      "BEYOND",
      "BIMOTA",
      "BLACK FOREST",
      "BLANEY",
      "BMW",
      "BOMBARDIER",
      "BRAMMO",
      "BRIXTON",
      "BUELL",
      "BUGGY",
      "BULLIT",
      "CAGIVA",
      "CAN AM",
      "CANNONDALE",
      "CARVOS",
      "CF MOTO",
      "DAELIM",
      "DAREN",
      "DAZON",
      "DERBI",
      "DINLI",
      "DIRT DEMON",
      "DUCATI",
      "ECCHO",
      "EML",
      "ETON",
      "FANTIC",
      "FB MONDIAL",
      "FOX",
      "GAS GAS",
      "GILERA",
      "GUZZI",
      "HARLEY DAVIDSON",
      "HEROWAY",
      "HONDA",
      "HONDHU",
      "HUSABERG",
      "HUSQVARNA",
      "HYOSUNG",
      "HYTRACK",
      "INDIAN",
      "JAMES B",
      "JIALING",
      "JINLONG",
      "JORDAN",
      "KASEA",
      "KAWASAKI",
      "KEEWAY",
      "KINDER",
      "KL",
      "KSR",
      "KTM",
      "KVN",
      "KYMCO",
      "LAVERDA",
      'LEM',
      "LOEONART",
      "LINHAI",
      "LONCIN",
      "M FERGUSSON",
      "MAGPOWER",
      "MASAI",
      "MASH",
      "MATRA",
      "MBK",
      "MINIBIKE",
      "MINICO",
      "MORINI",
      "MOTORTEK",
      "MOTRAC",
      "MSA",
      "MV-AGUSTA",
      "MZ",
      "NECO",
      "NEWSTAR",
      "NIU",
      "NORTON",
      "ORCAL",
      "PEUGEOT",
      "PGO",
      "PIAGGIO",
      "POLARIS",
      "QUADRO",
      "RIEJU",
      "ROXON",
      "ROYAL-ENFIELD",
      "SCOOTH",
      "SECMA",
      "SHERCO",
      "SKYTEAM",
      "SMC",
      "SUKIDA",
      "SUMO MOTOR",
      "SUPER SOCO",
      "SUZUKI",
      "SWM",
      "SYM",
      'TGB',
      "TNT MOTOR",
      "TOM DO",
      "TRIUMPH",
      "TWISTER",
      "VESPA",
      "VICTORY",
      "VLS",
      "VOXAN",
      "XINGYUE",
      "YAMAHA",
      "YAMASAKI",
      "YCF",
      "ZERO",
      "ZNEN",
      "ZONGSHEN"
    ];
  }

  componentDidMount() {
    if(this.props.activite == Activite.Agricole)
      this.fetchMarques();
  }

  async fetchMarques() {
    this.setState({
      fetchingMarques: true
    })

    fetch(localStorage.ClientApiURL+'/referentiel/marques', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then(json => {
        this.setState({
          listeMarques: JSON.parse(json),
          fetchingMarques: false
        });
    })
    .catch((error) => {
        console.log(error)
    });
  }

  async fetchModeles() {
    this.setState({
      modelesMateriel: [],
      fetchingModeles: true,
    })

    var marque = lodash.find(this.state.listeMarques, {Descr: this.state.marque});

    if(!marque){
      this.setState({
        fetchingModeles: false
      })
      return;
    }

    fetch(localStorage.ClientApiURL+'/referentiel/modeles?marque='+marque.IDMarque, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then(json => {
        this.setState({
          listeModeles: JSON.parse(json),
          fetchingModeles: false,
        });
    })
    .catch((error) => {
        console.log(error)
    });
  }

  generateComment() {
    let comment = '';

    if(this.props.activite == Activite.Agricole) {
      comment += 'Marque : ' + this.state.marque + '\n';
      comment += 'Modèle : ' + this.state.modele + '\n';
      comment += 'Prix : ' + this.state.budget + '\n';
      comment += 'Nb heures Max : ' + this.state.nbhmax + '\n';
      comment += 'Année : ' + this.state.agemax + '\n';

      comment += 'Reprise : ' + (this.state.reprise ? 'oui' : 'non') + '\n';
      comment += 'Financement : ' + (this.state.financement ? 'oui' : 'non') + '\n';
      comment += 'Demande de démonstration : ' + (this.state.demandeessai ? 'oui' : 'non') + '\n';
      comment += 'Commentaire :\n' + this.state.comment + '\n';
    }
    else {
      comment += 'Marque : ' + this.state.marque + '\n';
      comment += 'Modèle : ' + this.state.modele + '\n';
      comment += 'Energie : ' + this.state.energie + '\n';
      comment += 'Boîte : ' + this.state.boite + '\n';
      comment += 'Prix : ' + this.state.budget + '\n';
      comment += 'KMs Max : ' + this.state.kmmax + '\n';
      comment += 'Année : ' + this.state.agemax + '\n';

      comment += 'Reprise : ' + (this.state.reprise ? 'oui' : 'non') + '\n';
      comment += 'Vente à marchand : ' + (this.state.ventemarchand ? 'oui' : 'non') + '\n';
      comment += 'Financement : ' + (this.state.financement ? 'oui' : 'non') + '\n';
      comment += 'Demande d\'essai : ' + (this.state.demandeessai ? 'oui' : 'non') + '\n';
      comment += 'Commentaire :\n' + this.state.comment + '\n';
    }

    return comment;
  }

  updateChangeValue(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === "marque" && value === "_SAISIE-MANUELLE_"){
      this.setState({
        saisieMarqueMan: true,
        saisieModeleMan: true,
      }, () => {
        this.refs.saisieMarqueMan.focus();
      })
      return;
    }
    if(name === "modele" && value === "_SAISIE-MANUELLE_"){
      this.setState({
        saisieModeleMan: true,
      }, () => {
        this.refs.saisieModeleMan.focus();
      })
      return;
    }

    this.setState({
      [name]: value
    }, () => {
      this.props.changeValue('commentVO', this.generateComment());
      if(name === "marque" && !this.state.saisieMarqueMan){
        this.fetchModeles();
      }
    });
  }

  getIconInteret() {
    if(this.props.activite == Activite.Moto) {
      return "/img/MOTO2.svg"
    }

    return "/img/AFFAIRE VO.svg"
  }

  renderAgri() {
    return (
      <div className="panel panel-default form-interet">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-vo">
            <h2>
              <img src="/img/AFFAIRE MO.svg" role='presentation'/> Interêt Occasion
            </h2>
          </a>
        </li>
      </ul>
        <div className="row">

          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Marque</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              {this.state.saisieMarqueMan ?
                <input type="text" name="marque" className="form-control" ref="saisieMarqueMan" onChange={evt => this.updateChangeValue(evt)} />
              :
                <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)}>
                  <option value="">{this.state.fetchingMarques ? "Chargement des marques...": ""}</option>
                  <option value="_SAISIE-MANUELLE_">Saisir une marque</option>
                  {this.state.listeMarques.map((marque) => {
                    return <option value={marque.Descr} key={marque.IDMarque}>{marque.Descr}</option>
                  })}
                </select>
              }
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Modèle</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              {this.state.saisieModeleMan ?
                <input type="text" name="modele" className="form-control" ref="saisieModeleMan" onChange={evt => this.updateChangeValue(evt)} />
                :
                <select className='form-control' name="modele" onChange={evt => this.updateChangeValue(evt)}>
                  <option value="">{this.state.fetchingModeles ? "Chargement des modèles...": ""}</option>
                  <option value="_SAISIE-MANUELLE_">Saisir un modèle</option>
                  {this.state.listeModeles.map((modele) => {
                    return <option value={modele.Descr} key={modele.IDModele}>{modele.Descr}</option>
                  })}
                </select>
              }
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-12">

            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-nowrap">Prix</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <input type="text" name="budget" className="form-control" onChange={evt => this.updateChangeValue(evt)}/>
              </div>
            </div>

            <div className="form-group col-kmage">
              <label className="col-sm-4 control-label">Nb heures max</label>
              <div className="col-md-3">
                <input type="text" name="nbhmax" autoComplete="off" className="form-control" onChange={evt => this.updateChangeValue(evt)}/>
              </div>
              <label className="col-sm-2 control-label">Année</label>
              <div className="col-md-3">
                <input type="text" autoComplete="off" className="form-control" name="agemax" onChange={evt => this.updateChangeValue(evt)} />
              </div>
            </div>
          </div>

          <div className="col-md-1 col-xs-12"></div>
          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <div className="col-md-12">
                  <textarea  name="comment" id="comment" style={{ width: '100%' }} onChange={evt => this.updateChangeValue(evt)} placeholder="Commentaire" value={this.state.comment}></textarea>
              </div>
            </div>
            <div className="form-group checkbox-lines">
              <div className="col-md-6 col-xs-12">
                <input type="checkbox" name="reprise" id="reprise" onChange={evt => this.updateChangeValue(evt)} />
                <label className="label-box" htmlFor="reprise"> Reprise </label>
              </div>

              <div className="col-md-6 col-xs-12">
                <input type="checkbox" name="financement" id="financement" onChange={evt => this.updateChangeValue(evt)}/>
                <label className="label-box" htmlFor="financement"> Financement </label>
              </div>
              
              <div className="col-md-6 col-xs-12">
                <input type="checkbox" name="demandeessai" id="demandeessai" onChange={evt => this.updateChangeValue(evt)}/>
                <label className="label-box" htmlFor="demandeessai" style={{whiteSpace:'nowrap'}}> Demande de démonstration </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }

  render() {
    if(this.props.activite == Activite.Agricole)
      return this.renderAgri();

    return (
      <div className="panel panel-default form-interet">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-vo">
            <h2>
              <img src={this.getIconInteret()} role='presentation'/> Interêt VO
            </h2>
          </a>
        </li>
      </ul>
        <div className="row">
          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Marque</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                {this.props.activite == Activite.Moto ? 
                <>
                    {this.state.saisieMarqueMan ?
                      <input type="text" name="marque" className="form-control" ref="saisieMarqueMan" onChange={evt => this.updateChangeValue(evt)} />
                    :
                    <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)}>
                      <option value=""></option>
                      <option value="_SAISIE-MANUELLE_">Saisir une marque</option>
                        {this.marquesMoto.map((marque)=>{
                          return (
                            <option key={marque} value={marque}>{marque}</option>
                          )
                        })}
                    </select>
                    }
                </>

                  
                :
                <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)}>
                  <option value=""></option>
                  <option value="ABARTH">ABARTH</option>
                  <option value="AIWAYS">AIWAYS</option>
                  <option value="ALFA ROMEO">ALFA ROMEO</option>
                  <option value="ALPINA">ALPINA</option>
                  <option value="ALPINE">ALPINE</option>
                  <option value="ARO">ARO</option>
                  <option value="ARTEGA">ARTEGA</option>
                  <option value="ASTON MARTIN">ASTON MARTIN</option>
                  <option value="AUDI">AUDI</option>
                  <option value="BAIC">BAIC</option>
                  <option value="BENTLEY">BENTLEY</option>
                  <option value="BLUECAR GROUPE BOLLORE">BLUECAR GROUPE BOLLORE</option>
                  <option value="BMW">BMW</option>
                  <option value="BMW i">BMW i</option>
                  <option value="BYD">BYD</option>
                  <option value="CADILLAC">CADILLAC</option>
                  <option value="CHEVROLET">CHEVROLET</option>
                  <option value="CHRYSLER">CHRYSLER</option>
                  <option value="CUPRA">CUPRA</option>
                  <option value="CITROEN">CITROEN</option>
                  <option value="CORVETTE">CORVETTE</option>
                  <option value="DACIA">DACIA</option>
                  <option value="DAIHATSU">DAIHATSU</option>
                  <option value="DANGEL">DANGEL</option>
                  <option value="DODGE">DODGE</option>
                  <option value="DR">DR</option>
                  <option value="DS">DS</option>
                  <option value="FERRARI">FERRARI</option>
                  <option value="FIAT">FIAT</option>
                  <option value="FISKER">FISKER</option>
                  <option value="FORD">FORD</option>
                  <option value="HONDA">HONDA</option>
                  <option value="HUMMER">HUMMER</option>
                  <option value="HYUNDAI">HYUNDAI</option>
                  <option value="INFINITI">INFINITI</option>
                  <option value="ISUZU">ISUZU</option>
                  <option value="IVECO">IVECO</option>
                  <option value="JAGUAR">JAGUAR</option>
                  <option value="JEEP">JEEP</option>
                  <option value="KIA">KIA</option>
                  <option value="LADA">LADA</option>
                  <option value="LAMBORGHINI">LAMBORGHINI</option>
                  <option value="LANCIA">LANCIA</option>
                  <option value="LAND ROVER">LAND ROVER</option>
                  <option value="LEAP MOTOR">LEAP MOTOR</option>
                  <option value="LEXUS">LEXUS</option>
                  <option value="LINK&CO">LINK&CO</option>
                  <option value="LOTUS">LOTUS</option>
                  <option value="MAHINDRA">MAHINDRA</option>
                  <option value="MASERATI">MASERATI</option>
                  <option value="MASTRETTA">MASTRETTA</option>
                  <option value="MAYBACH">MAYBACH</option>
                  <option value="MAZDA">MAZDA</option>
                  <option value="MCLAREN">MCLAREN</option>
                  <option value="MERCEDES">MERCEDES</option>
                  <option value="MG">MG</option>
                  <option value="MIA">MIA</option>
                  <option value="MINI">MINI</option>
                  <option value="MITSUBISHI">MITSUBISHI</option>
                  <option value="MORGAN">MORGAN</option>
                  <option value="NISSAN">NISSAN</option>
                  <option value="OPEL">OPEL</option>
                  <option value="PEUGEOT">PEUGEOT</option>
                  <option value="PGO">PGO</option>
                  <option value="PIAGGIO">PIAGGIO</option>
                  <option value="PORSCHE">PORSCHE</option>
                  <option value="RENAULT">RENAULT</option>
                  <option value="RENAULT TRUCKS">RENAULT TRUCKS</option>
                  <option value="ROLLS-ROYCE">ROLLS-ROYCE</option>
                  <option value="ROVER">ROVER</option>
                  <option value="SAAB">SAAB</option>
                  <option value="SANTANA">SANTANA</option>
                  <option value="SEAT">SEAT</option>
                  <option value="SERES">SERES</option>
                  <option value="SHUANGHUAN">SHUANGHUAN</option>
                  <option value="SKODA">SKODA</option>
                  <option value="SMART">SMART</option>
                  <option value="SSANGYONG">SSANGYONG</option>
                  <option value="SUBARU">SUBARU</option>
                  <option value="SUZUKI">SUZUKI</option>
                  <option value="TESLA">TESLA</option>
                  <option value="TOYOTA">TOYOTA</option>
                  <option value="VOLKSWAGEN">VOLKSWAGEN</option>
                  <option value="VOLVO">VOLVO</option>
                </select>}
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Modèle</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <input type="text" name="modele" className="form-control" onChange={evt => this.updateChangeValue(evt)} />
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Prix</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <input type="text" name="budget" className="form-control" onChange={evt => this.updateChangeValue(evt)}/>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Énergie</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <select name="energie" className="form-control" onChange={evt => this.updateChangeValue(evt)}>
                <option value="Essence">Essence</option>
                <option value="Diesel">Diesel</option>
                <option value="GPL">GPL</option>
                <option value="Electrique">Electrique</option>
                <option value="Hybride">Hybride</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Boîte</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <select name="boite" className="form-control" onChange={evt => this.updateChangeValue(evt)}>
                  <option value=""></option>
                  <option value="Automatique">Automatique</option>
                  <option value="Manuelle">Manuelle</option>
                </select>
              </div>
            </div>

            <div className="form-group col-kmage">
              <label className="col-sm-3 control-label">Kms Max</label>
              <div className="col-md-3">
                <input type="text" name="kmmax" autoComplete="off" className="form-control" onChange={evt => this.updateChangeValue(evt)}/>
              </div>
              <label className="col-sm-3 control-label">Année</label>
              <div className="col-md-3">
                <input type="text" autoComplete="off" className="form-control" name="agemax" onChange={evt => this.updateChangeValue(evt)} />
              </div>
            </div>
          </div>

          <div className="col-md-1 col-xs-12"></div>
          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <div className="col-md-12">
                  <textarea  name="comment" id="comment" style={{ width: '100%' }} onChange={evt => this.updateChangeValue(evt)} placeholder="Commentaire" value={this.state.comment}></textarea>
              </div>
            </div>
            <div className="form-group checkbox-lines">
              <div className="col-md-6 col-xs-12">
                <input type="checkbox" name="reprise" id="reprise" onChange={evt => this.updateChangeValue(evt)} />
                <label className="label-box" htmlFor="reprise"> Reprise </label>
              </div>

              <div className="col-md-6 col-xs-12">
                <input type="checkbox" name="financement" id="financement" onChange={evt => this.updateChangeValue(evt)}/>
                <label className="label-box" htmlFor="financement"> Financement </label>
              </div>

              <div className="col-md-6 col-xs-12">
                <input type="checkbox" name="demandeessai" id="demandeessai" onChange={evt => this.updateChangeValue(evt)}/>
                <label className="label-box" htmlFor="demandeessai"> Demande de démonstration </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default FormVO;
