import React, { Component } from 'react';

import moment from 'moment'
import BadgeDiffDay from '../Components/BadgeDiffDay';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowComment from '../Components/TableRowComment';
import TableRowSite from '../Components/TableRowSite';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import { CustomView, isMobileOnly, isMobile, MobileOnlyView } from 'react-device-detect';

class TypeEnCours extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      typeEnCours: ''
    }
  }

  componentDidMount(){
    this.setState({
      typeEnCours: this.props.typeEnCours
    })
  }

  componentDidUpdate(){
    if(this.props.typeEnCours !== this.state.typeEnCours){
      this.setState({
        typeEnCours: this.props.typeEnCours
      })
    }
  }

  handleclickAffaire(IDLead) {
    //browserHistory.push('/rapportAtelier/' + IDLead);
    this.props.onClick(IDLead);
  }

  getTypeDemande(lead) {
    return "Demande de devis";
  }

  render() {
    return (
      <div className="">
      <CustomView condition={!isMobileOnly}>
          <table className="table table-eseller-lead table-striped table-hover table-condensed">
            <thead>
              <tr>
                <th className='text-center'>Com.</th>
                <th>Type de demande</th>
                <th>Date de relance</th>
                <th style={{ width: '150px', }}>Site</th>
                <th style={{ width: '200px', }}>Campagne / Origine</th>
                <th>Contact</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.leads.map((lead, i) => {

                return (<tr key={i} onClick={this.handleclickAffaire.bind(this, lead.IDLead)} className={isMobile ? "tr-touch":"tr-link"}>
                  <td className='text-center'>
                    <PastilleVendeur utilisateur={lead.SuiviPar} />
                  </td>
                  <td>{this.getTypeDemande(lead)}</td>
                  <td>
                    <TableRowDateRelance date={lead.ActionAFaire_TODO.DateHProchaineEtape} />
                  </td>
                  <td style={{ overflow: 'hidden' }}>
                    <TableRowSite Site={lead.Site} />
                  </td>
                  <td>
                    <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.ActionFaite_DO.OrigineAction} />
                  </td>
                  <td>
                    {lead.Prospect.Nom} {lead.Prospect.Prenom}
                  </td>
                  <td style={{whiteSpace:'nowrap'}}>
                      <BadgeDiffDay date={lead.ActionAFaire_TODO.DateHProchaineEtape} />
                  </td>
                  <td className='text-center'>
                    <TableRowComment comment={lead.ActionFaite_DO.Commentaire} />
                  </td>
                </tr>);
              })}
            </tbody>
          </table>
      </CustomView>

      <MobileOnlyView>
        <table className="table table-eseller-lead table-striped table-hover table-condensed" style={{marginBottom:'8px'}}>
          <tbody>
          {this.props.leads.map((lead, i) => {
            return (<tr key={i} onClick={this.handleclickAffaire.bind(this, lead.IDLead)} className="tr-touch">
              <td className='text-center'>
                <PastilleVendeur utilisateur={lead.SuiviPar} />
              </td>
              <td>{this.getTypeDemande(lead)}</td>
              <td>
                <TableRowDateRelance date={lead.ActionAFaire_TODO.DateHProchaineEtape} />
              </td>
              <td style={{wordBreak:'break-word'}}>
                {lead.Prospect.Nom} {lead.Prospect.Prenom}
              </td>
              <td style={{whiteSpace:'nowrap', textAlign:'right'}}>
                  <BadgeDiffDay date={lead.ActionAFaire_TODO.DateHProchaineEtape} />
              </td>
            </tr>);
            })}
            </tbody>
          </table>
      </MobileOnlyView>
      </div>
    );
  }
}

export default TypeEnCours;
