import React, {Component} from 'react';
import Globals from '../Globals';

let lodash = require('lodash')

const styleIcon =()=>({
    width: '30px',
    height: '30px',
    margin: '0px',
})

class FormSeller extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rangUser:0,
            _utilisateurs: [],
            utilisateurs: [],
            selected: null,
            called: false,
            aucunVendeurDispo: false,
            potcommun: false,

            utilisateursGroupeWithId:[],
        }
    }

    componentWillMount() {
      if (this.props.IDSite) {
        this.fetchutilisateurs();
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.IDSite === prevProps.IDSite) { // Si pas de changement de site
          return;
      }

      if (this.props.IDSite != 0 && (this.props.IDSite !== prevProps.IDSite)) {
        this.setState({
          rangUser:0,
          _utilisateurs: [],
          utilisateurs: [],
          selected: null,
          called: false,
          aucunVendeurDispo: false,
          potcommun: false,

          utilisateursGroupeWithId:[],
        }, () => {
          this.fetchutilisateurs();
        })
      }
    }

    /***
     * Appel du vendeur
     */
    appelOnClick(){
        fetch("http://192.168.1.4/public/api/agent/alias.php?command=agentConsultDial&calledNumber=0679609728&agentID=8&token=679ecef160580dd84c81147a5ca8ac0dae110ddf", {
            })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                console.log(response)
                //return response.json();
            })

            .catch((error) => {
                console.log(error)
            });

    }

    /***
     * Récupération des utilisateurs à appeler pour ce site
     */
    fetchutilisateurs() {
        fetch(localStorage.ClientApiURL + '/welcomepro/sites/'+this.props.IDSite+'/prioritylist', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    _utilisateurs: json,
                }, () => {
                    this.loadutilisateurs();
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    /***
     * Tri des utilisateurs
     */
    loadutilisateurs() {
        this.props.onChangeValue('ToVendeurCallwinOK', false);


        let aucunVendeurDispo = false;
        if (!this.props.IDSite || this.props.IDSite ===0) {
            this.setState({
                aucunVendeurDispo:false,
                utilisateurs: [],
                called: false,
                selected: null,
                rangUser: 0,
            });
            return;
        }else {
            if (this.state._utilisateurs ===[] || this.state.utilisateurs.length <1){
                aucunVendeurDispo = true;
                this.props.onChangeValue('ToVendeurCallwinOK', true);
            }}

        this.setState({
            aucunVendeurDispo:  aucunVendeurDispo,
            called: false,
            selected: null,
            rangUser: 0,
        })

        var utilisateurs = lodash.filter(this.state._utilisateurs, (utilisateur) => {

            if (!utilisateur.EstActif)
                return false;

            /*  var _found = utilisateur.Sites.find((site, i) => {

                  if (parseInt(site.IDSite, 10) !== parseInt(this.props.IDSite, 10)) {
                      return false;
                  }

                  return true;
              });
              var _seller = utilisateur.TypeProfilLead === 'Vendeur';

              if (_found && _seller) {
                  return true;
              }*/

            return true;
        });

        this.setState({
            utilisateurs: utilisateurs,
        });
    }

    /***
     * Sélection d'un utilisateur
     * */
   /* onClick(IDUtilisateur) {
        this.setState({ selected: IDUtilisateur });
    }*/

    /***
     * Passage à l'utilisateur suivant
     **/
    updateRangUser() {
        this.setState({
            called: false,
            rangUser: this.state.rangUser + 1,
        }, () => {
            if(this.state.rangUser === this.state.utilisateurs.length){
                this.props.onChangeValue('ToVendeurCallwinOK', true);
                this.props.onChangeValue('ToVendeurCallwinNOK', true);
            }
        })
    }
    /***
     * Sélection d'un utilisateur
     * */
    updateSelected(user){

        this.setState({
            selected: user
        });
        this.props.onChangeValue('ToVendeurCallwinOK', true);
        this.props.onChangeValue('todoVendeur', user.IDUtilisateur);
        this.props.onChangeValue('todoToVendeur', true);
    }

    onChangeValueComment(event) {
        this.props.onChangeValue('todoComment',event.target.value);
    }

    getMobilePhone(utilisateur) {
      if (!utilisateur.Telephones || !utilisateur.Telephones.length)
        return '';

      var mobilePhone = lodash.filter(utilisateur.Telephones, (t) => {
        return t.Type == 'Mobile';
      })

      if (!mobilePhone.length) {
        return '';
      }

      return mobilePhone[0].Numero;
    }

    attribuerPotcommun() {
      this.props.onChangeValue('ToVendeurCallwinOK', true);
      this.props.onChangeValue('ToVendeurCallwinOKPotcommun', true);
      this.setState({
          potcommun: true,
      })
    }

    render() {

        return (
            <div className="panel panel-default">
                <h2>
                    <img src="/img/VENDEUR.svg" role='presentation'/> Lié à un vendeur {this.state.utilisateurs.length ?
                      <a className='btn btn-default' onClick={this.attribuerPotcommun.bind(this)} style={{ fontSize: '12px', marginLeft: '15px', position: 'absolute', left: '-300px', top: '150px', width: '170px', paddingLeft: '0px', paddingRight: '0px' }}>Attribuer au pot commun</a>
                     : null}
                </h2>

                <div className="row">

                    {this.state.utilisateurs.length && !this.state.potcommun ?
                        <div className="col-md-12">

                            <table className='table table-condensed' style={{ marginTop: '10px' }}>
                                <thead>
                                <tr>
                                    <th>Vendeur</th>
                                    {this.props.CallcenterTypeCreateLead === 'PriorisationKiamo' ? <th className='text-center'>Appeler</th> : null}
                                    <th className='text-center'>Affecter Lead</th>
                                    <th className='text-center'>Vendeur Suivant</th>
                                    <th></th>

                                </tr>
                                </thead>
                                <tbody>

                                {/* Si le vendeur est sélectionné*/}
                                {this.state.selected != null ?
                                    <tr>
                                        <td className="name"><span>{this.state.selected.Nom}</span></td>
                                        {this.props.CallcenterTypeCreateLead === 'PriorisationKiamo' ? <td></td> : null}
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><p className="alert alert-success">Valider pour attribuer le lead  <i className="fa fa-check" style={{marginLeft:2}}></i></p></td>
                                    </tr>
                                    /* Sinon on parcour tous les vendeurs*/
                                    : this.state.utilisateurs.map((utilisateur, i) => {
                                        if (i<= this.state.rangUser){

                                            let tdName=<td className="name">
                                              <span>{utilisateur.Nom}</span>
                                              {this.props.CallcenterTypeCreateLead === 'PriorisationAppelManuel' ? <span style={{ display: 'inline-block', fontSize: '14px', padding: '3px', border: '1px solid #DDD', backgroundColor: '#DDD', marginLeft: '15px' }}>{this.getMobilePhone(utilisateur)}</span> : null}
                                            </td>;
                                            let tdPhone=null;
                                            let tdLead=<td className="text-center"><div className="btn btn-link disabled" ><img style={styleIcon()}  src="/img/picto-lead-affecte.svg" role="presentation"/></div></td>;
                                            let tdNextUser = <td className="text-center"><div className="btn btn-link disabled" ><img style={styleIcon()}  src="/img/picto-vendeur-suivant.svg" role="presentation"/></div></td>;
                                            let tdResult=null;

                                            if (i !== this.state.rangUser) {
                                                tdPhone = this.props.CallcenterTypeCreateLead === 'PriorisationKiamo' ? <td></td> : null;
                                                tdResult = <td><p className="alert alert-danger">Vendeur indisponible</p></td>;
                                            }
                                            /** Si c'est le vendeur qui doit être géré**/
                                            else {
                                                if (this.props.CallcenterTypeCreateLead === 'PriorisationKiamo') {
                                                  tdPhone = <td className="text-center"><a className="btn btn-link" target="_blank" onClick={() => (this.appelOnClick(),this.setState({called: true}))}><i ><img style={styleIcon()}  src="/img/picto-tel.svg" role="presentation"/></i></a></td>;
                                                }

                                                /** Si l'appel est passé*/
                                                if (this.state.called || this.props.CallcenterTypeCreateLead === 'PriorisationAppelManuel') {
                                                    tdResult = <td><p className="alert alert-info">Attribution du lead <i className="fa fa-spinner fa-spin "></i></p></td>;
                                                    tdLead = <td className="text-center"><div className="btn btn-link" onClick={() => this.updateSelected( utilisateur)}><img style={styleIcon()}  src="/img/picto-lead-affecte.svg" role="presentation"/></div></td>;
                                                    tdNextUser = <td className="text-center"><div className="btn btn-link" onClick={() => this.updateRangUser()}><img style={styleIcon()}  src="/img/picto-vendeur-suivant.svg" role="presentation"/></div></td>;
                                                }
                                                else {
                                                    tdResult = <td><p className="alert alert-info">En attente d'appel <i className="fa fa-spinner fa-spin "></i></p></td>;
                                                }
                                            }


                                            return <tr key={i} style={{ height: '60px' }}>
                                                {tdName}
                                                {tdPhone}
                                                {tdLead}
                                                {tdNextUser}
                                                {this.props.CallcenterTypeCreateLead === 'PriorisationKiamo' ? tdResult : null}
                                            </tr>
                                        }
                                    })
                                }

                                {this.state.rangUser === this.state.utilisateurs.length  ?
                                    <tr>
                                        <td className="alert alert-warning text-center"  colSpan={6}>
                                            Aucun vendeur disponible, valider le lead sans l'associer à un vendeur
                                        </td>
                                    </tr>
                                    : null }


                                </tbody>
                            </table>
                            {this.state.selected != null || this.state.rangUser === this.state.utilisateurs.length ?
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <textarea  name="comment" id="comment" style={{ width: '100%' }} placeholder="Commentaire pour le vendeur" onChange={this.onChangeValueComment.bind(this)}></textarea>
                                    </div>
                                </div>
                                : null }
                        </div>
                        :

                        this.state.aucunVendeurDispo && !this.state.potcommun ?

                            <div className="alert alert-warning text-center"  colSpan={6}>
                                Aucun vendeur disponible, valider le lead sans l'associer à un vendeur
                            </div>

                            :
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-7" style={{marginTop:10}}>
                                    {!this.state.potcommun ? <div className="text-left" style={{ fontSize: 12}}><em>Veuillez sélectionner une société et un site.</em></div> : null}
                                </div>
                            </div>

                    }

                    {this.state.potcommun ?
                      <div className="alert alert-warning text-center">
                          Le lead sera envoyé dans le pot commun.
                      </div>
                    : null}

                </div>


            </div>

        );
    }

}
export default FormSeller;
