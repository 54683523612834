import React, { Component } from 'react';
var Auth = require('./Auth').default;

class Logout extends Component {
  componentDidMount() {
    Auth.logout()
    this.props.router.push('/')
  }

  render() {
    return <p>You are now logged out</p>
  }
};

export default Logout;
