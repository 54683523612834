import React, { Component } from 'react';

import Globals from '../../Globals';

import InputMask from 'react-input-mask';
import { Link } from 'react-router';
import moment from 'moment';
import ListInterlocuteurs from './ListInterlocuteurs';
import ListTypesProduction from './ListTypesProduction';
import Dictionary from '../../Dictionnary';
import Tooltip from "rc-tooltip";

var lodash = require('lodash')

class FormEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      changeValues: false,
      prospect: this.props.prospect,
      updateInProgress: false,
      initialCivilite: this.props.prospect ? this.props.prospect.Civilite : '',
      initialCategorieClient: this.props.prospect ? this.props.prospect.IDCategorieClientAgri : null,

      fidelites:null,
      categoriesClientAgri:[],
      libFidelite:'',
      rawAdresse :'',

      telsInitialValidation: {
        Mobile: true,
        Prive: true,
        Professionnel: true,
        Fax: true
      }
    }
  }

  componentDidMount() {
    this.fetchFidelites();
    if(localStorage.IsAgri === "true")
      this.fetchCategoriesClientAgri();

    this.buildRawAdresse();
    this.checkInitialTelsAreValid();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.prospect !== this.props.prospect) {
      this.setState({
        changeValues: false,
        prospect: this.props.prospect,
        updateInProgress: false,
        initialCivilite: this.props.prospect ? this.props.prospect.Civilite : '',

        fidelites: this.state.fidelites
      });
      this.checkInitialTelsAreValid();
    }
  }

  checkInitialTelsAreValid() {
    console.log("Check init tels are valid")
    const formatTelFR = localStorage.eSellerLeadFormatTelFR == 'true';
    const telMobile = this.getNumeroTelephone('Mobile');
    const telPrive = this.getNumeroTelephone('Prive');
    const telPro = this.getNumeroTelephone('Professionnel');
    const fax = this.getNumeroTelephone('Fax');

    var telsInitialValidation = {
      Mobile: !telMobile || this.isValidTel(telMobile, true, formatTelFR),
      Prive: !telPrive || this.isValidTel(telPrive, false, formatTelFR),
      Professionnel: !telPro || this.isValidTel(telPro, false, formatTelFR),
      Fax:  !fax || this.isValidTel(fax, false, formatTelFR),
    }

    this.setState({
      telsInitialValidation: telsInitialValidation
    });
  }

  isValidTel(tel, isMobile, formatTelFR) {
    var regex;
    if(formatTelFR){
      if(isMobile) {
        regex = /0(6|7)(?:[ .-]?[0-9]{2}){4}/;
      }
      else {
        regex = /0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}/;
      }
    }
    else {
      regex = /[0-9.()\- ]{2,14}/;
    }

    return regex.test(tel);
  }

  fetchFidelites() {
    fetch(localStorage.ClientApiURL + '/Fidelites', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var IDFidelite = this.state.prospect.IDFidelite
      var selectedFidelite = lodash.find(json, function(f) {
        return f.IDFidelite === IDFidelite;
      });
      this.setState({
        fidelites: json,
        libFidelite: selectedFidelite ? selectedFidelite.Descr : '',
      }); 
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchCategoriesClientAgri() {
    fetch(localStorage.ClientApiURL + '/agri/categoriesClientAgri', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        categoriesClientAgri: json,
      }); 
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeValue(event) {

    const target  = event.target;
    const name    = target.name;

    let prospect = this.state.prospect;

    if (name === 'Mobile' || name === "Prive" || name === "Professionnel" || name === "Fax") {
      if(target.id !== ""){
        prospect["Telephones"][target.id]['Numero'] = event.target.value;
        if (!event.target.value) {
          prospect["Telephones"][target.id] = null
          prospect["Telephones"] = lodash.filter(prospect["Telephones"], (telephone) => {
            return telephone ? true : false;
          })
        }
      }
      else {
        let tmp = {};
        tmp["Type"] = name;
        tmp["Numero"] = event.target.value;
        if(!prospect["Telephones"]){
          prospect["Telephones"] = [];
        }
        prospect["Telephones"].push(tmp);
      }
    }
    else if(name === 'IDFidelite'){
      var selectedFidelite = lodash.find(this.state.fidelites, function(f) {
        return f.IDFidelite === parseInt(event.target.value, 10);
      });

      prospect.IDFidelite = event.target.value;
      if(!selectedFidelite || selectedFidelite.Descr.toLowerCase() !== 'oui'){
        prospect.CommentaireFidelite =  '';
      }
        
      this.setState({
        libFidelite: selectedFidelite ? selectedFidelite.Descr : ''
      })
    }
    else {
      var value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      if (name === 'Prenom') {
        value = value.replace(/[^ -]+/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}); // uppercase all first letter of words
      }

      if (name === 'Nom') {
        value = value.toUpperCase();
      }

      prospect[name] = value;
    }
    this.setState({
      prospect: prospect,
      changeValues: true,
    });
  }

  onChangeAdresse(event) {
    var lignesAdresse = event.target.value.split("\n");
    var prospect = this.state.prospect;
    var colAdresse = ['Adresse','AdresseComplement','Adresse3','Adresse4'];
    for(var i in colAdresse)
      prospect[colAdresse[i]] = '';
    var colAdresseNum = 0;
    for(var i in lignesAdresse){
      if(lignesAdresse[i] != "" || (lignesAdresse[i-1] && lignesAdresse[i-1] != "")) {
        prospect[colAdresse[colAdresseNum]] = lignesAdresse[i];
        ++colAdresseNum;
        if(colAdresseNum > 3)break;
      }
    }
    this.setState({
      prospect: prospect,
      rawAdresse: event.target.value,
      changeValues: true,
    });
  }

  buildRawAdresse(){
    var adresses = [];
    if(this.state.prospect){
      adresses.push(this.state.prospect.Adresse);
      if(this.state.prospect.AdresseComplement)adresses.push(this.state.prospect.AdresseComplement);
      if(this.state.prospect.Adresse3)adresses.push(this.state.prospect.Adresse3);
      if(this.state.prospect.Adresse4)adresses.push(this.state.prospect.Adresse4);
    }
    this.setState({
      rawAdresse: adresses.join("\n").trim()
    })
  }

  handleSubmit(event) {

    event.preventDefault();

    if ((!this.state.prospect.Mail || !this.state.prospect.Mail.length) && (!this.state.prospect.Telephones || !this.state.prospect.Telephones.length)) {
      alert('Mail ou téléphone du prospect obligatoire');
      return;
    }

    this.save();
  }

  save() {
    if (this.state.updateInProgress)
      return;

    this.setState({
      updateInProgress: true,
    })

    fetch(localStorage.ClientApiURL + '/prospects/'+this.state.prospect.IDProspect, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify(this.state.prospect),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      this.setState({
        updateInProgress: false,
        changeValues: false,
        initialCategorieClient: this.state.prospect.IDCategorieClientAgri
      })

      return;
    })
    .catch((error) => {
      console.log(error)
      this.setState({ updateInProgress: false })
    });
  }

  getNumeroTelephone(type){
    var phone = lodash.filter(this.state.prospect.Telephones, (telephone) => {
      return telephone.Type === type;
    })

    return phone.length ? lodash.head(phone).Numero.replace('+33', '0') : '';
  }

  getIdTelephone(type){
    var id = lodash.findIndex(this.state.prospect.Telephones, (telephone) => {
      return telephone.Type === type;
    })

    return id > -1 ? id : '';
  }

  leadTypeExist(){
    var type = this.props.typeActive;
    var found = false;
    this.props.typesCreate["leads"].map((affaire, i) => {
      if (affaire.Type === type){
        found = true;
      }
      return found;
    });
    return found;
  }

  getCivilites(){
    var civilites = [];
    if(this.state.prospect.TypeClient === 'Particulier')
    {
      civilites = ["Madame", "Monsieur"];
      //Si civilité "personnalisée" pour ce prospect, on l'ajoute à la liste
      if(this.state.initialCivilite && civilites.indexOf(this.state.initialCivilite) === -1)
        civilites.push(this.state.initialCivilite);
    }
    else
      civilites = ["EURL", "SA", "SARL", "SAS", "Autre"];

    return civilites;
  }

  returnFullForm(){
    const formatTelFR = localStorage.eSellerLeadFormatTelFR == 'true';
    //Pour éviter de rappeler de multiples fois la méthode getNumeroTelephone
    const telMobile = this.getNumeroTelephone('Mobile');
    const telPrive = this.getNumeroTelephone('Prive');
    const telPro = this.getNumeroTelephone('Professionnel');
    const fax = this.getNumeroTelephone('Fax');

    return <div>
      <div className="form-prospect">
      <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

        {(typeof(this.props.withoutEditingType) !== 'undefined' && this.props.withoutEditingType === false) || typeof(this.props.withoutEditingType) === 'undefined' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Type</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12 flex-box">
              <div>
                <input type="radio" name="TypeClient" id="Particulier" value="Particulier" checked={this.state.prospect.TypeClient === 'Particulier'} onChange={this.onChangeValue.bind(this)} />
                <label className="label-radio radio-A" htmlFor="Particulier">Particulier</label>
              </div>
              <div>
                <input type="radio" name="TypeClient" id="Societe" value="Societe" checked={this.state.prospect.TypeClient === 'Societe'} onChange={this.onChangeValue.bind(this)}   />
                <label className="label-radio radio-A" htmlFor="Societe">Société</label>
              </div>
            </div>
          </div>
        : null}

        {localStorage.IsAgri === 'true' && this.state.prospect.TypeClient === 'Societe' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Catégorie</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <select className="form-control" name='IDCategorieClientAgri' onChange={this.onChangeValue.bind(this)} value={this.state.prospect.IDCategorieClientAgri ? this.state.prospect.IDCategorieClientAgri : ''}>
                <option value=""></option>
                {this.state.categoriesClientAgri.map((categ) => {
                  return <option key={categ.IDCategorieClient} value={categ.IDCategorieClient}>{categ.Libelle}</option>
                })}
              </select>
            </div>
          </div>
        : null}

        <div className="form-group">
          <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right text-nowrap">{this.state.prospect.TypeClient === 'Societe' ? 'Forme juridique' : 'Civilité'}</label>
          <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
            <select className="form-control"  name='Civilite' onChange={this.onChangeValue.bind(this)} value={this.state.prospect.Civilite ? this.state.prospect.Civilite.toLowerCase() : ''}>
              <option value=""></option>
              {lodash.map(this.getCivilites(), (civilite, i) => {
                return <option key={i} value={civilite.toLowerCase()}>{civilite}</option>
              })}
            </select>

          </div>
        </div>

        <div className="form-group">
          <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right text-right text-nowrap">{this.state.prospect.TypeClient === 'Societe' ? 'Raison sociale' : 'Nom'}</label>
          <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
            <input type="text" className='form-control' value={this.state.prospect.Nom ? this.state.prospect.Nom : ''} name='Nom' onChange={this.onChangeValue.bind(this)} />
          </div>
        </div>

        {this.state.prospect.TypeClient === 'Particulier' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Prenom</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="text" className='form-control' value={this.state.prospect.Prenom ? this.state.prospect.Prenom : ''} name='Prenom' onChange={this.onChangeValue.bind(this)} />
            </div>
          </div>
        : null}

        {this.state.prospect.TypeClient === 'Societe' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">{localStorage.CodeLangue === "fr-MA" ? "N° RC" : "SIRET"}</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="text" className='form-control' value={this.state.prospect.SIRET ? this.state.prospect.SIRET : ''} name='SIRET' minLength={localStorage.CodeLangue === "fr-MA" ? 6 : 14} maxLength={localStorage.CodeLangue === "fr-MA" ? 6 : 14} onChange={this.onChangeValue.bind(this)} />
            </div>
          </div>
        : null}

      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div className="form-group">
          <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Mobile</label>
          <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12" style={{position:"relative"}}>
              <InputMask mask={(formatTelFR && this.state.telsInitialValidation.Mobile) ? "99.99.99.99.99" : null} maskChar={" "} type="text" className="form-control"  value={telMobile}
                id={this.getIdTelephone('Mobile')} name='Mobile' onChange={this.onChangeValue.bind(this)} pattern={this.state.telsInitialValidation.Mobile ? (formatTelFR ? "0(6|7)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}") : null} />
              {telMobile && !this.isValidTel(telMobile, true, formatTelFR) ? 
                <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                  <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                    <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                  </div>
                </Tooltip>
              : null}
          </div>
        </div>

        {this.state.prospect.TypeClient !== 'Societe' || (telPrive && telPrive != '') ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Tél. Priv</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <InputMask mask={(formatTelFR && this.state.telsInitialValidation.Prive) ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control"  value={telPrive}
                    id={this.getIdTelephone('Prive')} name='Prive' onChange={this.onChangeValue.bind(this)} pattern={this.state.telsInitialValidation.Prive ? (formatTelFR ? "0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}") : null} />
                {telPrive && !this.isValidTel(telPrive, false, formatTelFR) ? 
                  <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                    <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                      <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                    </div>
                  </Tooltip>
                : null}
            </div>
          </div>
        : null}

        <div className="form-group">
          <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Tél. Pro</label>
          <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <InputMask mask={(formatTelFR && this.state.telsInitialValidation.Professionnel) ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control"  value={telPro}
                id={this.getIdTelephone('Professionnel')} name='Professionnel' onChange={this.onChangeValue.bind(this)} pattern={this.state.telsInitialValidation.Professionnel ? (formatTelFR ? "0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}") : null} />
              {telPro && !this.isValidTel(telPro, false, formatTelFR) ? 
                <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de téléphone ne semble pas valide"}>
                  <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                    <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                  </div>
                </Tooltip>
              : null}
          </div>
        </div>

        {this.state.prospect.TypeClient === 'Societe' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Fax</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <InputMask mask={(formatTelFR && this.state.telsInitialValidation.Fax) ? "99.99.99.99.99" : null} maskChar=" " type="text" className="form-control"  value={fax}
                    id={this.getIdTelephone('Fax')} name='Fax' onChange={this.onChangeValue.bind(this)} pattern={this.state.telsInitialValidation.Fax ? (formatTelFR ? "0(1|2|3|4|5|6|7|9)(?:[ .-]?[0-9]{2}){4}" : "[0-9.()\\- ]{2,14}") : null} />
                {fax && !this.isValidTel(fax, false, formatTelFR) ? 
                  <Tooltip trigger={['hover']} placement={"top"} overlay={"Ce numéro de fax ne semble pas valide"}>
                    <div style={{position:"absolute", left:0, top:0, display:"flex", alignItems:"center", height:"100%"}}>
                      <i className="fa fa-exclamation-circle color red" aria-hidden="true"></i> 
                    </div>
                  </Tooltip>
                : null}
            </div>
          </div>
        : null}

        {this.state.prospect.TypeClient === 'Particulier' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Mail</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="email" className='form-control' value={this.state.prospect.Mail ? this.state.prospect.Mail : ''} name='Mail' onChange={this.onChangeValue.bind(this)} minLength="7" />
            </div>
          </div>
        : null}

        {this.state.prospect.TypeClient === 'Particulier' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Mail Pro</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="email" className='form-control' value={this.state.prospect.MailPro ? this.state.prospect.MailPro : ''} name='MailPro' onChange={this.onChangeValue.bind(this)} minLength="7" />
            </div>
          </div>
        : null}

        {this.state.prospect.TypeClient === 'Societe' ?
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Mail Pro</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="email" className='form-control' value={this.state.prospect.Mail ? this.state.prospect.Mail : ''} name='Mail' onChange={this.onChangeValue.bind(this)} minLength="7" />
            </div>
          </div>
        : null}
      </div>

      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">

        <div className="form-group">
          <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">Adresse</label>
          <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
            <textarea value={this.state.rawAdresse} name='Adresse' onChange={this.onChangeAdresse.bind(this)} style={{ width: '100%' }}></textarea>
          </div>
        </div>
        <div className="clear" style={{ marginBottom: '10px' }}></div>
        <div className="form-group">
          <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label text-right">CP / Ville</label>
          <div className="col-lg-4 col-md-4 col-sm-3">
            <InputMask mask="99999" maskChar=""  type="text" autoComplete="off" className="form-control" value={this.state.prospect.CodePostal ? this.state.prospect.CodePostal : ''} name='CodePostal' onChange={this.onChangeValue.bind(this)} pattern="[0-9]{5}" style={{ paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }} />
          </div>
          <div className="col-lg-5 col-md-4 col-sm-1" style={{ paddingLeft: '0px' }}>
            <input type="text" className='form-control' value={this.state.prospect.Ville ? this.state.prospect.Ville : ''} name='Ville' onChange={this.onChangeValue.bind(this)} />
          </div>
        </div>
        {localStorage.GestionVIP === "true" ?
        <div className='form-group'>
            <label style={{marginTop:"4px"}} className='col-lg-3 col-md-4 col-sm-3 col-xs-12 text-right' htmlFor='ClientVIP'>
                Client VIP
            </label>
            <input style={{display: "block"}} name="ClientVIP" type="checkbox" id="ClientVIP" onChange={this.onChangeValue.bind(this)} checked={this.state.prospect.ClientVIP} />
        </div>: null}

        {this.state.fidelites && this.state.fidelites.length ?
          <div className="form-group">
            <label className="col-sm-3 control-label" style={{whiteSpace:'nowrap'}}>Carte de fidélité</label>
            <div className="col-sm-9">
              <select name="IDFidelite" className="form-control" value={this.state.prospect.IDFidelite?this.state.prospect.IDFidelite:''} onChange={(e) => this.onChangeValue(e)} disabled={this.state.fidelites === null}>
                <option></option>
                  {this.state.fidelites.map((f) => {
                    return <option value={f.IDFidelite} key={"fidelite-"+f.IDFidelite}>{f.Descr}</option>
                  })}
              </select>  
            </div>
          </div>: null}

        {this.state.fidelites && this.state.libFidelite.toLowerCase() === "oui" ? 
        <div className="form-group">
          <label className="col-sm-3 control-label" style={{whiteSpace:'nowrap'}}>Info fidélité</label>
          <div className="col-sm-9">
            <input type='text' autoComplete='off' name="CommentaireFidelite" className="form-control" value={this.state.prospect.CommentaireFidelite ? this.state.prospect.CommentaireFidelite : ''} onChange={(e) => this.onChangeValue(e)} /> 
          </div>
        </div>:null}
        
        <div className="form-group">
          <div className="col-lg-offset-2 col-lg-offset-3 col-md-offset-4 col-sm-offset-3 col-lg-9 col-md-8 col-sm-9 col-xs-12">
            {!this.props.noDisplayButtonSave && this.state.changeValues ?
              <button type="submit" className="text-center btn btn-default" disabled={this.state.updateInProgress}>
                {this.state.updateInProgress ? <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &nbsp;</span> : null}
                Sauvegarder la modification
              </button>
            : null}
          </div>
        </div>
        
      </div>
    </div>

    {localStorage.IsAgri === "true" && this.state.prospect.TypeClient === "Societe" && !localStorage.bypass_iFrame ? 
      <div style={{marginLeft:'15px', marginTop:'10px',marginBottom:'10px', marginRight:'15px', display:'inline-block'}}>
        <ListTypesProduction IDProspect={this.state.prospect.IDProspect}/>
      </div>
      /*<div className="form-group">
        <div className="col-lg-offset-2 col-lg-offset-3 col-md-offset-4 col-sm-offset-3 col-lg-9 col-md-8 col-sm-9 col-xs-12">
          <button type="button" className="text-center btn btn-default btn-xs" onClick={() => this.openTypesProduction()}>
            Informations commerciales
          </button>
        </div>
            </div>*/
    :null}

    {this.state.prospect.TypeClient === 'Societe' ?
      <div style={{marginLeft:'15px', marginTop:'10px',marginBottom:'10px', marginRight:'15px'}}>
        <ListInterlocuteurs IDProspect={this.state.prospect.IDProspect}/>
      </div>
    :null}

    <div className="row">
          {this.props.typesCreate && this.props.typesCreate["warning"] !== false && this.leadTypeExist()?
            <div className="col-md-12"  style={{ paddingBottom: '20px', marginTop:'10px'}}>
              <span className="color red">Le prospect dispose déjà d'affaires du même type :</span>
              <ul>
                {this.props.typesCreate["leads"].map((affaire, i) => {
                  return (affaire.Type === this.props.typeActive) ?
                        (affaire.fetchLeadDone && !localStorage.bypass_iFrame) ?
                              <li key={i}><Link to={(affaire.Type === 'Atelier' || affaire.Type === 'Autres' ? '/rapportAtelier/' : '/rapport/')+affaire.IDLead}>{Dictionary.getUCFirst(affaire.Type)} :  {moment(affaire.DateDebut).format('DD/MM/Y à HH:mm')}</Link></li>
                              : <li key={i}>{Dictionary.getUCFirst(affaire.Type)} :  {moment(affaire.DateDebut).format('DD/MM/Y à HH:mm')}</li>
                        : "" ;
                  }
                )}
              </ul>
            </div>
          : null}
    </div>
    </div>
    </div>;
  }

  render() {

    if (!this.state.prospect) {
      return null;
    }

    if(!this.props.noDisplayButtonSave){
      return (<form className="form-horizontal" onSubmit={this.handleSubmit.bind(this)}> {this.returnFullForm()} </form>);
    }
    else{
      return (<div> {this.returnFullForm()} </div>);
    }
  }
}

export default FormEdit;
