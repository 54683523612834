import Globals from '../Globals';

const LibLeads = {

  /**
   * Récupère les leads visibles par l'utilisateur connecté suivant un type "typeDashboard" (comme sur l'appli mobile)
   * Utilisé sur la page Action
   * @param typeDashboard string
   * @return array
   */
  async getLeads(typeDashboard) {
    try {
      const response = await fetch(localStorage.ClientApiURL+'/leads?type_dashboard='+typeDashboard, {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  },
}

export default LibLeads;