import React, {Component} from 'react';
import Globals from '../Globals';

var lodash = require('lodash')

class FormSocieteSiteVO extends Component {

  constructor(props) {
    super(props);

    this.state = {
      societes: [],
      sites: [],
      _plaques: [],
      userSites:[],
    }
  }

  componentWillMount() {
    if(this.props.origin === "CallWin")
      this.fetchSocietesSites();
    else 
      this.fetchUserSocietesSites();
    this.loadAffectation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.prospect !== prevProps.prospect) {
      this.loadAffectation();
    }
  }

  loadAffectation(){
    if(this.props.prospect && this.props.prospect.IDProspect && localStorage.bypass_iFrame !== "true"){
      if(this.props.prospect.SiteAffectation && this.props.prospect.SiteAffectation.EstVO === true){
        this.props.onChangeValue('societe', this.props.prospect.SiteAffectation.Plaque.IDPlaque);
        this.props.onChangeValue('site', this.props.prospect.SiteAffectation.IDSite);
        this.props.onChangeValue('activite', this.props.prospect.SiteAffectation.Activite);
      }
      else{
        this.props.onChangeValue('marque', '');
        this.props.onChangeValue('societe', 0);
        this.props.onChangeValue('site', 0);
        this.props.onChangeValue('activite', null);
      }
    }
  }

  fetchUserSocietesSites(){
    fetch(localStorage.ClientApiURL + '/Utilisateurs/'+localStorage.user_IDUtilisateur, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        userSites: lodash.map(json.Sites, 'IDSite'),
      }, this.fetchSocietesSites);
    })
    .catch((error) => {
        console.log(error)
    });
  }

  fetchSocietesSites() {
    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var societes = [];
      var sites = [];

      lodash.map(json, (plaque) => {
        var sites = lodash.filter(plaque.Sites, (site) => {
          return site.Est_eSellerLeadActif === true 
            && (site.EstVO === true)
            && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1);
        });

        if (!sites.length) {
          return;
        }

        return;
      });

      json.map((plaque) => {
        return societes[plaque.IDPlaque] = plaque.Nom;
      })

      json.map((plaque) => {
        var index = societes.indexOf(plaque.Nom);
        sites[index] = [];
        return plaque.Sites.map((site) => {
          //récupération des sites actifs, Libelle s'il existe, et VO pour affichage
          if(site.Est_eSellerLeadActif === true 
            && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1)
            && (site.EstVO === true)) {
              return sites[index][site.IDSite] = site.Libelle ? site.Libelle : site.Nom;
          }
          return null;
        })
      })

      this.setState({
        societes: societes,
        sites: sites,
        _plaques: json,
      }, () => {
        if(this.props.site) { //S'il y a un site pré-sélectionné on récupère son activité afin d'avoir le bon formulaire
          const site = this.getSite(this.props.site);
          this.props.onChangeValue('activite', site ? site.Activite : null)
        }
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleClickCanal(event) {
    this.props.onChangeValue('canal', event.target.value);
  }

  handleChangeSociete(event) {
    this.props.onChangeValue('societe', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('site', 0);
    this.props.onChangeValue('activite', null);
    this.props.onChangeValue('todoToVendeur', false);
  }

  handleChangeSite(event) {
    this.props.onChangeValue('site', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('todoToVendeur', false);
    const site = event.target.value ? this.getSite(event.target.value) : null
    this.props.onChangeValue('activite', site ? site.Activite : null)
  }

  getSite(IDSite) {
    const plaque = lodash.find(this.state._plaques, (p) => p.IDPlaque == this.props.societe)
    if(!plaque) {
      return null;
    }

    return lodash.find(plaque.Sites, (s) => s.IDSite == IDSite)
  }

  //TODO: Toujours appelé par ref il y a sûrement moyen de faire mieux
  generateAll(){
    this.fetchSocietesSites();
    this.props.onChangeValue('marque', '');
    if(!this.props.callwin){
      this.props.onChangeValue('societe', 0);
      this.props.onChangeValue('site', 0);
      this.props.onChangeValue('activite', null);
    }
    this.loadAffectation();
  }

  render() {
    return (
      <div >
        {this.props.location  && this.props.location === 'CallWin' ?
          <h2 style={{color:this.props.textColor, marginLeft: this.props.marginleft}}>
              <img src="/img/HOME Empty.svg" role='presentation'/> Société / Site
          </h2> :  <h3>Société / Site</h3>
        }

        <div className="form-group">
          <div className="col-md-8 col-md-offset-2">
            <select className="form-control" onChange={this.handleChangeSociete.bind(this)} id="leadSociete" name="leadSociete" value={this.props.societe} required="required">
              <option key="empty" value=''>Société</option>
              {this.state._plaques.map((societe, id) => {
                return this.state.sites[societe.IDPlaque].length ? <option key={societe.IDPlaque} value={societe.IDPlaque}>{ societe.Nom }</option> : null;
              })}
            </select>
          </div>
        </div>

        {(this.props.societe || this.props.societe == 0) && this.props.societe !== "Société" ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="leadSite" name="leadSite" value={this.props.site} required="required">
                <option value="" key="0">Site</option>
                {this.state._plaques.map((societe, id) => {
                  if (parseInt(societe.IDPlaque, 10) === parseInt(this.props.societe, 10)) {
                    return societe.Sites.map((site) => {
                      if (site.EstVO && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1)) {
                        return <option key={site.IDSite} value={site.IDSite} >{ site.Libelle ? site.Libelle : site.Nom }</option>;
                      }
                      return null;
                    })
                  }
                  return null;
                })}
              </select>
            </div>
          </div>
        : null }

      </div>
    );
  }
}

export default FormSocieteSiteVO;
