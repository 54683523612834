import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';
import moment from 'moment'

class Offre extends Component {

  constructor(props) {
    super(props);

    this.state = {
      offre: null,
      monnaie: "€",
    }
  }

  componentDidMount() {
    if(localStorage.CodeLangue == "fr-MA"){
      this.setState({
        monnaie: "DH"
      });
    }

    if (this.props.IDOffre) {
      this.fetchOffre();
    }
  }

  fetchOffre() {
    fetch(localStorage.ClientApiURL + '/Offres/' + this.props.IDOffre, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        offre: json,
      }, () => {
        console.log(this.state)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  getTotalOffreTTC() {
    var total = this.getTotalVenteTTC();

    this.state.offre.Reprises.map((r) => {
      total -= r.Montant;
    })

    return total;
  }

  getTotalVenteTTC() {
    var total = 0.0;

    this.state.offre.Vehicules.map((v) => {
      total += v.Montant;
    })

    this.state.offre.Options.map((o) => {
      total += o.Montant;
    })

    this.state.offre.Accessoires.map((a) => {
      total += a.Montant;
    })

    this.state.offre.LignesFrequentes.map((l) => {
      total += l.Montant;
    })

    this.state.offre.Remises.map((l) => {
      total -= l.Montant;
    })

    return total;
  }

  renderVentes() {
    return (<div>
      <div className='row'>
        <div className='col-md-12'>
          {this.state.offre.Vehicules.map((v, i) => {
              return (<div key={i}>
                  <h2><i className='fa fa-car'></i> {v.Designation} <span className='pull-right'>{v.Montant.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</span></h2>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Réf. DMS</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.Vehicule ? v.Vehicule.RefDMS : '-'}</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Véhicule</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.Vehicule ? v.Vehicule.Type : 'Catalogue'}</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Code modèle</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.CodeModele ? v.CodeModele : '-'}</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Emissions de CO2</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.ConsoPollution ? v.ConsoPollution + ' g/km' : '-'}</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Couleur Extérieure</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.CouleurExt ? v.CouleurExt : '-'}</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Sellerie Intérieure</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.CouleurInt ? v.CouleurInt : '-'}</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Garantie</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{v.Garantie ? v.Garantie.Designation + ' ('+ v.Garantie.Duree + ' '+ v.Garantie.Unite +')' : '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)
          })}
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h4>Options</h4>
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <tbody>
              {this.state.offre.Options.map((o, i) => {
                return (<tr key={i}>
                    <td>{o.Designation}</td>
                    <td className='text-right'>{o.Montant.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h4>Accessoires</h4>
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <tbody>
              {this.state.offre.Accessoires.map((o, i) => {
                return (<tr key={i}>
                    <td>{o.Designation}</td>
                    <td className='text-right'>{o.Montant.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h4>Prestations</h4>
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <tbody>
              {this.state.offre.LignesFrequentes.map((o, i) => {
                return (<tr key={i}>
                    <td>{o.Designation}</td>
                    <td className='text-right'>{o.Montant.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h4>Remises</h4>
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <tbody>
              {this.state.offre.Remises.map((o, i) => {
                return (<tr key={i}>
                    <td>{o.Designation}</td>
                    <td className='text-right'>- {o.Montant.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <div className='pull-right' style={{ fontWeight: 'bold' }}>Total vente TTC : {this.getTotalVenteTTC().toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</div>
        </div>
      </div>
    </div>)
  }

  render() {

    if (!this.state.offre)
      return <Loader />

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <h1>{this.state.offre.Statut} n° {this.props.IDOffre} <span className='pull-right' style={{ fontSize: '20px'}}>Total offre TTC : {this.getTotalOffreTTC().toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</span></h1>

            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Date offre</label>
                  <div className="col-sm-9">
                    <p className="form-control-static">{moment(this.state.offre.Date).format('DD/MM/YYYY')}</p>
                  </div>
                </div>
                {this.state.offre.DateValidite != this.state.offre.Date ?
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Fin de validité</label>
                    <div className="col-sm-9">
                      <p className="form-control-static">{moment(this.state.offre.DateValidite).format('DD/MM/YYYY')}</p>
                    </div>
                  </div>
                : null}
                {this.state.offre.DateCommande ?
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Date de commande</label>
                    <div className="col-sm-9">
                      <p className="form-control-static">{moment(this.state.offre.DateCommande).format('DD/MM/YYYY')}</p>
                    </div>
                  </div>
                : null}
              </div>
              <div className='col-md-6'>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Site</label>
                  <div className="col-sm-9">
                    <p className="form-control-static">{this.state.offre.Site.Plaque.Nom} - {this.state.offre.Site.Nom}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Vendeur</label>
                  <div className="col-sm-9">
                    <p className="form-control-static">{this.state.offre.SuiviPar.Nom}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active">
                <a href="#ventes" aria-controls="ventes" role="tab" data-toggle="tab">
                  Ventes
                </a>
              </li>
              <li role="presentation">
                <a href="#reprises" aria-controls="reprises" role="tab" data-toggle="tab">
                  Reprises ({this.state.offre.Reprises.length})
                </a>
              </li>
              <li role="presentation">
                <a href="#financement" aria-controls="financement" role="tab" data-toggle="tab">
                  Financements ({this.state.offre.Financements.length})
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div role="tabpanel" className="tab-pane active" id="ventes">
                {this.renderVentes()}
              </div>

              <div role="tabpanel" className="tab-pane" id="reprises">
                <div className='row'>
                  <div className='col-md-12'>
                    <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                      <tbody>
                        {this.state.offre.Reprises.map((o, i) => {
                          return (<tr key={i}>
                              <td>{o.Designation}</td>
                              <td className='text-right'>{o.Montant.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</td>
                            </tr>)
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div role="tabpanel" className="tab-pane" id="financement">
                {this.state.offre.Financements.length ?
                  <div className='row'>
                    <div className='col-md-12'>
                      <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                        <thead>
                          <tr>
                            <td>Numéro dossier</td>
                            <td>Status</td>
                            <td>Organisme</td>
                            <td>Type</td>
                            <td>Montant financé</td>
                            <td>Durée (mois)</td>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.offre.Financements.map((o, i) => {
                            return (<tr key={i}>
                                <td>{o.IDOffreFinancement}</td>
                                <td>{o.Status}</td>
                                <td>{o.Organisme}</td>
                                <td>{o.Type}</td>
                                <td>{o.MontantFinance.toLocaleString('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2})} {this.state.monnaie}</td>
                                <td>{o.DureeMois}</td>
                              </tr>)
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Offre;
