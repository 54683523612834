import React, { Component } from 'react';

import Tooltip from "rc-tooltip";

class PastilleVendeur extends Component {
  render() {
    const colors = {
      "Vendeur": "purple",
      "ChefDesVentes": "purple",
      "BDC": "orange",
      "WelcomePro": "blue",
      "Conseiller": "green",
      null: "purple"
    };

    if (!this.props.utilisateur) {
      return <span />
    }

    let color = colors[this.props.utilisateur.TypeProfilLead];
    if(!color)
      color = "gray"

    if(this.props.utilisateur.IDUtilisateur == 0) {
      return <span className={'pastille big ' + color}>{this.props.utilisateur.Initiale}</span>
    }

    return <Tooltip overlay={<div>{this.props.utilisateur.Nom}</div>}>
      <span className={'pastille big ' + color}>{this.props.utilisateur.Initiale}</span>
    </Tooltip>
  }
}

export default PastilleVendeur;
