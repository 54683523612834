import React, { Component } from 'react';

import moment from 'moment'

var lodash = require('lodash')

class InputTime extends Component {

  constructor(props) {
    super(props)

    moment.locale('fr');

    this.state = {
      hours: this.props.value ? parseInt(this.props.value.get('hours'), 10) : null,
      minutes: this.props.value ? parseInt(this.props.value.get('minutes'), 10) : null,
      afterMinute: null,
      afterHour: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.after !== this.props.after) {
      if(nextProps.after){
        var hm = nextProps.after.split(':');
        this.setState({
          afterHour: hm[0],
          afterMinute: hm[1]
        })
      }
      else {
        this.setState({
          afterHour: null,
          afterMinute: null
        })
      }
    }
  }

  componentDidMount(){
    if(this.props.after){
      var hm = this.props.after.split(':');
      this.setState({
        afterHour: hm[0],
        afterMinute: hm[1]
      })
    }
  }

  onChangeHours(event) {
    var time = this.props.value ? this.props.value : moment();
    if (!this.props.value) {
      time.set('minutes', 0)
    }
    time.set('hours', event.target.value);
    this.props.onChange(time)
    this.setState({
      hours: event.target.value
    })
  }

  onChangeMinutes(event) {
    var time = this.props.value ? this.props.value : moment();
    time.set('minutes', event.target.value);
    this.props.onChange(time)
    this.setState({
      minutes: event.target.minutes
    })
  }

  render() {
    return (
      <div className='row flex-box'>
        <div className='col-md-6' style={{ paddingRight: '0px',  boxSizing: 'content-box', marginRight: '4%', width: '50%' }}>
          <select className='form-control' style={{ paddingRight: '0px'}} onChange={this.onChangeHours.bind(this)} style={this.props.SelectStyle ? this.props.SelectStyle : {}}>
            <option value='' key='0'></option>
            {lodash.range(7, 21, 1).map(value => {  
              if(this.state.afterHour && this.state.afterHour > value)
                return null;
              return <option key={value} value={value} selected={this.props.value && value === parseInt(this.props.value.get('hours'), 10)}>{lodash.padStart(value, 2, '0')}h</option> 
            })}
          </select>
        </div>
        <div className='col-md-6' style={{ paddingLeft: '2px',   boxSizing: 'content-box', width: '50%' }}>
          <select className='form-control' style={{ paddingRight: '0px'}} onChange={this.onChangeMinutes.bind(this)} style={this.props.SelectStyle ? this.props.SelectStyle : {}}>
            <option value='' key='0'></option>
            {lodash.range(0, 60, 15).map(value => {  
              if(this.state.afterHour && this.state.afterHour == this.state.hours && this.state.afterMinute > value)
                return null;
              return <option key={value} value={value} selected={this.props.value && value === parseInt(this.props.value.get('minutes'), 10)}>{lodash.padStart(value, 2, '0')}</option> 
            })}
          </select>
        </div>
      </div>
    );
  }
}

export default InputTime;
