import React, { Component } from 'react';

import Loader from '../Loader';
import Globals from '../../Globals';

import moment from 'moment'

class OverlayVehicule extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      vehicules: [],
      moreVehicules: 0,
      loaded: false,
    }
  }

  componentDidUpdate(prevProps){
    if(!this.state.loaded){
      this.setState({
        loaded: true
      }, () => {
        if(this.state.vehicules.length === 0)
          this.loadVehicules();
      })
    }
  }

  loadVehicules() {
    console.log('load')
    if (this.props.prospect && this.props.prospect.LiensVehicules) {
      for(let i in this.props.prospect.LiensVehicules){
        if(i == 10){
          this.setState({
            moreVehicules: this.props.prospect.LiensVehicules.length - 10
          })
          break;
        }

        this.loadVehicule(this.props.prospect.LiensVehicules[i].IDVehicule);
      }
    }
  }

  loadVehicule(IDVehicule) {
    fetch(localStorage.ClientApiURL + '/Vehicules/' + IDVehicule, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var vehicules = this.state.vehicules;
      vehicules.push(json);
      this.setState({
        vehicules: vehicules,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {
    return (<div style={{ width: '300px' }}>
      {this.state.vehicules.length ?
        <React.Fragment>
        {
          this.state.vehicules.map((vehicule, i) => {
            return <div key={i} style={{ fontFamily: 'sans-serif', fontWeight: 'light', fontSize: '11px', marginBottom: '2px', marginTop: '2px' }}>{vehicule.Marque} {vehicule.Modele} {vehicule.Version} {vehicule.Immatriculation}</div>
          })
        }
        {this.state.moreVehicules > 0 ?
          <div style={{ fontFamily: 'sans-serif', fontWeight: 'light', fontSize: '11px', marginBottom: '2px', marginTop: '2px' }}>+ {this.state.moreVehicules} autre{this.state.moreVehicules > 1 ? "s" : ""} véhicule{this.state.moreVehicules > 1 ? "s" : ""}</div>
        :null}
        </React.Fragment>
      : 
        <Loader styleIcon={{ fontSize: '12px', color: 'white' }} /> 
      }
    </div>);
  }
}

export default OverlayVehicule;
