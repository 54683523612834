import React, { Component } from 'react';
import { Link } from 'react-router';

import Globals from '../Globals';
import FilterOrigine from '../Leads/FilterOrigine';
import FilterAction from '../Leads/FilterAction';
import FilterSocieteSite from '../Leads/FilterSocieteSite';
import FilterCampagne from '../Leads/FilterCampagne';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowComment from '../Components/TableRowComment';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import TableRowSite from '../Components/TableRowSite';

import Loader from '../Components/Loader';
import FilterDate from '../Components/FilterDate';

import PastilleVendeur from '../Components/PastilleVendeur';

import moment from 'moment'
import LibUtilisateurs from "../Lib/Utilisateurs";
import LibInfo from "../Lib/Info";
import LibRapports from "../Lib/Rapports";
import SweetAlert from "react-bootstrap-sweetalert";

let lodash = require('lodash')

class Actions extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
            leads: [],
            filters: [],
            distinctEtapes: [],
            distinctActions: [],
            distinctInterets: [],
            distinctCampagnes: [],
            displaySidebar: true,
            cacheLead: [],
            selectedProspect: null,
            dateStart: moment().startOf('days'),
            dateEnd: moment().endOf('days'),
            selectedActions: [],
            selectedActionsIDSite: null,
            utilisateursTransfert: [],
            showTransfertForm: false,
            transfertTo: null,
            fetchingUserInfo: true,
            userInfo: null,
            showAlertMessage: false,
            submitting: false,
            submittingMessage: null,
        }
    }

    componentWillMount() {
        this.fetchDatas();
    }

    async componentDidMount() {
        await this.fetchUserInfo();
    }

    async fetchUserInfo() {
        this.setState({
            fetchingUserInfo: true
        })
        var userInfo = await LibInfo.get();
        userInfo = lodash.pick(userInfo, ["Utilisateur.TypeProfilLead", "Utilisateur.IDUtilisateur", "BDGp"]);
        this.setState({
            userInfo: userInfo,
            fetchingUserInfo: false
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.type !== this.props.params.type) {
            this.setState({
                leads: [],
                cacheLead: [],
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.params.type === prevProps.params.type) {
            return;
        }

        this.setState({
            leads: [],
            filters: [],
            distinctEtapes: [],
            distinctActions: [],
            distinctInterets: [],
            distinctVendeurs: [],
            distinctCampagnes: [],
            cacheLead: [],
            selectedProspect: null,
            fetchInProgress: false,
            dateStart: moment().startOf('days'),
            dateEnd: moment().endOf('days'),
        }, () => {
            this.fetchDatas();
        })
    }

    async fetchDatas() {
        this.setState({
            fetchInProgress: true,
        });

        var leads = [];

        if (localStorage.access_superviseurBDC === 'true') {

            var mes_sites = !(localStorage.EstSuperviseur === 'true');

            await fetch(localStorage.ClientApiURL + '/Leads?a_redistribuer=&mes_sites=' + mes_sites + '&type_profil=BDC&date_relance_fin=' + moment(this.state.dateEnd).format('Y-MM-DDT23:59:59'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKey': Globals.API_KEY,
                    'IDClient': localStorage.user_IDClient,
                    'Login': localStorage.user_login,
                    'Password': localStorage.user_password
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((json) => {
                    leads = json;
                })
                .catch((error) => {
                    console.log(error)
                });

        }

        fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + '/rapports?date_relance_fin=' + moment(this.state.dateEnd).format('Y-MM-DDT23:59:59'), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {

                leads = lodash.uniqBy(lodash.concat(json, leads), "IDLead");

                this.setState({
                    leads: leads,
                }, () => {
                    this.generateCacheLeads();
                    this.generateDistinctActions();
                    this.generateDistinctEtapes();
                    this.generateDistinctInterets();
                    this.generateDistinctCampagnes();
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    generateDistinctActions() {
        const actions = lodash.countBy(this.state.leads, function (lead) {
            return lead.ActionAFaire_TODO.ActionAFaire ? lead.ActionAFaire_TODO.ActionAFaire.Description : "";
        });

        if (this.isNissan()) {
            const nouveauxContactRDVAAffecter = lodash.filter(this.state.leads, (lead) => {
                return (lead.ActionAFaire_TODO.ActionAFaire && lead.ActionAFaire_TODO.ActionAFaire.Description === "RDV Atelier")
                    && (lead.SuiviPar && lead.SuiviPar.IDUtilisateur === 0)
            })
            if (nouveauxContactRDVAAffecter.length > 0) {
                actions["Nouveaux contacts RDV Atelier à affecter"] = nouveauxContactRDVAAffecter.length
            }
        }

        this.setState({ distinctActions: actions });
    }

    isNissan() {
        return this.state.userInfo && this.state.userInfo.BDGp.indexOf("BDGpNi") !== -1;
    }

    generateDistinctEtapes() {
        var etapes = lodash.countBy(this.state.leads, function (lead) {
            return lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
        });
        this.setState({ distinctEtapes: etapes });
    }

    generateDistinctCampagnes() {
        var campagnes = lodash.countBy(this.state.leads, function (lead) {
            return lead.Campagne ? lead.Campagne.Nom : '';
        });
        this.setState({ distinctCampagnes: campagnes });
    }

    generateDistinctInterets() {
        var niveaux = [];

        var interets = lodash.countBy(this.state.leads, function (lead) {

            if (niveaux.indexOf(lead.ActionAFaire_TODO.NiveauInteret.Niveau) < 0) {
                niveaux[lead.ActionAFaire_TODO.NiveauInteret.Niveau] = {
                    name: lead.ActionAFaire_TODO.NiveauInteret.Description,
                    niveau: lead.ActionAFaire_TODO.NiveauInteret.Niveau
                }
            }
            return lead.ActionAFaire_TODO.NiveauInteret.Niveau;
        });

        lodash.forIn(interets, function (value, key) {
            niveaux[key].count = value;
        });

        this.setState({ distinctInterets: niveaux });
    }

    generateCacheLeads() {
        let leads = this.state.leads;

        if (this.state.filters) {
            if (this.state.filters['origines'] && this.state.filters['origines'].length) {
                const filter_origines = this.state.filters['origines'];
                leads = lodash.filter(leads, function (lead) {
                    const check = lead.ActionFaite_DO.OrigineAction ? lead.ActionFaite_DO.OrigineAction.Famille + "-" + lead.ActionFaite_DO.OrigineAction.SousFamille + "-" + lead.ActionFaite_DO.OrigineAction.Origine : "";
                    return filter_origines.indexOf(check) > -1;
                });
            }

            let filtersActions = this.state.filters['actions'] ? this.state.filters['actions'] : []

            const standardFilters = filtersActions.filter(item => item !== "Nouveaux contacts RDV Atelier à affecter")

            if (standardFilters && standardFilters.length) {
                leads = lodash.filter(leads, function (lead) {
                    const check = lead.ActionAFaire_TODO.ActionAFaire ? lead.ActionAFaire_TODO.ActionAFaire.Description : "";
                    return standardFilters.indexOf(check) > -1;
                });
            }

            if (this.isNissan()) {
                if (filtersActions && filtersActions.length) {
                    if (filtersActions.includes("Nouveaux contacts RDV Atelier à affecter")) {
                        leads = lodash.filter(leads, function (lead) {
                            return (lead.ActionAFaire_TODO.ActionAFaire && lead.ActionAFaire_TODO.ActionAFaire.Description === "RDV Atelier")
                                && (lead.SuiviPar && lead.SuiviPar.IDUtilisateur === 0)
                        })
                    }
                }
            }

            if (this.state.filters['etapes'] && this.state.filters['etapes'].length) {
                var filter_etapes = this.state.filters['etapes'];
                leads = lodash.filter(leads, function (lead) {
                    var check = lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
                    return filter_etapes.indexOf(check) > -1;
                });
            }

            if (this.state.filters['sites'] && this.state.filters['sites'].length) {
                var filter_sites = this.state.filters['sites'];
                leads = lodash.filter(leads, function (lead) {
                    return filter_sites.indexOf(lead.Site ? lead.Site.IDSite : "") > -1;
                });
            }

            if (this.state.filters['vendeurs'] && this.state.filters['vendeurs'].length) {
                var filter_vendeurs = this.state.filters['vendeurs'];
                leads = lodash.filter(leads, function (lead) {
                    return filter_vendeurs.indexOf(lead.SuiviPar.Nom) > -1;
                });
            }

            if (this.state.filters['campagnes'] && this.state.filters['campagnes'].length) {
                var filter_campagnes = this.state.filters['campagnes'];
                leads = lodash.filter(leads, function (lead) {
                    return filter_campagnes.indexOf(lead.Campagne ? lead.Campagne.Nom : '') > -1;
                });
            }
        }

        leads.sort(function (a, b) {
            var date_a, date_b;

            date_a = moment(a.ActionAFaire_TODO.DateHRelance);
            date_b = moment(b.ActionAFaire_TODO.DateHRelance);

            if (!date_a || !date_b) {
                return -1;
            }

            if (date_a < date_b)
                return -1;

            if ((date_a.format('DMY HH:mm') === date_b.format('DMY HH:mm')) && (parseInt(a.IDLead, 10) && parseInt(b.IDLead, 10))) {
                if (parseInt(a.IDLead, 10) < parseInt(b.IDLead, 10)) {
                    return -1;
                }
            }

            return 1;
        });

        this.setState({ cacheLead: leads, fetchInProgress: false })
    }

    onChangeFilters(type, datas) {
        var currentFilters = this.state.filters;
        currentFilters[type] = datas;
        this.setState({ filters: currentFilters }, () => {
            this.generateCacheLeads();
        });
    }

    onChangeDate(start, end) {
        this.setState({
            dateStart: start,
            dateEnd: end,
        }, () => {
            this.fetchDatas();
        })
    }

    reloadLeads() {
        this.fetchDatas();
    }

    selectAction(IDLead, IDSite) {
        let selectedActions = this.state.selectedActions;
        let selectedActionsIDSite = this.state.selectedActionsIDSite;
        let utilisateursTransfert = this.state.utilisateursTransfert;
        let showTransfertForm = this.state.showTransfertForm;
        let transfertTo = this.state.transfertTo;
        if (selectedActions.indexOf(IDLead) !== -1) {
            selectedActions = selectedActions.filter(s => s != IDLead);
            if (selectedActions.length === 0) {
                selectedActionsIDSite = null;
                utilisateursTransfert = [];
                showTransfertForm = false;
                transfertTo = null;
            }
        } else {
            selectedActions.push(IDLead);
            if (selectedActions.length === 1) {
                selectedActionsIDSite = IDSite;
            }
        }

        this.setState({
            selectedActions: selectedActions,
            selectedActionsIDSite: selectedActionsIDSite,
            utilisateursTransfert: utilisateursTransfert,
            showTransfertForm: showTransfertForm,
            transfertTo: transfertTo
        })
    }

    showTransfertForm() {
        var IDSite = (this.state.tab === "actions" ? this.state.selectedActionsIDSite : this.state.selectedLeadsIDSite);
        var type = (this.state.tab === "actions" ? null : this.state.selectedLeadsType);
        this.fetchUtilisateursTransfert(IDSite, type);
        this.setState({
            showTransfertForm: true
        })
    }

    onChangeTransfertTo(e) {
        this.setState({
            transfertTo: e.target.value
        })
    }

    async fetchUtilisateursTransfert(IDSite, typeLead = null) {
        this.setState({
            fetchingUtilisateursTransfert: true
        })
        let utilisateurs = await LibUtilisateurs.getUtilisateursManages(this.state.userInfo.Utilisateur.IDUtilisateur, IDSite);
        if (typeLead) {
            utilisateurs = lodash.filter(utilisateurs, u => {
                if (typeLead === "VN") {
                    return u.EstTypeVN
                } else if (typeLead === "VN") {
                    return u.EstTypeVO
                } else if (typeLead === "Atelier") {
                    return u.EstTypeConseiller
                } else if (typeLead === "Autres") {
                    return u.EstTypeAutre
                }
            })
        }
        utilisateurs = lodash.orderBy(utilisateurs, 'Nom');
        this.setState({
            fetchingUtilisateursTransfert: false,
            utilisateursTransfert: utilisateurs
        })
    }

    renderTransfertForm(disableButton) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: '36px' }}>
                {this.state.showTransfertForm ?
                    this.state.utilisateursTransfert.length ?
                        <div className="form-group" style={{
                            marginBottom: '0px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <div className='text-nowrap'>Transférer à&nbsp;</div>
                            <select className="form-control" id="vendeur" name="vendeur"
                                    onChange={this.onChangeTransfertTo.bind(this)} value={this.state.transfertTo || ""}
                                    required={this.state.showTransfertForm}>
                                <option></option>
                                <option value={this.state.userInfo.Utilisateur.IDUtilisateur}>Moi-même</option>
                                {this.state.utilisateursTransfert.map((utilisateur, i) => {
                                    return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                })}
                            </select>
                            &nbsp;
                            <button onClick={this.submitTransfertActions.bind(this)}
                                    disabled={!this.state.transfertTo || this.state.submitting}
                                    className='btn btn-default' style={{ borderRadius: 5 }}>
                                {this.state.submitting ?
                                    <i className="fa fa-spinner fa-spin fa-fw" style={{ marginRight: 5 }}
                                       aria-hidden="true"></i>
                                    : null}
                                Valider
                            </button>
                        </div>
                        :
                        <div className="form-group" style={{
                            marginBottom: '0px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            {this.state.fetchingUtilisateursTransfert ?
                                <label>Récupération des utilisateurs...</label>
                                :
                                <label className="warning">[Aucun vendeur paramétré]</label>
                            }
                        </div>
                    :
                    <button onClick={this.showTransfertForm.bind(this)} disabled={disableButton}
                            className='btn btn-default' style={{ borderRadius: 5 }}>Transférer à un vendeur</button>
                }
            </div>
        )
    }

    async submitTransfertActions() {
        this.setState({
            submitting: true,
            submittingMessage: "Transfert des actions sélectionnées..."
        })
        let actions = lodash.filter(this.state.leads, (lead) => {
            return this.state.selectedActions.indexOf(lead.IDLead) !== -1;
        });


        let result = await LibRapports.transfertManyTo(actions, this.state.transfertTo);

        if (result) {
            let leads = lodash.filter(this.state.leads, (lead) => {
                return this.state.selectedActions.indexOf(lead.IDLead) === -1;
            });

            this.setState({
                submitting: false,
                showTransfertForm: false,
                utilisateursTransfert: [],
                transfertTo: null,
                leads: leads,
                showAlertMessage: "Transfert effectué avec succès"
            })
            this.reloadLeads()
        } else {
            alert("Erreur lors du transfert d'au moins une action");
        }
    }

    render() {
        return (
            <div>
                {this.state.submitting ?
                    <SweetAlert title="" showConfirm={false} onConfirm={() => {
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                            <span style={{ marginTop: 15 }}>{this.state.submittingMessage}</span>
                        </div>
                    </SweetAlert>
                    : null
                }

                {this.state.showAlertMessage ?
                    <SweetAlert success title="" onConfirm={() => {
                        this.setState({ showAlertMessage: false })
                    }}>
                        <span>{this.state.showAlertMessage}</span>
                    </SweetAlert>
                    : null
                }
                <div className="page-sidebar-left">

                    <div id="sidebar" className='page-sidebar-left-sidebar'>
                        <FilterCampagne campagnes={this.state.distinctCampagnes}
                                        onChange={this.onChangeFilters.bind(this)}/>
                        <FilterAction actions={this.state.distinctActions} onChange={this.onChangeFilters.bind(this)}/>
                        <FilterOrigine leads={this.state.leads} onChange={this.onChangeFilters.bind(this)}/>
                        <FilterSocieteSite leads={this.state.leads} onChange={this.onChangeFilters.bind(this)}/>
                    </div>

                    <div className='page-sidebar-left-content'>
                        <div className="">
                            <FilterDate onChangeDate={this.onChangeDate.bind(this)} type={this.props.params.type}
                                        className='pull-left'/>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "end"
                            }}>
                                {this.renderTransfertForm(!this.state.selectedActions.length)}

                                <div className='pull-right'
                                     style={{
                                         marginTop: '5px',
                                         marginRight: '10px',
                                         marginLeft: "10px"
                                     }}>{this.state.cacheLead.length} actions
                                </div>
                            </div>

                            {this.state.fetchInProgress === false ?
                                <div className=''>
                                    <div className="wrap-table">
                                        <table
                                            className="table table-eseller-lead table-striped table-hover table-condensed"
                                            style={{ tableLayout: 'fixed' }}>
                                            <thead>
                                            <tr>
                                                <th style={{ width: '80px', }}>Type</th>
                                                <th style={{ width: '100px', }}>Site</th>
                                                <th style={{ width: '60px', }} className='text-center'>Suivi</th>
                                                <th style={{ width: '200px', }}>Campagne / Origine</th>
                                                <th>Etape</th>
                                                <th>Contact</th>
                                                <th>Téléphone</th>
                                                <th>Action</th>
                                                <th>Relance</th>
                                                <th style={{ width: '40px', }} className='text-center'></th>
                                                <th style={{ width: '40px', }} className='text-center'></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.cacheLead.map((lead, i) => {
                                                return <tr key={i}>
                                                    <td>
                                                        {lead.Affaire ?
                                                            lead.Affaire.OrigineAction ? lead.Affaire.Type : null
                                                            : lead.ActionFaite_DO.OrigineAction ?
                                                                lead.ActionFaite_DO.OrigineAction.Type
                                                                : null}
                                                    </td>
                                                    <td style={{ overflow: 'hidden' }}>
                                                        <TableRowSite Site={lead.Site}/>
                                                    </td>
                                                    <td className='text-center'>
                                                        <PastilleVendeur utilisateur={lead.SuiviPar}/>
                                                    </td>
                                                    <td>
                                                        <TableRowCampagneOrigine campagne={lead.Campagne}
                                                                                 origine={lead.ActionFaite_DO.OrigineAction}/>
                                                    </td>
                                                    <td>{lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : null}</td>
                                                    <td>
                                                        <Link to={`/prospect/${lead.Prospect.IDProspect}`}>
                                                            {lead.Prospect.Nom} {lead.Prospect.Prenom}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {lead.Prospect.Telephones.length ? lead.Prospect.Telephones[0].Numero : null}
                                                    </td>
                                                    <td>
                                                        {lead.ActionAFaire_TODO.ActionAFaire ? lead.ActionAFaire_TODO.ActionAFaire.Description : ''}
                                                    </td>
                                                    <td>
                                                        {lead.EstAppelSortant ?
                                                            <Link
                                                                to={'/lead/create?EstAppelSortant=true&prospect_id=' + lead.Prospect.IDProspect + '&IDLead=' + lead.IDLead}>
                                                                <TableRowDateRelance
                                                                    date={lead.ActionAFaire_TODO.DateHRelance}/>
                                                            </Link>
                                                            :
                                                            <Link
                                                                to={'/rapport' + (!lead.Affaire || !lead.Affaire.OrigineAction || lead.Affaire.Type === 'VN' || lead.Affaire.Type === 'VO' ? '' : 'Atelier') + '/' + lead.IDLead}
                                                                className={moment(lead.ActionAFaire_TODO.DateHRelance).diff(moment()) < 0 ? 'color red' : null}>
                                                                <TableRowDateRelance
                                                                    date={lead.ActionAFaire_TODO.DateHRelance}/>
                                                            </Link>
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        <TableRowComment comment={lead.ActionFaite_DO.Commentaire}/>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedActionsIDSite === null || (lead.Site.IDSite == this.state.selectedActionsIDSite) ?
                                                            <div className="checkbox-form active">
                                                                <input type="checkbox" name={"action_" + lead.IDLead}
                                                                       id={"action_" + lead.IDLead}
                                                                       checked={this.state.selectedActions.indexOf(lead.IDLead) !== -1}
                                                                       onChange={this.selectAction.bind(this, lead.IDLead, lead.Site.IDSite)}/>
                                                                <label className="label-box"
                                                                       htmlFor={"action_" + lead.IDLead}></label>
                                                            </div>
                                                            : null}
                                                    </td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : <Loader style={{ marginTop: 150 }}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Actions;
