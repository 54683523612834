import React, { Component } from 'react';
import PastilleVendeur from '../../Components/PastilleVendeur';

import Globals from '../../Globals';
import moment from 'moment';
import Tooltip from "rc-tooltip";
var lodash = require('lodash');

class ListMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allMessages:[],
            messages:[],
            affaires:[],
            fetching:false,
            filtreIDAffaire:'',
        }
    }

    componentDidMount() {
        this.fetchMessages();
    }

    /*componentDidUpdate(prevProps){
        if(prevstate.allMessages !== this.state.allMessages){
            this.setState({
                messages:this.state.allMessages
            }, () => this.loadAffaires())
        }
    }*/

    fetchMessages() {
        this.setState({
            fetching:true
        });
        fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.IDProspect + '/messages', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            //console.log(json);
            this.setState({
                allMessages: json,
                messages: json,
                fetching:false,
                filtreIDAffaire:'',
            }, () => {
                this.loadAffaires();
                if(this.props.onLoad)this.props.onLoad(this.state.messages);
            });
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                fetching: false
            })
            if(this.props.onLoad)this.props.onLoad([]);
        });
    }

    filterMessages(IDAffaire){
        if(IDAffaire === ''){
            this.setState({
                messages:this.state.allMessages,
                filtreIDAffaire:IDAffaire,
            });
        }
        else if(IDAffaire === '0'){
            this.setState({
                messages: lodash.filter(this.state.allMessages, (message) => {
                    return message.IDAffaire === null;
                }),
                filtreIDAffaire:IDAffaire,
            });
        }
        else {
            this.setState({
                messages: lodash.filter(this.state.allMessages, (message) => {
                    return message.IDAffaire === parseInt(IDAffaire, 10);
                }),
                filtreIDAffaire:IDAffaire,
            });
        }
    }

    loadAffaires(){
        var idsAffaires = [];
        var affaires = [];
        lodash.forEach(this.state.allMessages, (message) => {
            var idAffaire = message.IDAffaire ? message.IDAffaire : 0;
            var idx = idsAffaires.indexOf(idAffaire);
            if(idx === -1){
                affaires.push({
                    IDAffaire:idAffaire,
                    nomAffaire:message.Affaire ? "Lead "+(message.Affaire.Type ? message.Affaire.Type : '')+" du "+moment(message.Affaire.DateDebut).format('DD/MM/YYYY') : "Aucune affaire",
                    dateAffaire:message.Affaire ? message.Affaire.DateDebut : null,
                    nbMessages:1
                });
                idsAffaires.push(idAffaire);
            }
            else 
                ++affaires[idx].nbMessages;
        });
        this.setState({
            affaires:lodash.sortBy(affaires, 'dateAffaire')
        });
    }

    render() {
        if(this.props.hidden)
            return null;

        return (
            <div style={{marginTop:'15px', paddingBottom:'15px'}}>
            {this.state.messages.length > 0 ?
                <div>
                {this.state.affaires.length > 0 ?
                    <div style={{ marginLeft: '10px', marginBottom:'5px' }}>
                        Affaire : 
                        <select className='form-control' value={this.state.filtreIDAffaire} style={{ width: '250px', display: 'inline-block', marginLeft: '10px' }} onChange={(e) => this.filterMessages(e.target.value)}>
                            <option value=''>Toutes</option>
                            {this.state.affaires.map((affaire, i) => {
                                return (<option key={"selectaffairemessages"+i} value={affaire.IDAffaire}>{affaire.nomAffaire} ({affaire.nbMessages})</option>);
                            })}
                        </select><br/>
                    </div>
                :null}
                <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                    <thead>
                        <tr style={{height:'auto'}}>
                            <th>Date</th>
                            <th className="text-center">Envoyé par</th>
                            <th>Canal</th>
                            <th>Affaire</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.messages.map((message) => {
                            return (
                                <tr key={"message"+message.IDMessagesHistorique}>
                                    <td>{moment(message.DateEnvoi).format('DD/MM/YYYY HH[h]mm')}</td>
                                    <td className="text-center">                      
                                        <PastilleVendeur utilisateur={message.Utilisateur} />
                                    </td>
                                    <td>{message.Canal} ({message.Destination})</td>
                                    {message.Affaire && message.Affaire.Type ?
                                        <td>
                                            Lead {message.Affaire.Type ? message.Affaire.Type : ''} du {moment(message.Affaire.DateDebut).format('DD/MM/YYYY')}
                                        </td>
                                        :
                                        <td>-</td>
                                    }
                                    {message.Texte.length > 100 || message.SujetMail ?
                                        <td>
                                            <Tooltip trigger={['hover']} overlayStyle={{zIndex:10000}} overlay={<div style={{whiteSpace:'pre-wrap'}}>{message.SujetMail ? <b>Sujet : {message.SujetMail}<br/></b>: null}{message.Texte}</div>} placement="top">
                                                <span>{message.Texte.substr(0, 100)} {message.Texte.length > 100 ? "..." : null}</span>
                                            </Tooltip>
                                        </td>
                                    :
                                        <td>{message.Texte}</td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                </div>
            :
                <span style={{marginLeft:'15px'}}>
                {this.state.fetching?
                    <em>Chargement des messages en cours...</em>
                :
                    <em>Aucun message</em>
                }
                </span>
            }
            </div>
        );
    }
}

export default ListMessages;
