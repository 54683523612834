import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class FormCible extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {

    }
  }

  render() {
    return (
    <div className='row' style={this.props.style}>

      <div className='col-md-12'>
        <h3>Cible</h3>

        <br />

        <p>Cette campagne concerne <span style={{ fontWeight: 'bold' }}>{this.props.campagne.CountClients}</span> prospects.</p>

      </div>

    </div>
    );
  }
}

export default FormCible;
