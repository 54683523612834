import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import FormStep2 from './FormStep2.js';
import FormStep3 from './FormStep3.js';

import ResumeCampagne from './ResumeCampagne';
import FormCible from './FormCible.js'
import FormAffectation from './FormAffectation.js'

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')


class CampgneSynthese extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      fetchInProgress: true,
      campagne: null,
    }
  }

  componentDidMount() {
    this.fetchCampagne();
    this.fetchCampagneStats();
  }

  fetchCampagne() {
    fetch(localStorage.ClientApiURL + '/Campagnes/' + this.props.params.IDCampagne, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        campagne: json,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchCampagneStats() {
    fetch(localStorage.ClientApiURL + '/Campagnes/' + this.props.params.IDCampagne + '/synthese', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        this.setState({
          stats: json,
          fetchInProgress: false,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  getTotalAboutis() {
    var list = this.state.stats.listeCloture;
    list = this.filterAboutis(list);

    return this.state.stats.affaireCount + list.length;
  }

  getTotalNonAboutis() {
    var list = this.state.stats.listeCloture;
    list = this.filterNonAboutis(list);

    return list.length;
  }

  filterAboutis(list) {

    var list1 = this.filterRefusArgumente(list);
    var list2 = this.filterRefusNonArgumente(list);
    
    return lodash.concat(list1, list2);
  }
  filterNonAboutis(list) {
    
    list = lodash.filter(list, (rapport) => {
      return (lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 9") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 6") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 8") 
      )
    })

    return list;
  }

  getPourcentTotalAboutis() {
    return Math.round((this.getTotalAboutis() / this.state.stats.cibleCount) * 100);
  }

  getPourcentTotalNonAboutis() {
    return Math.round((this.getTotalNonAboutis() / this.state.stats.cibleCount) * 100);
  }

  getTotalRefusArgumente() {
    var list = this.state.stats.listeCloture;
    list = this.filterRefusArgumente(list);

    return list.length;
  }

  getTotalRefusNonArgumente() {
    var list = this.state.stats.listeCloture;
    list = this.filterRefusNonArgumente(list);

    return list.length;
  }

  filterRefusArgumente(list) {
    list = lodash.filter(list, (rapport) => {
      
      return (lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 15") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 50") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 30") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 25") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 26") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 16") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 18") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 10") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 17") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 14")
      )
    })

    return list;
  }

  filterRefusNonArgumente(list) {
    
    list = lodash.filter(list, (rapport) => {
      return (lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 71") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 79") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 73") || 
              lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : 64")
      )
    })

    return list;
  }

  getTotalByCode(code) {

    var list = this.state.stats.listeCloture;

    list = lodash.filter(list, (rapport) => {
      return lodash.startsWith(rapport.Comment, "Qualification de l'appel sortant : " + code)
    })

    return list.length;
  }

  render() {

    if (!this.state.campagne || !this.state.stats) {
        return <Loader style={{ marginTop: 150 }} />;
    }

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div>
                <h2 style={{ marginLeft: '28px' }}>Synthèse de la campagne</h2>

                <br />

                <ResumeCampagne campagne={this.state.campagne} noDisplayLinkSynthese={true} />

                <div>
                  <div className='col-md-12' style={{ marginBottom: '30px' }}>
                    <div className='col-md-3'>
                      <u>Total contacts ciblés</u> : {this.state.stats.cibleCount}
                    </div>
                    <div className='col-md-3'>
                      <u>Appels aboutis</u> : {this.getTotalAboutis()} ({this.getPourcentTotalAboutis()}%)
                    </div>
                    <div className='col-md-3'>
                      <u>Appels non aboutis</u> : {this.getTotalNonAboutis()} ({this.getPourcentTotalNonAboutis()}%)
                    </div>
                    <div className='col-md-3'>
                      <u>Reste à contacter</u> : {this.state.stats.restantCount}
                    </div>
                  </div>
                </div>

                <br /><br />

                <div className='container'>
                  <div className='col-md-12'>
                    <table className='table table-bordered table-condensed'>
                      <thead>
                        <tr style={{ backgroundColor: 'gray', color: 'white' }}>
                          <th>Qualification appel</th>
                          <th>Nb de fiches</th>
                          <th>% de la cible</th>
                          <th>Appels aboutis</th>
                          <th>Appels non aboutis</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      <tr style={{ backgroundColor: '#eaeaea', color: 'black', fontWeight: 'bold' }}>
                          <td>Contact positif</td>
                          <td className='text-right'>{this.state.stats.affaireCount}</td>
                          <td className='text-right'>{Math.round((this.state.stats.affaireCount/this.state.stats.cibleCount)*100)}%</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>Transformé en lead</td>
                          <td className='text-right'>{this.state.stats.affaireCount}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.state.stats.affaireCount}</td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>

                        <tr style={{ backgroundColor: '#eaeaea', color: 'black', fontWeight: 'bold' }}>
                          <td>Refus argumenté</td>
                          <td className='text-right'>{this.getTotalRefusArgumente()}</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td>15 - Entretien récent fait</td>
                          <td className='text-right'>{this.getTotalByCode('15')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('15')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>50 - Contactera la concession</td>
                          <td className='text-right'>{this.getTotalByCode('50')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('50')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>30 - Réclamation client</td>
                          <td className='text-right'>{this.getTotalByCode('30')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('30')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>25 - A quitté la région</td>
                          <td className='text-right'>{this.getTotalByCode('25')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('25')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>26 - N'a plus le véhicule</td>
                          <td className='text-right'>{this.getTotalByCode('26')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('26')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>16 - Non acceptation de l'offre</td>
                          <td className='text-right'>{this.getTotalByCode('16')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('16')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>18 - Autre raison</td>
                          <td className='text-right'>{this.getTotalByCode('18')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('18')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>10 - Barrage / Décisionnaire injoignable</td>
                          <td className='text-right'>{this.getTotalByCode('10')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('10')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>17 - N'a pas reçu l'offre</td>
                          <td className='text-right'>{this.getTotalByCode('17')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('17')}</td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>14 - Déjà en relation avec un garage</td>
                          <td className='text-right'>{this.getTotalByCode('14')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('14')}</td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>

                        <tr style={{ backgroundColor: '#eaeaea', color: 'black', fontWeight: 'bold' }}>
                          <td>Refus non argumenté</td>
                          <td className='text-right'>{this.getTotalRefusNonArgumente()}</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td>71 - Refus de répondre aux questions</td>
                          <td className='text-right'>{this.getTotalByCode('71')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('71')}</td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td>79 - Décédé</td>
                          <td className='text-right'>{this.getTotalByCode('79')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('79')}</td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td>73 - Stop téléphone</td>
                          <td className='text-right'>{this.getTotalByCode('73')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('73')}</td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td>64 - Numéro réaffecté</td>
                          <td className='text-right'>{this.getTotalByCode('64')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('64')}</td>
                          <td className='text-right'></td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>

                        <tr style={{ backgroundColor: '#eaeaea', color: 'black', fontWeight: 'bold' }}>
                          <td>Non abouti</td>
                          <td className='text-right'>{this.getTotalNonAboutis()}</td>
                          <td className='text-right'>{this.getPourcentTotalNonAboutis()}%</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>
                        <tr>
                          <td>61 - Répondeur/occupé</td>
                          <td className='text-right'>{this.getTotalByCode('61')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('61')}</td>
                        </tr>
                        <tr>
                          <td>62 - Faux numéro</td>
                          <td className='text-right'>{this.getTotalByCode('62')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('62')}</td>
                        </tr>
                        <tr>
                          <td>63 - Toujours injoigniable</td>
                          <td className='text-right'>{this.getTotalByCode('63')}</td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'>{this.getTotalByCode('63')}</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                        </tr>

                      </tbody>
                        
                      <tfoot>
                        <tr>
                            <th>TOTAL</th>
                            <th className='text-right'>{this.state.stats.affaireCount+this.getTotalRefusArgumente()+this.getTotalRefusNonArgumente()+this.getTotalNonAboutis()}</th>
                            <th className='text-right'></th>
                            <th className='text-right'>{this.getTotalAboutis()}</th>
                            <th className='text-right'>{this.getTotalNonAboutis()}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );

  }
}

export default CampgneSynthese;
