import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';
import moment from 'moment'
class Vehicule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vehicule: null,
    }
  }

  componentDidMount() {
    if (this.props.params.vehiculeId) {
      this.fetchVehicule();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.params.vehiculeId === prevProps.params.vehiculeId) {
      return;
    }

    if (!this.props.params.vehiculeId)
      return;

    this.fetchVehicule();
  }

  fetchVehicule() {
    fetch(localStorage.ClientApiURL + '/Vehicules//' + this.props.params.vehiculeId, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        vehicule: json,
      }, () => {
        console.log(this.state.vehicule)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.vehicule)
      return <Loader />

    return (
      <div>
        <div className="container-fluid vehicule-tmp">
          <div className="row">

            <div className="col-md-12">

              <h2 className='title'>{this.state.vehicule.Type === "VO" ? <img src="/img/AFFAIRE VO.svg" role='presentation' /> : <img src="/img/AFFAIRE VO.svg" role='presentation' /> }
                {this.state.vehicule.Marque} {this.state.vehicule.Modele} {this.state.vehicule.Version}
              </h2>
              <div className="breakrow"></div>
              <div className="row">
                <div className="col-md-2 col-sm-4">
                  <div className="img-container"><img src="#" role='presentation' /></div>
                </div>

                <div className="col-md-10 col-sm-8">
                    <div className="col-md-12">
                      <label>Disponibilité : {this.state.vehicule.Disponibilite === "EnStock" ?
                        <span className="color green">En stock <div className="pastille small round green"></div></span>
                        : this.state.vehicule.Disponibilite === "Reserve" ?
                        <span className="color red">Réserve <div className="pastille small round red"></div></span>
                        : <span className="color red">Non disponible <div className="pastille small round red"></div></span>}</label>
                      {this.state.vehicule.Tarification.PrixVenteTTC ? <label>Prix de vente TTC : <span>{this.state.vehicule.Tarification.PrixVenteTTC} €</span></label> : null}
                      {this.state.vehicule.Annee ? <label>Année modèle : <span>{this.state.vehicule.Annee}</span></label> : null}

                      <div className="breakrow"></div>
                      {this.state.vehicule.DateMEC ? <label>Mise en circulation : <span>{this.state.vehicule.DateMEC ? moment(this.state.vehicule.DateMEC).format('DD/MM/Y') : null}</span></label> : null}
                      {this.state.vehicule.Garanties ? "<label>Garantie : <span>"+this.state.vehicule.Garanties+"</span></label>" : ""}
                      {this.state.vehicule.Garanties ? "<label>Garantie : <span>"+this.state.vehicule.Garanties+"</span></label>" : ""}
                      {this.state.vehicule.Kilometrage ?
                        <label>Kilométrage
                        {this.state.vehicule.KilometrageCertifie ? "(Certifié)" : ""}
                        : <span>{this.state.vehicule.Kilometrage} Km</span></label>
                        : ""}
                      {this.state.vehicule.Couleur ? <label>Extérieure : <span>{this.state.vehicule.Couleur}</span></label> : null }
                      {this.state.vehicule.Finition ? <label>Intérieur : <span>{this.state.vehicule.Finition}</span> </label> : null }
                      {this.state.vehicule.RefDMS ? <label>RefDMS : <span>{this.state.vehicule.RefDMS}</span></label> : null }
                    </div>
                </div>
              </div>
              <div className="breakrow"></div>
              <div className="row">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className="active">
                    <a href="#infostech" aria-controls="infostech" role="tab" data-toggle="tab">Infos tech</a>
                  </li>
                  <li role="presentation">
                    <a href="#equipements" aria-controls="equipements" role="tab" data-toggle="tab">Équipements</a>
                  </li>
                  <li role="presentation">
                    <a href="#options" aria-controls="options" role="tab" data-toggle="tab">Options</a>
                  </li>
                  <li role="presentation">
                    <a href="#infosgestion" aria-controls="infosgestion" role="tab" data-toggle="tab">Infos gestion</a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="infostech">
                    <div className="col-md-12">
                      <h4><img src="/img/VOITURE.svg" role='presentation'/> Carrosserie</h4>
                      <div className="col-md-6">
                        <label><span>{this.state.vehicule.Gamme} {this.state.vehicule.Type === "VO" ? "/ VO" : "/ VN" }</span></label>
                        {this.state.vehicule.SpecifiqueAutomobile.NbPorte ? <label>Nbre de portes : <span>{this.state.vehicule.SpecifiqueAutomobile.NbPorte}</span></label> : null }
                      </div>
                      <div className="col-md-6">
                        {this.state.vehicule.Famille ? <label>Carrosserie : <span>{this.state.vehicule.Famille}</span></label> : null}
                        {this.state.vehicule.CodeModele ? <label>Code modèle : <span>{this.state.vehicule.CodeModele}</span></label> : null}
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>


                      <h4><img src="/img/" role='presentation'/> Motorisation</h4>
                      <div className="col-md-6">
                        {this.state.vehicule.Moteur.PuissanceFiscale || this.state.vehicule.Moteur.PuissanceCV ? <label>Puissance : <span>{this.state.vehicule.Moteur.PuissanceFiscale ? <span>{this.state.vehicule.Moteur.PuissanceFiscale} CV fiscaux -</span> : null} {this.state.vehicule.Moteur.PuissanceCV ? <span>{this.state.vehicule.Moteur.PuissanceCV} CV DIN</span> : null }</span></label> : null}
                        {this.state.vehicule.Moteur.CM3 ? <label>Cylindrée : <span>{this.state.vehicule.Moteur.CM3} cm3</span></label> : null}
                        {this.state.vehicule.Moteur.NbCylindre ? <label>Type de moteur : <span>{this.state.vehicule.Moteur.NbCylindre } cylindres</span></label> : null}
                        {this.state.vehicule.Moteur.Energie ? <label>Énergie : <span>{this.state.vehicule.Moteur.Energie}</span></label> : null}
                        {this.state.vehicule.Moteur.BoiteDevitesse ? <label>Boite de vitesses : <span>{this.state.vehicule.Moteur.BoiteDeVitesse}</span></label> : null}
                      </div>
                      <div className="col-md-6">
                        <label>Type mines : <span>{this.state.vehicule.TypeMine ? this.state.vehicule.TypeMine : "n.c"}</span></label>
                        {this.state.vehicule.Pneumatique ?  <label>Pneus : <span>{this.state.vehicule.Pneumatique}</span></label> : null }
                        <label>Indice Vitesse PneuAV : <span></span> - AR : <span></span></label>
                        <label>Diamètre JanteAV : <span></span> - AR : <span></span> </label>
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>


                      <h4><img src="/img/" role='presentation'/> Consommations</h4>
                      <div className="col-md-6">
                        {this.state.vehicule.Moteur.EmissionCO2  ? <label>Émissions de CO2 : <span>{this.state.vehicule.Moteur.EmissionCO2} g/km</span> </label> : null}
                        {this.state.vehicule.Moteur.ConsommationMixte ? <label>Consommation mixte : <span>{this.state.vehicule.Moteur.ConsommationMixte} l/100km</span> </label> : null}
                      </div>
                      <div className="col-md-6">
                        {this.state.vehicule.Moteur.ConsommationUrbaine ? <label>Consommation urbaine : <span>{this.state.vehicule.Moteur.ConsommationUrbaine} l/100km</span> </label> : null}
                        {this.state.vehicule.Moteur.ConsommationExtraUrbaine ? <label>Consommation extra urbaine : <span>{this.state.vehicule.Moteur.ConsommationExtraUrbaine} l/100km</span> </label> : null}
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>

                      <h4><img src="/img/" role='presentation'/> Dimensions et poids </h4>
                      <div className="col-md-6">
                        {this.state.vehicule.Dimension.Longueur ? <label>Longueur : <span>{this.state.vehicule.Dimension.Longueur} m</span> </label> : null}
                        {this.state.vehicule.Dimension.Largeur ? <label>Largeur : <span>{this.state.vehicule.Dimension.Largeur} m</span> </label> : null}
                        {this.state.vehicule.Dimension.Hauteur ? <label>Hauteur : <span>{this.state.vehicule.Dimension.hauteur} m</span> </label> : null}
                      </div>
                      <div className="col-md-6">
                        {this.state.vehicule.Dimension.PoidsVide ? <label>Poids à vide : <span>{this.state.vehicule.Dimension.PoidsVide} kg</span> </label> : null}
                        {this.state.vehicule.Dimension.PTAC ? <label>PTAC : <span>{this.state.vehicule.Dimension.PTAC}</span> </label> : null}
                        {this.state.vehicule.Moteur.Reservoir ? <label>Réservoir : <span>{this.state.vehicule.Moteur.Reservoir}</span> </label> : null}
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>
                    </div>
                  </div>

                  <div role="tabpanel" className="tab-pane" id="equipements">
                    <div className="col-md-12">
                      <div className="col-md-6">
                        {this.state.vehicule.Equipements.map((equip, j) =>
                          j%2===0 ? <li key={j}>{equip}</li> : null
                        )}
                      </div>
                      <div className="col-md-6">
                      {this.state.vehicule.Equipements.map((equip, i) =>
                          i%2===1 ? <li>{equip}</li> : null
                      )}
                      </div>
                    </div>
                  </div>

                  <div role="tabpanel" className="tab-pane" id="options">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <table>
                            <tbody>
                              {this.state.vehicule.Options.map((option, j) =>
                                <tr key={j}>
                                  <td>{option}</td>
                                  <td>-</td>
                                </tr>
                              )}
                              <tr>
                                <td><b>Total des options</b></td>
                                <td>
                                  <hr />
                                  ...
                                  <b></b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                  </div>

                  <div role="tabpanel" className="tab-pane" id="infosgestion">
                    <div className="col-md-12">

                      <h4><img src="" role='presentation'/> Lieu de stockage</h4>
                      <div className="col-md-6">
                        {this.state.vehicule.LieuDeStockage.Nom ? <label>Société de stockage : <span>{this.state.vehicule.LieuDeStockage.Nom}</span> </label> : null}
                        {this.state.vehicule.LieuDeStockage.Plaque.Nom ?<label>Site de stockage : <span>{this.state.vehicule.LieuDeStockage.Plaque.Nom}</span></label> : null}
                      </div>
                      <div className="col-md-6">
                        {this.state.vehicule.SiteProprietaire.Nom ? <label>Société propriétaire : <span>{this.state.vehicule.SiteProprietaire.Nom}</span></label> : null}
                        {this.state.vehicule.SiteProprietaire.Plaque.Nom ?<label>Site propriétaire :  <span>{this.state.vehicule.SiteProprietaire.Plaque.Nom}</span></label> : null}
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>

                      <h4><img src="" role='presentation'/> Autres infos</h4>
                      <div className="col-md-6">
                        {this.state.vehicule.Statut ? <label>Statut : <span>{this.state.vehicule.Statut}</span> </label> : null}
                        {this.state.vehicule.DateEntreeEnStock ? <label>Entrée en stock : <span>{moment(this.state.vehicule.DateEntreeEnStock).format('DD/MM/Y')}</span></label> : null}
                        {this.state.vehicule.VIN ? <label>N° de série : <span>{this.state.vehicule.VIN}</span></label> : null}
                        {this.state.vehicule.Immatriculation ? <label>Immatriculation : <span>{this.state.vehicule.Immatriculation}</span></label> : null}
                        {this.state.vehicule.DateCtrlTechnique ? <label>Date ctrl. Technique : <span>{moment(this.state.vehicule.DateCtrlTechnique).format('DD/MM/Y')}</span></label> : ""}
                        {this.state.vehicule.NumCommandeFournisseur ? <label>N° de commande : <span>{this.state.vehicule.NumCommandeFournisseur}</span></label> : null}
                      </div>
                      <div className="col-md-6">
                        {this.state.vehicule.IDVehicule ? <label>Réf. CRM  : <span>{this.state.vehicule.IDVehicule}</span></label> : null}
                        <label>Assujetti à TVA :  <span></span></label>
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>

                      <h4><img src="" role='presentation'/> Tarification</h4>
                      <div className="col-md-6">
                        <label><u>Conditions d'achat</u></label>
                        <div className="breakrow m-row"></div>

                        <label>Prix d'achat HT : <span></span></label>
                        <label>Total cessions HT : <span></span></label>
                        <label>Prix d'achat net HT (B) : <span></span></label>

                        <div className="breakrow m-row"></div>
                        <label><u>Conditions de vente</u></label>
                        <div className="breakrow m-row"></div>
                        <label>Prix de vente HT Options incluses (A) : <span>{this.state.vehicule.Tarification.PrixVenteHT + this.state.vehicule.Tarification.TotalOptionsHT} €</span> </label>
                      </div>
                      <div className="col-md-6">
                        <label>Marge HT (A-B)<span></span></label>
                        <label><span></span></label>
                      </div>
                      <div className="clear"></div>
                      <div className="breakrow m-row"></div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Vehicule;
