import React, { Component } from 'react';

import moment from 'moment'

var Datetime = require('react-datetime');

class FilterDate extends Component {

  constructor(props) {
    super(props)

    moment.locale('fr');

    this.state = {
      periode: props.defaultPeriode ? props.defaultPeriode : 'days',
      date_start: props.dateStart ? moment(props.dateStart) : (moment().startOf(props.defaultPeriode ? props.defaultPeriode : 'days')),
      date_end: props.dateEnd ? moment(props.dateEnd) : (moment().endOf(props.defaultPeriode ? props.defaultPeriode : 'days')),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({
        periode: 'days',
        date_start: moment().startOf('days'),
        date_end: moment().endOf('days'),
      });
    }
    else if(prevProps.datePeriode && prevProps.datePeriode != this.state.periode){
      this.setState({
        periode: this.props.datePeriode,
        date_start: this.props.dateStart ? this.props.dateStart : moment().startOf(this.props.datePeriode),
        date_end: this.props.dateEnd ? this.props.dateEnd : moment().endOf(this.props.datePeriode),
      })
    }
  }

  add() {
    this.setState({
      date_start: moment(this.state.date_start).add(1, this.state.periode).startOf(this.state.periode),
      date_end: moment(this.state.date_start).add(1, this.state.periode).endOf(this.state.periode),
    }, () => {
      this.props.onChangeDate(this.state.date_start, this.state.date_end)
    });
  }

  subtract() {
    this.setState({
      date_start: moment(this.state.date_start).subtract(1, this.state.periode).startOf(this.state.periode),
      date_end: moment(this.state.date_start).subtract(1, this.state.periode).endOf(this.state.periode),
    }, () => {
      this.props.onChangeDate(this.state.date_start, this.state.date_end)
    });
  }

  onChangeDateStart(date_start) {
    this.setState({
      date_start: moment(date_start).startOf('day'),
    }, () => {
      this.props.onChangeDate(this.state.date_start, this.state.date_end)
    });
  }

  onChangeDateEnd(date_end) {
    this.setState({
      date_end: moment(date_end).endOf('day'),
    }, () => {
      this.props.onChangeDate(this.state.date_start, this.state.date_end)
    });
  }

  handleClickPeriode(event) {

    var periode = event.target.value;

    var date_start = moment();
    var date_end = moment();

    if (periode === 'weeks') {
      date_start = date_start.startOf('weeks')
      date_end = date_end.endOf('weeks')
    }
    else if (periode === 'months') {
      date_start = date_start.startOf('months')
      date_end = date_end.endOf('months')
    }

    this.setState({
      periode: periode,
      date_start: date_start,
      date_end: date_end,
    }, () => {
      this.props.onChangeDate(this.state.date_start, this.state.date_end, this.state.periode)
    });
  }

  render() {
    return (
      <div className={this.props.className}>

          <div className="btn-group pull-left" style={{ display: 'inline-block', paddingTop: 1, marginRight: '30px' }}>
            <button className={this.state.periode === 'days' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="days" onClick={this.handleClickPeriode.bind(this)}>Jour</button>
            <button className={this.state.periode === 'weeks' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="weeks" onClick={this.handleClickPeriode.bind(this)}>Semaine</button>
            <button className={this.state.periode === 'months' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="months" onClick={this.handleClickPeriode.bind(this)}>Mois</button>
          </div>

          <div className='pull-left'>
            <div className='form-group pull-left' style={{ display: 'inline-block', height: '26px' }}>
              <a style={{ marginRight: 5 }} onClick={this.subtract.bind(this)}>
                <i className="fa fa-chevron-left" aria-hidden="true" style={{ verticalAlign: 'bottom' }}></i>
              </a>
            </div>

            <div className='form-group' style={{ width: 110, display: 'inline-block', }}>
              <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} value={this.state.date_start.format('L')} open={false} onChange={this.onChangeDateStart.bind(this)} />
            </div>
            &nbsp;
            <div className='form-group' style={{ width: 110, display: 'inline-block', }}>
              <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} value={this.state.date_end.format('L')} open={false} onChange={this.onChangeDateEnd.bind(this)} />
            </div>

            <div className='form-group pull-right' style={{ display: 'inline-block', height: '26px' }}>
              <a style={{ marginLeft: 5 }} onClick={this.add.bind(this)}>
                <i className="fa fa-chevron-right" aria-hidden="true" style={{ verticalAlign: 'bottom' }}></i>
              </a>
            </div>
          </div>

          <div className='clearfix' />

      </div>
    );
  }
}

export default FilterDate;
