import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';

//import './WeekCalendar.css';

class HistoriqueProspect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      HP_list: [],
      fetchInProgress: true,
    }
  }

  componentWillMount() {
    if (!localStorage.historiqueProspect) {
      localStorage.historiqueProspect = [];
    }
  }

  componentDidMount() {


    this.fetchProspects();
  }

  fetchProspects() {
    var HP_list = this.state.HP_list;
    var _tmp = localStorage.historiqueProspect.length ? JSON.parse(localStorage.historiqueProspect) : localStorage.historiqueProspect;
    if(_tmp.length){
      _tmp.map((utilisateur, i) => {
        return fetch(localStorage.ClientApiURL + '/prospects/' + utilisateur, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(json => {
            HP_list[i] = json;
            this.setState({
              HP_list: HP_list,
            });
            if(i === _tmp.length - 1){
              this.setState({
                fetchInProgress: false,
              });
            }
          })
        .catch((error) => {
          console.log(error)
        })
      });
    }
    else{
      this.setState({
        fetchInProgress: false,
      });
    }
  }

  handleSwitch(id){
    this.props.onChange('idprospect', id);
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col-md-12">
            <label>Historique des profils consultés</label>
          </div>
        </div>
        <hr></hr>

        {this.state.fetchInProgress ?
          <div className='col-md-12'>
            <Loader style={{ marginTop: 50 }} />
          </div>
         : null}

        <div>
          {!this.state.fetchInProgress && this.state.HP_list && this.state.HP_list.length ?

            <table className="table table-eseller-lead table-striped table-hover table-condensed">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>CP</th>
                  <th>Ville</th>
                </tr>
              </thead>
              <tbody>
                {this.state.HP_list.map((prospect, i) => {
                  return  <tr onClick={this.handleSwitch.bind(this, prospect.IDProspect)} key={prospect.IDProspect}><td>{prospect.Nom}</td><td>{prospect.Prenom}</td><td>{prospect.CodePostal}</td><td>{prospect.Ville}</td></tr>
                })}
              </tbody>
            </table>
          : null}

          {!localStorage.historiqueProspect.length? <label className="color red">Vous n'avez visité aucun prospect au cours de votre session</label>  : null}
        </div>
      </div>

    );
  }
}

export default HistoriqueProspect;
