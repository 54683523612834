import React, {Component} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Globals from "../Globals";
import SkyLight from 'react-skylight';
import ReactGA from 'react-ga';
import PastilleVendeur from '../Components/PastilleVendeur';
import Scheduler from "./Scheduler";
import moment from 'moment';
import Tooltip from "rc-tooltip";
import InputMask from 'react-input-mask';
import Loader from '../Components/Loader';
let lodash = require('lodash');


/***
 * Réordonner un élément d'un tableau
 */
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const lastResult= lodash.filter(result,(utilisateur)=>{
        if(utilisateur == null){
            return;
        }
        return true;
    });

    return lastResult;
};

/***
 * Style à appliquer pour a zone de Drag and Drop
 */
const getListStyle = isDraggingOver => ({
    padding: grid,
    width: '100%',
    border:'1px solid #ddd',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgb(221, 221, 221)',
    borderImageSource: 'none',
    borderImageslice: '100%',
    borderImageWidth: '1',
    borderImageOutset: '0',
    borderImageRepeat: 'stretch stretch',
    backgroundColor:'rgb(240, 240, 240)'

});

const grid = 8;

/***
 * Stye à appliquer pour l'élément à Drag and Drop
 */
const getItemStyle = (isDragging, draggableStyle, isNewUser) => ({
    // some basic styles to make the items look a bit nicer

    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
    userSelect: 'none',
    padding: 8 ,
    margin: '1px',
    marginBottom: '4px',
    marginTop: '4px',
    backgroundColor: isNewUser ? 'rgb(212, 177, 177)' : ""
});

/***
 * Style à appliquer sur le taux de transformation
 */
const styleTauxTransformation=(taux)=>{
    switch (true){
        case (taux>=15):
            return {color:'rgb(9, 215, 0)'}
        case(taux>10):
            return {color:'rgb(4, 146, 254)'}
        case(taux>5):
            return{color:'rgb(242, 172, 0)'}
        case(taux<=5):
            return{color:'red'}

    }
};

/***
 * Style à appliquer sur le Statut actuel du vendeur
 */
const styleCalendar = (holidays)=>{
    if(holidays &&  holidays.IDPeopleHoliday ) {
        if(holidays.EstRecurrent){
            return {fontSize: 23, color: 'orange'}
        }
        return {fontSize: 23, color: 'red'}
    }
    return {fontSize:23,color:'green'}

};

class GestionVendeurs extends Component {
    constructor(state) {
        super(state);

        this.state = {
            fetchInProgress : false,
            frameStateAbsence : false,

            /* Select societes et sites*/
            societes: [],
            sites: [],

            viewSelectSociete:true,
            viewSelectSite: true,

            IDPlaque: null,
            currentIDSite: null,

            /* Gestions des Vendeurs*/
            disabledValidation:true,
            selected: "",
            _utilisateurs: [],
            utilisateurs: [],
            holidaysTodayUser:[],
            utilisateursGrouped:[],
            utilisateursGroupeWithId:[],
            message:"",
            arrayNewVendeurs:[],
            arrayInactifs:[],

            /** Result PUT et POST via l'API */
            successCreate: false,
            errorCreate:false,
            errorCreateMessage:"",

            /* Pour simuler des chiffres*/
            tauxTransformation:Math.floor(Math.random() * Math.floor(15)),
            NbLeadSemaine:Math.floor(Math.random() * Math.floor(50)),
            NbLead:Math.floor(Math.random() * Math.floor(10)),

        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    /***
     * Au chargement du composant
     */
    componentWillMount() {
        this.fetchSite();
        this.fetchutilisateurs()
    }

    /***
     * Récupération dans le l'ordre dans lequel afficher les utilisateurs
     */
    fetchOrdreUser(){
        fetch(localStorage.ClientApiURL + '/welcomepro/sites/'+this.state.currentIDSite+'/utilisateurs', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                let UtilisateurGrouped = [];
                let UtilisateurStats = [];
                let i =0;

                json.map((Obj) => {
                   UtilisateurGrouped[parseInt(Obj.Groupe,10)-1] = UtilisateurGrouped[parseInt(Obj.Groupe,10)-1] || [];
                   UtilisateurStats[Obj.Utilisateur.IDUtilisateur] = Obj.Stats;
                    return UtilisateurGrouped[parseInt(Obj.Groupe,10)-1][parseInt(Obj.Ordre,10)-1]= Obj.Utilisateur;
                });
                this.setState({
                    utilisateursGroupeWithId:UtilisateurGrouped,
                    UtilisateurStats: UtilisateurStats,
                }, () => {
                    this.loadOrdre();
                })

            })
            .catch((error) => {
                console.log(error)
            });
    }

    /***
     * Modifier la liste utilisateurs groupe pour que sa forme soit correct ( ici remplacer les ID par des obj)
     * Ou la chargé si elle n'a jamais été enregistré
     */
    loadOrdre(){
        let UtilisateurGrouped = this.state.utilisateursGroupeWithId;
        let message="";
        let userNeedToBeAdd=[];
        let nbNewVendeurs=[];
        let userInactifs=[];
        let userPasserelle= 0;

        this.state.utilisateurs.map((utilisateur, indexUtilisateur)=>{
            if(utilisateur.EstPasserelle){
                return userPasserelle+=1;
            }
            if(! utilisateur.EstActif){
                return userInactifs.push(utilisateur);
            }
            let userFind=false;
            UtilisateurGrouped[indexUtilisateur - (userInactifs.length+userPasserelle)]= UtilisateurGrouped[indexUtilisateur - (userInactifs.length +userPasserelle)] || [];

            UtilisateurGrouped.map((group, indexGroup)=>{
                return group.map((userGrouped, indexIdUser)=>{
                    /** L'utilisateur a déja un ordre attribué*/
                    if(parseInt(userGrouped.IDUtilisateur,10)=== parseInt(utilisateur.IDUtilisateur,10)){
                        userFind=true;
                        return UtilisateurGrouped[indexGroup][indexIdUser]=utilisateur;
                    }
                })
            });

            UtilisateurGrouped[99] = [];

            if(! userFind){
                userNeedToBeAdd.push(utilisateur);
                lodash.orderBy(userNeedToBeAdd,[utilisateur.Nom],['asc'] )
            }

            if(indexUtilisateur === this.state.utilisateurs.length-1){
                let indexToAdd=this.state.utilisateurs.length-(1+userInactifs.length+userPasserelle);

                if(userNeedToBeAdd.length === (this.state.utilisateurs.length - (userInactifs.length+userPasserelle))){
                    indexToAdd=0;
                    message="Première configuration, les utilisateurs sont positionnés par défault dans le premier groupe par ordre alphabétique";
                }
                else if (userNeedToBeAdd.length >0){
                    message="De nouveaux utilisateurs ont été détecté, ils sont positionnés par défault dans le dernier groupe";
                }
                userNeedToBeAdd.map((user)=>{
                    UtilisateurGrouped[99].push(user);
                   nbNewVendeurs.push(user.IDUtilisateur);
                })

            }
        });
        /** Si tous les vendeurs sont nouveaux*/
        if(nbNewVendeurs.length === (this.state.utilisateurs.length - (userInactifs.length+userPasserelle))){
            nbNewVendeurs=[];
        }

        if (!UtilisateurGrouped[99]) {
          UtilisateurGrouped[99] = [];
        }

        this.setState({
            arrayNewVendeurs:nbNewVendeurs,
            utilisateursGrouped:UtilisateurGrouped,
            message:message,
            arrayInactifs:userInactifs,
            fetchInProgress : false,
        },()=>{
            this.loadHolidays();
        })
    }


    /***
     * Appel de l'API pour insérer en base de données la nouvelle gestion des vendeurs
     * @param event
     */
    validerGestion(event){
        event.preventDefault();

        if(this.state.utilisateursGrouped.length <=0 || this.state.currentIDSite === null || this.state.IDPlaque ===null){
            console.log("Une variable importante est nul ");
            return;
        }

        let body=[];
        let reduireIndexGroupe=0;
        this.state.utilisateursGrouped.map((groupUser, indexGroup)=>{
            if (parseInt(indexGroup) === 99) {
              return;
            }

            if(groupUser.length <=0){
                reduireIndexGroupe+=1;
            }
            groupUser.map((utilisateur, indexUser)=>{
                let unUser={
                    "IDWelcomeproPeoplePriority":null,
                    "IDSite" : this.state.currentIDSite,
                    Utilisateur:{
                        "IDUtilisateur": utilisateur.IDUtilisateur,
                    },
                    "Ordre": (indexUser+1),
                    "Groupe": (indexGroup+1) -reduireIndexGroupe,
                };
                body.push(unUser);
            })
        });
        body = JSON.stringify(body);

        fetch(localStorage.ClientApiURL + '/welcomepro/sites/'+this.state.currentIDSite+'/utilisateurs', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: body,
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        this.setState({
                            errorCreate: true,
                            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                        });
                    });

                    throw Error(response.statusText);
                }
                else {
                    this.setState({
                      disabledValidation:true,
                        message:"Ordre enregistré avec succès",
                        _utilisateurs: [],
                        utilisateurs: [],
                        holidaysTodayUser:[],
                        utilisateursGrouped:[],
                        utilisateursGroupeWithId:[],
                    }, () => {
                      this.fetchutilisateurs()
                    })
                }
            })

            .catch((error) => {
                console.warn(error);
            });
    }

    /**
     * Récupération des sociétés et des sites de l'utilisateur connecté, ici le chef de vente
     */
    fetchSite(){
        fetch(localStorage.ClientApiURL + '/Utilisateurs/'+localStorage.user_IDUtilisateur, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password

            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                let societes = [];
                let array_sites = [];


                json.Sites.map((site) => {
                    if(site.EstVO || site.EstVN) {
                        societes[site.Plaque.IDPlaque]=societes[site.Plaque.IDPlaque] || [];
                        societes[site.Plaque.IDPlaque]=site.Plaque.Nom;

                        array_sites[site.Plaque.IDPlaque] = array_sites[site.Plaque.IDPlaque] || [];
                        return array_sites[site.Plaque.IDPlaque][site.IDSite] = site.Nom;
                    }

                    return ;
                });

                this.setState({
                    societes: societes,
                    sites: array_sites,
                });

            })
            .catch((error) => {
                console.log(error)
            });
    }

    /***
     * Récupère tous les Utilisateurs
     */
    fetchutilisateurs() {
        fetch(localStorage.ClientApiURL + '/Utilisateurs?LoadDetailDroitProfil=false&LoadPlaqueMarques=false', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    _utilisateurs: json,
                }, () => {
                    this.loadutilisateurs();
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }


    /***
     * Ajoute au tableau holidaysTodayUser une ligne avec en clé l'ID user puis un object holiday si un utilisateur est en vacance aujourd'hui
     * @param IDUtilisateur
     */
    fetchTodayHolidays(IDUtilisateur) {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth()+1; //January is 0!
        let yyyy = today.getFullYear();

        if(dd<10) {
            dd = '0'+dd
        }

        if(mm<10) {
            mm = '0'+mm
        }

        today = yyyy + '-' + mm + '-' + dd;

        fetch(localStorage.ClientApiURL + '/Utilisateurs/'+IDUtilisateur+'/holidays?date='+today, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                let haveHolidayToday=this.state.holidaysTodayUser;
                haveHolidayToday[IDUtilisateur]=[];
                if (json.length > 0){
                    /** Différences entre absences récurrentes et normales*/
                    let absToday = false;
                    let now = moment();

                    json.map((holiday)=>{
                        if(!absToday){
                            if(!holiday.EstRecurrent){

                                absToday=true;
                                haveHolidayToday[IDUtilisateur]=holiday;
                            }else {
                                let week = holiday.Frequence.IDPeopleHolidayFrequence === 1; /** hebdomadaire*/
                                week = week || (holiday.Frequence.IDPeopleHolidayFrequence ===2 && now.isoWeek()%2===0); /** semaine paire*/
                                week = week || (holiday.Frequence.IDPeopleHolidayFrequence ===3 && now.isoWeek()%2!==0); /** semaine impaire*/

                                let creneau = holiday.Creneau === "J";
                                creneau = creneau || (holiday.Creneau == "M" && now.format('a') == "am");
                                creneau = creneau || (holiday.Creneau == "A" && now.format('a') == "pm");

                                if(now.day() === holiday.Jour+1 && week && creneau){
                                    absToday=true;
                                    haveHolidayToday[IDUtilisateur]=holiday;
                                }
                            }
                        }
                    })
                }

                this.setState({
                    holidaysTodayUser: haveHolidayToday,
                });

            })
            .catch((error) => {
                console.log(error)
            });
    }

    /***
     * Tri sur les utilisateurs à afficher
     */
    loadutilisateurs() {
        this.setState({
            disabledValidation:true,
        });

        if (!this.state.currentIDSite) {
            this.setState({
                utilisateurs: [],
                selected: null,
                utilisateursGrouped:[],
            });

            return;
        }

        let utilisateurs = lodash.filter(this.state._utilisateurs, (utilisateur) => {


            let _found = utilisateur.Sites.find((site, i) => {

                return parseInt(site.IDSite, 10) === parseInt(this.state.currentIDSite, 10)

            });

            let _seller = utilisateur.TypeProfilLead === 'Vendeur' || utilisateur.TypeProfilLead === 'ChefDesVentes';

            if (!utilisateur.EstActif){
            }

            return  _found && _seller

        });

        this.setState({
            utilisateurs: utilisateurs,

        },() => {
            this.fetchOrdreUser();
        });

    };


    /***
     * Chargement du Tableau holidays pour tous les à afficher
     */
    loadHolidays(){
        this.state.utilisateurs.map((utilisateur) => {

            return this.fetchTodayHolidays(utilisateur.IDUtilisateur);

        });
    }

    /***
     * Renvoi le numéro de mobile d'un utilisateur , s'il n'existe pas renvoi ""
     * @param utilisateur
     */
    haveNumMobile(utilisateur){
        let mobile= "";
        utilisateur.Telephones.map((tel, indexTel)=> {
            if(tel.Numero && tel.Type === 'Mobile' ) {
                mobile= tel.Numero;
                return mobile;
            }
        });

        return mobile;
    }


    /***
     * Insertion ou modification d'un numéro de teléphone mobile
     * @param event // Nouveau numéro de téléphone mobile
     * @param utilisateur // Utilisateur à qui appartient le téléphone
     */
    fetchMobile(event, utilisateur){
        var val = event.target.value.split(' ').join('');
        if (!(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(event.target.value)) || val.length != 10){
          if(val.length != 0){
              this.setState({
                  message:"Format du téléphone incorrect",
              })
              return null;
          }
          else{
            val = null;
          }
        }

        var body = {
          MobilePhone: val,
        }

        fetch(localStorage.ClientApiURL + "/utilisateurs/"+utilisateur.IDUtilisateur+"/mobile", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: JSON.stringify(body),
        }).then((response) => {
              if (!response.ok) {
                  response.json().then((value) => {
                      this.setState({
                          errorCreate: true,
                          errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                      });
                  });

                  throw Error(response.statusText);
              }
              else {
                  this.setState({
                      message:"Téléphone modifié pour : "+utilisateur.Nom,
                      _utilisateurs: [],
                      utilisateurs: [],
                      holidaysTodayUser:[],
                      utilisateursGrouped:[],
                      utilisateursGroupeWithId:[],
                  }, () => {
                    this.fetchutilisateurs()
                  })
              }
          })
    }

    /***
     * Changement de société sélectionnée
     * @param event
     */
    handleChangeSociete(event) {
        this.setState({
            IDPlaque: event.target.value,
            currentIDSite: null,
            utilisateurs: [],
            utilisateursGrouped:[],
            message:"",
            arrayInactifs:[],
        })
    }

    /***
     * Changement du site sélectionné
     * @param event
     */
    handleChangeSite(event) {
        this.setState({
                currentIDSite: event.target.value,
                utilisateurs: [],
                message:"",
                arrayInactifs:[],
                fetchInProgress : true,
            },
            () => {
                this.loadutilisateurs();
            })
    }

    /***
     * Event sur le submit
     * @param event
     */
    handleSubmit(event) {
        event.preventDefault();

    }

    /***
     * Actions à appliquer sur le drop du Drag end Drop
     * @param result
     * @param group
     * @param indexGroup
     */
    onDragEnd(result, group, indexGroup) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        group = reorder(
            group,
            result.source.index,
            result.destination.index,
        );
        const listGroup = this.state.utilisateursGrouped;
        listGroup[indexGroup]=group;

        this.setState({
            utilisateurGrouped:listGroup,
            disabledValidation:false,
        });
    }

    /***
     * Changement de groupe d'un vendeur
     * @param event
     * @param groupSelect
     * @param utilisateurSelect
     */
    changeGroupUser(event, groupSelect, utilisateurSelect){

        let arrayGroup = this.state.utilisateursGrouped;
        let needToDelete = false;

        /** Ajoute l'utilisateur dans le nouveau groupe*/
        arrayGroup.map((group,index)=>{
            if(group===[]){
                group = [];
                if(index+1===parseInt(event.target.value)){
                    group.push(utilisateurSelect);
                    needToDelete=true
                }
            }
            else {
                if(index+1===parseInt(event.target.value)){
                    group.push(utilisateurSelect);
                    needToDelete=true
                }
            }
          });

        if (parseInt(event.target.value) === 99) {
          arrayGroup[99].push(utilisateurSelect);
          needToDelete=true;
        }

        /** Supprime l'utilisateur de l'ancien groupe**/
        if(needToDelete){
            groupSelect = lodash.remove(groupSelect, (o)=> {
                return parseInt(o.IDUtilisateur, 10) === parseInt(utilisateurSelect.IDUtilisateur, 10)
            })
        }

        this.setState({
            utilisateursGrouped:arrayGroup,
            disabledValidation:false,
        })
    }

    /***
     * Supprime les groupes sans vendeurs
     **/
    UpdateGroup(){
        let arrayGrp = this.state.utilisateursGrouped;
        arrayGrp.map((group, index)=>{
            if(group===[]){
                delete arrayGrp[index];
            }
        })
        this.setState({
            utilisateursGrouped:arrayGrp,
        })
    }

    render(){
        return(

            <div className="container">
                <h2>
                    <img src="/img/VENDEUR.svg" role='presentation'/> Gestion des vendeurs
                </h2>
                <hr/>
                <div className="row">

                    {/*Select de la societe et du site*/}
                    <div className="col-md-3" >

                        <div className="form-group row" >
                            <label className="col-lg-9 control-label text-left" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Société :</label>
                            <div className="col-lg-9">
                                {this.state.viewSelectSociete ?
                                    <select className="form-control" onChange={this.handleChangeSociete.bind(this)} id="societe" name="societe">
                                        <option></option>
                                        {this.state.societes.map((societe, id) => {
                                            return this.state.sites[id].length ? <option key={id} value={id}>{ societe }</option> : null;
                                        })}
                                    </select>
                                    : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3" >
                        <div className="form-group row ">
                            <label className="col-lg-9 control-label text-left" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Site :</label>
                            <div className="col-lg-9">
                                {this.state.viewSelectSite && this.state.IDPlaque ?
                                    <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="site" name="site">
                                        <option></option>
                                        {this.state.sites[this.state.IDPlaque].map((site, id) => {
                                            return <option key={id} value={id}>{site}</option>;
                                        })}
                                    </select>

                                    : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3" >
                        <div className="text-center">
                            <button type="submit" className="text-center btn btn-default submit-vo" style={{marginTop:'10px'}}   disabled={this.state.disabledValidation} onClick={(e)=>this.validerGestion(e)}>Valider l'ordre</button>
                        </div>
                    </div>

                    <div className="col-md-3" >
                        <div className="text-center">
                            <button  className="text-center btn btn-default submit-vo" style={{marginTop:'10px'}}  disabled={this.state.currentIDSite==null || this.state.IDPlaque==null } onClick={() => {this.refs.agendaVendeurs.show(); this.setState({selected:null,});  ReactGA.event({category: 'Gestion Priorités Vendeurs', action: 'Informations Agenda tous les Vendeurs'})}} >Agenda des vendeurs</button>
                        </div>
                    </div>

                    <div className="col-md-12" >

                        <div className="list-group-item row" style={{marginBottom:'2%'}}>
                            <div className="col-md-3" style={{marginTop:'12px'}}><b>Nom, Prénom</b></div>
                            <div className="col-md-2" style={{marginTop:'12px'}}><b>Téléphones</b></div>
                            <div className="col-md-1 text-center text-center" style={{marginTop:'12px'}}><b>Groupe</b></div>
                            <div className="col-md-1 text-center text-center"><b><p>Nb Leads</p> </b><small>Jour</small></div>
                            <div className="col-md-2 text-center text-center"><b><p>Nb Leads</p> </b><small>Semaine Glissante</small></div>
                            <div className="col-md-2 text-center text-center"><b><p>Taux de</p> </b>Transformation</div>

                            <div className="col-md-1 text-center" ><b><p>Statut</p></b><small>Actuel</small></div>

                        </div>

                        {this.state.fetchInProgress === true ?
                          <Loader style={{ marginTop: 150 }} />
                        : <div>
                          {this.state.message ?
                              <div>{this.state.message.includes('succès')?
                                      <p className={"alert alert-success"}>{this.state.message}</p>
                                  :   <p className={"alert alert-info"}>{this.state.message}</p>}
                              </div>
                              :null}

                          {this.state.utilisateursGrouped.map((group,indexGroup)=>{
                              return(  <div key={indexGroup}>
                                  <DragDropContext onDragEnd={(e) => this.onDragEnd(e, group, indexGroup)}>
                                      <Droppable droppableId={"droppable" + indexGroup}>
                                          {(provided, snapshot) => (
                                              <div ref={provided.innerRef} className="list-group"
                                                   style={group.length ===0 ? {all:'unset'}:getListStyle(snapshot.isDraggingOver)}>

                                                  {group.map((utilisateur, index) => (

                                                      <Draggable key={utilisateur.IDUtilisateur}
                                                                 draggableId={utilisateur.IDUtilisateur}
                                                                 index={index}
                                                                 style={{color:'red'}}
                                                                 draggableStyle={{color:'red'}}
                                                      >
                                                          {(provided, snapshot) => (
                                                              <div>
                                                                  <div
                                                                      ref={provided.innerRef}{...provided.draggableProps}{...provided.dragHandleProps}
                                                                      className={snapshot.isDragging ? "list-group-item active row" : "list-group-item row"}
                                                                      style={ getItemStyle(snapshot.isDragging, provided.draggableProps.style, this.state.arrayNewVendeurs.includes(utilisateur.IDUtilisateur))}>
                                                                      <div className="col-md-3">
                                                                          <PastilleVendeur utilisateur={utilisateur}/>
                                                                          &nbsp; &nbsp;
                                                                          {utilisateur.Nom}
                                                                      </div>
                                                                      <div className="col-md-2">
                                                                          <div className={'row'}>


                                                                              <Tooltip trigger={['focus']} overlay={"Appuyer sur entrer pour enregistrer la modification"}>
                                                                                  <div>
                                                                                        <InputMask key={utilisateur.IDUtilisateur+'mobile'} className={'col-md-10'} mask="99 99 99 99 99" maskChar=" " type="text" className="form-control"  defaultValue={this.haveNumMobile(utilisateur)} onKeyUp={(e)=>e.keyCode===13 ?  this.fetchMobile(e, utilisateur):""} pattern="[0-9 ]{14}"/>
                                                                                  </div>
                                                                              </Tooltip>
                                                                          </div>
                                                                      </div>
                                                                      <div className="col-md-1">
                                                                          <select className="center-block"
                                                                                  value={(indexGroup == 99) ? indexGroup : (indexGroup + 1)}
                                                                                  onChange={(e) => this.changeGroupUser(e, group, utilisateur)}>

                                                                              {this.state.utilisateurs.map((user, indexSelect) => {
                                                                                  return (
                                                                                      (indexSelect < (this.state.utilisateurs.length - this.state.arrayInactifs.length)) ?
                                                                                          <option key={user.IDUtilisateur}
                                                                                              value={indexSelect + 1}>{indexSelect + 1}</option>
                                                                                          : null
                                                                                  )
                                                                              })

                                                                              }

                                                                              <option key={99} value={99}>Aucun</option>

                                                                          </select>
                                                                      </div>

                                                                      <div>
                                                                          <div
                                                                              className="col-md-1 text-center">
                                                                                {this.state.UtilisateurStats[utilisateur.IDUtilisateur] ?
                                                                                    this.state.UtilisateurStats[utilisateur.IDUtilisateur].NombreLeadJour
                                                                                  : 'NC' }
                                                                              </div>
                                                                          <div
                                                                              className="col-md-2 text-center">
                                                                              {this.state.UtilisateurStats[utilisateur.IDUtilisateur] ?
                                                                                this.state.UtilisateurStats[utilisateur.IDUtilisateur].NombreLeadSemaine
                                                                              : 'NC' }
                                                                            </div>
                                                                            {this.state.UtilisateurStats[utilisateur.IDUtilisateur] ?
                                                                              <div className="col-md-2 text-center"
                                                                                   style={styleTauxTransformation(this.state.UtilisateurStats[utilisateur.IDUtilisateur].TauxTransformation)}>
                                                                                   {this.state.UtilisateurStats[utilisateur.IDUtilisateur].TauxTransformation}%
                                                                              </div>
                                                                            : <div className="col-md-2 text-center">NC</div>
                                                                          }
                                                                      </div>


                                                                      <Tooltip trigger={['hover']} overlay={<div>{this.state.holidaysTodayUser[utilisateur.IDUtilisateur] && this.state.holidaysTodayUser[utilisateur.IDUtilisateur].IDPeopleHoliday ? ("Absent : "+ (this.state.holidaysTodayUser[utilisateur.IDUtilisateur].Type.Libelle)+ (this.state.holidaysTodayUser[utilisateur.IDUtilisateur].EstRecurrent ?  " (récurrent)":"" )): "Pas d'absence aujourd'hui"}</div>}>
                                                                          <a className="col-md-1 text-center"
                                                                             onClick={() => {
                                                                                 this.setState({
                                                                                    selected: utilisateur,
                                                                                    frameStateAbsence : false,
                                                                                  }, () => {
                                                                                     this.refs.agendaVendeurs.show();
                                                                                 });
                                                                                 ReactGA.event({
                                                                                     category: 'Gestion Priorités Vendeurs',
                                                                                     action: 'Informations Agenda un  Vendeur'
                                                                                 });
                                                                             }}>
                                                                              <i className="fa fa-calendar fa-2x"
                                                                                 style={styleCalendar(this.state.holidaysTodayUser[utilisateur.IDUtilisateur])}>

                                                                              </i>
                                                                          </a>
                                                                      </Tooltip>
                                                                  </div>
                                                                  {provided.placeholder}
                                                              </div>
                                                          )}
                                                      </Draggable>
                                                  ))}
                                                  {provided.placeholder}
                                              </div>
                                          )}
                                      </Droppable>
                                  </DragDropContext>
                              </div>)
                          })}
                        </div>
                      }

                    </div>

                </div>
                {this.state.arrayInactifs.length >0 ? <h6>Utilisateurs inactifs</h6> : null}
                <div className={'list-group'} >
                    {this.state.arrayInactifs.map((utilisateur,index)=>{
                        return(<div key={utilisateur.IDUtilisateur}>
                            <li className={'list-group-item'} style={{backgroundColor:'rgb(194, 194, 194)', color:'white'}}>{utilisateur.Nom}</li>
                        </div>)
                    })}
                </div>
                <br />

                <SkyLight
                    hideOnOverlayClicked ref="agendaVendeurs" dialogStyles={{ width: '60%', left: '5%', marginLeft: '20%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>

                    <Scheduler
                        IDPlaque={this.state.IDPlaque}
                        IDSite={this.state.currentIDSite}
                        IDPeople={this.state.selected ? this.state.selected.IDUtilisateur : null}
                        NomPeople={this.state.selected ? this.state.selected.Nom : null}
                        TypeProfilLead="Vendeur"
                        Component={true}
                    />
                </SkyLight>

            </div>
        )
    }
}
export default GestionVendeurs;
