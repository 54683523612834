import React, {Component} from 'react';
import Globals from '../Globals';

import moment from 'moment'
var Datetime = require('react-datetime');
var lodash = require('lodash')

class FormToDoCreateAtelierAutres extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      _utilisateurs: [],
      utilisateurs: [],
      selected:null,
    }
  }

  componentWillMount() {
    this.fetchutilisateurs();
  }

  componentDidMount(){
    this.props.onChangeValue('todoToVendeur', true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.site !== this.props.site) {
      this.fetchutilisateurs();
    }
  }

  fetchutilisateursBySite() {
    fetch(localStorage.ClientApiURL + '/Utilisateurs?EstActif=true&LoadDetailDroitProfil=false&LoadPlaqueMarques=false', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var utilisateurs = lodash.filter(json, (utilisateur) => {
        return lodash.find(utilisateur.Sites, (site, i) => {

          if (parseInt(site.IDSite, 10) !== parseInt(this.props.site, 10)) {
            return false;
          }

          if (this.props.typeActive){
            if(this.props.typeActive === "Atelier")
              return utilisateur.EstTypeConseiller;
            else if(this.props.typeActive === "Autres")
              return utilisateur.EstTypeAutre;
          }

          return false;
        });
      });
      utilisateurs = lodash.orderBy(utilisateurs, 'Nom');

      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      },()=>{
        this.initiateUsers()
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchutilisateurs() {

    if (localStorage.user_IDUtilisateur == '-1' && localStorage.bypass_iFrame == 'true') {
      this.fetchutilisateursBySite();
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + this.props.site , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var utilisateurs = lodash.orderBy(json, 'Nom');
      
      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      },()=>{
        this.initiateUsers()
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeListeVendeur(id){
    var ListeArray =  this.props.todoVendeur;
    if(this.props.todoVendeur.indexOf(id) === -1){
      ListeArray.push(id);
    }
    else{
      ListeArray.splice(ListeArray.indexOf(id), 1);
    }
    this.props.onChangeValue('todoVendeur', ListeArray);
  }

  changeToVendeur(event) {
    this.props.onChangeValue('todoToVendeur', event.target.checked);
  }

  changeVendeur(event) {
    this.props.onChangeValue('todoVendeur', event.target.value);
  }

  getAllUsers(){
    var utilisateurs = lodash.filter(this.state.utilisateurs, (utilisateur) => {
      console.log(utilisateur.EstTypeConseiller)
      if (this.props.typeActive){
        if(this.props.typeActive === "Atelier")
          return utilisateur.EstTypeConseiller;
        else if(this.props.typeActive === "Autres")
          return utilisateur.EstTypeAutre;
      }
    });
    return utilisateurs;
  }

  initiateUsers(){
    var ListeArray =  [];
    this.getAllUsers().map((utilisateur, i) => {
        ListeArray.push(utilisateur.IDUtilisateur);
    });
    this.props.onChangeValue('todoVendeur', ListeArray);
  }

  getCheckedUser(id){
    if(this.props.todoVendeur && this.props.todoVendeur.indexOf(id) > -1)
      return true;
    else
      return false;
  }

  render() {
    if(!this.props.todoToVendeur)
      return null

    return (
      <div style={{ marginBottom: '55px' }}>

        <div className='row'>
          <div className="col-md-10 col-md-offset-1">
            <div className="form-group" style={{ marginBottom: '0px' }}>
              <div className="col-md-12" style={{ textAlign: 'left' }}>
                <h3>Conseillers disponibles</h3>
              </div>
            </div>
            {this.getAllUsers().length ?
                  <div className="form-group">
                    <div className="col-md-12" style={{ textAlign: 'left', float: 'none' }}>
                      {this.getAllUsers().map((utilisateur, i) => {
                      return <div className="col-md-12" key={i} style={{ textAlign: 'left', float: 'none' }}>
                        <input type='checkbox' id={'Vendeur-' + i} checked={this.getCheckedUser(utilisateur.IDUtilisateur)}
                          onChange={ (e) => this.changeListeVendeur(utilisateur.IDUtilisateur)} />
                        <label className="label-box" htmlFor={'Vendeur-'+i}>{utilisateur.Nom}</label>
                      </div>
                      })}
                    </div>
                  </div>
            :
            <div>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <label className="warning">[Aucun conseiller paramétré]</label>
                </div>
              </div>
            </div>
            }

            {this.getAllUsers().length && this.props.todoVendeur.length === 0 ?
            <div className="form-group" style={{ marginTop: '10px' }}>
              <div className="col-md-12" style={{ textAlign: 'center' }}>
                <label className="warning orange"><i>Un ou plusieurs conseillers doivent être renseignés</i></label>
              </div>
            </div> : null}

          </div>
        </div>

      </div>
    );
  }
}

export default FormToDoCreateAtelierAutres;
