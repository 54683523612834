import React, { Component } from 'react';

import SkyLight from 'react-skylight';
import Globals from '../Globals';
import Loader from './Loader';
var lodash = require('lodash');

class ModalSearchProspect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
              name: '',
              idprospect: ''
            },
            hasSearched: false,
            prospects: [],
            fetching: false,
        };
    }

    componentDidMount(){
        
    }

    fetchProspects() {
      this.setState({
        fetching: true,
        hasSearched: false,
      })
      var url = '/Prospects?name='+(this.state.search.name ? this.state.search.name : '')+'&phone=&email=&CodePostal=&Ville=&idprospect='+(this.state.search.idprospect ? this.state.search.idprospect : '')+'&VIN=&immat=&page=0&page_size=40&order_column=Name';

      fetch(localStorage.ClientApiURL + url , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        var prospects = json;
        if(this.props.excludeGroupements){
          prospects = lodash.filter(prospects, (p) => {return !p.IsGroupement});
        }
        if(this.props.excludeIDs){
          prospects = lodash.filter(prospects, (p) => {return this.props.excludeIDs.indexOf(p.IDProspect) === -1});
        }
        this.setState({
          prospects: prospects,
          fetching: false, 
          hasSearched: true, 
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({ fetching: false })
      });
    }

    show() {
        this.refs.modalSearchProspect.show();
    }

    hide() {
        this.refs.modalSearchProspect.hide();
    }

    canSearch() {
        return this.state.search.name.length > 2 || this.state.search.idprospect;
    }

    onChangeValue(prop, value){
      var search = this.state.search;
      search[prop] = value;
      this.setState({
        search: search
      })
    }

    selectProspect(prospect){
      this.props.onProspectSelected(prospect);
      return false;
    }

    render() {
        return (
                <SkyLight ref="modalSearchProspect"
                    dialogStyles={{ padding: 0, width: '50%', maxWidth: '800px', minWidth: '300px', height: 'auto', minHeight:'500px', left: '0', right: '0', top: '10%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                    title="Ajouter un adhérent"
                    titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                    closeButtonStyle={{ color: 'white'}}
                    >
                    <div className="inner-large-content" style={{ height: '100%', padding: '20px' }}>
                      <h2 className="title">Rechercher un prospect</h2>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12">
                              <input type="text" name="name" autoFocus={true} className="form-control" placeholder="Nom, Prénom" value={this.state.search.name} onChange={(e) => this.onChangeValue('name', e.target.value)} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />
                              <input type="text" name="idprospect" className="form-control" placeholder="N° prospect" value={this.state.search.idprospect} onChange={(e) => this.onChangeValue('idprospect', e.target.value)} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />
                          </div>

                          <div className="col-md-12 text-center">
                              <button className="btn btn-default" disabled={!this.canSearch()} onClick={this.fetchProspects.bind(this)}>Rechercher</button>
                          </div>
                        </div>
                      </div>

                      {this.state.hasSearched ? 
                        this.state.prospects.length ? 
                          <div className="row">
                            <div className="col-md-12" style={{maxHeight:'500px', overflowY:'auto'}}>
                              <table className="table-striped light-table">
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Nom</th>
                                    <th>CP</th>
                                    <th>Ville</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.prospects.map((p) => {
                                    return (<tr key={p.IDProspect}>
                                      <td>{p.IDProspect}</td>
                                      <td>
                                        <a onClick={() => this.selectProspect(p)}>{p.Nom} {p.Civilite ? p.Civilite+' ' : ''}</a>
                                      </td>
                                      <td>{p.CodePostal}</td>
                                      <td>{p.Ville}</td>
                                    </tr>)
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        :
                        <div className="text-center">Aucun résultat</div>
                      :this.state.fetching ?
                        <div className="text-center">
                            <Loader style={{ marginTop: 50 }} />
                        </div>
                      :null}
                    </div>
                </SkyLight>
        );
    }
}

export default ModalSearchProspect;
