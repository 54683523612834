import React, { Component } from 'react';

import SkyLight from 'react-skylight';

import Globals from '../../Globals';
var lodash = require('lodash');

class AffectationSiteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            IDSite:0,
            saving: false,
            sites: [],
        };
    }

    componentDidMount(){
        if(this.props.sites){
            this.setState({
                sites:this.props.sites
            });
        }
        else 
            this.fetchSites();
    }

    fetchSites(){
        fetch(localStorage.ClientApiURL + '/Plaques', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var sites = [];
      
            json.map((plaque) => {
              return plaque.Sites.map((site) => {
                sites.push(site);
                return site;
              })
            })
      
            sites = lodash.uniqBy(sites, (site) => {
              return site.IDSite;
            })
            sites = lodash.sortBy(sites, (site) => {
              return site.Plaque.Nom + ' - ' + (site.Libelle ? site.Libelle : site.Nom);
            })
      
            this.setState({
              sites: sites,
            });
        })
        .catch((error) => {
            console.log(error)
        });
    }

    submitSiteAffectation() {
        this.setState({
            saving:true
        });

        var prospect = this.props.prospect;
        prospect.SiteAffectation = {
            IDSite: this.state.IDSite
        }
    
        fetch(localStorage.ClientApiURL + '/prospects/'+this.props.prospect.IDProspect, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: JSON.stringify(prospect),
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        
            this.setState({
                saving:false
            })
            if(this.props.onSiteSaved)this.props.onSiteSaved(this.state.IDSite)

        })
        .catch((error) => {
            console.log(error)
            this.setState({
                saving:false
            })
        });
    }

    canSubmit() {
        return this.state.IDSite !== 0 && !this.state.saving;
    }

    selectSite(target) {
        this.setState({
            IDSite: parseInt(target.value, 10)
        });
    }

    show() {
        this.refs.affectSiteModal.show();
    }

    hide() {
        this.refs.affectSiteModal.hide();
    }

    render() {
        return (
            <SkyLight hideOnOverlayClicked ref="affectSiteModal"
                dialogStyles={{ padding: 0, width: '50%', maxWidth: '800px', minWidth: '300px', height: 'auto', left: '0', right: '0', top: '25%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                title="Point de vente"
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
                <div className="inner-large-content" style={{ height: '100%', padding: '20px' }}>
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="form-group" style={{padding:'30px'}}>
                                Indiquez le point de vente du prospect
                                <select className="form-control" onChange={(e) => this.selectSite(e.target)}>
                                    <option value={0}></option>
                                    {this.state.sites.map((s) => {
                                        if(!s.EstFictif)
                                            return <option key={s.IDSite} value={s.IDSite}>{s.Plaque.Nom} - {s.Libelle}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-xs-12 text-right">
                            <button type="button" className="btn btn-default" disabled={!this.canSubmit()} onClick={() => this.submitSiteAffectation()}>
                                {this.state.saving ? <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{marginRight:'5px'}}></i> : null} Valider
                            </button>
                        </div>
                    </div>
                </div>
            </SkyLight>
        );
    }
}

export default AffectationSiteModal;
