import React, {Component} from 'react';
import Globals from '../Globals';

var lodash = require('lodash')

class FormSocieteSiteVN extends Component {

  constructor(props) {
    super(props);

    this.state = {
      marques: [],
      societes: [],
      sites: [],
      _plaques: [],
      userSites: [],
    }
  }

  componentWillMount() {
    if(this.props.origin === "CallWin")
      this.fetchSocietesSites();
    else
      this.fetchUserSocietesSites();
    this.loadAffectation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.prospect !== prevProps.prospect) {
      this.loadAffectation();
    }
  }

  loadAffectation(){
    if(this.props.prospect && this.props.prospect.IDProspect && localStorage.bypass_iFrame !== "true"){
      if(this.props.prospect.SiteAffectation && this.props.prospect.SiteAffectation.EstVN === true && this.props.prospect.SiteAffectation.Marques.length == 1){
        this.props.onChangeValue('societe', this.props.prospect.SiteAffectation.Plaque.IDPlaque);
        this.props.onChangeValue('site', this.props.prospect.SiteAffectation.IDSite);
        this.props.onChangeValue('marque', this.props.prospect.SiteAffectation.Marques[0].Description);
      }
      else{
        this.props.onChangeValue('marque', '');
        this.props.onChangeValue('societe', 0);
        this.props.onChangeValue('site', 0);
      }
    }
  }

  fetchUserSocietesSites(){
    fetch(localStorage.ClientApiURL + '/Utilisateurs/'+localStorage.user_IDUtilisateur, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        userSites: lodash.map(json.Sites, 'IDSite'),
      }, this.fetchSocietesSites);
    })
    .catch((error) => {
        console.log(error)
    });
  }

  fetchSocietesSites() {
    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      console.log(json)
      var societes = [];
      var sites = [];

      var marques = [];

      lodash.map(json, (plaque) => {
        var sites = lodash.filter(plaque.Sites, (site) => {
          return site.Est_eSellerLeadActif === true 
            && ((this.props.typeActive === "VO" && site.EstVO === true) || (this.props.typeActive === "VN" && site.EstVN === true))
            && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1);
        });

        if (!sites.length) {
          return;
        }

        plaque.Sites.map((site) => {
          return site.Marques.map((marque) => {
            marques.push(marque.Description);
            return marque;
          });
        });

        return;
      });

      marques = lodash.uniq(marques).sort();

      json.map((plaque) => {
        return societes[plaque.IDPlaque] = plaque.Nom;
      })

      json.map((plaque) => {
        var index = societes.indexOf(plaque.Nom);
        sites[index] = [];
        return plaque.Sites.map((site) => {
          //récupération des sites actifs, Libelle s'il existe, et VN/VO pour affichage
          if(site.Est_eSellerLeadActif === true
            && (this.props.origin === "CallWin" || this.state.userSites.indexOf(site.IDSite) !== -1)
            && ((this.props.typeActive === "Atelier") || (this.props.typeActive === "VO" && site.EstVO === true ) || (this.props.typeActive === "VN" && site.EstVN === true) ) ){
              sites[index][site.IDSite] = site.Libelle ? site.Libelle : site.Nom;
          }
          return site;
        })
      })

      this.setState({
        marques: marques,
        societes: societes,
        sites: sites,
        _plaques: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleClickCanal(event) {
    this.props.onChangeValue('canal', event.target.value);
  }

  handleChangeSociete(event) {
    this.props.onChangeValue('societe', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('site', 0);
    this.props.onChangeValue('todoToVendeur', false);
  }

  handleChangeSite(event) {

    var societe = 0;

    if (event.target.value) {
      this.state._plaques.map((plaque) => {
        return plaque.Sites.map((site) => {
          if (parseInt(site.IDSite, 10) === parseInt(event.target.value, 10)) {
            societe = plaque.IDPlaque;
          }
          return site;
        })
      })
    }

    this.props.onChangeValue('societe', societe);
    this.props.onChangeValue('site', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('todoToVendeur', false);
  }

  generateAll(){
    this.fetchSocietesSites();
    this.props.onChangeValue('marque', 0);
    if(!this.props.callwin){
      this.props.onChangeValue('societe', 0);
      this.props.onChangeValue('site', 0);
    }
    this.loadAffectation();
    //console.log("marque :"+this.props.marque+", societe : "+this.props.societe+", site : "+this.props.site);
  }

  handleChangeMarque(event) {
    this.props.onChangeValue('marque', event.target.value);
    this.props.onChangeValue('societe', 0);
    this.props.onChangeValue('site', 0);
  }

  render() {
    if(this.props.origin === "CallWin"){
      return <div>

        <div style={{ marginBottom: '55px' }}>
        <h3>Société / Site</h3>

        <div className="form-group">
          <div className="col-md-8 col-md-offset-2">
            <select className="form-control" onChange={this.handleChangeSociete.bind(this)} id="leadSociete" name="leadSociete" value={this.props.societe} required="required">
              <option key="-1" value=''>Société</option>
              {this.state._plaques.map((societe, id) => {

                var findMarques = (this.props.typeActive === 'VN') ? true : false;

                societe.Sites.map((site) => {
                  return site.Marques.map((marque) => {
                    findMarques = findMarques ? findMarques : (marque.Description === this.props.marque);
                    return marque;
                  })
                })

                if (!findMarques) {
                  return null;
                }
                return this.state.sites[societe.IDPlaque].length ? <option key={societe.IDPlaque} value={societe.IDPlaque}>{ societe.Nom }</option> : null;
              })}
            </select>
          </div>
        </div>

        {this.props.societe !== null && this.props.societe !== "Société" ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="leadSite" name="leadSite" value={this.props.site} required="required">
                <option value="" key="-1">Site</option>
                {this.state._plaques.map((societe, id) => {
                  if (parseInt(societe.IDPlaque, 10) === parseInt(this.props.societe, 10)) {
                    return societe.Sites.map((site) => {
                      if (site.EstVN) {
                        return <option key={site.IDSite} value={site.IDSite} >{ site.Libelle ? site.Libelle : site.Nom }</option>;
                      }
                      return null;
                    })
                  }
                  return null;
                })}
              </select>
            </div>
          </div>
        : null }
      </div>

      </div>;
    }


    return (
      <div>

        <div style={{ marginBottom: '55px' }}>
        <h3>Société / Site</h3>

        {this.props.typeActive === 'VN' ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeMarque.bind(this)} id="leadMarque" name="leadMarque" value={this.props.marque} required="required">
                <option key="-1" value=''>Marque</option>
                {this.state.marques.map((marque, id) => {
                  return <option key={id} value={marque}>{ marque }</option>;
                })}
              </select>
            </div>
          </div>
        : null}

        {this.props.marque || this.props.site ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="leadSite" name="leadSite" value={this.props.site} required="required">
                <option key="-1" value=''>Site</option>
                {this.state._plaques.map((societe, id) => {

                  return societe.Sites.map((site) => {

                    if (site.EstFictif) {
                      return null;
                    }

                    var findMarques = false;
                    site.Marques.map((marque) => {
                      findMarques = findMarques ? findMarques : (marque.Description === this.props.marque)
                      return marque;
                    })
                    if (!findMarques) {
                      return null;
                    }

                    if(this.state.userSites.indexOf(site.IDSite) === -1)
                      return null;

                    return <option key={site.IDSite} value={site.IDSite}>{ site.Libelle ? site.Libelle : site.Nom }</option>;
                  })
                })}
              </select>
            </div>
          </div>
        : null}

        {this.props.marque && this.props.site ?
          <div className="form-group">
            <div className="col-md-8 col-md-offset-2">
              <p className='static-control'>
                Société : &nbsp;
                {this.state._plaques.map((plaque) => {
                  return plaque.Sites.map((site) => {
                    return parseInt(site.IDSite, 10) === parseInt(this.props.site, 10) ? plaque.Nom : '';
                  })
                })}
              </p>
            </div>
          </div>
        : null}

      </div>

      </div>
    );
  }
}

export default FormSocieteSiteVN;
