import React, { Component } from 'react';
import moment from 'moment';

class NewsView extends Component {

    render() {
        return (
            <div className="panel panel-default news-view">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-xs-12 text-right">
                            Début de la diffusion : {moment(this.props.news.dDebutValidation).format("DD/MM/YYYY")}<br/>
                            Fin de la diffusion : {moment(this.props.news.dFinValidation).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    <div className="row">
                        <br/>
                        <div className="col-xs-12 text-left" dangerouslySetInnerHTML={{__html:this.props.news.sMessageNews}}>
                        </div>
                    </div>
                    {this.props.news.oPieceJointe && this.props.news.oPieceJointe.iIDPieceJointe != "0" ? 
                        <div className="row">
                            <div className="col-xs-12 text-right">
                                <div className="text-center" style={{display:'inline-block'}}>
                                    <br/>
                                    <i className="fa fa-file-text-o" style={{fontSize:'30px', marginBottom:'5px'}}></i><br/>
                                    <a href={"http://"+this.props.news.oPieceJointe.sURLPJ}>{this.props.news.oPieceJointe.sNomFichier}</a>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}

export default NewsView;
