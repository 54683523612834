import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterMarque extends Component {

  constructor(props) {
    super(props);

    this.state = {
      marques: [],
      selected: [],
    }
  }

  componentDidMount() {
    //console.log(this.props.marques)
    if(this.props.selected){
      this.setState({
        selected: this.props.selected
      })
    }
  }

   onChange(event, marque) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(marque)
    }
    else {
      selected = lodash.without(selected, marque);
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('marques', this.state.selected);
    });
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  isChecked(marque) {
    return this.state.selected.indexOf(marque) !== -1;
  }

  render() {

    var marques = null;

    if (this.props.marques.length) {
      marques = lodash.map(this.props.marques, (marque, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={this.removeWhiteSpace("filtermarque_"+marque.marque)} checked={this.isChecked(marque.marque)} onChange={(event) => this.onChange(event, marque.marque)} />
          <label className="label-box" htmlFor={this.removeWhiteSpace("filtermarque_"+marque.marque)}>
            {marque.marque}
            &nbsp;<span className="count">({marque.nb})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par marque">
        {marques}
      </PanelFilter>
    );
  }
}

export default FilterMarque;
