import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';

import { browserHistory } from 'react-router';

import InputTime from '../Components/InputTime';

import moment from 'moment'

import { Link } from 'react-router';
var Datetime = require('react-datetime');

class PeopleHolidaysEdit extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
            types: [],
            utilisateur: null,
            holiday: null,
            fetchEditInProgress: false,

            /** Gestion absences récurrentes */
            frequences:[],
        }
    }

    componentDidMount() {
        this.fetchHoliday();
        this.fetchTypes();
        this.fetchFrequences();
    }

    fetchTypes() {
        fetch(localStorage.ClientApiURL + '/PeopleHolidaysTypes' , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    types: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchutilisateur() {
        fetch(localStorage.ClientApiURL + '/utilisateurs/' + this.state.holiday.IDPeople , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    utilisateur: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    /***
     * Chargement de toutes les fréquences
     */
    fetchFrequences(){
        fetch(localStorage.ClientApiURL + '/PeopleHolidays/frequences', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    frequences: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchHoliday() {
        fetch(localStorage.ClientApiURL + '/PeopleHolidays/' + this.props.params.IDPeopleHoliday , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    holiday: json,
                }, () => {
                    this.fetchutilisateur();
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    changeType(event) {
        var holiday = this.state.holiday;
        holiday.IDPeopleHolidayType = event.target.value
        this.setState({
            holiday: holiday,
        })
    }

    changeDateStart(event) {
        var holiday = this.state.holiday;
        holiday.DateHStart = event.set('hours', moment(this.state.holiday.DateHStart).get('hours')).set('minutes', moment(this.state.holiday.DateHStart).get('minutes')).set('seconds', 0).utcOffset(2).format()
        this.setState({
            holiday: holiday,
        })
    }

    changeDateEnd(event) {
        var holiday = this.state.holiday;
        holiday.DateHEnd = event.set('hours', moment(this.state.holiday.DateHEnd).get('hours')).set('minutes', moment(this.state.holiday.DateHEnd).get('minutes')).set('seconds', 0).utcOffset(2).format()
        this.setState({
            holiday: holiday,
        })
    }

    changeTimeStart(event) {
        var holiday = this.state.holiday;
        holiday.DateHStart = moment(this.state.holiday.DateHStart).set('hours', event.get('hours')).set('minutes', event.get('minutes')).set('seconds', 0).utcOffset(2).format()
        this.setState({
            holiday: holiday,
        })
    }

    changeTimeEnd(event) {
        var holiday = this.state.holiday;
        holiday.DateHEnd = moment(this.state.holiday.DateHEnd).set('hours', event.get('hours')).set('minutes', event.get('minutes')).set('seconds', 0).utcOffset(2).format()
        this.setState({
            holiday: holiday,
        })
    }

    isValidDateStart(event) {
        return true;
    }

    isValidDateEnd(event) {
        return true;
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            fetchEditInProgress: true,
        })

        fetch(localStorage.ClientApiURL + '/PeopleHolidays/' + this.props.params.IDPeopleHoliday, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: JSON.stringify(this.state.holiday),
        })
            .then((response) => {
                this.setState({
                    fetchEditInProgress: false,
                })

                if (!response.ok) {
                    throw Error(response.statusText);
                }

                if (localStorage.bypass_iFrame == "true") {
                    browserHistory.push('/iframe/holidays/' + this.state.holiday.IDPeople);
                }
                else {
                    browserHistory.push('/holidays/' + this.state.holiday.IDPeople);
                }

            })
            .catch((error) => {
                console.log(error)
            });
    }

    handleCheckBoxAbsRecu(){
        let holiday = this.state.holiday;
        holiday.EstRecurrent=!holiday.EstRecurrent;
        this.setState({
            holiday:holiday,
        })
    }

    handleChangeJour(e){
        let holiday = this.state.holiday;
        holiday.Jour=e.target.value;
        this.setState({
            holiday:holiday,
        })
    }

    handleChangeCreneau(e){
        let holiday = this.state.holiday;
        holiday.Creneau=e.target.value;
        this.setState({
            holiday:holiday,
        })
    }

    handleChangeFrequence(e){
        let holiday = this.state.holiday;
        holiday.Frequence={'IDPeopleHolidayFrequence':e.target.value};
        this.setState({
            holiday:holiday,
        })
    }

    render() {

        if (!this.state.holiday) {
            return <Loader style={{ marginTop: 150 }} />;
        }

        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>

                        <div>
                            <h1>Gestion des absences</h1>
                            {localStorage.bypass_iFrame == "true" ?
                                <Link className='btn btn-default pull-right' to={'/iframe/holidays/'+this.props.params.IDPeople}>Voir les absences</Link>
                                :
                                <Link className='btn btn-default pull-right' to={'/holidays/'+this.props.params.IDPeople}>Voir les absences</Link>
                            }
                        </div>

                        <br />

                        <h2>Modification d'une absence</h2>

                        <form onSubmit={this.handleSubmit.bind(this)}>

                            {/*Partie gauche de la page*/}
                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className="col-md-3">Vendeur</label>
                                    <div className="col-md-7">
                                        <p className="form-control-static">{this.state.utilisateur ? this.state.utilisateur.Nom : null}</p>
                                    </div>
                                </div>



                            <div className="col-md-12" />


                                <div className="form-group">
                                    <label className="col-md-3">Type</label>
                                    <div className="col-md-6">
                                        <select className="form-control" id="type" name="type" onChange={this.changeType.bind(this)} value={this.state.holiday.Type.IDPeopleHolidayType} required="required">
                                            <option></option>
                                            {this.state.types.map((type, i) => {
                                                return <option key={i} value={type.IDPeopleHolidayType}>{type.Libelle}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>


                            <div className="col-md-12" />


                                <div className="form-group">
                                    <label className="col-md-3">Date de début</label>
                                    <div className="col-md-3">
                                        <Datetime inputProps={{required: 'required'}} timeFormat={false} closeOnSelect={true} dateFormat="DD/MM/YYYY" onChange={this.changeDateStart.bind(this)} value={moment(this.state.holiday.DateHStart)} key='start-date' isValidDate={this.isValidDateStart.bind(this)} />
                                    </div>
                                    {!this.state.isAbsRecu ?
                                    <div className="col-md-3" style={{ paddingLeft: '0px' }}>
                                        <InputTime value={moment(this.state.holiday.DateHStart)} onChange={this.changeTimeStart.bind(this)} />
                                    </div>
                                    : null}
                                </div>

                                <div className="form-group">
                                    <label className="col-md-3">Date de fin</label>
                                    <div className="col-md-3">
                                        <Datetime inputProps={{required: 'required'}} timeFormat={false} closeOnSelect={true} dateFormat="DD/MM/YYYY" onChange={this.changeDateEnd.bind(this)} value={moment(this.state.holiday.DateHEnd)} key='end-date' isValidDate={this.isValidDateEnd.bind(this)} />
                                    </div>
                                    {!this.state.isAbsRecu ?
                                    <div className="col-md-3" style={{ paddingLeft: '0px' }}>
                                        <InputTime value={moment(this.state.holiday.DateHEnd)} onChange={this.changeTimeEnd.bind(this)} />
                                    </div>
                                    : null}
                                </div>


                            {/* Affichage abscences récurrentes*/}

                                <div className="form-group">
                                    <div className="col-md-3">Récurrence</div>
                                    <div className="col-md-6" style={{paddingLeft:0}}>

                                        <div className="col-md-12" style={{ textAlign: 'left', marginLeft:0 }}>
                                            <input type='checkbox' id='checkRecurrente' onChange={this.handleCheckBoxAbsRecu.bind(this)} checked={this.state.holiday.EstRecurrent} />
                                            <label className="label-box" htmlFor="checkRecurrente">Abscence Récurrente </label>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {this.state.holiday.EstRecurrent ?
                                /*Partie droite de la page*/
                                <div className={'col-md-6 form-group'} id={'gestionAbsRecu'}>
                                    <div className={ 'text-center col-md-7'} style={{marginBottom:'15px'}}><span >Spécificités de l'absence récurrente</span></div>

                                    <div className={'col-md-12'}/>
                                    <label className="col-md-3">Jour</label>
                                    <div className="col-md-5">
                                        <select className="form-control" id="jour" name="jour" onChange={event =>  this.handleChangeJour(event)} defaultValue={this.state.holiday.Jour} required="required">
                                            <option key={-1}> </option>
                                            <option key={0} value={"0"}>Lundi</option>
                                            <option key={1} value={"1"}>Mardi</option>
                                            <option key={2} value={"2"}>Mercredi</option>
                                            <option key={3} value={"3"}>Jeudi</option>
                                            <option key={4} value={"4"}>Vendredi</option>
                                            <option key={5} value={"5"}>Samedi</option>
                                            <option key={6} value={"6"}>Dimanche</option>

                                        </select>


                                    </div>
                                    <div className={'col-md-12'}/>

                                    <label className="col-md-3">Créneau</label>
                                    <div className="col-md-5">
                                        <select className="form-control" id="jour" name="jour" onChange={(event) =>  this.handleChangeCreneau(event)} defaultValue={this.state.holiday.Creneau} required="required">
                                            <option key={-1}> </option>
                                            <option key={'M'} value={"M"}>Matin</option>
                                            <option key={'A'} value={"A"}>Après-midi</option>
                                            <option key={'J'} value={"J"}>Jour entier</option>

                                        </select>
                                    </div>

                                    <div className={'col-md-12'}/>

                                    <label className="col-md-3">Fréquence</label>
                                    <div className="col-md-5">
                                        <select className="form-control" id="jour" name="jour" onChange={(event) =>  this.handleChangeFrequence(event)} defaultValue={this.state.holiday.Frequence ? this.state.holiday.Frequence.IDPeopleHolidayFrequence : null} required="required">
                                            <option key={-1}> </option>
                                            {this.state.frequences.map((frequence,index)=>{
                                                return <option key={frequence.IDPeopleHolidayFrequence} value={frequence.IDPeopleHolidayFrequence}>{frequence.Libelle}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                :null}

                            <div className="col-md-7">
                                <div className="col-md-8 col-md-offset-3">
                                    <button type="submit" className="text-center btn btn-default" disabled={this.state.fetchEditInProgress}>{this.state.fetchEditInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Sauvegarder</button>
                                </div>

                            </div>

                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default PeopleHolidaysEdit;
