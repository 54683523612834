import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import moment from 'moment'

import ResumeCampagne from './ResumeCampagne';
import ResumeFile from './ResumeFile';

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class FormStep3 extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      societes: [],
      campagne: {
        Code: '',
        Nom: '',
        Marque: '',
        Societe: {
          IDPlaque: '',
        },
        OrigineAction: {
          IDOrigineAction: '',
        },
        DateDebutCampagne: '',
      },
      displayFormConstructeur: false,
      showRepartition: false,
    }
  }

  componentDidMount() {
    this.fetchSocietes();
    this.fetchOrigines();
  }

  fetchSocietes() {
    fetch(localStorage.ClientApiURL + '/Plaques' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var societes = lodash.sortBy(json, (s) => {
        return s.Nom;
      })

      this.setState({
        societes: societes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchOrigines() {
    fetch(localStorage.ClientApiURL + '/LeadOrigineActions' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var origines = lodash.filter(json, (o) => {
        if (o.EstInactif)
          return false;

        if (!o.EstLead)
          return false;

        if (!o.Type || o.Type === '')
          return false;

        return true;
      })

      origines = lodash.sortBy(origines, (o) => {
        return o.Type + ' - ' + o.Famille + ' - ' + o.SousFamille + ' - ' + o.Origine;
      })

      this.setState({
        origines: origines,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {
    return (
    <div className='row' style={this.props.style}>

      <ResumeCampagne campagne={this.props.campagne} />

      <ResumeFile campagne={this.props.campagne} />

      <div className='col-md-12'>
        <h3>Stats</h3>

        <br />

        <div className='col-md-12'>
          <div className='col-md-3'>
            <u>Nombre de fiches</u> : 300<br />
          </div>

          <div className='col-md-3'>
            <u>Fiches existantes</u> : 100<br />
          </div>

          <div className='col-md-3'>
            <u>Fiches avec un téléphone différent</u> : 10<br />
          </div>

          <div className='col-md-3'>
            <u>Fiches avec une adresse email différente</u> : 10<br />
          </div>

        </div>

      </div>

      <div className='col-md-12'>

        <br />
        <br />

        <div className='col-md-6 text-center'>
          <a className='btn btn-default'>Campagne réalisée par Welcome-Pro</a>
        </div>
        <div className='col-md-6 text-center'>
          <a className='btn btn-default' onClick={() => this.setState({ showRepartition: true, })}>Campagne réalisée en interne</a>
        </div>
      </div>

      {this.state.showRepartition ?
        <div>
          <div className='col-md-12'>

            <br />
            <br />

            <div className='col-md-6'>
              <div>
                <label htmlFor="Nom" className="col-sm-3 control-label" style={{ fontWeight: 'normal', fontSize: '12px', lineHeight: '26px' }}>Opérateur</label>
                <div className="col-sm-6">
                  <select className="form-control" style={{ width: '200px', margin: 'auto', }} multiple={true}>
                    <option value='1' key='1'>Opérateur 1</option>
                    <option value='2' key='2'>Opérateur 2</option>
                    <option value='3' key='3'>Opérateur 3</option>
                    <option value='4' key='4'>Opérateur 4</option>
                  </select>
                </div>
              </div>

            </div>
            <div className='col-md-6'>
              <br /><br />
              <div className="form-group">
                <label htmlFor="Nom" className="col-sm-3 control-label">Appel par jour</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control" value='10' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 text-center'>
            <a className='btn btn-default'>Valider</a>
          </div>
        </div>
      : null}

    </div>
    );
  }
}

export default FormStep3;
