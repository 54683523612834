import React, { Component } from 'react';

import moment from 'moment'

class FilterPeriode extends Component {

  constructor(props) {
    super(props)

    moment.locale('fr');
  }

  handleClickType(value, event) {
    this.props.onChangePeriode(value);
  }

  getTotal() {
    var total = (this.props.counters.nouveau || 0) + (this.props.counters.late || 0) + (this.props.counters.incoming || 0);
    if(this.props.showTabReportes && this.props.counters.reportes) {
      total += this.props.counters.reportes;
    }
    return total;
  }

  render() {
    return (
      <div className={this.props.className}>

          <div className="btn-group pull-left" style={{ display: 'inline-block', paddingTop: 1, marginRight: '30px', marginBottom: '15px' }}>
            <button className={this.props.typePeriode === 'nouveau' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="nouveau" onClick={this.handleClickType.bind(this, 'nouveau')}>
              Nouveau
              <span> ({this.props.counters.nouveau || 0})</span>
            </button>
            <button className={this.props.typePeriode === 'late' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="late" onClick={this.handleClickType.bind(this, 'late')}>
              En cours
              <span> ({this.props.counters.late || 0})</span>
            </button>
            <button className={this.props.typePeriode === 'incoming' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="incoming" onClick={this.handleClickType.bind(this, 'incoming')}>
              A venir
              <span> ({this.props.counters.incoming || 0})</span>
            </button>
            {this.props.showTabReportes ? 
              <button className={this.props.typePeriode === 'reportes' ? 'btn btn-primary active' : 'btn btn-primary'} type="button" value="reportes" onClick={this.handleClickType.bind(this, 'reportes')}>
                Reportés
                <span> ({this.props.counters.reportes || 0})</span>
              </button>
            :null}
          </div>
          <div style={{display:'inline-block', paddingTop: 4, fontSize:'0.9em'}}>Total {this.getTotal()}</div>

          <div className='clearfix' />

      </div>
    );
  }
}

export default FilterPeriode;
