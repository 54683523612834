import React, { Component } from 'react';
import { Link } from 'react-router';

import FilterType from './FilterType.js';
import FilterTypeDemande from './FilterTypeDemande.js';
import FilterUrgent from './FilterUrgent.js';
import FilterSocieteSite from './../Leads/FilterSocieteSite';
import FilterOrigine from './../Leads/FilterOrigine';
import FilterVendeur from './FilterVendeur';

import Tooltip from "rc-tooltip";

class Sidebar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showFilters: true,
    }
  }

  onClick(event) {
    this.props.showList();
    if (event.currentTarget.href === window.location.href) {
      this.setState({ showFilters: !this.state.showFilters });
    }
    else if(window.location.pathname.indexOf("AffairesEncours") !== -1){
      this.props.onChangeFilters("reset");
    }
  }

  renderModeDegrade() {
    
    var currentLocation = window.location.pathname;

    return (
      <div  className="container-fluid">
        <div className="row">
          <div className="col-md-12">

            <Link to={'/leadsAtelier/TypeAtelier'} className={"btn btn-default linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Leads atelier <span></span>
            </Link>

            {currentLocation === '/leadsAtelier/TypeAtelier' && this.state.showFilters ?
              <div>
                <FilterType types={this.props.types} onChange={this.props.onChangeFilters} />
                {/*<FilterTypeDemande types={this.props.typesDemandes} onChange={this.props.onChangeFilters} />*/}
                <FilterUrgent priorites={this.props.priorites} onChange={this.props.onChangeFilters} />
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
              </div>
            : null}

          </div>
        </div>
      </div>
    );
  }

  render() {

    if (localStorage.eSellerLeadAtelierMailRapport === 'true') {
      return this.renderModeDegrade();
    }

    var currentLocation = window.location.pathname;

    return (
      <div  className="container-fluid">
        <div className="row">
          <div className="col-md-12">

            {localStorage.access_redistribuer === 'true' ?
              <Link to={'/leadsAtelier/AffairesEncours/ATraiter'} className={"btn btn-default linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
                Leads à traiter
              </Link>
            : null}

              {localStorage.access_redistribuer === 'true' && currentLocation === '/leadsAtelier/AffairesEncours/ATraiter' && this.state.showFilters ?
                <div>
                  {/*<FilterTypeDemande types={this.props.typesDemandes} onChange={this.props.onChangeFilters} />*/}
                  <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                  <FilterUrgent priorites={this.props.priorites} onChange={this.props.onChangeFilters} />
                  <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                </div>
              : null}

            {localStorage.access_redistribuer === 'true' ?
              [<br key={1}/>, <br key={2} />]
            : null}

            {this.props.typesDemandesSidebar.map((type, index) => {
              return (
              <span key={index}>
                <Link to={'/leadsAtelier/AffairesEncours/'+encodeURI(type.name)} className={"btn linkToList " + (this.state.showFilters ? 'withFilters' : '') + " "+type.class} activeClassName="active" onClick={this.onClick.bind(this)}>
                  <div className="row">
                    <div className="col-xs-8">{type.name}</div>
                    <div className="col-xs-4 compteurs">
                      <div className="row">
                        <div className="col-xs-3" style={{padding:0}}>
                          <Tooltip trigger={['hover']} placement='left' overlay="En retard">
                            <i className="icons icon-clock"></i>
                          </Tooltip>
                        </div>
                        <div className="col-xs-2 text-center" style={{padding:0}}>
                          {this.props.nbLeadsEnCours[type.name+"_EnRetard"] ? this.props.nbLeadsEnCours[type.name+"_EnRetard"] : 0}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-3" style={{padding:0}}>
                          <Tooltip trigger={['hover']} placement='left' overlay="A venir">
                            <i className="icons icon-calendar"></i>
                          </Tooltip>
                        </div>
                        <div className="col-xs-2 text-center" style={{padding:0}}>
                          {this.props.nbLeadsEnCours[type.name+"_AVenir"] ? this.props.nbLeadsEnCours[type.name+"_AVenir"] : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                {currentLocation === '/leadsAtelier/AffairesEncours/'+encodeURI(type.name) && this.state.showFilters ?
                <div>
                  <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                  <FilterUrgent priorites={this.props.priorites} onChange={this.props.onChangeFilters} />
                  <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                </div>
                :null}
                <br/><br/>
              </span>
              );
            })}

            <Link to={'/leadsAtelier/AffairesGagnees'} className={"btn btn-success linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Leads transformés <span></span>
            </Link>

            {currentLocation === '/leadsAtelier/AffairesGagnees' && this.state.showFilters ?
              <div>
                <FilterTypeDemande types={this.props.typesDemandes} onChange={this.props.onChangeFilters} />
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterUrgent priorites={this.props.priorites} onChange={this.props.onChangeFilters} />
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} />
              </div>
            : null}

            <br /><br />
            

            <Link to={'/leadsAtelier/AffairesClotures'} className={"btn btn-danger linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Leads non transformés <span></span>
            </Link>

            {currentLocation === '/leadsAtelier/AffairesClotures' && this.state.showFilters ?
              <div>
                <FilterTypeDemande types={this.props.typesDemandes} onChange={this.props.onChangeFilters} />
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterUrgent priorites={this.props.priorites} onChange={this.props.onChangeFilters} />
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} />
              </div>
            : null}

            <br /><br />
{/*
          <Link to={'/leadsAtelier/AffairesTraitees'} className={"btn linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)} >
              Leads clôturés <span style={{ backgroundColor: 'grey !important' }}></span>
            </Link>

            {currentLocation === '/leadsAtelier/AffairesTraitees' && this.state.showFilters ?
              <div>
                <FilterUrgent priorites={this.props.priorites} onChange={this.props.onChangeFilters} />
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} />
              </div>
            : null}
*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
