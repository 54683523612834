import React, { Component } from 'react';

class PanelFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showBody: true,
    }
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  render() {

    return (
      <div className="panel panel-default panel-leads-filter">
        <div className="panel-heading">
          <h3 className="panel-title pull-left" style={{ lineHeight: '14px' }}>
              <label className="lvl-1" >
                {this.props.title}
              </label>
          </h3>
          <div className="clearfix" />
        </div>
        <div className="panel-body">
          {this.props.children ? this.props.children : <br />}
        </div>
      </div>
    );
  }
}

export default PanelFilter;
