import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterVendeur extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vendeurs: [],
      selected: [],
    }
  }

  componentWillMount() {
    this.loadVendeurs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendeurs !== prevProps.vendeurs) {
      this.loadVendeurs();
    }
  }

  componentDidMount() {
    if(this.props.selected){
      this.setState({
        selected: this.props.selected
      })
    }
  }

  loadVendeurs() {
    var vendeurs = [];

    lodash.forIn(this.props.vendeurs, function(value, key){
      vendeurs.push({name: key, count: value});
    });

    vendeurs = lodash.sortBy(vendeurs, ['name']);

    this.setState({
      vendeurs: vendeurs,
    });
  }

  onChange(event, vendeur) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(vendeur)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== vendeur;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('vendeurs', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.vendeurs, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('vendeurs', this.state.selected);
    });
  }

  render() {

    var vendeurs = null;

    if (this.state.vendeurs.length) {
      vendeurs = this.state.vendeurs.map((vendeur, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={vendeur.name} checked={this.state.selected.indexOf(vendeur.name) > -1 ? true : false} onChange={(event) => { this.onChange(event, vendeur.name); }} />
          <label className="label-box" htmlFor={vendeur.name}>
            {vendeur.name}
            &nbsp;<span className="count">({vendeur.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par vendeur" allSelected={this.state.selected.length === this.state.vendeurs.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {vendeurs}
      </PanelFilter>
    );
  }
}

export default FilterVendeur;
