import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterSocieteSite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      plaques: [],
      sites: [],
      selectedPlaques: [],
      selectedSites: [],
    }

    this.key = this.props.name || ""
  }

  componentWillMount() {
    this.initPlaques();
    this.initSites();
  }

  componentDidMount() {
    if(this.props.selected) {
      this.setState({
        selectedSites: this.props.selected
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leads !== prevProps.leads) {
      this.initPlaques();
      this.initSites();
    }
  }

  initPlaques() {

    // Récupération des différentes plaques
    var plaques = lodash.map(this.props.leads, (lead) => {
      return lead.Site.Plaque;
    });

    // Dédoublonnage des plaques
    plaques = lodash.uniqBy(plaques, (plaque) => {
      return plaque.IDPlaque;
    });

    // Tri par ordre alphabétique des plaques
    plaques = lodash.sortBy(plaques, (plaque) => {
      return plaque.Nom;
    })

    this.setState({
      plaques: plaques,
    });
  }

  initSites() {

    // Récupération des différents sites
    var sites = lodash.map(this.props.leads, (lead) => {
      return lead.Site;
    });

    // Dédoublonnage des sites
    sites = lodash.uniqBy(sites, (site) => {
      return site.IDSite;
    });

    // Tri par ordre alphabétique des sites
    sites = lodash.sortBy(sites, (site) => {
      return sites.Nom;
    })

    this.setState({
      sites: sites,
    });
  }

  onChangePlaque(event) {

    var IDPlaque = event.target.name;

    var selectedPlaques = this.state.selectedPlaques;
    var selectedSites = this.state.selectedSites;

    if (event.target.checked) {
      selectedPlaques.push(parseInt(IDPlaque, 10));

      // Ajout de tous les sites enfants de la plaque
       var sitesToAdd = lodash.filter(this.state.sites, (site) => {
         return parseInt(site.Plaque.IDPlaque, 10) === parseInt(IDPlaque, 10)
       }).map((site) => {
         return site.IDSite;
       });
       selectedSites = selectedSites.concat(sitesToAdd);
    }
    else {
      selectedPlaques = lodash.remove(selectedPlaques, (plaque) => {
        return parseInt(plaque, 10) !== parseInt(IDPlaque, 10);
      });

      // Suppression de tous les sites enfants de la plaque
      var sitesToDelete = lodash.filter(this.state.sites, (site) => {
        return parseInt(site.Plaque.IDPlaque, 10) === parseInt(IDPlaque, 10)
      }).map((site) => {
        return site.IDSite;
      });

      sitesToDelete.map((siteToDelete) => {
        return lodash.remove(selectedSites, (site) => {
          return parseInt(site, 10) === parseInt(siteToDelete, 10);
        });
      });
    }

    this.setState({
      selectedPlaques: selectedPlaques,
      selectedSites: selectedSites,
    }, () => {
      this.props.onChange('sites', this.state.selectedSites);
    });
  }

  onChangeSite(IDPlaque, event) {

    var IDSite = event.target.name;

    var selectedPlaques = this.state.selectedPlaques;
    var selectedSites = this.state.selectedSites;

    if (event.target.checked) {
      selectedSites.push(parseInt(IDSite, 10));

      // Si la plaque n'est pas sélectionné, on l'ajoute
      if (this.state.selectedPlaques.indexOf(parseInt(IDPlaque, 10)) <= -1) {
        selectedPlaques.push(parseInt(IDPlaque, 10));
      }
    }
    else {
      selectedSites = lodash.remove(selectedSites, (site) => {
        return parseInt(site, 10) !== parseInt(IDSite, 10);
      });

      // Si plus de site actif pour la plaque parente, on décoche la plaque parente
      var selectedSitesOfPlaque = lodash.filter(this.state.sites, (site) => {
        return site.Plaque.IDPlaque === IDPlaque && selectedSites.indexOf(parseInt(site.IDSite, 10)) > -1;
      });

      if (selectedSitesOfPlaque.length === 0) {
        selectedPlaques = lodash.remove(selectedPlaques, (plaque) => {
          return plaque !== IDPlaque;
        });
      }
    }

    this.setState({
      selectedPlaques: selectedPlaques,
      selectedSites: selectedSites,
    }, () => {
      this.props.onChange('sites', this.state.selectedSites);
    });
  }

  isCheckedSite(site) {
    if (this.state.selectedSites.indexOf(site.IDSite) > -1) {
      return true;
    }

    return false;
  }

  renderSites(plaque) {

    var sites = lodash.filter(this.state.sites, (site) => {
      return site.Plaque.IDPlaque === plaque.IDPlaque;
    });

    return sites.map((site, j) => {
      return (
        <div key={site.IDSite} className={'block-height' + (j === 0 ? ' first' : '') + (j === sites.length-1 ? ' empty ' : '')}>
          <div className="checkbox rank-1">
            <input type="checkbox" id={this.key+'site-'+site.IDSite} name={site.IDSite} checked={this.isCheckedSite(site)} onChange={this.onChangeSite.bind(this, plaque.IDPlaque)} />
            <label className="label-box" htmlFor={this.key+'site-'+site.IDSite}>
              <span className="label-root">{site.Nom}</span>
              <span className="count"></span>
            </label>
            <div className="clearfix"></div>
          </div>
        </div>
      );
    });
  }

  render() {

    var content;

    if (this.state.plaques.length) {
      content = this.state.plaques.map((plaque, i) => {
        let id = this.key+'plaque-'+plaque.IDPlaque;
        return (
          <div key={plaque.IDPlaque}>
            <div className="global-check">
              <input type="checkbox" id={id} name={plaque.IDPlaque} onChange={this.onChangePlaque.bind(this)} checked={(this.state.selectedPlaques.indexOf(plaque.IDPlaque) > -1) ? true : false} />
              <label className="label-box" htmlFor={id}>
                <span className="label-root">{plaque.Nom}</span>
              </label>
            </div>
            <div  className="block-height first empty">
              {this.renderSites(plaque)}
            </div>
          </div>);
      });
    }

    return (
      <PanelFilter title="Par société / site">
        <div className="panel panel-default panel-leads-filter filters-grad" style={{paddingTop: 5}}>
          <div className="panel-body">
            {content}
          </div>
        </div>
      </PanelFilter>
    );
  }
}

export default FilterSocieteSite;
