module.exports = {
  VERSION: '2.4.0',
  API_URL: 'https://api.e-seller.selectup.com/api',
  API_URL_DEV: 'https://apidev.e-seller.selectup.com/api',
  API_URL_DEV_AGRI: 'https://apidev.e-seller.selectup.com/api',
  API_URL_HONDA: 'http://honda.api.e-seller.selectup.com/api',
  API_KEY: 'f5058a51-9fd3-4a9d-9c39-82267467c25e',
  USERNAME_WALLY: 'wally',
  PASSWORD_WALLY: 'wally123',
  URL_WALLY: 'https://v1.api.wally.edatavoice.com',
  FETCH_TIMEOUT: 1000 * 10, // 10 Secondes
};
