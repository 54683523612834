import React, { Component, Children } from 'react';
import ReactTooltip from 'react-tooltip'

class InfobulleDashboard extends Component {

  render() {
    var id = 'InfDash'+Math.random().toString(36);
    return (
        <span>
          <span data-for={id} data-tip={this.props.children}>
            <i className='fa fa-question-circle'/>
          </span>
          <ReactTooltip id={id} place='top'/>
        </span>
    )
  }

}

export default InfobulleDashboard;


