import React, { Component } from 'react';
import PanelFilter from '../Leads/PanelFilter';

var lodash = require('lodash')

class FilterTypeDemande extends Component {

  constructor(props) {
    super(props);

    this.state = {
      types: [],
      selected: [],
    }
  }

  componentWillMount() {
    this.loadtypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.types !== prevProps.types) {
      this.loadtypes();
    }
  }

  loadtypes() {
    var types = [];

    lodash.forIn(this.props.types, function(value, key){
      types.push({name: key, count: value});
    });

    types = lodash.sortBy(types, ['name']);

    this.setState({
      types: types,
    });
  }

  onChange(event, type) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(type)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== type;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('typesDemandes', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.types, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('typesDemandes', this.state.selected);
    });
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  isChecked(type) {
    if (this.state.selected.length === 0) {
      return false;
    }

    return this.state.selected.indexOf(type) > -1 ? true : false
  }


  render() {

    //console.log(this.props.types)

    //return null;

    var types = null;

    if (this.state.types.length) {
      types = this.state.types.map((type, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={this.removeWhiteSpace("type_demande_"+type.name)} checked={this.isChecked(type.name)} onChange={(event) => { this.onChange(event, type.name); }} />
          <label className="label-box" htmlFor={this.removeWhiteSpace("type_demande_"+type.name)}>
            {type.name}
            &nbsp;<span className="count">({type.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par type de demande" allSelected={this.state.selected.length === this.state.types.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {types}
      </PanelFilter>
    );
  }
}

export default FilterTypeDemande;
