import React, {Component} from 'react';
import Globals from '../Globals';

import FormTypeAppelSortant from './FormTypeAppelSortant.js';
import TableRowComment from '../Components/TableRowComment';
import TableRowDateRelance from '../Components/TableRowDateRelance';

import moment from 'moment'
import Dictionary from '../Dictionnary';
var lodash = require('lodash');

var FileSaver = require('file-saver');

class FormOrigine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      campagnes: [],
      tabCampagne: [],
      origines: [],
      originesByType: [],
      familles: [],
    }
  }

  componentWillMount() {
    this.fetchCampagnes();
    this.fetchOrigines();
  }



  setFrameOrigine(){
    //Si iframe venant d'eseller-team, renseigner l'origine
    if (localStorage.bypass_iFrame === "true" && this.props.iframeOrigine.toString().substr(0, 4) === 'http'){
      var origineDefault = lodash.find(this.state.origines, (o) => {
        return o.Type === this.props.typeActive
          && o.Famille === "Interne"
          && o.SousFamille === "Visite showroom"
          && o.Origine === ("Intérêt " + this.props.typeActive)
      })

      if (origineDefault) {
          this.props.onChangeValue('origine_famille', origineDefault.IDFamille);
          this.props.onChangeValue('origine_sousfamille', origineDefault.IDSousFamille);
          this.props.onChangeValue('choiceOrigine', origineDefault.IDOrigineAction);
          this.props.onChangeValue('defaultOrigine', null);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultOrigine !== prevProps.defaultOrigine || this.props.type !== prevProps.type) {
      this.loadActiveFamilles();
    }

    if(this.props.typeActive !== prevProps.typeActive){
      this.setFrameOrigine();
    }
  }

  fetchCampagnes() {
    fetch(localStorage.ClientApiURL + '/Campagnes', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        campagnes: json,
      });
      this.arrayCampagne();
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchOrigines() {
    fetch(localStorage.ClientApiURL + '/LeadOrigineActions', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        if(this.props.onErrorLoadingOrigines)
          this.props.onErrorLoadingOrigines(response);
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      let origines = lodash.sortBy(json, (o) => {
        return o.Famille+" - "+o.SousFamille+" - "+o.Origine
      });
      let originesByType = lodash.groupBy(origines, (o) => {
        return o.Type;
      })

      console.log(origines)
      console.log(originesByType)

      this.setState({
        origines: origines,
        originesByType: originesByType
      }, () =>{
        this.loadActiveFamilles();
      });

    })
    .then((response) => {
      this.setFrameOrigine();
    })
    .catch((error) => {
      console.log(error)
    });
  }

  arrayCampagne(){
    if(this.props.EstAppelSortant){
      console.log(this.props.campagne);
      var c = [];
      lodash.sortBy(this.state.campagnes, 'Nom').map((campagne, i) => {
        if (campagne.Statut !== "EnCours" || !campagne.OrigineAction.Type || !campagne.OrigineAction.EstAppelSortant)
          return null;
        c.push(campagne);
      });
    }
    else{
      var c = {
        "VN" : [] ,
        "VO" : [] ,
        "Atelier" : [] ,
        "Autres" : [],
        "VDL": [],
        "Accessoires": [],
      };
      lodash.sortBy(this.state.campagnes, 'Nom').map((campagne, i) => {
        if (campagne.Statut !== "EnCours" || !campagne.OrigineAction.Type || campagne.OrigineAction.EstInactif)
          return null;
        c[campagne.OrigineAction.Type].push(campagne);
      });
    }
    this.setState({ 'tabCampagne' : c});
  }

  loadActiveFamilles() {

    var familles = [];

    var currentFamille = null;
    var currentSousFamille = null;
    var currentIdxFamille = null;
    var currentIdxSousFamille = null;
    
    if(this.props.EstAppelSortant){
      //si appelsortant le type d'affaire est généré par le type de l'origine sélectionnée
      lodash.each(this.state.origines, (origine, i) => {
        if ((!this.props.EstAppelSortant && origine.EstAppelEntrant) || (this.props.EstAppelSortant && origine.EstAppelSortant )) {
          if(!origine.Type){
            return;
          }

          if(origine.IDFamille != currentFamille)
          {
            familles.push({
              IDFamille: origine.IDFamille,
              libelleFamille: origine.Famille,
              sousFamilles: [],
            });
            currentFamille = origine.IDFamille;
            currentIdxFamille = familles.length - 1;
          }

          if(origine.IDSousFamille != currentSousFamille)
          {
            familles[currentIdxFamille].sousFamilles.push({
              IDSousFamille: origine.IDSousFamille,
              libelleSousFamille: origine.SousFamille,
              origines: []
            });
            currentSousFamille = origine.IDSousFamille;
            currentIdxSousFamille = familles[currentIdxFamille].sousFamilles.length - 1;
          }

          familles[currentIdxFamille].sousFamilles[currentIdxSousFamille].origines.push(origine);
        }
      });
    }
    else if(this.props.type in this.state.originesByType){
      //si create lead normal le type d'affaire génère filtre au niveau des origines
      lodash.each(this.state.originesByType[this.props.type], (origine, i) => {
        if ((!this.props.EstAppelSortant && origine.EstAppelEntrant) || (this.props.EstAppelSortant && origine.EstAppelSortant ) || (this.props.location === 'CallWin')) {
          
          if(origine.IDFamille != currentFamille)
          {
            familles.push({
              IDFamille: origine.IDFamille,
              libelleFamille: origine.Famille,
              sousFamilles: [],
            });
            currentFamille = origine.IDFamille;
            currentIdxFamille = familles.length - 1;
          }

          if(origine.IDSousFamille != currentSousFamille)
          {
            familles[currentIdxFamille].sousFamilles.push({
              IDSousFamille: origine.IDSousFamille,
              libelleSousFamille: origine.SousFamille,
              origines: []
            });
            currentSousFamille = origine.IDSousFamille;
            currentIdxSousFamille = familles[currentIdxFamille].sousFamilles.length - 1;
          }

          familles[currentIdxFamille].sousFamilles[currentIdxSousFamille].origines.push(origine);
        }
      });
    }

    if (this.props.defaultOrigine) {
      var defaultOrigine = lodash.find(this.state.origines, (o) => {return o.IDOrigineAction == this.props.defaultOrigine})

      if (defaultOrigine) {
        this.props.onChangeValue('type', defaultOrigine.Type);
        this.props.onChangeValue('origine_famille', defaultOrigine.IDFamille);
        this.props.onChangeValue('origine_sousfamille', defaultOrigine.IDSousFamille);
        this.props.onChangeValue('choiceOrigine', defaultOrigine.IDOrigineAction);
        this.props.onChangeValue('defaultOrigine', null);
      }
    }
    console.log(familles)
    this.setState({
      familles: familles,
    });
  }

  handleClickType(event) {
    this.props.onChangeValue('type', event.target.value);
  }

  handleClickCanal(event) {
    this.props.onChangeValue('canal', event.target.value);
  }

  handleChangeFamille(event){
    this.props.onChangeValue('origine_famille', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('origine_sousfamille', 0);
    this.props.onChangeValue('choiceOrigine', 0);
    this.props.onChangeValue('campagne', 0);
      if(this.props.EstAppelSortant)
        this.props.onChangeValue('type', '');
        
    let selectedFamille = lodash.find(this.state.familles, (f) => {return f.IDFamille == event.target.value});

    //Si une seule sous-famille on la sélectionne par défaut
    if(selectedFamille && selectedFamille.sousFamilles.length === 1)
    {
      let selectedSousFamille = selectedFamille.sousFamilles[0];
      this.props.onChangeValue('origine_sousfamille', selectedSousFamille.IDSousFamille);

      //Si une seule origine on la sélectionne par défaut
      if(selectedSousFamille.origines.length === 1) 
      {
        let selectedOrigine = selectedSousFamille.origines[0];
        this.props.onChangeValue('choiceOrigine', selectedOrigine.IDOrigineAction);
        if(this.props.EstAppelSortant){
          this.props.onChangeValue('type', selectedOrigine.Type);
        }
      }
    }

  }

  handleChangeSousFamille(event){
    this.props.onChangeValue('origine_sousfamille', event.target.value ? event.target.value : 0);
    this.props.onChangeValue('choiceOrigine', 0);
    this.props.onChangeValue('campagne', 0);
    if(this.props.EstAppelSortant)
      this.props.onChangeValue('type', '');

    let selectedFamille = lodash.find(this.state.familles, (f) => {return f.IDFamille == this.props.origine_famille});
    if(selectedFamille)
    {
      let selectedSousFamille = lodash.find(selectedFamille.sousFamilles, (sf) => {return sf.IDSousFamille == event.target.value});

      //Si une seule origine on la sélectionne par défaut
      if(selectedSousFamille && selectedSousFamille.origines.length === 1) 
      {
        let selectedOrigine = selectedSousFamille.origines[0];
        this.props.onChangeValue('choiceOrigine', selectedOrigine.IDOrigineAction);
        if(this.props.EstAppelSortant){
          this.props.onChangeValue('type', selectedOrigine.Type);
        }
      }
    }
  }

  handleChangeChoiceOrigine(event) {
    this.props.onChangeValue('campagne', 0);
    this.props.onChangeValue('choiceOrigine', event.target.value ? event.target.value : 0);

    if(this.props.EstAppelSortant){
      let selectedOrigine = lodash.find(this.state.origines, (o) => {return o.IDOrigineAction == event.target.value});
      if(selectedOrigine)
        this.props.onChangeValue('type', selectedOrigine.Type);
    }
  }

  handleChangeCampagne(event) {

    var IDCampagne = event.target.value ? event.target.value : null;
    var campagne = null;


    if (IDCampagne) {
      campagne = lodash.find(this.state.campagnes, (campagne) => {
        return parseInt(campagne.IDCampagne, 10) === parseInt(IDCampagne, 10);
      });
    }

    if(campagne != null) {
      var origine_famille = campagne.OrigineAction.IDFamille;
      var origine_sousfamille = campagne.OrigineAction.IDSousFamille;
      var choiceOrigine = campagne.OrigineAction.IDOrigineAction;
    }

    this.props.onChangeValue('origine_famille', campagne ? origine_famille : 0);
    this.props.onChangeValue('origine_sousfamille', campagne ? origine_sousfamille : 0);
    this.props.onChangeValue('campagne', IDCampagne);
    this.props.onChangeValue('choiceOrigine', campagne ? choiceOrigine : 0);
    if(this.props.EstAppelSortant && campagne != null){
      let selectedOrigine = lodash.find(this.state.origines, (o) => {return o.IDOrigineAction == event.target.value});
      if(selectedOrigine)
        this.props.onChangeValue('type', selectedOrigine.Type);
    }
  }
  
  downloadFileCampagne(event) {
    fetch(this.props.campagneFile).then(res => res.blob()).then(blob => FileSaver.saveAs(blob, "fichier_campagne_"+this.props.campagne+".pdf"))
  }

  render() {

    var rapport = this.props.leadAppelSortant;
    const selectedFamille = this.props.origine_famille ? lodash.find(this.state.familles, (f) => {return f.IDFamille == this.props.origine_famille}) : null
    const selectedSousFamille = (selectedFamille && this.props.origine_sousfamille) ? lodash.find(selectedFamille.sousFamilles, (sf) => {return sf.IDSousFamille == this.props.origine_sousfamille}) : null

    return (

      <div className="panel panel-default form-origine">

        {this.props.EstAppelSortant && this.props.leadAppelSortant ?
          <div>
            <table className='table table-eseller-lead table-striped table-hover table-condensed' style={{ marginBottom: '10px' }}>
              <thead>
                <tr>
                  <th>Site</th>
                  <th>Date</th>
                  <th>Origine</th>
                  <th>Campagne</th>
                  <th>Type contact</th>
                  <th>Relance</th>
                  <th>Action à faire</th>
                  <th className='text-center'>Commentaire</th>
                </tr>
              </thead>
              <tbody>
                <tr key={rapport.IDLead}>
                  <td>{rapport.Site.Code}</td>
                  <td>{moment(rapport.InfoCreation.DateH).format('DD/MM/Y')}</td>
                  <td>
                    {rapport.ActionFaite_DO.OrigineAction ? rapport.ActionFaite_DO.OrigineAction.Origine : null}
                  </td>
                  <td>{rapport.Campagne ? rapport.Campagne.Nom : null}</td>
                  <td>{rapport.ActionFaite_DO.TypeContact.Description}</td>
                  <td>
                    <TableRowDateRelance date={rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                  </td>
                  <td>{rapport.ActionAFaire_TODO.ActionAFaire ? rapport.ActionAFaire_TODO.ActionAFaire.Description : null}</td>
                  <td className='text-center'>
                    <TableRowComment comment={rapport.ActionFaite_DO.Commentaire} />
                  </td>
                </tr>
              </tbody>
            </table>
          
            {rapport.ActionFaite_DO.Commentaire && rapport.ActionFaite_DO.Commentaire.length > 1000 ?
              <div style={{whiteSpace: 'pre-line', marginBottom: '20px', backgroundColor: '#f3f3f3', padding: '15px', fontSize: '12px' }}>
                {rapport.ActionFaite_DO.Commentaire}
              </div>
            : null}
          </div>
        : null}

        <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
          <li className="active">
            <a data-toggle="tab" href="#form-origine">
              {this.props.EstAppelSortant && !this.props.appelSortantCreateLead?
                <h2><i className="fa fa-phone" aria-hidden="true" style={{ verticalAlign: 'top', marginRight: '5px' }}></i> Appel sortant</h2>
              :
                <h2><img src="/img/VENDEUR.svg" role='presentation'/> Nouveau lead </h2>
              }
            </a>
          </li>
        </ul>
        {this.props.EstAppelSortant && this.props.appelSortantCreateLead ?
          <FormTypeAppelSortant  onChangeValue={this.props.onChangeValue.bind(this)} typeAppelSortant={this.props.typeAppelSortant}
            selectedOrigine={lodash.find(this.state.origines, (o) => {return o.IDOrigineAction == this.props.choiceOrigine})}
          />
          : null}


          <div className={"row"}>
            {!this.props.EstAppelSortant ?
            <div className="col-md-6 col-sm-6 col-xs-12 col-affaire">
              <div className="form-group">
                <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Type d'affaire</label>
                <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                  <div className={"btn-group flex-box type-affaire"+(localStorage.IsAgri === 'true' ? " agri" : "")}>
                    {localStorage.IsVDL === 'true' ?
                      <button className={this.props.type === 'VDL'
                      ? 'btn btn-default active btn-group-xs'
                      : 'btn btn-default btn-group-xs'} type="button" value="VDL" onClick={this.handleClickType.bind(this)}>VDL</button>
                    :
                      [
                      <button key="VN" className={this.props.type === 'VN'
                        ? 'btn btn-default active btn-group-xs'
                        : 'btn btn-default btn-group-xs'} type="button" value="VN" onClick={this.handleClickType.bind(this)}>{Dictionary.getUCFirst('VN')}</button>,
                      <button key="VO" className={this.props.type === 'VO'
                          ? 'btn btn-default active btn-group-xs'
                          : 'btn btn-default btn-group-xs'} type="button" value="VO" onClick={this.handleClickType.bind(this)}>{Dictionary.getUCFirst('VO')}</button>
                      ]
                    }
                    {this.props.viewTypeAtelierAutre ?
                      <button key="typeAtelier" className={this.props.type === 'Atelier'
                        ? 'btn btn-default active btn-group-xs'
                        : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Atelier" onClick={this.handleClickType.bind(this)}>Atelier</button>
                    :null}
                    {localStorage.IsVDL === 'true' ? 
                      <button key="typeAccessoires" className={this.props.type === 'Accessoires'
                      ? 'btn btn-default active btn-group-xs'
                      : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Accessoires" onClick={this.handleClickType.bind(this)}>Accessoires</button>
                    :null}
                    {this.props.viewTypeAtelierAutre ?
                      <button key="typeAutres" className={this.props.type === 'Autres'
                        ? 'btn btn-default active btn-group-xs'
                        : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Autres" onClick={this.handleClickType.bind(this)}>Autres</button>
                    : null}
                  </div>
                </div>
              </div>
            </div>
            : null}

            <div className={"col-md-6 col-sm-6 col-xs-12 col-canal" + (this.props.EstAppelSortant ? ' hide' : '')}>
              <div className="form-group margin-canal">
                <label className="col-lg-2 col-md-2 col-sm-2 col-xs-12">Canal</label>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 flex-box">
                  <div>
                    <input type="radio" name="canal" id="telephone" value="Téléphone" checked={this.props.canal === 'Téléphone'}  onChange={this.handleClickCanal.bind(this)} />
                    <label className="label-radio radio-A" htmlFor="telephone">Téléphone</label>
                  </div>
                  <div>
                    <input type="radio" name="canal" id="email" value="Email" checked={this.props.canal === 'Email'} onChange={this.handleClickCanal.bind(this)}   />
                    <label className="label-radio radio-A" htmlFor="email">Email</label>
                  </div>
                  <div>
                    <input type="radio" name="canal" id="face" value="Face à face" checked={this.props.canal === 'Face à face'}  onChange={this.handleClickCanal.bind(this)} />
                    <label className="label-radio radio-A" htmlFor="face">Face à face</label>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div className={"row" + (this.props.EstAppelSortant && this.props.appelSortantCreateLead? ' hide' : '')}>

            <div className={"col-md-4 col-xs-12"}>
              <div className="form-group">
                <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Campagne</label>
                <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                  <select className="form-control" id="leadCampagne" name="leadCampagne" onChange={this.handleChangeCampagne.bind(this)} value={this.props.campagne} disabled={this.props.disabledEditOrigine}>
                    <option key="0" value="0"></option>
                    {!this.props.EstAppelSortant?
                        this.props.type in this.state.tabCampagne?
                        this.state.tabCampagne[this.props.type].map((campagne, i) => {
                          return <option key={i} value={campagne.IDCampagne}>{campagne.Nom}</option>;
                        }) : null
                      :
                      this.state.tabCampagne.map((campagne, i) => {
                        return <option key={i} value={campagne.IDCampagne}>{campagne.Nom}</option>;
                      })
                    }
                  </select>


                </div>
              </div>
            </div>

            {this.props.campagneFile ?
              <a className='btn btn-default btn-xs pull-right' onClick={this.downloadFileCampagne.bind(this)} style={{ marginTop: '10px', marginRight: '15px' }}>
                Voir le fichier de la campagne
              </a>
            : null}
        </div>
        <div className={"row" + (this.props.EstAppelSortant && this.props.appelSortantCreateLead? ' hide' : '')}>
            <div className="col-md-4 col-xs-12">
              <div className="form-group">
                <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Famille</label>
                <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                  <select className="form-control" id="leadFamille" name="leadFamille" onChange={this.handleChangeFamille.bind(this)} value={this.props.origine_famille} required="required" disabled={this.props.disabledEditOrigine}>
                    <option></option>
                    {this.state.familles.map((famille) => {
                      return <option key={famille.IDFamille} value={famille.IDFamille}>{famille.libelleFamille}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className="form-group">
                <label className="col-lg-4 col-md-4 col-sm-3 col-xs-12 control-label">Sous-Famille</label>
                <div className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                  <select className="form-control" id="leadSousFamille" name="leadSousFamille" onChange={this.handleChangeSousFamille.bind(this)} value={this.props.origine_sousfamille} required="required" disabled={this.props.disabledEditOrigine}>
                    <option></option>
                    {selectedFamille ? 
                      selectedFamille.sousFamilles.map((sousFamille) => {
                        return <option key={sousFamille.IDSousFamille} value={sousFamille.IDSousFamille}>{sousFamille.libelleSousFamille}</option>;
                      })
                    :null}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className="form-group">
                <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Origine</label>
                <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                  <select className="form-control" id="choiceOrigine" name="choiceOrigine" onChange={this.handleChangeChoiceOrigine.bind(this)} value={this.props.choiceOrigine} required="required" disabled={this.props.disabledEditOrigine}>
                    <option></option>
                    {selectedSousFamille ? 
                      selectedSousFamille.origines.map((origine) => {
                        return <option key={origine.IDOrigineAction} value={origine.IDOrigineAction}>{origine.Origine}</option>;
                      })
                    :null}
                  </select>
                </div>
              </div>
            </div>

          </div>

      </div>
    );
  }
}

export default FormOrigine;
