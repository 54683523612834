import React, { Component } from 'react';

import SkyLight from 'react-skylight';
import TypesProduction from './TypesProduction';

class ModalTypesProduction extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount(){

    }

    show() {
        this.refs.modalTypesProduction.show();
    }

    hide() {
        this.refs.modalTypesProduction.hide();
    }

    render() {
        return (
            <SkyLight hideOnOverlayClicked ref="modalTypesProduction"
                dialogStyles={{ padding: 0, width: '60%', maxWidth: '800px', minWidth: '300px', height: 'auto', minHeight:'500px', left: '0', right: '0', top: '10%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                title="Types de production"
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}
                >
                <TypesProduction IDProspect={this.props.IDProspect} onSubmit={this.props.onSubmit}/>
            </SkyLight>
        );
    }
}

export default ModalTypesProduction;
