import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import FormCreate from './FormCreate.js';
import FormStep2 from './FormStep2.js';
import FormStep3 from './FormStep3.js';

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')


class Campagnes extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      fetchInProgress: true,
      campagnes: [],
      filterText: '',
      filterStatut: 'EnCours',
      filterSociete: '',
      societes: [],

      campagneCreate: null,
    }
  }

  componentDidMount() {
    this.fetchCampagnes();
  }

  fetchCampagnes() {
    fetch(localStorage.ClientApiURL + '/Campagnes', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var campagnes = lodash.sortBy(json, (campagne) => {
        return campagne.Nom;
      })

      campagnes = lodash.filter(campagnes, (campagne) => {
        return campagne.Site != null;
      })

      var societes = campagnes.map((c) => {
        return c.Societe;
      })

      societes = lodash.uniqBy(societes, (s) => {
        return s.IDPlaque;
      })

      societes = lodash.sortBy(societes, (s) => {
        return s.Nom;
      })

      this.setState({
        campagnes: campagnes,
        societes: societes,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  goToCampagne(campagne) {
    console.log(campagne)
    if(campagne.Statut == "Archivee")
      browserHistory.push("/campagnes/"+campagne.IDCampagne+"/synthese")

    if (campagne.Statut != 'EnPreparation' && campagne.Statut != 'EnCours' && campagne.Statut != 'Terminee') {
      return;
    }

    browserHistory.push('/campagnes/' + campagne.IDCampagne);
  }

  onChangeFilter(event) {
    this.setState({
      filterText: event.target.value,
    })
  }
  onChangeFilterStatut(event) {
    this.setState({
      filterStatut: event.target.value,
    })
  }

  onChangeFilterSociete(event) {
    this.setState({
      filterSociete: event.target.value,
    })
  }

  displayStatut(campagne) {
    if (campagne.Statut === 'EnPreparation') {
      return 'En préparation';
    }

    if (campagne.Statut === 'Prete') {
      return 'Prête';
    }

    if (campagne.Statut === 'EnCours') {
      return 'En cours';
    }

    if (campagne.Statut === 'Terminee') {
      return 'Terminée';
    }

    if (campagne.Statut === 'Archivee') {
      return 'Archivée';
    }

    return campagne.Statut;
  }

  renderCreateCampagneStep2() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div>
                <h2>Créer sa campagne</h2>
                <FormStep2 style={{ marginTop: '30px', }} campagne={this.state.campagneCreate} goToStep3={(campagne) => {this.setState({createCampagneStep3: true, })}} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  renderCreateCampagneStep3() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div>
                <h2>Créer sa campagne</h2>
                <FormStep3 style={{ marginTop: '30px', }} campagne={this.state.campagneCreate} goToStep3={(campagne) => {this.setState({createCampagneStep3: true, })}} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  render() {

    if (this.state.createCampagneStep3) {
      return this.renderCreateCampagneStep3();
    }

    if (this.state.createCampagneStep2) {
      return this.renderCreateCampagneStep2();
    }

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div>
                <h2>Créer sa campagne</h2>

                <FormCreate style={{ marginTop: '30px', }} goToStep2={(campagne) => {this.setState({createCampagneStep2: true, campagneCreate: campagne})}} />

              </div>
            </div>

            <br />
            <hr />
            <br />

            <div>
              <div>
                <h2>Campagnes en cours</h2>
              </div>

              <div>
                {!this.state.fetchInProgress ?
                  <div className='form-group form-group-sm pull-right text-right' style={{ width: '65%', marginTop: '27px', marginBottom: '0' }}>

                      <select className='form-control' onChange={this.onChangeFilterSociete.bind(this)} value={this.state.filterSociete} style={{ width: '250px', display: 'inline-block', verticalAlign: 'middle', marginRight: '15px', lineHeight: '13px' }}>
                        <option value=''>Filter par société</option>
                        {this.state.societes.map((societe) => {
                          return <option value={societe.IDPlaque} key={societe.IDPlaque}>{societe.Nom}</option>
                        })}
                      </select>

                      <select className='form-control' onChange={this.onChangeFilterStatut.bind(this)} value={this.state.filterStatut} style={{ width: '250px', display: 'inline-block', verticalAlign: 'middle', marginRight: '15px', lineHeight: '13px' }}>
                        <option value=''>Filter par statut</option>
                        <option value="Aucun">Aucun</option>
                        <option value="EnPreparation">En préparation</option>
                        <option value="Prete">Prête</option>
                        <option value="EnCours">En cours</option>
                        <option value="Terminee">Terminée</option>
                        <option value="Archivee">Archivée</option>
                      </select>

                      <input type="search" className='form-control' value={this.state.filterText} onChange={this.onChangeFilter.bind(this)} placeholder='Filtre sur code / nom' style={{ width: '250px', border: '1px solid rgb(204, 204, 204)', marginRight: '5px', display: 'inline-block', }} />

                      <div className='clearfix'></div>

                  </div>
                : null}

                <div className='clearfix'></div>

              </div>
            </div>

            <br />

            {this.state.fetchInProgress ?
              <Loader style={{ marginTop: 150 }} />
            :
              <div>

                <table className='table table-eseller-lead table-striped'>
                  <thead>
                    <tr>
                      <th>Société</th>
                      <th>Date début campagne</th>
                      <th>Date fin campagne</th>
                      <th>Code</th>
                      <th>Nom</th>
                      <th>Total contacts</th>
                      <th>Statut</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.campagnes.map((campagne, i) => {

                      if (this.state.filterText) {
                        if ((campagne.Nom.toLowerCase().search(this.state.filterText.toLowerCase()) === -1) && (campagne.Code.toLowerCase().search(this.state.filterText.toLowerCase()) === -1)) {
                          return false;
                        }
                      }

                      if (this.state.filterStatut) {
                        if (campagne.Statut !== this.state.filterStatut) {
                          return false;
                        }
                      }

                      if (this.state.filterSociete) {
                        if (parseInt(campagne.Societe.IDPlaque, 10) !== parseInt(this.state.filterSociete, 10)) {
                          return false;
                        }
                      }

                      return (<tr key={i} onClick={this.goToCampagne.bind(this, campagne)}>
                        <td>{campagne.Societe.Nom} {campagne.Site ? ' - ' + campagne.Site.Nom : null}</td>
                        <td>{campagne.DateDebutCampagne ? moment(campagne.DateDebutCampagne).format('DD/MM/YYYY') : null}</td>
                        <td>{campagne.DateFinCampagne ? moment(campagne.DateFinCampagne).format('DD/MM/YYYY') : null}</td>
                        <td>{campagne.Code}</td>
                        <td>{campagne.Nom}</td>
                        <td>{campagne.CountClients}</td>
                        <td>{this.displayStatut(campagne)}</td>
                        <td></td>
                      </tr>)
                    })}

                  </tbody>
                </table>
              </div>
            }

          </div>
        </div>
      </div>
    );
  }
}

export default Campagnes;
