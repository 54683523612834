import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowSite from '../Components/TableRowSite';
import TableRowComment from '../Components/TableRowComment';
import {isMobile} from 'react-device-detect';

import moment from 'moment'

class AffairesGagnees extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  getTypeDemande(affaire) {
    if (!affaire.Description)
      return 'Autres';

    var matchs = affaire.Description.match(/Type de demande : (.*)(\n|\r)/);

    if (!matchs)
      return 'Autres';

    return matchs[1];
  }

  render() {
    return (
      <div className="">
        <table className="table table-eseller-lead table-striped table-hover table-condensed">
          <thead>
            <tr>
              <th>Suivi</th>
              <th style={{ width: '150px', }}>Site</th>

              <th>Date du lead</th>
              <th>Type de demande</th>
              <th>Contact</th>
              <th>Date de clôture</th>
              <th className='text-center'>Commentaire</th>
            </tr>
          </thead>
          <tbody>
            {this.props.leads.map((lead, i) =>
              <tr key={i} onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} className={isMobile ? "tr-touch":"tr-link"}>
                <td className='text-center'>
                  <PastilleVendeur utilisateur={lead.SuiviPar} />
                </td>
                <td style={{ overflow: 'hidden' }}>
                  <TableRowSite Site={lead.Site} />
                </td>
                <td>{moment(lead.DateDebut).format('L')}</td>
                <td>{this.getTypeDemande(lead)}</td>
                <td>
                  {lead.Prospect.Nom} {lead.Prospect.Prenom}
                </td>
                <td>{moment(lead.DateHFin).format('L')}</td>
                <td className='text-center'><TableRowComment comment={lead.CommentaireDernierRapport} /></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AffairesGagnees;
