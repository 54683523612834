import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowSite from '../Components/TableRowSite';

import moment from 'moment'
import Dictionary from '../Dictionnary';

class AffairesClotures extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  getOffresNumber(offres) {
    var _offres = offres.map((offre) => {
      return offre.IDOffre;
    });
    return _offres.join(', ');
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  render() {
    return (
      <div className="">
        <table className="table table-eseller-lead table-striped table-hover table-condensed">
          <thead>
            <tr>
              <th>Suivi</th>
              <th style={{ width: '150px', }}>Site</th>
              <th>Date du lead</th>
              <th>Numéro(s) d'offre</th>
              <th style={{ width: '200px', }}>Campagne / Origine</th>
              <th>Contact</th>
              {localStorage.IsAgri === 'true' ? <th>Type</th> : null}
              <th>Date du rejet</th>
              <th>Etape</th>
              <th>Motif</th>
            </tr>
          </thead>
          <tbody>
            {this.props.leads.map((lead, i) =>
              <tr key={i} onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} className="tr-link">
                <td className='text-center'>
                  <PastilleVendeur utilisateur={lead.DernierRapportUtilisateur ? lead.DernierRapportUtilisateur : lead.SuiviPar} />
                </td>
                <td style={{ overflow: 'hidden' }}>
                  <TableRowSite Site={lead.Site} />
                </td>
                <td>{moment(lead.DateDebut).format('L')}</td>
                <td>
                  {lead.Offres ?
                    this.getOffresNumber(lead.Offres)
                  : null}
                </td>
                <td>
                  <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.OrigineAction ? lead.OrigineAction : null} />
                </td>
                <td>
                  {lead.Prospect.Nom} {lead.Prospect.Prenom}
                </td>
                {localStorage.IsAgri === 'true' ?
                  <td>
                    {Dictionary.getUCFirst(lead.Type)}
                  </td>
                :null}
                <td>{moment(lead.DateHFin).format('L')}</td>
                <td>{lead.EtapeDernierRapport ? lead.EtapeDernierRapport.Description : null}</td>
                <td>{lead.RaisonStop}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AffairesClotures;
