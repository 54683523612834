import React, { Component } from 'react';
import ModalDPOContact from './ModalDPOContact';

import Globals from '../Globals';

class ButtonRGPD extends Component {

  constructor(props) {
    super(props);

    this.state = {
      iframeUrl: '',
      status: '',
    }
  }

  componentDidMount(){
    this.fetchDPOContactIframeUrl();
  }

  componentDidUpdate(prevProps){
    if(prevProps.IDSite !== this.props.IDSite)
      this.fetchDPOContactIframeUrl();
  }

  fetchDPOContactIframeUrl() {
    var IDSociete = this.props.IDSociete;
    if(!IDSociete)
      IDSociete = -1;

    var IDSite = this.props.IDSite;
    if(!IDSite)
      IDSite = -1;

    fetch(localStorage.ClientApiURL +'/Prospects/'+ this.props.IDProspect + '/dpoInfos/'+ IDSociete + '/' + IDSite, {
      method:'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        iframeUrl: json.iframeUrl,
        status: json.status
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  reload(){
    if(this.state.status != "") //On ne recharge que si le status était connu (on est sur une soc précise) sinon ça ne sert à rien on aura le même résultat
      this.fetchDPOContactIframeUrl();
  }

  openDPOContactIframe() {
    this.refs.modalDPOContact.show();
  }

  getButtonColor(){
    switch(this.state.status){
      case 'ok':
      case 'warning':
      case 'ko':
      case 'unknown':
        return "dpo-"+this.state.status;
      default :
        return "";
    }
  }

  render() {
    if(this.state.iframeUrl === '')
      return null;

    return (
      <span style={{display:'inline-block'}}>
        <button type='button' className={"btn btn-xs btn-default padding-xs "+this.getButtonColor()} onClick={() => this.openDPOContactIframe()} style={this.props.style}>RGPD</button>
        <ModalDPOContact dpoContactIframeUrl={this.state.iframeUrl} onClose={this.reload.bind(this)} ref="modalDPOContact"/>
      </span>
    );
  }
}

export default ButtonRGPD;
