import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import OverlayAffaire from './OverlayAffaire';
import OverlayVehicule from './OverlayVehicule';
import TableRowSite from '../../Components/TableRowSite';

class List extends Component {
  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {

    }
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  renderOrderIcon(column) {
    if (column == this.props.order_column) {
      if (this.props.order_type == 'desc') {
        return <i className="fa fa-sort-desc" style={{ marginLeft: '4px' }}></i>;
      }
      else {
        return <i className="fa fa-sort-asc" style={{ marginLeft: '4px' }}></i>;
      }
    }

    return null;
  }

  render() {

    var content = [];

    this.props.prospects.map((prospect, i) => {
      content.push(<tr key={i} onClick={this.handleclickAffaire.bind(this, prospect.IDProspect)} className="tr-link">
          <td>{prospect.IDProspect}</td>
          <td>
            {prospect.SiteAffectation ?
              <TableRowSite Site={prospect.SiteAffectation} displayVille={true} />
            : null}
          </td>
          <td>{prospect.Nom}</td>
          <td>{prospect.Prenom}</td>
          <td>{prospect.Adresse ? prospect.Adresse : prospect.AdresseComplement}</td>
          <td>{prospect.CodePostal}</td>
          <td>{prospect.Ville}</td>
          <td>{prospect.Telephones && prospect.Telephones.length ? prospect.Telephones[0].Numero : null}</td>
          <td>{prospect.DateHDerniereAction ? moment(prospect.DateHDerniereAction).format('DD/MM/YYYY') : ''}</td>
          <td className="text-center">
            {prospect.NombreAffaireActive ?
              <div>
                <span data-for={'OverlayAffaire' + prospect.IDProspect} data-tip='tooltip'>({prospect.NombreAffaireActive})</span>
                <ReactTooltip id={'OverlayAffaire' + prospect.IDProspect} getContent={() => {return <OverlayAffaire prospect={prospect} />}} place='bottom' html={false} />
              </div>
            : '-'}
          </td>
          <td className="text-center">
            {prospect.Type === 'Suspect' ?
              <img src="/img/TABLER ETAT.svg" role='presentation'/>
            : null}
          </td>
          <td className="text-center">
            {prospect.Type === 'Prospect' ?
              <img src="/img/TABLER ETAT.svg" role='presentation'/>
             : null}
          </td>
          <td className="text-center">
            {prospect.Type === 'Client' ?
              <img src="/img/TABLER ETAT.svg" role='presentation'/>
             : null}
          </td>
          <td>
            {prospect.LiensVehicules && prospect.LiensVehicules.length ?
              <div>
                <span data-for={'OverlayVehicule' + prospect.IDProspect} data-tip='tooltip'><img src={localStorage.IsAgri === 'true' ? "/img/TRACTEUR.svg" : "/img/VOITURE.svg"} role='presentation'/></span>
                <ReactTooltip id={'OverlayVehicule' + prospect.IDProspect} getContent={() => {return <OverlayVehicule prospect={prospect} />}} place='left' html={false} />
              </div>
            : '-'}
          </td>
        </tr>)

        return null;
      }
    )



    return (
      <div className="container-fluid">
        <div className="row">

            <table className="table table-eseller-lead table-striped table-hover table-condensed">
              <thead>
                <tr>
                  <th>Ref. CRM</th>
                  <th>Point de vente</th>
                  <th className='th-sortable' onClick={()=>{
                    this.props.onChangeOrder('Name', this.props.order_type === 'asc' ? 'desc' : 'asc');
                  }}>
                    Nom
                    {this.renderOrderIcon('Name')}
                  </th>
                  <th className='th-sortable' onClick={()=>{
                    this.props.onChangeOrder('FirstName', this.props.order_type === 'asc' ? 'desc' : 'asc');
                  }}>
                    Prenom
                    {this.renderOrderIcon('FirstName')}
                  </th>
                  <th className='th-sortable' onClick={()=>{
                    this.props.onChangeOrder('Address1', this.props.order_type === 'asc' ? 'desc' : 'asc');
                  }}>
                    Adresse
                    {this.renderOrderIcon('Address1')}
                  </th>
                  <th className='th-sortable' onClick={()=>{
                    this.props.onChangeOrder('Zip', this.props.order_type === 'asc' ? 'desc' : 'asc');
                  }}>
                    CP
                    {this.renderOrderIcon('Zip')}
                  </th>
                  <th className='th-sortable' onClick={()=>{
                    this.props.onChangeOrder('City', this.props.order_type === 'asc' ? 'desc' : 'asc');
                  }}>
                    Ville
                    {this.renderOrderIcon('City')}
                  </th>
                  <th>Téléphone</th>
                  <th>Visite</th>
                  <th className="text-center">Lead</th>
                  <th className="text-center">Suspect</th>
                  <th className="text-center">Prospect</th>
                  <th className="text-center">Client</th>
                  <th>Parc</th>
                </tr>
              </thead>
             <tbody>
               {content}
              </tbody>
            </table>

        </div>
      </div>
    );
  }
}

export default List;
