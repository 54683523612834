import Globals from '../Globals';

/**
 * Info
 * Permet de récupérer les infos de groupe et de l'utilisateur
 */
const LibInfo = {

  /**
   * @return Info (https://api.e-seller.selectup.com/Help/Api/GET-api-Info)
   */
  async get() {
    try {
      const response = await fetch(localStorage.ClientApiURL+'/info', {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return null;
      }
    }
    catch(error){
      console.log(error);
      return null;
    }
  },
}

export default LibInfo;