import React, { Component } from 'react';

import Tooltip from "rc-tooltip";

class TableRowComment extends Component {
  render() {

    if (!this.props.comment)
      return null;

    return (<Tooltip trigger={['hover']} placement='left' overlay={<div style={{ whiteSpace: 'pre' }}>{this.props.comment.replace(/(?:\\[rn])+/g, '\n')}</div>}>
        <img src="/img/TABLERC.svg" role='presentation' />
      </Tooltip>)
  }
}

export default TableRowComment;
