import React, {Component} from 'react';
import Globals from '../Globals';

import moment from 'moment'

import InputTime from '../Components/InputTime';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
var Datetime = require('react-datetime');

class FormToDoRapport extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      etapes: [],
      interets: [],
    }
  }

  componentWillMount() {
    this.fetchEtapes();
    this.fetchInterets();

  }

  fetchEtapes() {
    this.setState({
      etapes: [
        {
            "IDEtape": 1,
            "Description": "A qualifier"
        },
        {
            "IDEtape": 2,
            "Description": "Découverte"
        },
        {
            "IDEtape": 3,
            "Description": "Essai"
        },
        {
            "IDEtape": 4,
            "Description": "Offre"
        },
        {
            "IDEtape": 5,
            "Description": "Négociation"
        },
        {
            "IDEtape": 6,
            "Description": "Commande"
        }
      ]
    });
  }

  fetchInterets() {
    fetch(localStorage.ClientApiURL + '/NiveauInterets', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        interets: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  getInteretDescription() {
    let choiceInteret;

    this.state.interets.map((interet, i) => {
      if (interet.IDNiveauInteret === this.props.todoNiveauInteret) {
        choiceInteret = interet;
      }
      return interet
    });

    if (choiceInteret)
      return choiceInteret.Description;

    return '';
  }

  getInteretNiveau() {
    let choiceInteret;

    this.state.interets.map((interet, i) => {
      if (interet.IDNiveauInteret === this.props.todoNiveauInteret) {
        choiceInteret = interet;
      }
      return interet
    });

    if (choiceInteret)
      return choiceInteret.Niveau;

    return 4;
  }

  changeRelanceMonth(event) {
    this.props.onChangeValue('relanceMonth', event.target.value);
  } 

  changeNiveauInteret(value) {
    var interet = this.state.interets[value];

    this.props.onChangeValue('todoNiveauInteret', this.state.interets[value].IDNiveauInteret);
    this.props.onChangeValue('todoDate', moment().add(interet.NbJour, 'days'));
  }

  changeDate(event) {
    this.props.onChangeValue('todoDate', event.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
  }

  isValidDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  isValidHeure(current) {
    return true;
  }

  changeHeureRelance(event) {
    this.props.onChangeValue('todoHeure', event);
    this.props.onChangeValue('todoHeureFin', moment(event).add(1, 'hours'));
  }

  changeHeureRelanceFin(event) {
    this.props.onChangeValue('todoHeureFin', event);
  }

  changeEtape(event) {
    this.props.onChangeValue('todoEtape', event.target.value);
    this.props.onChangeValue('todoEtapeText', event.target.options[event.target.selectedIndex].text);
  }

  changeActionType(event) {
    this.props.onChangeValue('action', event.target.value);
    if(event.target.value === 'cloturer' && localStorage.ReportingTransformationCyclevente !== "true") {
        this.changeTypeCloture("AffairePerdue")
    }
  }

  changeComment(event) {
    this.props.onChangeValue('todoComment', event.target.value);
  }

  changeRaison(event) {
    this.props.onChangeValue('todoRaison', event.target.value);
  }

  changeAction(event) {
    this.props.onChangeValue('todoAction', event.target.value);
  }

  changeTypeCloture(value) {
    this.props.onChangeValue('typeCloture', value)
  }

  render() {

    return (
      <div>
        <h3>{this.props.displayImageTitle ? <img src="/img/PROCHAINE ACTION Copy 2.svg" role='presentation' /> : null} Prochaine Action</h3>

        <div className='row'>
          <div className="col-md-10 col-md-offset-1">

            {this.props.displayNextActionType ?
              <div className="form-group btn-group">
                <div className=" col-md-3 text-left">
                  <button className={this.props.nextActionType === 'continuer'
                    ? 'btn btn-default active'
                    : 'btn btn-default'} type="button" value="continuer" onClick={this.changeActionType.bind(this)} style={{ width: '85px', }}>Continuer</button>
                </div>
                <div className=" col-md-3 text-center">
                  <button className={this.props.nextActionType  === 'cloturer'
                    ? 'btn btn-default active'
                    : 'btn btn-default'} type="button" value="cloturer" onClick={this.changeActionType.bind(this)} style={{ width: '85px', margin: 'auto' }}>Clôturer</button>
                </div>
                <div className=" col-md-3 text-right">
                  <button className={this.props.nextActionType  === 'reporter'
                    ? 'btn btn-default active'
                    : 'btn btn-default'} type="button" value="reporter" onClick={this.changeActionType.bind(this)} style={{ width: '85px', margin: 'auto' }}>Reporter</button>
                </div>
              </div>
            : null}

            {this.props.nextActionType === 'cloturer' ?
              <>
                <div className="form-group">
                  <div className="col-md-12" style={{marginTop:'5px'}}>
                    {localStorage.ReportingTransformationCyclevente === "true" ? 
                      <>
                        <input type="radio" name='resultat_cloture' id="resultat_cloture_gagne" checked={this.props.typeCloture === 'AffaireGagnee'}  onChange={this.changeTypeCloture.bind(this, "AffaireGagnee")} required/>
                        <label className="label-radio radio-A" htmlFor="resultat_cloture_gagne">
                          Affaire gagnée
                        </label>
                        <br/>
                      </>
                    :null}
                    <input type="radio" name='resultat_cloture' id="resultat_cloture_perdu" checked={this.props.typeCloture === 'AffairePerdue'}  onChange={this.changeTypeCloture.bind(this, "AffairePerdue")} required/>
                    <label className="label-radio radio-A" htmlFor="resultat_cloture_perdu">
                      Affaire perdue
                    </label>
                  </div>
                </div>
                {this.props.typeCloture === "AffairePerdue" ? 
                  <div className="form-group">
                    <label className="col-md-4">Raison</label>
                    <div className=" col-md-8">
                      <select className="form-control" id="raison" name="raison" onChange={this.changeRaison.bind(this)} value={this.props.todoRaison} required="required">
                        <option></option>
                        <option value='Achat extérieur'>Achat extérieur</option>
                        <option value='Disponibilité'>Disponibilité</option>
                        <option value='Prix'>Prix</option>
                        <option value='Financement'>Financement</option>
                        <option value='Reprise'>Reprise</option>
                        <option value='Marchand'>Marchand</option>
                        <option value="Annulation de l'achat">Annulation de l'achat</option>
                        <option value="Pas venu">Pas venu</option>
                        <option value="Injoignabe">Injoignabe</option>
                        <option value='Autre'>Autre</option>
                      </select>
                    </div>
                  </div>
                :null}
              </>
            : null}

            {this.props.nextActionType === 'continuer' ?
              <div className="form-group">
                <label className="col-md-4">Étape</label>
                <div className=" col-md-8">
                  <select className="form-control" id="etapes" name="etapes" onChange={this.changeEtape.bind(this)} value={this.props.todoEtape} required="required">
                    <option></option>
                    {this.state.etapes.map((etape, i) => {

                      if (etape.Description === 'Rejet' || etape.Description === 'Abandon')
                        return null;

                      return <option key={i} value={etape.IDEtape}>{etape.Description}</option>
                    })}
                  </select>
                </div>
              </div>
            : null}

            {this.props.nextActionType === 'continuer' && !this.props.todoTransfertToVendeur && !this.props.transfertToPotCommunVendeurs ?
              <div className="form-group">
                <label className="col-md-4">Action</label>
                <div className=" col-md-8">
                  <select className="form-control" id="action" name="action" onChange={this.changeAction.bind(this)} value={this.props.todoAction} required="required">
                    <option value=''></option>
                    <option value='Email'>Email</option>
                    <option value='Téléphoner'>Téléphoner</option>
                    <option value='SMS'>SMS</option>
                  </select>
                </div>
              </div>
            : null}

            {this.props.nextActionType === 'continuer' ?
              <div className="form-group">
                <label className="col-md-4">INTERET</label>
                <div className="col-md-8">
                  <Slider min={0} max={5} defaultValue={this.getInteretNiveau()} onChange={this.changeNiveauInteret.bind(this)} />
                </div>
                {this.state.interets ?
                  <div className="col-md-8 col-md-offset-4">
                    <span className="help-block" style={{ fontSize: '12px', textAlign: 'left' }}>
                      {this.getInteretNiveau()} : {this.getInteretDescription()}
                    </span>
                  </div>
                : null }
                <div className='clearfix'></div>
              </div>
            : null}

            {this.props.nextActionType === 'continuer' ?
              <div className="form-group datepicker-center">
                <div className="row">
                  <label className="col-md-4">Le</label>
                  <div className="col-md-6">
                    <Datetime className="datetime" closeOnSelect={true} timeFormat={false} locale="fr" isValidDate={this.isValidDate.bind(this)} value={this.props.todoDate} onChange={this.changeDate.bind(this)} />
                  </div>
                  <div className="col-md-1">
                    <i className="icon-calendar icons"></i>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-4">de</label>
                  <div className="col-md-6">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeure} onChange={this.changeHeureRelance.bind(this)} />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <i className="icon-clock icons"></i>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-4">à</label>
                  <div className="col-md-6">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeureFin} onChange={this.changeHeureRelanceFin.bind(this)} />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <i className="icon-clock icons"></i>
                  </div>
                </div>
              </div>
            : null}

            {this.props.nextActionType === 'reporter' ?
              <div className="form-group">
                <label className="col-md-5">A recontacter dans :</label>
                <div className=" col-md-7">
                  <select className="form-control" id="relanceMonth" name="relanceMonth" onChange={this.changeRelanceMonth.bind(this)} value={this.props.relanceMonth ? this.props.relance : ""} required="required">
                    <option value=''></option>
                    <option value='1'>1 mois</option>
                    <option value='2'>2 mois</option>
                    <option value='3'>3 mois</option>
                    <option value='6'>6 mois</option>
                    <option value='9'>9 mois</option>
                  </select>
                </div>
              </div>
            : null}

            {!(this.props.nextActionType === 'cloturer' && this.props.typeCloture !== 'AffairePerdue') ? 
              <div className="form-group" style={{ marginLeft: '0px', marginRight: '0px', }}>
                <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.props.todoComment} onChange={this.changeComment.bind(this)} />
              </div>
            :null}

          </div>
        </div>

      </div>
    );
  }
}

export default FormToDoRapport;
