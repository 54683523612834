import React, {Component} from 'react';

import moment from 'moment'
import InputTime from '../Components/InputTime';
var Datetime = require('react-datetime');

class FormRelance extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {

    }
  }

  componentWillMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  changeRelanceDate(event) {
    this.props.onChangeValue('relanceDate', event.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
  }

  isValidRelanceDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  changeRelance(event) {
    this.props.onChangeValue('relance', event.target.checked);
  }

  isValidHeure(current) {
    return true;
  }

  changeRelanceHeure(event) {
    this.props.onChangeValue('relanceHeure', event);
  }

  changeComment(event) {
    this.props.onChangeValue('relanceCommentaire', event.target.value);
  }

  changeAction(event) {
    this.props.onChangeValue('relanceAction', event.target.value);
  }

  render() {

    return (
      <div>

        {this.state.showTitle ?
          <h3>{this.props.displayImageTitle ? <img src="/img/PROCHAINE ACTION Copy 3.svg" role='presentation' /> : null} Relance</h3>
        : null}

        <div className='row'>
          <div className="col-md-10 col-md-offset-1">

            <div className="form-group" style={{ marginBottom: '0px' }}>
              <div className="col-md-12" style={{ textAlign: 'left' }}>
                <input type='checkbox' id='relance' onChange={this.changeRelance.bind(this)} />
                <label className="label-box" htmlFor="relance">Me créer une alerte</label>
              </div>
            </div>

            {this.props.relance ?
              <div className="form-group">
                <label className="col-md-4">Action</label>
                <div className=" col-md-8">
                  <select className="form-control" id="action" name="action" onChange={this.changeAction.bind(this)} value={this.props.relanceAction} required="required">
                    <option value=''></option>
                    <option value='Email'>Email</option>
                    <option value='Téléphoner'>Téléphoner</option>
                    <option value='SMS'>SMS</option>
                  </select>
                </div>
              </div>
            : null}

            {this.props.relance ?
              <div className="form-group" style={{ height: '90px' }}>
                <div className="col-md-12">
                  <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.props.relanceCommentaire} onChange={this.changeComment.bind(this)} />
                </div>
              </div>
            : null}

            {this.props.relance ?
              <div className="form-group datepicker-center">
                <label className="col-md-2">Le</label>
                <div className="col-md-8">
                  <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false}  isValidDate={this.isValidRelanceDate.bind(this)} defaultValue={this.props.relanceDate} open={false} onChange={this.changeRelanceDate.bind(this)} />
                </div>
                <div className="col-md-2">
                  <i className="icon-calendar icons" style={{ paddingTop: '4px', display: 'block' }}></i>
                </div>
              </div>
            : null}

            {this.props.relance ?
              <div className="form-group datepicker-center">
                <label className="col-md-2">à</label>
                <div className="col-md-8">
                  <InputTime value={this.props.relanceHeure} onChange={this.changeRelanceHeure.bind(this)} SelectStyle={{paddingLeft: '3px'}} />
                </div>
                <div className="col-md-2">
                  <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                </div>
              </div>
            : null}

          </div>
        </div>
      </div>
    );
  }
}

export default FormRelance;
