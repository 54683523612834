import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import FormStep2 from './FormStep2.js';
import FormStep3 from './FormStep3.js';

import ResumeCampagne from './ResumeCampagne';
import FormCible from './FormCible.js'
import FormAffectation from './FormAffectation.js'

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')


class CampgneFormProcess extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      fetchInProgress: true,
      campagne: null,
      campagneToEdit: null,
    }
  }

  componentDidMount() {
    this.fetchCampagne();
  }

  fetchCampagne() {
    fetch(localStorage.ClientApiURL + '/Campagnes/' + this.props.params.IDCampagne, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        campagne: json,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  displayStatut(campagne) {
    if (campagne.Statut === 'EnPreparation') {
      return 'En préparation';
    }

    if (campagne.Statut === 'Prete') {
      return 'Prête';
    }

    if (campagne.Statut === 'EnCours') {
      return 'En cours';
    }

    if (campagne.Statut === 'Terminee') {
      return 'Terminée';
    }

    if (campagne.Statut === 'Archivee') {
      return 'Archivée';
    }

    return campagne.Statut;
  }

  render() {

    if (!this.state.campagne) {
      return <Loader style={{ marginTop: 150 }} />;
    }

    if (this.state.campagne.Statut == 'EnCours' || this.state.campagne.Statut == 'Terminee') {
      return this.renderCampagneEnCours();
    }

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div>
                <h2>Campagne</h2>

                <br />

                <ResumeCampagne campagne={this.state.campagneToEdit ? this.state.campagneToEdit : this.state.campagne} />

                <FormCible style={{ marginTop: '30px', }} campagne={this.state.campagne} goToStep3={(campagne) => {this.setState({createCampagneStep3: true, })}} />

                {this.state.campagne.CountClients > 0 ?
                  <FormAffectation style={{ marginTop: '30px', }} campagne={this.state.campagne} onSubmit={() => {return;}} />
                : null}

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  onChangeFilterStatut(ev) {

    var campagneToEdit = lodash.clone(this.state.campagne);
    campagneToEdit.Statut = ev.target.value;

    this.setState({
      campagneToEdit: campagneToEdit
    })

  }

  updateCampagne() {
    this.setState({
      updateInProgress: true,
    })

    fetch(localStorage.ClientApiURL + '/Campagnes/' + this.state.campagne.IDCampagne, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify(this.state.campagneToEdit),
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }

      this.setState({
        updateInProgress: false,
        campagneToEdit: null,
      });
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  renderCampagneEnCours() {

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>

            <div>
              <div>
                <h2>Campagne</h2>
                
                <br />

                <ResumeCampagne campagne={this.state.campagneToEdit ? this.state.campagneToEdit : this.state.campagne} editable={true} onChangeFilterStatut={this.onChangeFilterStatut.bind(this)} />

                <br />

                {this.state.campagneToEdit ?
                  <div className='col-md-12 text-center'>
                    <button className='btn btn-default' disabled={this.state.updateInProgress} onClick={this.updateCampagne.bind(this)}>
                      {this.state.updateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Enregistrer
                    </button>
                  </div>
                : null}
              </div>
            </div>

          </div>
        </div>
      </div>
    );

  }
}

export default CampgneFormProcess;
