import React, { Component } from 'react';

import Globals from '../../Globals';

import { Link } from 'react-router';
import moment from 'moment';
import Loader from '../Loader';

var lodash = require('lodash')


class AffectationVendeur extends Component {

  constructor(props) {
    super(props);
    moment.locale('fr');

    this.state = {
      vendeurs: [],
      IDPeople: 0,
      fetchCreateInProgress: false,
    }
  }

  componentDidMount() {
    this.fetchVendeurs();
  }

  fetchVendeurs() {
    if(!this.props.prospect.SiteAffectation){
      this.setState({
        vendeurs:[]
      })
      return;
    }

    fetch(localStorage.ClientApiURL + '/Utilisateurs?LoadPlaqueMarques=false&LoadDetailDroitProfil=false&EstActif=true&TypeProfilLead=2&LoadSites=false&IDSite='+this.props.prospect.SiteAffectation.IDSite, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var vendeurs = lodash.orderBy(json, 'Nom');

      this.setState({
        vendeurs: vendeurs,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      fetchCreateInProgress: true,
    });

    fetch(localStorage.ClientApiURL + '/prospects/'+this.props.prospect.IDProspect+'/affectation', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify({IDPeople: this.state.IDPeople}),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      this.props.finishCreateVehicule();

      return;
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchCreateInProgress: false })
    });


  }

  render() {

    if (!this.state.vendeurs.length) {
      return <div className='container-fluid'><Loader style={{ marginTop: 50 }} /></div>
    }

      return(
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12' style={{ marginBottom: '20px' }}>
              <h2>Affectation à un vendeur</h2>

              <form className="form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
                <div className="form-group">
                  <label className="col-md-4">Vendeur</label>
                  <div className=" col-md-8">
                    <select className="form-control" id="IDPeople" name="IDPeople" onChange={(ev) => this.setState({IDPeople: ev.target.value})} value={this.state.IDPeople} required="required">
                      <option key='0' value='0'></option>
                      {this.state.vendeurs.map((vendeur) => {
                        return (<option key={vendeur.IDUtilisateur} value={vendeur.IDUtilisateur}>{vendeur.Nom}</option>)
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="center-div bigger-bottom">
                    <button type="submit" className="text-center btn btn-default submit-vn" disabled={this.state.fetchCreateInProgress}>
                      {this.state.fetchCreateInProgress ?
                        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                      : null}
                      Valider
                     </button>
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      );
  }

}

export default AffectationVendeur;
