import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowSite from '../Components/TableRowSite';

import moment from 'moment'
import Dictionary from '../Dictionnary';

var lodash = require('lodash')

class AffairesGagnees extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  getCommandeFromAffaire(affaire) {
    var offres = lodash.filter(affaire.Offres, (offre) => {
      return (offre.Statut === 'Commande' || offre.Statut === 'CommandeValidee' || offre.Statut === 'Facture');
    });
    return lodash.head(offres);
  }

  render() {
    return (
      <div className="">
        <table className="table table-eseller-lead table-striped table-hover table-condensed">
          <thead>
            <tr>
              <th>Com.</th>
              <th>Site</th>
              <th>Date de création</th>
              <th>Numéro d'offre</th>
              <th>Date de l'offre</th>
              <th style={{ width: '200px', }}>Campagne / Origine</th>
              <th>Client</th>
              {localStorage.IsAgri === 'true' ? <th>Type</th> : null}
              <th>{Dictionary.getUCFirst("véhicule")}</th>
              <th>Reprise</th>
            </tr>
          </thead>
          <tbody>
            {this.props.leads.map((lead, i) => {
              const commande = this.getCommandeFromAffaire(lead)
              return (<tr key={i} onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} className="tr-link">
                  <td>{commande ? <PastilleVendeur utilisateur={commande.SuiviPar} /> : null}</td>
                  <td>
                    <TableRowSite Site={lead.Site} />
                  </td>
                  <td>{moment(lead.DateDebut).format('L')}</td>
                  <td>{commande ? commande.NumOffre : null}</td>
                  <td>{commande ? moment(commande.Date).format('L') : null}</td>
                  <td>
                    <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.OrigineAction ? lead.OrigineAction : null} />
                  </td>
                  <td>
                    {lead.Prospect.Nom} {lead.Prospect.Prenom}
                  </td>
                  {localStorage.IsAgri === 'true' ?
                    <td>
                      {Dictionary.getUCFirst(lead.Type)}
                    </td>
                  :null}
                  <td>
                    {commande ?
                      commande.Vehicules.map((vehicule, i) => {
                        return <div key={i}>{vehicule.Designation}</div>;
                      })
                    : null}
                  </td>
                  <td>
                    {commande ? (commande.Reprises.length ? 'Oui' : 'Non' ): null}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AffairesGagnees;
