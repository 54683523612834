import React, { Component } from 'react';

import NewsCard from './Card';
import NewsView from './View';
import SkyLight from 'react-skylight';
import Globals from '../../Globals';
var lodash = require('lodash');

class NewsModal extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            entering: false,
            fetching: false,
            news:[],
            displayedNews:null,
        };
    }

    checkNewsIsRead(newsID){
        var newsReadIds = localStorage.newsReadIds||[];
        return (newsReadIds.indexOf(newsID) !== -1);
    }

    displayList(){
        this.setState({
            entering:false,
        }, () => setTimeout(() => this.setState({displayedNews:null,}), 300));
    }

    displayNews(newsID){
        var displayedNews = lodash.find(this.state.news, ['iIDNews', newsID]);
        if(displayedNews){
            this.setState({
                entering:true,
            }, () => setTimeout(() => this.setState({displayedNews:displayedNews,}), 300));
            this.setNewsRead(newsID);
        }
    }

    fetchNewsAndShow(){
        this.show();
        this.setState({
            fetching: true,
        });
        fetch(localStorage.ClientApiURL + '/news', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var resultNews = json['soap:Envelope']['soap:Body']['GetNewsResponse']['GetNewsResult'];
            if(resultNews && resultNews['UtilisateursNews'])
                var news = resultNews['UtilisateursNews'];
            else 
                var news = [];
            if (news.length === undefined) {
                news = [news];
            }
            this.setState({
                news: news,
                fetching: false,
            });
        })
        .catch((error) => {
            console.log(error);
        });
    }

    setNewsRead(newsID){
        var newsReadIds = [];
        var newsReadIds_ = localStorage.newsReadIds;
        if(newsReadIds_)
            newsReadIds = JSON.parse(newsReadIds_);
        if(newsReadIds.indexOf(newsID) === -1){
            newsReadIds.push(newsID);
            localStorage.newsReadIds = JSON.stringify(newsReadIds);
        }
    }

    setNews(news){
        this.setState({
            news:news,
        });
    }

    show() {
        this.refs.newsModal.show();
    }

    render() {
        var title;
        if(this.state.displayedNews){
            title = <div className="modal-custom-title link" onClick={() =>this.displayList() }>
                <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;{this.state.displayedNews.sTitre}
            </div>;
        }
        else {
            title = <div className="modal-custom-title">News en cours {this.state.news.length > 0 ? " ("+this.state.news.length+")" : ""}</div>;
        }
        return (
            <SkyLight hideOnOverlayClicked ref="newsModal"
                dialogStyles={{ padding:0, width: '50%', left: '25%', marginLeft: '0%', height: '80%', top: '10%', marginTop: '0%', overflow: 'auto', zIndex: 9999, background:"#efefef"}}
                title={title}
                titleStyle={{ padding:10, margin:0, color:'white'}}
                closeButtonStyle={{color:'white'}}>
                <div className="inner-large-content" id="newsModal">
                {
                    !this.state.fetching 
                    ?
                        this.state.displayedNews 
                        ?
                            <div className={"news-list "+(this.state.entering?"fade-in":"fade-out")}>
                                <NewsView news={this.state.displayedNews} handleBack={() => this.displayList()} />
                            </div>
                        :
                            <div className={"news-list "+(this.state.entering?"fade-out":"fade-in")}>
                            {this.state.news.length > 0
                                ?
                                    this.state.news.map((n, i) => {
                                        return (<NewsCard key={"newscard"+i} news={n} handleClick={(newsID)=>this.displayNews(newsID)} unread={!this.checkNewsIsRead(n.iIDNews)}/>); 
                                    })
                                :
                                    <div style={{textAlign:'center', fontSize:'1.1em', 'marginTop':'70px'}}>Aucune news en cours</div>
                            }
                            </div>
                    :
                    <div className="col-xs-12 text-center">
                        <i className="fa fa-refresh fa-spin fa-3x fa-fw" aria-hidden="true" style={{ marginTop:'150px'}}></i>
                    </div>
                }
                </div>
            </SkyLight>
        );
    }
}

export default NewsModal;
