import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterOrigine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      familles: [],
      sousFamilles: [],
      origines: [],
      selectedFamilles: [],
      selectedSousFamilles: [],
      selectedOrigines: [],
    }

    this.key = this.props.name || ""
  }

  componentDidMount() {
    this.initDatas();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leads !== prevProps.leads) {
      this.initDatas();
    }
  }

  /**
   *  Coche les origines sélectionnées par défaut 
   */
  checkSelected() {
    var selectedFamilles = [];
    var selectedSousFamilles = [];
    var selectedOrigines = []; 
    if(this.props.selected) {
      selectedOrigines = this.props.selected;
      for(var i in selectedOrigines) {
        var origine = lodash.find(this.state.origines, o => o.Famille+"-"+o.SousFamille+"-"+o.Nom == selectedOrigines[i]);
        if(origine) {
          var sousFamille = origine.Famille+"-"+origine.SousFamille;
          if(selectedSousFamilles.indexOf(sousFamille) === -1) {
            selectedSousFamilles.push(sousFamille);
          }
          if(selectedFamilles.indexOf(origine.Famille) === -1) {
            selectedFamilles.push(origine.Famille);
          }
        }
      }

      this.setState({
        selectedOrigines: selectedOrigines,
        selectedSousFamilles: selectedSousFamilles,
        selectedFamilles: selectedFamilles
      });
    }
  }

  /**
   * Retourne l'origine du lead en fonction du type d'affichage...
   * La donnée n'est pas toujours au même endroit :(
   */
  getOrigine(lead) {
    if (this.props.type === 'AffairesGagnees' || this.props.type === 'AffairesClotures' || this.props.type === 'AffairesCloturees') {
      return lead.OrigineAction;
    }
    else if(this.props.type === "ActionsAFaire") {
        return lead.Affaire.OrigineAction;
    }
    return lead.ActionFaite_DO.OrigineAction;
  }

  initDatas() {

    // Récupération des différentes familles
    var familles = lodash.map(this.props.leads, (lead) => {
      let origine = this.getOrigine(lead);
      return origine ? {Nom: origine.Famille} : null;
    });

    familles = lodash.remove(familles, (famille) => {
      return famille !== null;
    })

    // Dédoublonnage des familles
    familles = lodash.uniqBy(familles, (famille) => {
      return famille.Nom;
    });

    // Tri par ordre alphabétique des familles
    familles = lodash.sortBy(familles, (famille) => {
      return famille.Nom;
    })

    // Récupération des différentes sous-familles
    var sousFamilles = lodash.map(this.props.leads, (lead) => {
      let origine = this.getOrigine(lead);
      return origine ? {Famille: origine.Famille, Nom: origine.SousFamille} : null;
    });

    sousFamilles = lodash.remove(sousFamilles, (sousFamille) => {
      return sousFamille !== null;
    })

    // Dédoublonnage des sous-familles
    sousFamilles = lodash.uniqBy(sousFamilles, (sousFamille) => {
      return sousFamille.Famille + ' - ' + sousFamille.Nom;
    });

    // Tri par ordre alphabétique des sous-familles
    sousFamilles = lodash.sortBy(sousFamilles, (sousFamille) => {
      return sousFamille.Famille + ' - ' + sousFamille.Nom;
    })

    // Récupération des différentes origines
    var origines = lodash.map(this.props.leads, (lead) => {
      let origine = this.getOrigine(lead);
      return origine ? {Famille: origine.Famille, SousFamille: origine.SousFamille, Nom: origine.Origine} : null;
    });

    origines = lodash.remove(origines, (origine) => {
      return origine !== null;
    })

    // Dédoublonnage des origines
    origines = lodash.uniqBy(origines, (origine) => {
      return origine.Famille + ' - ' + origine.SousFamille + ' - ' + origine.Nom;
    });

    // Tri par ordre alphabétique des origines
    origines = lodash.sortBy(origines, (origine) => {
      return origine.Famille + ' - ' + origine.SousFamille + ' - ' + origine.Nom;
    })

    this.setState({
      familles: familles,
      sousFamilles: sousFamilles,
      origines: origines,
    }, () => {
      this.checkSelected()
    });
  }

  onChangeFamille(event) {
    var familleName = event.target.name;

    var selectedFamilles = this.state.selectedFamilles;
    var selectedSousFamilles = this.state.selectedSousFamilles;
    var selectedOrigines = this.state.selectedOrigines;

    if(event.target.checked){
      selectedFamilles.push(familleName);

      //ajout des sous familles
      var sfToAdd = lodash.filter(this.state.sousFamilles, (sousFamille) => {
        return sousFamille.Famille === familleName
       }).map((sousFamille) => {
         return sousFamille.Famille+"-"+sousFamille.Nom;
       });
       selectedSousFamilles = selectedSousFamilles.concat(sfToAdd);

      //ajout des origines
      var ogToAdd = lodash.filter(this.state.origines, (origine) => {
        return origine.Famille === familleName
      }).map((origine) => {
         return origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom;
       });
       selectedOrigines = selectedOrigines.concat(ogToAdd);
    }
    else{
        selectedFamilles = lodash.remove(selectedFamilles, (famille) => {
          return famille !== familleName;
        });

        //Suppression de toutes les sous familles
        var sfToDelete = lodash.filter(this.state.sousFamilles, (sousFamille) => {
          return sousFamille.Famille === familleName
        }).map((sousFamille) => {
          return sousFamille.Famille+"-"+sousFamille.Nom;
        });

        sfToDelete.map((sfToDeleteUnit) => {
          return lodash.remove(selectedSousFamilles, (sousFamille) => {
            return sousFamille === sfToDeleteUnit;
          });
        });
        //Suppression de toutes les origines
        var ogToDelete = lodash.filter(this.state.origines, (origine) => {
          return origine.Famille === familleName
        }).map((origine) => {
          return origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom;
        });

        ogToDelete.map((ogToDeleteUnit) => {
          return lodash.remove(selectedOrigines, (origine) => {
            return origine === ogToDeleteUnit;
          });
        });
    }

    this.setState({
      selectedFamilles: selectedFamilles,
      selectedSousFamilles: selectedSousFamilles,
      selectedOrigines: selectedOrigines,
    }, () => {
      this.props.onChange('origines', this.state.selectedOrigines);
    });

  }

  onChangeSousFamille(FamilleName, event) {
    var sousFamilleName = event.target.name;

    var selectedFamilles = this.state.selectedFamilles;
    var selectedSousFamilles = this.state.selectedSousFamilles;
    var selectedOrigines = this.state.selectedOrigines;

    if(event.target.checked){
      selectedSousFamilles.push(FamilleName+"-"+sousFamilleName);

      //Si famille non sélectionnée, on ajoute
      if (this.state.selectedFamilles.indexOf(FamilleName) <= -1) {
        selectedFamilles.push(FamilleName);
      }

      //si origines enfant non sélectionnées, on ajoute
      var ogToAdd = lodash.filter(this.state.origines, (origine) => {
        return origine.SousFamille === sousFamilleName
      }).map((origine) => {
         return origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom;
       });
       selectedOrigines = selectedOrigines.concat(ogToAdd);
    }
    else {

      selectedSousFamilles = lodash.remove(selectedSousFamilles, (sf) => {
        return sf !== FamilleName+"-"+sousFamilleName;
      });

      //Suppression de toutes les origines
      var ogToDelete = lodash.filter(this.state.origines, (origine) => {
        return origine.SousFamille === sousFamilleName
      }).map((origine) => {
        return origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom;
      });

      ogToDelete.map((ogToDeleteUnit) => {
        return lodash.remove(selectedOrigines, (origine) => {
          return origine === ogToDeleteUnit;
        });
      });

      // Si plus de sous familles actives pour la famille, on décoche
      var selectedSfOfF = lodash.filter(this.state.sousFamilles, (sf) => {
        return sf.Famille === FamilleName && selectedSousFamilles.indexOf(sf.Famille+"-"+sf.Nom) > -1;
      });

      if (selectedSfOfF.length === 0) {
        selectedFamilles = lodash.remove(selectedFamilles, (famille) => {
          return famille !== FamilleName;
        });
      }
    }

    this.setState({
      selectedFamilles: selectedFamilles,
      selectedSousFamilles: selectedSousFamilles,
      selectedOrigines: selectedOrigines,
    }, () => {
      this.props.onChange('origines', this.state.selectedOrigines);
    });
  }

  onChangeOrigine(FamilleName, SousFamilleName, event) {
    var origineName = event.target.name;
    var selectedFamilles = this.state.selectedFamilles;
    var selectedSousFamilles = this.state.selectedSousFamilles;
    var selectedOrigines = this.state.selectedOrigines;

    if(event.target.checked){
      selectedOrigines.push(FamilleName+"-"+SousFamilleName+"-"+origineName);

      //si sous famille et famille non sélectionnées on ajoute
      if (this.state.selectedSousFamilles.indexOf(FamilleName+"-"+SousFamilleName) <= -1) {
        selectedSousFamilles.push(FamilleName+"-"+SousFamilleName);
      }

      if (this.state.selectedFamilles.indexOf(FamilleName) <= -1) {
        selectedFamilles.push(FamilleName);
      }
    }
    else{
      selectedOrigines = lodash.remove(selectedOrigines, (origine) => {
        return origine !== FamilleName+"-"+SousFamilleName+"-"+origineName;
      });

      //si plus d'origine active retirer la sous famille
      var selectedOgOfSf = lodash.filter(this.state.origines, (origine) => {
        return origine.SousFamille === SousFamilleName && selectedOrigines.indexOf(origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom) > -1;
      });

      if (selectedOgOfSf.length === 0) {
        selectedSousFamilles = lodash.remove(selectedSousFamilles, (sf) => {
          return sf !== FamilleName+"-"+SousFamilleName;
        });
      }

      //si plus de sous famille active retirer la famille
      var selectedSfOfF = lodash.filter(this.state.sousFamilles, (sf) => {
        return sf.Famille === FamilleName && selectedSousFamilles.indexOf(sf.Famille+"-"+sf.Nom) > -1;
      });

      if (selectedSfOfF.length === 0) {
        selectedFamilles = lodash.remove(selectedFamilles, (famille) => {
          return famille !== FamilleName;
        });
      }
    }

    this.setState({
      selectedFamilles: selectedFamilles,
      selectedSousFamilles: selectedSousFamilles,
      selectedOrigines: selectedOrigines,
    }, () => {
      this.props.onChange('origines', this.state.selectedOrigines);
    });
  }

  isCheckedFamille(famille) {
    return false; //(this.state.selectedFamilles.indexOf(famille.Nom) > -1) ? true : false;
  }

  isCheckedSousFamille(SousFamille) {
    return false;
  }

  isCheckedOrigine(origine) {
    return false;
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.origines, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('origines', this.state.selected);
    });
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  renderOrigine(famille, sousFamille) {

    var origines = lodash.filter(this.state.origines, (origine) => {
      return (origine.Famille === famille.Nom && origine.SousFamille === sousFamille.Nom);
    });

    return origines.map((origine, k) => {
      let id = this.key+'origine-'+origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom
      return (<div key={k} className={'checkbox rank-2' + (k === 0 ? ' first' : '')}>
        <input type="checkbox" id={id} name={origine.Nom} checked={(this.state.selectedOrigines.indexOf(origine.Famille+"-"+origine.SousFamille+"-"+origine.Nom) > -1) ? true : false} onChange={this.onChangeOrigine.bind(this, famille.Nom, sousFamille.Nom)} />
        <label className="label-box" htmlFor={id}>
          <span className="label-root">{origine.Nom}</span>
          <span className="count"></span>
        </label>
        <div className="clearfix"></div>
      </div>);
    });
  }

  renderSousFamille(famille) {

    var sousFamilles = lodash.filter(this.state.sousFamilles, (sousFamille) => {
      return (sousFamille.Famille === famille.Nom);
    });

    return sousFamilles.map((sousFamille, j) => {
      let id = this.key+'sousfamille-'+sousFamille.Famille+"-"+sousFamille.Nom;
      return (
        <div key={j} className={'block-height' + (j === 0 ? ' first' : '') + (j === sousFamilles.length-1 ? ' last' : '')}>
          <div className="checkbox rank-1">
            <input type="checkbox" id={id} name={sousFamille.Nom} checked={(this.state.selectedSousFamilles.indexOf(sousFamille.Famille+"-"+sousFamille.Nom) > -1) ? true : false} onChange={this.onChangeSousFamille.bind(this, famille.Nom)} />
            <label className="label-box" htmlFor={id}>
              <span className="label-root">{sousFamille.Nom}</span>
              <span className="count"></span>
            </label>
            <div className="clearfix"></div>
          </div>

          {this.renderOrigine(famille, sousFamille)}
        </div>
      );
    })
  }

  render() {

    var content = null;

    if (this.state.familles.length) {
      content = this.state.familles.map((famille, i) => {
        let id = this.key+'famille-'+famille.Nom;
        return <div key={i}>
          <div className="global-check">
            <input type="checkbox" id={id} name={famille.Nom} onChange={this.onChangeFamille.bind(this)} checked={(this.state.selectedFamilles.indexOf(famille.Nom) > -1) ? true : false}  />
            <label className="label-box" htmlFor={id}>
              <span className="label-root">{famille.Nom}</span>
            </label>
          </div>
          <div  className="block-height empty">
            {this.renderSousFamille(famille)}
          </div>
        </div>
      });
    }

    return (
      <PanelFilter title="Par origine">
        <div className="panel panel-default panel-leads-filter filters-grad" style={{paddingTop: 5}}>
          <div className="panel-body">
            {content}
          </div>
        </div>
      </PanelFilter>
    );
  }
}

export default FilterOrigine;
