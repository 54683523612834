import React, { Component } from 'react';

import Globals from '../Globals';

import TypesProduction from '../Components/TypesProduction';

class IframeTypesProduction extends Component {

    constructor(props) {
        super(props);

        this.state = {
          prospect: null,
          prospectLoaded: false,
        }

        if(!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password){
            localStorage.bypass_iFrame = true;
            localStorage.user_IDClient = this.props.location.query.idclient;
            localStorage.user_login = this.props.location.query.login;
            localStorage.user_password = this.props.location.query.password;
            localStorage.token = 'iframe'
            localStorage.version = Globals.VERSION

            localStorage.ClientApiURL = Globals.API_URL;
            var parts = window.location.hostname.split('.');
            if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod' || parts[1] === 'preprod' || parts[0] === 'localhost') {
              localStorage.ClientApiURL = Globals.API_URL_DEV;
            }

            if (parts[0] === 'devagri' || parts[1] === 'devagri') {
              localStorage.ClientApiURL = Globals.API_URL_DEV_AGRI;
            }
        }
    }

    componentDidMount() {
      this.fetchProspect();
    }

    fetchProspect() {
      this.setState({
        prospectLoaded: false
      });
      fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.location.query.idprospect, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        console.log(json)
        this.setState({
          prospect: json,
          prospectLoaded: true,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          prospectLoaded:true
        })
      });
    }

    render() {

        return (
            <div style={{maxWidth:'100vw'}}>
              <div className="iframe-title">Types de production</div>
              {!this.state.prospect ?
                !this.state.prospectLoaded ?
                  <div className="center-div" style={{paddingTop:'100px'}}>
                    <br/>
                    <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                    <br/><br/>Chargement des informations...
                  </div>
                :
                  <div className="center-div" style={{paddingTop:'100px'}}>
                    <i className="fa fa-exclamation-circle fa-2x"></i>
                    <br/><br/>Prospect {this.props.location.query.idprospect} non trouvé
                  </div>
              :
                <TypesProduction IDProspect={this.state.prospect.IDProspect} iframe={true}/>
              }
            </div>
        );
    }
}

export default IframeTypesProduction;
