import React, { Component } from 'react';
import Globals from '../Globals';
import { browserHistory } from 'react-router'

import moment from 'moment'

import IconCanal from '../Components/IconCanal';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowSite from '../Components/TableRowSite';
import TableRowComment from '../Components/TableRowComment';
import Delai from '../Components/Delai'

class TypeATraiter extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {

    }
  }

  componentWillMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leads !== prevProps.leads) {
      this.setState({

      });
    }
  }

  getTypeDemande(lead) {
    if (!lead.Affaire || !lead.Affaire.Description)
      return 'Autres';

    var matchs = lead.Affaire.Description.match(/Type de demande : (.*)(\n|\r)/);

    if (!matchs)
      return 'Autres';

    return matchs[1];
  }

  handleclick(IDLead) {
    browserHistory.push('/rapportAtelier/' + IDLead);
  }

  render() {

    return (
      <div>
        <div className="">
          <div className="wrap-table">
          <table className="table table-eseller-lead table-striped table-hover table-condensed">
            <thead>
              <tr>
                <th className='text-right' style={{ width: '130px', }}>Date</th>
                <th className='text-center'>Canal</th>
                <th style={{ width: '200px', }}>Campagne / Origine</th>
                <th>Type de demande</th>
                <th style={{ width: '150px', }}>Site</th>
                <th>Contact</th>
                <th>Délai</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.leads.map((lead, i) =>
                <tr key={lead.IDLead} className={((this.state.selected && this.state.selected === lead.IDLead) ? 'active ' : '') + 'tr-link'} onClick={this.handleclick.bind(this, lead.IDLead)}>
                  <td className='text-right'>
                    {moment(lead.InfoCreation.DateH).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td className='text-center'>
                    <IconCanal canal={lead.ActionFaite_DO.CanalOrigine} />
                  </td>
                  <td>
                    <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.ActionFaite_DO.OrigineAction} />
                  </td>
                  <td>{this.getTypeDemande(lead)}</td>
                  <td style={{ overflow: 'hidden' }}>
                    <TableRowSite Site={lead.Site} />
                  </td>
                  <td>
                    {lead.Prospect.Nom} {lead.Prospect.Prenom}
                  </td>
                  <td>
                    <Delai date={lead.InfoCreation.DateH} />
                  </td>
                  <td>
                    <TableRowComment comment={lead.Affaire ? lead.Affaire.Description : ''} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
        </div>

      </div>
    );
  }
}

export default TypeATraiter;
