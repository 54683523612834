import React, { Component } from 'react';

import moment from 'moment'

class BadgeDiffDay extends Component {

  // https://coderwall.com/p/wkdefg/converting-milliseconds-to-hh-mm-ss-mmm
  msToTime(duration) {
    var minutes = parseInt((duration/(1000*60))%60, 10)
        , hours = parseInt((duration/(1000*60*60))%24, 10);

    minutes = (minutes < 10) ? ("0" + minutes) : minutes;

    return hours + " h " + minutes;
  }

  render() {
    const now = moment();
    const date = moment(this.props.date);

    if (date.format('HHmm') === '0000' && date.isSame(now, "day")) {
      return null;
    }

    var diffHours = date.diff(now, 'hours');

    var diff = moment(this.props.date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).diff(moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0), 'days');

    var className = 'pull-right pastille rect ';
    if (diffHours < 0) {
      className += ' red';
    }
    else if (diffHours > 0) {
      className += ' green';
    }

    return (
      <span className={className}>
        {(Math.abs(diffHours) < 24) ?
          <span style={{whiteSpace:'nowrap'}}>
            {this.msToTime(Math.abs(moment(this.props.date).diff(now)))}
          </span>
        :
          <span style={{whiteSpace:'nowrap'}}>
            {Math.abs(diff)} j
          </span>
        }
      </span>
    );
  }
}

export default BadgeDiffDay;
