import React, { Component } from 'react';
import { Link } from 'react-router';
import Sidebar from './Sidebar';
import Globals from '../Globals';
import LeadsARedistribuer from './LeadsARedistribuer';
import AffairesEncours from './AffairesEncours';
import AffairesGagnees from './AffairesGagnees';
import AffairesClotures from './AffairesClotures';
import AffairesEncoursMap from './AffairesEncoursMap'
import Loader from '../Components/Loader';
import FilterDate from '../Components/FilterDate';
import FilterPeriode from '../Components/FilterPeriode';
import './Leads.css';

import moment from 'moment'
import CSVLeads from './CSVLeads';
var lodash = require('lodash')
var SHA1 = require("crypto-js/sha1")
var Datetime = require('react-datetime');

class Leads extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      leads: [],
      filters: [],
      filterName: '',
      distinctEtapes: [],
      distinctInterets: [],
      distinctVendeurs: [],
      distinctMarques: [],
      displaySidebar: true,
      cacheLead: [],
      selectedProspect: null,
      dateStart: this.getDefaultDateStart(),
      dateEnd: this.getDefaultDateEnd(),
      typePeriode: 'nouveau',
      datePeriode: 'days',
      datasToDownload: null,
      map:false,
      fetchInProgress: false,
      refreshInProgress: false,
      sortBy: null, //Sort by this
      sortByAlt: null, //If sortBy is null for a lead, use this
      sortByOrder: 'asc',
    }
  }

  componentDidMount() {
    if(this.props.location.query){
      var queryParams = this.props.location.query;
      var stateChanges = {};
      var currentFilters = [];
      for(var type in queryParams){
        if(queryParams[type] == "")
          continue;

        if(['typePeriode', 'filterName', 'datePeriode', 'sortBy', 'sortByOrder', 'sortByAlt'].indexOf(type) !== -1)
          stateChanges[type] = queryParams[type]
        else if(type == 'dateStart')
          stateChanges.dateStart = moment(queryParams[type])
        else if(type == 'dateEnd')
          stateChanges.dateEnd = moment(queryParams[type])
        else if(type == 'sites')
          currentFilters[type] = lodash.map(queryParams[type].split(","), (f) => {return parseInt(f);});
        else 
          currentFilters[type] = queryParams[type].split(",");
      }
      stateChanges.filters = currentFilters
      this.setState(stateChanges, () => {
        this.fetchDatas();
      });
    }
    else 
      this.fetchDatas();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.type !== this.props.params.type) {
      this.setState({
        leads: [],
        cacheLead: [],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.params.type === prevProps.params.type) {
      return;
    }

    this.setState({
      leads: [],
      filters: [],
      distinctEtapes: [],
      distinctInterets: [],
      distinctVendeurs: [],
      cacheLead: [],
      selectedProspect: null,
      fetchInProgress: false,
      dateStart: this.getDefaultDateStart(),
      dateEnd: this.getDefaultDateEnd(),
      typePeriode: 'nouveau',
      datePeriode: 'days'
    }, () => {
      this.fetchDatas();
    })
  }

  getDefaultDateStart() {
    if(this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesClotures') {
      return moment().startOf('days');
    }
    return null;
  }

  getDefaultDateEnd() {
    if(this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesClotures') {
      return moment().endOf('days');
    }
    return null;
  }

  fetchDatas() {
    this.setState({
      fetchInProgress: true,
      refreshInProgress: true,
    });

    setTimeout(() => this.fetchLeadsFromLocalStorage(), 1); //Evite d'avoir une page blanche pendant un plus ou moins court instant (suivant la puissance du PC)
   
    var type = this.props.params.type;
    var mes_sites = localStorage.access_superviseurBDC === 'true' ? false : true;

    var typesLeads = (localStorage.IsVDL === 'true' ? "VDL" : "VN,VO");

    if (this.props.params.type === 'LeadsARedistribuer' || this.props.params.type === 'AffairesEncoursBDC' || this.props.params.type === 'AffairesEncoursVendeur') {

      let a_redistribuer;
      let typeCompte;
      let creer_par_moi;
      let intervention_par_moi = false;
      let intervention_par_bdc = false;

      if (this.props.params.type === 'LeadsARedistribuer') {
        a_redistribuer = true;
        typeCompte = '';
        creer_par_moi = false;
      }
      else if (this.props.params.type === 'AffairesEncoursBDC') {
        a_redistribuer = '';
        typeCompte = 'BDC';
        creer_par_moi = false;

        if (localStorage.access_superviseurBDC === 'false') {
          fetch(localStorage.ClientApiURL + '/utilisateurs/'+localStorage.user_IDUtilisateur+'/rapports', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
          })
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then((json) => {
            if (type !== this.props.params.type) {
              return;
            }

            if(this.props.params.type === 'AffairesEncoursVendeur' || this.props.params.type === 'AffairesEncoursBDC')
              json = this.setInteretMarque(json); // Pour filtrage par marque : on extrait la marque depuis la description de l'affaire et on la stocke sur le lead
  
            this.setState({
              leads: json,
            }, () => {
              var leadsJSON = JSON.stringify(json)
              var hash = SHA1(leadsJSON).toString();
              if(localStorage.leadsHash != hash) { //Si les données ont changé (hash différents), on régénère les listes et son stocke tout dans le localstorage, sinon on ne stocke que la date
                this.generateCacheLeads();
                this.generateDistinctEtapes();
                this.generateDistinctInterets();
                this.generateDistinctVendeurs();
                this.generateDistinctMarques();
                this.saveLeadsToLocalStorage(leadsJSON, this.props.params.type, Date.now(), hash);
              }
              else 
                localStorage.leadsDate = Date.now();
            });
          })
          .catch((error) => {
            console.log(error)
          });
          return;
        }
      }
      else if (this.props.params.type === 'AffairesEncoursVendeur') {
        a_redistribuer = '';
        typeCompte = 'Vendeur';
        creer_par_moi = localStorage.access_superviseurBDC === 'true' ? false : true;

        intervention_par_moi = localStorage.access_superviseurBDC === 'true' ? false : true;
        intervention_par_bdc = localStorage.access_superviseurBDC === 'true' ? true : false;

        if (localStorage.user_profil === 'ChefDesVentes' || localStorage.user_profil === 'WelcomePro') {
          intervention_par_moi = false;
          intervention_par_bdc = false;
        }
      }

      var url = localStorage.ClientApiURL + '/Leads?typesLeads='+typesLeads+'&a_redistribuer='+a_redistribuer+'&mes_sites='+mes_sites+'&type_profil='+typeCompte+'&intervention_par_moi='+intervention_par_moi+'&intervention_par_bdc='+intervention_par_bdc;
      if(this.props.params.type === 'AffairesEncoursVendeur') {
        url = url.replace("/Leads", "/QuickLeads");
        if(this.state.dateStart) {
          url += '&date_debut='+moment(this.state.dateStart).format('Y-MM-DD');
        }
        if(this.state.dateEnd) {
          url += '&date_fin='+moment(this.state.dateEnd).format('Y-MM-DD');
        }
      }
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        if (type !== this.props.params.type) {
          return;
        }

        if(this.props.params.type === 'AffairesEncoursVendeur' || this.props.params.type === 'AffairesEncoursBDC' || this.props.params.type === 'LeadsARedistribuer')
          json = this.setInteretMarque(json); // Pour filtrage par marque : on extrait la marque depuis la description de l'affaire et on la stocke sur le lead

        var leadsJSON = JSON.stringify(json)
        var leadsJSONLength = leadsJSON.length;

        var reload = false;
        if(leadsJSONLength <= 4000000) //Données reçues inférieures à environ 4 Mo : on calcule le hash et on met à jour la table (déclenchement render)
        {
          var hash = SHA1(leadsJSON).toString(); //Si les données ont changé (hash différents), on régénère les listes et son stocke tout dans le localstorage, sinon on ne stocke que la date
          if(localStorage.leadsHash != hash)
            reload = true;
        }
        else 
        {
          if(!this.state.leads || !this.state.leads.length)
            reload = true;
        }
          
        if(reload)
        { 
          this.setState({
            leads: json,
          }, () => {
            //console.log("données reçues")
            this.generateCacheLeads();
            this.generateDistinctEtapes();
            this.generateDistinctInterets();
            this.generateDistinctVendeurs();
            this.generateDistinctMarques();
            this.saveLeadsToLocalStorage(leadsJSON, this.props.params.type, Date.now(), hash);
          })
        }
        else if(leadsJSONLength <= 4000000)
        {
           localStorage.leadsDate = Date.now();
        }
        else
        /* Données reçues supérieures à environ 4 Mo : un render est coûteur et provoque un freeze du navigateur, on ne le fait que si le tableau de leads était vide 
         * On stock quand même les leads dans le cache ainsi l'affichage sera mis à jour la prochaine fois qu'on reviendra via un back sur la page (pas de calcul du hash car non nécesaire) */
        {
          this.saveLeadsToLocalStorage(leadsJSON, this.props.params.type, Date.now(), null);
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.setState({
          refreshInProgress: false,
        })
      });
    }
    else if (this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesClotures') {
      var intervention_par_moi = localStorage.access_superviseurBDC === 'true' ? false : true;
      var intervention_par_bdc = localStorage.access_superviseurBDC === 'true' ? true : false;

      if (localStorage.user_profil === 'ChefDesVentes') {
        intervention_par_moi = false;
        intervention_par_bdc = false;
        mes_sites = true;
      }
      else {
        mes_sites = false;
      }

      var statut = (this.props.params.type === 'AffairesGagnees' ? "5" : "4");

      var url = localStorage.ClientApiURL + '/Affaires?typesAffaires='+typesLeads+'&lead=true&statut='+statut+'&date_debut='+moment(this.state.dateStart).format('Y-MM-DD 00:00:00')+'&date_fin='+moment(this.state.dateEnd).format('Y-MM-DD 23:59:59')+'&intervention_par_moi='+intervention_par_moi+'&intervention_par_bdc='+intervention_par_bdc+'&mes_sites='+mes_sites;
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        if (type !== this.props.params.type) {
          return;
        }

        this.setState({
          leads: json,
        }, () => {
          var leadsJSON = JSON.stringify(json)
          var hash = SHA1(leadsJSON).toString();
          if(localStorage.leadsHash != hash || !json.length) { //Si les données ont changé (hash différents), on régénère les listes et on stocke tout dans le localstorage, sinon on ne stocke que la date
            this.generateCacheLeads();
            this.generateDistinctVendeurs();
            this.saveLeadsToLocalStorage(leadsJSON, this.props.params.type, Date.now(), hash);
          }
          else 
            localStorage.leadsDate = Date.now();
        });
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }

  fetchLeadsFromLocalStorage(){
    if(localStorage.leadsDate && Date.now() - localStorage.leadsDate > 300000) { // 300000ms = 5 minutes. Si le localstorage est plus vieux on l'ignore et on supprime les données afin de forcer leur rechargement
      delete localStorage.leads
      delete localStorage.leadsHash
      delete localStorage.leadsType
      return;
    }

    if(localStorage.leads && localStorage.leadsType === this.props.params.type) { //Récupération des leads depuis le localstorage, la liste se recharge en tâche de fond
      var leads = [];
      if(localStorage.leads === "global")
      {
        var cacheLeads = window.$leads;
        var cacheLeadsOK = true;
        if(!cacheLeads)
          cacheLeadsOK = false;
        else
        {
          try {
            leads = JSON.parse(window.$leads);
          }
          catch(error){
            console.log("Cache leads invalide");
            cacheLeadsOK = false;
          }
        }

        if(!cacheLeadsOK){
          delete localStorage.leads
          delete localStorage.leadsHash
          delete localStorage.leadsType
          return;
        }
      }
      else 
        leads = JSON.parse(localStorage.leads);

      this.setState({
        leads: leads,
      }, () => {
        if (this.props.params.type === 'AffairesGagnees'){
          this.generateCacheLeads();
          this.generateDistinctVendeurs();
        }
        else if (this.props.params.type === 'AffairesClotures'){
          this.generateCacheLeads();
          this.generateDistinctVendeurs();
        }
        else {
          this.generateCacheLeads();
          this.generateDistinctEtapes();
          this.generateDistinctInterets();
          this.generateDistinctVendeurs();
          this.generateDistinctMarques();
        }
      });
    }
  }

  async saveLeadsToLocalStorage(leadsJSON, type, date, hash){
    if(leadsJSON.length > 4000000) //Plus d'environ 4 Mo de données : localstorage saturé on fait autre : on stock dans window. Sûrement pas idéal mais pas d'autre solution. Cela reste exceptionnel d'avoir plus de 4 Mo de data leads
    {
      window.$leads = leadsJSON;
      localStorage.leads = "global";
    }
    else 
    {
      window.$leads = null;
      localStorage.leads = leadsJSON;
    }
    localStorage.leadsType = type;
    localStorage.leadsDate = date;
    localStorage.leadsHash = hash
  }

  generateUrlWithFilters(){
    var urlParams = [];

    if(this.props.params.type === 'AffairesEncoursVendeur' || this.props.params.type === 'AffairesEncoursBDC') {
      urlParams.push('typePeriode='+this.state.typePeriode);
      if(this.state.filterName)
        urlParams.push('filterName='+this.state.filterName);
    }
    
    if(this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesClotures' || this.props.params.type === 'AffairesEncoursVendeur') {
      if(this.state.dateStart)
        urlParams.push('dateStart='+this.state.dateStart.format('YYYYMMDD'));
      if(this.state.dateEnd)
        urlParams.push('dateEnd='+this.state.dateEnd.format('YYYYMMDD'));
      if(this.props.params.type !== 'AffairesEncoursVendeur' && this.state.datePeriode)
        urlParams.push('datePeriode='+this.state.datePeriode);
    }

    var currentFilters = this.state.filters;
    for(var i in currentFilters){
      if(currentFilters[i] == "")continue;
      urlParams.push(i+'='+currentFilters[i].join(','))
    }

    if(this.state.sortBy) {
      urlParams.push('sortBy='+this.state.sortBy);
      urlParams.push('sortByOrder='+this.state.sortByOrder);
      if(this.state.sortByAlt) {
        urlParams.push('sortByAlt='+this.state.sortByAlt);
      }
    }

    window.history.replaceState("", "", window.location.pathname+'?'+urlParams.join('&'))
  }

  generateDistinctEtapes() {
    var etapes = lodash.countBy(this.state.leads, function(lead) {
      return lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
    });
    this.setState({distinctEtapes: etapes});
  }

  extractMarqueModeleFromAffaireDescription(description){
    var vehiculeInteretInfos = null;
    if(description){
      if(localStorage.BDGp === "BDGpAutoHallV4")
        vehiculeInteretInfos = description.match(/(?:marque|marque ):(.*)\n(?:.*)(?:mod[èe]le|mod[èe]le ):(.*)\n/i);
      else 
        vehiculeInteretInfos = description.match(/(?:marque|marque ):(.*)\n(?:modèle|modèle ):(.*)\n/i);

      if(vehiculeInteretInfos) {
        return [vehiculeInteretInfos[1].trim(), vehiculeInteretInfos[2].trim()]
      }
    }
    return ['', ''] 
  }

  setInteretMarque(leads){
    lodash.forEach(leads, lead => {
      let marqueModele = this.extractMarqueModeleFromAffaireDescription(lead.Affaire.Description);
      lead.Marque = marqueModele[0] ? lodash.upperFirst(marqueModele[0].toLowerCase()) : "Non précisée";
    })

    return leads;
  }

  generateDistinctMarques() {
    // On stocke la marque sur le lead afin d'éviter de refaire la recherche dans la description lors du filtrage
    var marques = lodash.countBy(this.state.leads, "Marque");

    marques = lodash.sortBy(lodash.map(marques, (nb, marque) => {
      return {
        "marque": marque,
        "nb": nb
      }
    }), "marque");

    this.setState({
      distinctMarques: marques,
    });
  }

  generateDistinctInterets() {
    var niveaux = [];

    var interets = lodash.countBy(this.state.leads, function(lead) {

      if (niveaux.indexOf(lead.ActionAFaire_TODO.NiveauInteret.Niveau) < 0) {
        niveaux[lead.ActionAFaire_TODO.NiveauInteret.Niveau] = {
          name: lead.ActionAFaire_TODO.NiveauInteret.Description,
          niveau: lead.ActionAFaire_TODO.NiveauInteret.Niveau
        }
      }
      return lead.ActionAFaire_TODO.NiveauInteret.Niveau;
    });

    lodash.forIn(interets, function(value, key){
      niveaux[key].count = value;
    });

    this.setState({distinctInterets: niveaux});
  }

  generateDistinctVendeurs(){
    var type = this.props.params.type;
    var vendeurs = lodash.countBy(this.state.leads, function(lead) {
      if(type === "AffairesClotures")
        return lead.DernierRapportUtilisateur ? lead.DernierRapportUtilisateur.Nom : lead.SuiviPar ? lead.SuiviPar.Nom : "";
      else if(type == "AffairesGagnees") {
        var lastOffre = lodash.head(lodash.filter(lead.Offres, (offre) => {
          return (offre.Statut === 'Commande' || offre.Statut === 'CommandeValidee' || offre.Statut === 'Facture');
        }));
        return lastOffre ? lastOffre.SuiviPar.Nom : "";
      }
      else
        return lead.SuiviPar ? lead.SuiviPar.Nom : "";
    });
    this.setState({distinctVendeurs: vendeurs});
  }

  filters(leads) {

    var type = this.props.params.type;

    if (this.state.filters) {
      if (this.state.filters['origines'] && this.state.filters['origines'].length) {
        var filter_origines = this.state.filters['origines'];
        leads = lodash.filter(leads, function(lead) {
          if (type === 'AffairesGagnees' || type === 'AffairesClotures') {
            var check = (lead.OrigineAction) ? lead.OrigineAction.Famille+"-"+lead.OrigineAction.SousFamille+"-"+lead.OrigineAction.Origine : '';
          }
          else {
            var check = (lead.ActionFaite_DO && lead.ActionFaite_DO.OrigineAction) ? lead.ActionFaite_DO.OrigineAction.Famille+"-"+lead.ActionFaite_DO.OrigineAction.SousFamille+"-"+lead.ActionFaite_DO.OrigineAction.Origine : '';
          }
          return filter_origines.indexOf(check) > -1;
        });
      }

      if (this.state.filters['etapes'] && this.state.filters['etapes'].length) {
        var filter_etapes = this.state.filters['etapes'];
        leads = lodash.filter(leads, function(lead) {
          var check = lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
          return filter_etapes.indexOf(check) > -1;
        });
      }

      if (this.state.filters['interets'] && this.state.filters['interets'].length) {
        var filter_interets = this.state.filters['interets'];
        leads = lodash.filter(leads, function(lead) {
          return filter_interets.indexOf(lead.ActionAFaire_TODO.NiveauInteret.Description) > -1;
        });
      }

      if (this.state.filters['sites'] && this.state.filters['sites'].length) {
        var filter_sites = this.state.filters['sites'];
        leads = lodash.filter(leads, function(lead) {
          var check;
          if (type === 'AffairesGagnees') {
            check = lead.Site ? lead.Site.IDSite : "";
          }
          else {
            check = lead.Site ? lead.Site.IDSite : "";
          }
          return filter_sites.indexOf(check) > -1;
        });
      }

      if (this.state.filters['vendeurs'] && this.state.filters['vendeurs'].length) {
        var filter_vendeurs = this.state.filters['vendeurs'];
        leads = lodash.filter(leads, function(lead) {
          if(type == "AffairesClotures")
            var check = lead.DernierRapportUtilisateur ? lead.DernierRapportUtilisateur.Nom : lead.SuiviPar ? lead.SuiviPar.Nom : "";
          else if(type == "AffairesGagnees") {
            var lastOffre = lodash.head(lodash.filter(lead.Offres, (offre) => {
              return (offre.Statut === 'Commande' || offre.Statut === 'CommandeValidee' || offre.Statut === 'Facture');
            }));
            var check = lastOffre ? lastOffre.SuiviPar.Nom : "";
          }
          else
            var check = lead.SuiviPar ? lead.SuiviPar.Nom : "";
          return filter_vendeurs.indexOf(check) > -1;
        });
      }

      if (this.state.filters['types'] && this.state.filters['types'].length) {
        var filter_types = this.state.filters['types'];
        leads = lodash.filter(leads, function(lead) {
          if(type == "AffairesClotures" || type == "AffairesGagnees")
            return filter_types.indexOf(lead.Type) != -1;
          else {
            if(lead.Affaire)
              return filter_types.indexOf(lead.Affaire.Type) != -1;
            else 
              return filter_types.indexOf("-") != -1;
          }
        });
      }

      if (this.state.filters['marques'] && this.state.filters['marques'].length) {
        var filter_marques = this.state.filters['marques'];
        leads = lodash.filter(leads, function(lead) {
          return filter_marques.indexOf(lead.Marque) !== -1
        });
      }

      if (this.state.filters['typesClient'] && this.state.filters['typesClient'].length) {
        var filter_typesclient = this.state.filters['typesClient'];
        leads = lodash.filter(leads, function(lead) {
          return filter_typesclient.indexOf(lead.Prospect.TypeClient) !== -1
        });
      }
    }

    return leads;
  }

  sort(leads) {
    if(this.state.sortBy) {
      leads = lodash.orderBy(
        leads, 
        (l) => {
          let value = ""+lodash.get(l, this.state.sortBy, "");
          if(this.state.sortByAlt) {
            value += lodash.get(l, this.state.sortByAlt, "");
          }
          return value.toLowerCase();
        }, 
        this.state.sortByOrder);
    }
    return leads;
  }

  generateCacheLeads() {
    var leads = this.state.leads;
    var type = this.props.params.type;
    var showReportes = this.getShowReportes();

    leads = this.filters(leads);

    // Filtre par période : A venir, en retard, aujourd'hui
    if (type === 'AffairesEncoursBDC' || type === 'AffairesEncoursVendeur') {

      if (this.state.typePeriode === 'late') {
        leads = lodash.filter(leads, function(lead) {
          if (showReportes && lead.ReportAffaire)
            return false;

          if (lead.PremiereActionEntrante)
            return false

          if (!lead.ActionAFaire_TODO.DateHProchaineEtape)
            return true;

          var diff = moment(lead.ActionAFaire_TODO.DateHProchaineEtape).diff(moment(), 'seconds');
          return diff < 0;
        });
      }
      else if (this.state.typePeriode === 'incoming') {
        leads = lodash.filter(leads, function(lead) {
          if (showReportes && lead.ReportAffaire)
            return false;

          if (lead.PremiereActionEntrante)
            return false

          if (!lead.ActionAFaire_TODO.DateHProchaineEtape)
            return false;

          var diff = moment(lead.ActionAFaire_TODO.DateHProchaineEtape).diff(moment(), 'seconds');
          return diff >= 0;
        });
      }
      else if (this.state.typePeriode === 'nouveau') {
        leads = lodash.filter(leads, function(lead) {
          if (showReportes && lead.ReportAffaire)
            return false;

          return lead.PremiereActionEntrante;
        });
      }
      else if (this.state.typePeriode === 'reportes') {
        leads = lodash.filter(leads, function(lead) {
          return lead.ReportAffaire
        });
      }
    }

    if(this.props.params.type !== 'AffairesEncoursVendeur') { //Si AffairesEncoursVendeur le tri par défaut est effectué côté serveur
      leads.sort(function(a, b) {
        var date_a, date_b;

        if (type === 'AffairesGagnees' || type === 'AffairesClotures') {
          date_a = moment(a.DateDebut);
          date_b = moment(b.DateDebut);
        }
        else if (type === 'LeadsARedistribuer') {
          date_a = moment(a.InfoCreation.DateH);
          date_b = moment(b.InfoCreation.DateH);
        }
        else {
          date_a = moment(a.ActionAFaire_TODO.DateHProchaineEtape ? a.ActionAFaire_TODO.DateHProchaineEtape : a.InfoCreation.DateH);
          date_b = moment(b.ActionAFaire_TODO.DateHProchaineEtape ? b.ActionAFaire_TODO.DateHProchaineEtape : b.InfoCreation.DateH);
        }

        if (!date_a || !date_b) {
          return -1;
        }

        if (date_a < date_b)
          return -1;

        if ((date_a.format('DMY HH:mm') === date_b.format('DMY HH:mm')) && (parseInt(a.IDLead, 10) && parseInt(b.IDLead, 10))) {
          if (parseInt(a.IDLead, 10) < parseInt(b.IDLead, 10)) {
            return -1;
          }
        }

        return 1;
      });
    }
    else {
      leads = this.sort(leads);
    }

    this.setState({cacheLead: leads, fetchInProgress: false})
  }

  onChangeFilters(type, datas) {
    var currentFilters = this.state.filters;
    currentFilters[type] = datas;
    this.setState({filters: currentFilters}, () => {
      this.generateUrlWithFilters();
      this.generateCacheLeads();
    });
  }

  onChangeDateStart(dateStart) {
    this.onChangeDate(dateStart, this.state.dateEnd);
  }

  onChangeDateEnd(dateEnd) {
    this.onChangeDate(this.state.dateStart, dateEnd);
  }

  onChangeDate(start, end, datePeriode) {
    if(!datePeriode)
      datePeriode = this.state.datePeriode

    delete localStorage.leads; //On doit forcément recharger les données depuis le serveur car on est dans les onglets Gagnés ou Clôturés
    delete localStorage.leadsHash; 

    this.setState({
      dateStart: start,
      dateEnd: end,
      datePeriode: datePeriode,
    }, () => {
      this.generateUrlWithFilters();
      this.fetchDatas();
    })
  }

  onChangePeriode(type) {
    this.setState({
      typePeriode: type
    }, () => {
      this.generateUrlWithFilters();
      this.generateCacheLeads();
    })
  }

  reloadLeads() {
    this.fetchDatas();
  }

  onChangeFilterName(event) {
    this.setState({
      filterName: event.target.value,
    }, () => {
      this.generateUrlWithFilters()
    })
  }

  onChangeSortBy(sortBy, sortByAlt) {
    var sortParams = {
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
      sortByAlt: sortByAlt
    };
    if(sortBy == this.state.sortBy) {
      sortParams.sortByOrder = this.state.sortByOrder == "asc" ? "desc" : "asc";
    }
    else {
      sortParams.sortBy = sortBy;
      sortParams.sortByOrder = "asc";
    }

    this.setState(sortParams, () => {
      this.generateUrlWithFilters();
      this.generateCacheLeads();
    })
  }

  toggleMap(event){
    this.setState(prevState => ({
      map: !prevState.map
    }));
  }

  getCounters() {
    var leads = this.state.leads;
    leads = this.filters(leads);
    var showReportes = this.getShowReportes();

    var counters = lodash.countBy(leads, function(lead) {
      if(showReportes && lead.ReportAffaire)
        return 'reportes';
      else if(lead.PremiereActionEntrante)
        return 'nouveau';
      else 
      {
        if(!lead.ActionAFaire_TODO.DateHProchaineEtape)
          return 'late';
        
        var diff = moment(lead.ActionAFaire_TODO.DateHProchaineEtape).diff(moment(), 'seconds');

        if(diff < 0)
          return 'late';
        else 
          return 'incoming'
      }
    });

    return counters;
  }

  //Condition pour afficher les leads reportés dans une onglets à part
  getShowReportes() {
    return this.props.params.type === 'AffairesEncoursVendeur'
  }

  render() {

    var leads = this.state.cacheLead;

    var content = null;

    switch (this.props.params.type) {
      case 'LeadsARedistribuer':
        content = <LeadsARedistribuer leads={leads} reloadLeads={this.reloadLeads.bind(this)} refreshInProgress={this.state.refreshInProgress} />
        break; 
      case 'AffairesEncoursBDC':
        if(this.state.map){
          content = <AffairesEncoursMap leads={leads} reloadLeads={this.reloadLeads.bind(this)} type={this.props.params.type} filterName={this.state.filterName} />
        }else{
          content = <AffairesEncours leads={leads} reloadLeads={this.reloadLeads.bind(this)} type={this.props.params.type} filterName={this.state.filterName} typePeriode={this.state.typePeriode} />
        }
        break;
      case 'AffairesEncoursVendeur':
        if(this.state.map){
          content = <AffairesEncoursMap leads={leads} reloadLeads={this.reloadLeads.bind(this)}  type={this.props.params.type} filterName={this.state.filterName}/>
        }else{
          content = <AffairesEncours leads={leads} reloadLeads={this.reloadLeads.bind(this)} type={this.props.params.type} filterName={this.state.filterName} typePeriode={this.state.typePeriode} onChangeSortBy={this.onChangeSortBy.bind(this)} sortBy={this.state.sortBy} sortByOrder={this.state.sortByOrder}/>
        }
        break;
      case 'AffairesGagnees':
        content = <AffairesGagnees leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
        break;
      case 'AffairesClotures':
        content = <AffairesClotures leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
        break;
      default:
        content = <h1></h1>
    }

    return (
      <div className="">
        <div className="page-sidebar-left">

          <div id="sidebar" className='page-sidebar-left-sidebar'>
            <Sidebar
              type={this.props.params.type}
              leads={this.state.leads}
              etapes={this.state.distinctEtapes}
              interets={this.state.distinctInterets}
              vendeurs={this.state.distinctVendeurs}
              marques={this.state.distinctMarques}
              onChangeFilters={this.onChangeFilters.bind(this)}
              queryFilters={this.props.location.query} />
          </div>

          <div className='page-sidebar-left-content'>
            
            <div style={{display: "flex", justifyContent:"space-between", alignItems:"flex-end"}}>
              {this.props.params.type !== 'LeadsARedistribuer' && this.props.params.type !== 'AffairesEncoursBDC' && this.props.params.type !== 'AffairesEncoursVendeur' ?
                <FilterDate onChangeDate={this.onChangeDate.bind(this)} type={this.props.params.type} className='pull-left' datePeriode={this.state.datePeriode} dateStart={this.state.dateStart} dateEnd={this.state.dateEnd}/>
              : null}

              {this.props.params.type === 'AffairesEncoursBDC' || this.props.params.type === 'AffairesEncoursVendeur' ?
                <div style={{display: "flex", justifyContent:"space-between", alignItems:"flex-end", flex: 1}}>
                  <div style={{display: "flex", alignItems:"flex-end"}}>
                    <FilterPeriode key='filter-periode' onChangePeriode={this.onChangePeriode.bind(this)} typePeriode={this.state.typePeriode} type={this.props.params.type} countLead={this.state.leads.length} countLeadActive={leads.length} counters={this.getCounters()} showTabReportes={this.getShowReportes()} className='pull-left' />
                    <div style={{display: "flex", flexDirection:"column", marginLeft: 45, fontSize:'0.9em'}}>
                      <div>Date de création du lead</div>
                      <div>
                        Du&nbsp;
                        <div className='form-group' style={{ width: 110, display: 'inline-block', position: 'relative'}}>
                          <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} value={this.state.dateStart ? this.state.dateStart.format('L') : ""} open={false} onChange={this.onChangeDateStart.bind(this)} inputProps={{readOnly: true}} />
                          {this.state.dateStart ?
                            <i style={{position: "absolute", right: 4, bottom: 7, color: "grey"}} className='fa fa-close' onClick={this.onChangeDateStart.bind(this, null)}></i>
                          :null}
                        </div>
                        &nbsp;au&nbsp;
                        <div className='form-group' style={{ width: 110, display: 'inline-block', position: 'relative'}}>
                          <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} value={this.state.dateEnd ? this.state.dateEnd.format('L') : ""} open={false} onChange={this.onChangeDateEnd.bind(this)} inputProps={{readOnly: true}} />
                          {this.state.dateEnd ?
                            <i style={{position: "absolute", right: 4, bottom: 7, color: "grey"}} className='fa fa-close' onClick={this.onChangeDateEnd.bind(this, null)}></i>
                          :null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent:"flex-end"}} key="icons">
                    {localStorage.IsAgri === 'true' ? 
                      <div>
                        <div className="pull-right" style={{marginLeft: '10px',marginRight:"10px"}}>
                          <Link onClick={this.toggleMap.bind(this)}>
                          {this.state.map?
                            <i className="fa fa-table fa-2x" aria-hidden="true"></i>
                          :
                            <i className="fa fa-map-marker fa-2x" aria-hidden="true"></i>
                          }
                          </Link>
                        </div>
                      </div>
                    :null}
                    <div key='div' className="form-group form-group-sm pull-right">
                      <input type="search" className='form-control' value={this.state.filterName} onChange={this.onChangeFilterName.bind(this)} placeholder='Filtre sur le contact' style={{ width: '200px', border: '1px solid #ccc', marginRight: '5px' }} />
                    </div>
                  </div>
                </div>
              : null}

              <div>
                {this.state.fetchInProgress === false ?
                  <div className="pull-right" style={{marginLeft: '5px', marginBottom: '5px'}}>
                    <CSVLeads leads={this.state.leads} type={this.props.params.type}/>
                  </div>
                :null}
              </div>
            
            </div>

            {this.state.fetchInProgress === false ?
              content
            : <Loader style={{ marginTop: 150 }} />}
          </div>
        </div>
      </div>
    );
  }
}

export default Leads;
