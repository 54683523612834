import React, {Component} from 'react';

import moment from 'moment'

import InputTime from '../Components/InputTime';
import InputDate from '../Components/InputDate';
import ButtonList from '../Components/ButtonList';
import { isMobile } from 'react-device-detect';

class FormRapportAccessoires extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      nonJoignable: false,
      devisEnvoye: null,
      rdvPris: null,
      poseSouhaitee: null,

      dateRdv: null,
      commentaire: '',
      dateRelance: moment(new Date()).add(1,'days'),

      showFormDateH: false,
    }
  }

  componentDidMount(){
    this.setState({
      poseSouhaitee: this.props.poseSouhaitee
    }, () => {this.props.onChangeValue(this.state)})
  }

  updateChangeValue(event, value = null) {
    const target = event.target;
    if(value === null)
      value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => this.props.onChangeValue(this.state));
  }

  updateChangeValue2(name, value) {
    this.setState({
      [name]: value
    }, () => {this.props.onChangeValue(this.state); console.log(this.state)});
  }

  changeDateRdv(value) {
    var dateRdv = this.state.dateRdv ? this.state.dateRdv : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    value.set('hour', dateRdv.get('hour')).set('minute', dateRdv.get('minute'));

    this.setState({
      dateRdv: value
    }, () => this.props.onChangeValue(this.state))
  }

  changeDateRelance(value) {
    var dateRelance = this.state.dateRelance ? this.state.dateRelance : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    value.set('hour', dateRelance.get('hour')).set('minute', dateRelance.get('minute'));
    if(value.isBefore(moment())){
      value.set('hour', moment().get('hour')).set('minute', moment().get('minute'));
    }

    this.setState({
      dateRelance: value
    }, () => this.props.onChangeValue(this.state))
  }

  changeHeureRdv(value) {
    var dateRdv = this.state.dateRdv ? this.state.dateRdv : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dateRdv.set('hour', value.get('hour')).set('minute', value.get('minute'));

    this.setState({
      dateRdv: dateRdv
    }, () => this.props.onChangeValue(this.state))
  }

  changeHeureRelance(value) {
    var dateRelance = this.state.dateRelance ? this.state.dateRelance : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dateRelance.set('hour', value.get('hour')).set('minute', value.get('minute'));
    if(dateRelance.isBefore(moment())){
      dateRelance.set('hour', moment().get('hour')).set('minute', moment().get('minute'));
    }

    this.setState({
      dateRelance: dateRelance
    }, () => this.props.onChangeValue(this.state))
  }

  isValidDateRdv(event) {
    return (event >= moment().startOf('day'));
  }

  isValidDateRelance(event) {
    return (event >= moment().startOf('day'));
  }

  showFormDateH(){
    return !this.state.nonJoignable && (this.state.rdvPris && this.state.poseSouhaitee);
  }

  render() {

    return (
      <div className="panel panel-default form-rapport-atelier">
        <h2><img src="/img/PROCHAINE ACTION Copy 2.svg" role='presentation' /> Action effectuée</h2>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group" style={{marginLeft:0}}>
              <input type="checkbox" name="nonJoignable" id="nonJoignable" onChange={evt => this.updateChangeValue(evt)} checked={this.state.nonJoignable}/>
              <label className={"label-box "+ (isMobile ? "isMobile" : "")} style={{fontSize:'1em'}} htmlFor="nonJoignable">Non joignable, à rappeler </label>
            </div>
            {!this.state.nonJoignable ? 
              <div className="row">
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-5">
                      Devis accepté ? 
                    </div>
                    <div className="col-xs-6">
                      <ButtonList buttons="Oui,Non" value={this.state.devisEnvoye ? 'Oui' : this.state.devisEnvoye === false ? 'Non' : null} name="devisEnvoye" small={true} onSelect={(e) => this.updateChangeValue2("devisEnvoye", e.target.value === 'Oui')}/>
                    </div>
                  </div>
                  <div className="row" style={{marginTop:'10px'}}>
                    <div className="col-md-3 col-xs-5">
                      Pose souhaitée ?
                    </div>
                    <div className="col-xs-6">
                      <ButtonList buttons="Oui,Non" value={this.state.poseSouhaitee ? 'Oui' : 'Non'} name="poseSouhaitee" small={true} onSelect={(e) => this.updateChangeValue2("poseSouhaitee", e.target.value === 'Oui')}/>
                    </div>
                  </div>
                  {this.state.devisEnvoye && this.state.poseSouhaitee ? 
                  <div className="row" style={{marginTop:'10px'}}>
                    <div className="col-md-3 col-xs-5">
                      Rendez-vous pris ? 
                    </div>
                    <div className="col-xs-6">
                      <ButtonList buttons="Oui,Non" value={this.state.rdvPris ? 'Oui' : this.state.rdvPris === false ? 'Non' : null} name="rdvPris" small={true} onSelect={(e) => this.updateChangeValue2("rdvPris", e.target.value === 'Oui')}/>
                    </div>
                  </div>
                  :null}
                </div>
              </div>
            : 
              <div>
                <div className="form-group">
                  <div className="col-md-6">
                    Date de relance <br/>
                    <InputDate isValidDate={this.isValidDateRelance.bind(this)} onChange={(e) => this.changeDateRelance(e)} value={this.state.dateRelance}/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-5 col-xs-10">
                    Heure <br/>
                    <InputTime value={this.state.dateRelance} onChange={this.changeHeureRelance.bind(this)} SelectStyle={{paddingLeft: '1px'}} after={this.state.dateRelance && this.state.dateRelance.isSame(moment(), 'day') ? moment().format("H:m") : null}/>
                  </div>
                  <div className="col-xs-2" style={{marginLeft:'-30px', marginTop:'3px'}}>
                    <br/>
                    <i className="icon-clock icons" style={{ marginLeft:'5px' }}></i>
                  </div>
                </div>
              </div>
            }
            {this.showFormDateH() ? 
            <div>
              <div className="form-group">
                <div className="col-md-6">
                  Date <br/>
                  <InputDate isValidDate={this.isValidDateRdv.bind(this)} onChange={(e) => this.changeDateRdv(e)} value={this.state.dateRdv}/>
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-5 col-xs-10">
                  Heure <br/>
                  <InputTime value={this.state.dateRdv} onChange={this.changeHeureRdv.bind(this)} SelectStyle={{paddingLeft: '1px'}} />
                </div>
                <div className="col-xs-2" style={{marginLeft:'-30px', marginTop:'3px'}}>
                  <br/>
                  <i className="icon-clock icons" style={{ marginLeft:'5px' }}></i>
                </div>
              </div>
            </div>
            :null}
            <div className="form-group" style={{marginLeft:0}}>
              <label className="control-label">Commentaire</label>
              <textarea className="form-control" onChange={(e) => this.updateChangeValue(e)} name="commentaire"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormRapportAccessoires;
