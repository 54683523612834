import React, { Component } from 'react';

import Loader from '../Components/Loader';
import ListPeopleHolidaysManage from '../Components/ListPeopleHolidaysManage';

import Globals from '../Globals';

import { Link } from 'react-router';

import moment from 'moment'
var lodash = require('lodash')

class PeopleHolidays extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
            holidays: [],
            holidaysLate: [],
            holidaysComing: [],
            utilisateur: null,
            /** gestion des absences recurrentes*/
            frequences:[],
            jours:[],
            creneaux:[],

        }

        if(!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password){
            localStorage.bypass_iFrame = true;
            localStorage.user_IDClient = this.props.location.query.idclient;
            localStorage.user_login = this.props.location.query.login;
            localStorage.user_password = this.props.location.query.password;
            localStorage.token = 'iframe'
            localStorage.version = Globals.VERSION

            localStorage.ClientApiURL = Globals.API_URL;
            var parts = window.location.hostname.split('.');
            if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod') {
                localStorage.ClientApiURL = Globals.API_URL_DEV;
            }
        }
    }

    componentDidMount() {

        this.fetchutilisateur();
        this.fetchutilisateurHolidays();
        let arrayCreneaux = [];
        arrayCreneaux['J']='Jour entier';
        arrayCreneaux['M']='Matin';
        arrayCreneaux['A']='Après-midi';
        let arrayJours = [];
        arrayJours[0]='Lundi';
        arrayJours[1]='Mardi';
        arrayJours[2]='Mercredi';
        arrayJours[3]='Jeudi';
        arrayJours[4]='Vendredi';
        arrayJours[5]='Samedi';
        arrayJours[6]='Dimanche';
        this.setState({
            jours:arrayJours,
            creneaux:arrayCreneaux,
        });
    }

    fetchutilisateur() {
        fetch(localStorage.ClientApiURL + '/utilisateurs/' + this.props.params.IDPeople , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    utilisateur: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }


    fetchutilisateurHolidays() {
        fetch(localStorage.ClientApiURL + '/utilisateurs/' + this.props.params.IDPeople + '/holidays' , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                var holidaysLate = lodash.filter(json, (holiday) => {
                    return moment(holiday.DateHEnd) < moment();
                })

                lodash.sortBy(holidaysLate, (holiday) => {
                    return holiday.DateHStart;
                });

                var holidaysComing = lodash.filter(json, (holiday) => {
                    return moment(holiday.DateHEnd) >= moment();
                })

                lodash.sortBy(holidaysComing, (holiday) => {
                    return holiday.DateHStart;
                });

                this.setState({
                    holidays: json,
                    holidaysLate: holidaysLate,
                    holidaysComing: holidaysComing
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    refresh() {
        this.setState({
            holidays: [],
            holidaysLate: [],
            holidaysComing: [],
        })
        this.fetchutilisateurHolidays();
    }

    render() {

        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>

                        <div>
                            <h1>Gestion des absences</h1>
                            {localStorage.bypass_iFrame == "true" ?
                                <Link className='btn btn-default pull-right' to={'/iframe/holidays/'+this.props.params.IDPeople+'/add'}>Ajouter une absence</Link>
                                :
                                <Link className='btn btn-default pull-right' to={'/holidays/'+this.props.params.IDPeople+'/add'}>Ajouter une absence</Link>
                            }
                        </div>

                        <br />

                        <form>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-md-4">Vendeur</label>
                                    <div className="col-md-8">
                                        <p className="form-control-static">{this.state.utilisateur ? this.state.utilisateur.Nom : null}</p>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <br />
                        <br />
                        <br />

                        <h2>À venir</h2>

                        {this.state.holidaysComing.length ?
                            <ListPeopleHolidaysManage
                                holidays={this.state.holidaysComing}
                                arrayJours={this.state.jours}
                                arrayCreneaux={this.state.creneaux}
                                refresh={this.refresh.bind(this)} />

                            : <em>Aucune</em>}

                        <br /><br />

                        <h2>Passées</h2>

                        {this.state.holidaysLate.length ?
                            <ListPeopleHolidaysManage
                                holidays={this.state.holidaysLate}
                                arrayJours={this.state.jours}
                                arrayCreneaux={this.state.creneaux}
                                refresh={this.refresh.bind(this)} />
                            : <em>Aucune</em>}
                    </div>
                </div>
            </div>
        );
    }
}

export default PeopleHolidays;
