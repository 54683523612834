import React, { Component } from 'react';
import {CSVLink} from 'react-csv';
import moment from 'moment';
var lodash = require("lodash")

class CSVLeads extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      datasToDownload: [],
      filename: "",
      gereratingFile: false
    }

    this.refCSVLink = React.createRef();
  }

  /*componentDidMount(){
   this.updateDatasToDownload() 
  }

  componentDidUpdate(prevProps){
    if(prevProps.leads.length != this.props.leads.length || prevProps.type !== this.props.type)
      this.updateDatasToDownload();
  }*/

  extractMarqueModeleFromAffaireDescription(description){
    var vehiculeInteretInfos = null;
    if(description){
      vehiculeInteretInfos = description.match(/(?:marque|marque ):(.*)\n(?:modèle|modèle ):(.*)\n/i);
      if(vehiculeInteretInfos) {
        return [vehiculeInteretInfos[1].trim(), vehiculeInteretInfos[2].trim()]
      }
    }
    return ['', ''] 
  }

  downloadFile(){
    this.setState({
      gereratingFile: true
    }, () => {
     setTimeout(() => {this.updateDatasToDownload()}, 10);
    });
  }

  updateDatasToDownload(){
    console.log("updateDataToDownload");
    switch(this.props.type){
      case "AffairesGagnees":
        this.prepareDatasAffaireToDownload();
        break;
      case "AffairesClotures":
        this.prepareDatasAffairesClotureesToDownload();
        break;
      case "AffairesEncoursBDC":
        this.setState({
          datasToDownload: []
        })
        break;
      default:
        this.prepareDatasToDownload(this.props.type === 'LeadsARedistribuer');
        break;
    }
    console.log("end u")
  }

  prepareDatasToDownload(leadsARedistribuer = false) {
    var datas = [];
    var headers = ['Type Lead','Commercial', 'Date de création', 'Date de relance', 'Site', 'Campagne', 'Famille', 'Sous-Famille', 'Origine'];
    if(localStorage.IsNissan === "true")
      headers.push('Marque', 'Modèle');
    headers.push('Contact', 'Prochaine étape', 'Identifiant Client', 'Tél Privé', 'Tél Pro', 'Tél Mobile', 'Email', 'Email Pro');
    if(!leadsARedistribuer)
      headers.push( 'Intérêt', 'Dernier commentaire');
    datas.push(headers);

    this.props.leads.map((l) => {
      let telPrive = lodash.find(l.Prospect.Telephones, {Type:'Prive'});
      let telPro = lodash.find(l.Prospect.Telephones, {Type:'Professionnel'});
      let telMobile = lodash.find(l.Prospect.Telephones, {Type:'Mobile'});

      var data = [
        l.Affaire.Type,
        l.SuiviPar.Nom,
        moment(l.Affaire.DateHDebut).format('DD/MM/Y HH:mm:ss'),
        moment(l.ActionAFaire_TODO.DateHProchaineEtape).format('DD/MM/Y HH:mm:ss'),
        l.Site.Code,
        l.Campagne ? l.Campagne.Nom : '',
        l.ActionFaite_DO.OrigineAction.Famille,
        l.ActionFaite_DO.OrigineAction.SousFamille,
        l.ActionFaite_DO.OrigineAction.Origine
      ];

      if(localStorage.IsNissan === "true"){
        if(l.Affaire.Description){
          data.push(...this.extractMarqueModeleFromAffaireDescription(l.Affaire.Description))
        } 
        else {
          data.push('', '');
        }
      }

      data.push(l.Prospect.Nom + ' ' + (l.Prospect.Prenom || ""),
        l.ActionAFaire_TODO.Etape ? l.ActionAFaire_TODO.Etape.Description : null,
        l.Prospect.IDProspect,
        telPrive ? '=""'+telPrive.Numero.toString()+'""' : '',
        telPro ? '=""'+telPro.Numero.toString()+'""' : '',
        telMobile ? '=""'+telMobile.Numero.toString()+'""' : '',
        l.Prospect.Mail ? l.Prospect.Mail : '',
        l.Prospect.MailPro ? l.Prospect.MailPro : '',
      );

      if(!leadsARedistribuer){
        data.push(l.ActionAFaire_TODO.NiveauInteret.Niveau);
        data.push(l.Affaire && l.Affaire.CommentaireDernierRapport ? l.Affaire.CommentaireDernierRapport.replace(/(\r\n|\r|\n|\\r\\n|\\n|\\r)/g, " ") : '');
      }
      
      datas.push(data);
    })

    this.setState({
      datasToDownload: datas,
      filename: this.getFileName(),
      gereratingFile: false
    }, () => {
      this.triggerClick();
    });
  }

  prepareDatasAffaireToDownload() {
    var datas = [];
    var headers = ['Type Lead', 'Commercial', 'Date de création', 'Site', 'Campagne', 'Famille', 'Sous-Famille', 'Origine'];
    if(localStorage.IsNissan === "true")
      headers.push('Marque', 'Modèle');
    headers.push('Contact', 'Identifiant Client', 'Tél Privé', 'Tél Pro', 'Tél Mobile', 'Email', 'Email Pro');
    datas.push(headers);

    this.props.leads.map((l) => { 
      let telPrive = lodash.find(l.Prospect.Telephones, {Type:'Prive'});
      let telPro = lodash.find(l.Prospect.Telephones, {Type:'Professionnel'});
      let telMobile = lodash.find(l.Prospect.Telephones, {Type:'Mobile'});
      var data = [
        l.Type,
        l.SuiviPar.Nom,
        moment(l.DateHDebut).format('DD/MM/Y HH:mm:ss'),
        l.Site.Code,
        l.Campagne ? l.Campagne.Nom : '',
        l.OrigineAction ? l.OrigineAction.Famille : '',
        l.OrigineAction ? l.OrigineAction.SousFamille : '',
        l.OrigineAction ? l.OrigineAction.Origine : '',
      ];

      if(localStorage.IsNissan === "true"){
        if(l.Description){
          data.push(...this.extractMarqueModeleFromAffaireDescription(l.Description))
        } 
        else {
          data.push('', '');
        }
      }

      data.push(
        l.Prospect.Nom + ' ' + l.Prospect.Prenom,
        l.Prospect.IDProspect,
        telPrive ? '=""'+telPrive.Numero.toString()+'""' : '',
        telPro ? '=""'+telPro.Numero.toString()+'""' : '',
        telMobile ? '=""'+telMobile.Numero.toString()+'""' : '',
        l.Prospect.Mail ? l.Prospect.Mail : '',
        l.Prospect.MailPro ? l.Prospect.MailPro : ''
      );

      datas.push(data);
    })

    this.setState({
      datasToDownload: datas,
      filename: this.getFileName(),
      gereratingFile: false
    }, () => {
      this.triggerClick();
    });
  }

  prepareDatasAffairesClotureesToDownload() {
    var datas = [];
    var headers = ['Type Lead', 'Commercial', 'Date de création', 'Site', 'Campagne', 'Famille', 'Sous-Famille', 'Origine', 'Étape à date de clôture']; 
    if(localStorage.IsNissan === "true")
      headers.push('Marque', 'Modèle');
    headers.push('Contact', 'Identifiant Client', 'Tél Privé', 'Tél Pro', 'Tél Mobile', 'Email', 'Email Pro', 'Dernier commentaire')
    datas.push(headers)

    this.props.leads.map((l) => {
      let telPrive = lodash.find(l.Prospect.Telephones, {Type:'Prive'});
      let telPro = lodash.find(l.Prospect.Telephones, {Type:'Professionnel'});
      let telMobile = lodash.find(l.Prospect.Telephones, {Type:'Mobile'});
      var data = [
        l.Type,
        l.SuiviPar.Nom,
        moment(l.DateHDebut).format('DD/MM/Y HH:mm:ss'),
        l.Site.Code,
        l.Campagne ? l.Campagne.Nom : '',
        l.OrigineAction ? l.OrigineAction.Famille : '',
        l.OrigineAction ? l.OrigineAction.SousFamille : '',
        l.OrigineAction ? l.OrigineAction.Origine : '',
        l.EtapeDernierRapport ? l.EtapeDernierRapport.Description : '',
      ];

      if(localStorage.IsNissan === "true"){
        if(l.Description){
          data.push(...this.extractMarqueModeleFromAffaireDescription(l.Description))
        } 
        else {
          data.push('', '');
        }
      }

      data.push(
        l.Prospect.Nom + ' ' + l.Prospect.Prenom,
        l.Prospect.IDProspect,
        telPrive ? '=""'+telPrive.Numero.toString()+'""' : '',
        telPro ? '=""'+telPro.Numero.toString()+'""' : '',
        telMobile ? '=""'+telMobile.Numero.toString()+'""' : '',
        l.Prospect.Mail ? l.Prospect.Mail : '',
        l.Prospect.MailPro ? l.Prospect.MailPro : '',
        l.CommentaireDernierRapport ? l.CommentaireDernierRapport.replace(/(\r\n|\r|\n|\\r\\n|\\n|\\r)/g, " ") : '',
      );

      datas.push(data)
    })

    this.setState({
      datasToDownload: datas,
      filename: this.getFileName(),
      gereratingFile: false
    }, () => {
      this.triggerClick();
    });
  }

  getFileName() {
    var name = 'eSeller_Lead_'+this.props.type+'_';
    name += moment().format('DD-MM-YYYY-hh-mm-ss');
    return name+'.csv';
  }

  triggerClick(){
    setTimeout(() => {
      this.refCSVLink.current.link.click()
    }, 500);
  }

  render() {
    if(!this.props.leads.length)
      return null;

    if(this.state.filename) {
      return (
        <CSVLink ref={this.refCSVLink}  data={this.state.datasToDownload} filename={this.state.filename} className="float-right" target="_self" separator={";"} style={{lineHeight: '30px', marginRight: '15px', cursor:'pointer'}}>
          <i className="fa fa-download" aria-hidden="true"></i> Télécharger
        </CSVLink>
      )
    }

    return (
      <a className="float-right" style={{lineHeight: '30px', marginRight: '15px', cursor:'pointer'}} onClick={this.downloadFile.bind(this)}>
        <i className={this.state.gereratingFile ? "fa fa-spinner fa-spin" : "fa fa-download"} aria-hidden="true"></i> Télécharger
      </a>
    );
  }
}

export default CSVLeads;
