import React, { Component } from 'react';

class Loader extends Component {
  render() {

    var styles = {fontSize: 60 };
    if (this.props.styleIcon) {
      styles = this.props.styleIcon;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div style={this.props.style} className="text-center">
            <i className="fa fa-refresh fa-spin fa-3x fa-fw text-purple" style={styles}></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
