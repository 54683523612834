import moment from 'moment';
import React, { Component } from 'react';

import LibAffaire from '../Lib/Affaire';
var lodash = require('lodash');
var Datetime = require('react-datetime');

class FormFinancement extends Component {
    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
            affaire: null,
            typesFinancement: [],
            updated: false,

            fetchingTypesFinancement: false,
            saving: false,
        };
    }

    componentDidMount() {
        this.setState({
            affaire: this.props.affaire
        });
        this.fetchTypesFinancement();
    }

    async fetchTypesFinancement(){
        this.setState({
            fetchingTypesFinancement: true
        });
        let typesFinancement = await LibAffaire.getTypesFinancement();
        this.setState({
            typesFinancement: typesFinancement,
            fetchingTypesFinancement: false
        });
    }

    onChangeValue(event){
        let affaire = this.state.affaire;
        affaire[event.target.name] = event.target.value;

        this.setState({
            affaire: affaire,
            updated: true,
        });
    }

    onChangeTypeFinancement(event){
        let affaire = this.state.affaire;
        if(!affaire.TypeFinancement)
            affaire.TypeFinancement = {};

        let typeFinancement = lodash.find(this.state.typesFinancement, (tf) => tf.IDAffaireTypeFinancement == event.target.value);
        if(typeFinancement)
            affaire.TypeFinancement = typeFinancement;

            this.setState({
            affaire: affaire,
            updated: true
        });
    }

    onChangeDateFinFinancement(value){
        let affaire = this.state.affaire;
        affaire.DateFinFinancement = moment(value).startOf("day").format();
        console.log(affaire.DateFinFinancement)

        this.setState({
            affaire: affaire,
            updated: true
        });
    }

    onAddOrRemoveFinancement(event){
        let affaire = this.state.affaire;
        affaire.Financement = parseInt(event.target.value);
        if(!affaire.Financement) {
            affaire.TypeFinancement = null;
            affaire.NbMoisFinancement = null;
            affaire.MensualiteFinancement = null;
            affaire.KmFinancement = null;
            affaire.DateFinFinancement = null;
        }

        this.setState({
            affaire: affaire,
            updated: true
        });
    }

    save() {
        var affaire = this.state.affaire;
        var description = affaire.Description;
        if(affaire.Financement)
        {
            var descrFinancement = `------ Financement ------
Type de financement : `+(affaire.TypeFinancement ? affaire.TypeFinancement.Libelle : '')+`
Durée (mois) : `+ (affaire.NbMoisFinancement || "") +`
Kilométrage : `+ (affaire.KmFinancement || "") +`
Mensualité : `+ (affaire.MensualiteFinancement || "") +`
Date de fin théorique : `+ (affaire.DateFinFinancement ? moment(affaire.DateFinFinancement).format("DD/MM/YYYY") : "") +`
------`;
            if(description.includes("------ Financement ------"))
                description = description.replace(/------ Financement ------\n[\s\S]+------/g, descrFinancement);
            else 
                description = description + "\n" + descrFinancement;
        }
        else 
        {
            description = description.replace(/\n------ Financement ------\n[\s\S]+------/g, '');
        }
        affaire.Description = description;

        if(this.props.onSave)
            this.props.onSave(affaire);
    }

    canSubmit() {
        return !this.state.saving && this.state.updated;
    }

    render() {
        if(this.state.fetchingTypesFinancement || !this.state.affaire) {
            return (
                <div className='col-md-12'>
                    <div className="center-div" style={{paddingTop:'100px'}}>
                        <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                        <br/><br/>Chargement des informations...
                    </div>
                </div>
            )
        }

        return (
                <div style={{ height: '100%', paddingLeft: '20px', paddingRight:'20px', paddingBottom:'20px'}}>
                    <div className='row'>
                        <div className='form-group' style={{marginBottom: 0}}>
                            <label className='col-md-3 control-label text-right'>
                                Financement
                            </label>
                            <div className="col-md-9">
                                <input type="radio" name='Financement' value={1} id="financementY" defaultChecked={this.state.affaire.Financement == 1} onClick={this.onAddOrRemoveFinancement.bind(this)}/>
                                <label className="label-radio radio-A" htmlFor="financementY">
                                Oui
                                </label>

                                <input type="radio" name='Financement' value={0} id="financementN" defaultChecked={this.state.affaire.Financement == 0} onClick={this.onAddOrRemoveFinancement.bind(this)}/>
                                <label className="label-radio radio-A" htmlFor="financementN" >
                                Non
                                </label>
                            </div>
                        </div>
                    </div>
                    <div style={{opacity: this.state.affaire.Financement ? 1 : 0.3}}>
                    <div className='row'>
                        <div className='form-group' style={{marginBottom: 0}}>
                            <label className='col-md-3 control-label text-right'>
                                Type de financement
                            </label>
                            <div className="col-md-9">
                                <select className='form-control' name="TypeFinancement" style={{width:'auto'}} value={this.state.affaire.TypeFinancement ? this.state.affaire.TypeFinancement.IDAffaireTypeFinancement : ""} onChange={this.onChangeTypeFinancement.bind(this)} disabled={!this.state.affaire.Financement}>
                                    <option value=""></option>
                                    {this.state.typesFinancement.map(tf => {
                                        return <option key={tf.IDAffaireTypeFinancement} value={tf.IDAffaireTypeFinancement}>{tf.Libelle}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group' style={{marginBottom: 0}}>
                            <label className='col-md-3 control-label text-right'>
                                Durée (mois)
                            </label>
                            <div className="col-md-9">
                                <input type="text" name="NbMoisFinancement" pattern="[0-9]*" className='form-control' style={{width:'6em'}} value={this.state.affaire.NbMoisFinancement || ""} onChange={this.onChangeValue.bind(this)} disabled={!this.state.affaire.Financement}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group' style={{marginBottom: 0}}>
                            <label className='col-md-3 control-label text-right'>
                                Kilométrage
                            </label>
                            <div className="col-md-9">
                                <input type="text" name="KmFinancement" pattern="[0-9]*" className='form-control' style={{width:'6em'}} value={this.state.affaire.KmFinancement || ""} onChange={this.onChangeValue.bind(this)} disabled={!this.state.affaire.Financement}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group' style={{marginBottom: 0}}>
                            <label className='col-md-3 control-label text-right'>
                                Mensualité
                            </label>
                            <div className="col-md-9">
                                <input type="text" name="MensualiteFinancement" pattern="[0-9]*" className='form-control' style={{width:'6em'}} value={this.state.affaire.MensualiteFinancement || ""} onChange={this.onChangeValue.bind(this)} disabled={!this.state.affaire.Financement}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group' style={{marginBottom: 0}}>
                            <label className='col-md-3 control-label text-right'>
                                Date de fin théorique
                            </label>
                            <div className="col-md-9">
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:4}}>
                                    <Datetime name="DateFinFinancement" className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} open={false} value={this.state.affaire.DateFinFinancement ? moment(this.state.affaire.DateFinFinancement) : ""} onChange={this.onChangeDateFinFinancement.bind(this)} disabled={!this.state.affaire.Financement}/>
                                    <i className="icon-calendar icons"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="row" style={{marginTop:'30px', marginBottom:'15px'}}>
                        <div className="text-center">
                            {this.state.savingMessage ?
                                <span style={{ marginRight: '15px' }}>
                                    {this.state.savingMessage}
                                </span>
                            :null}
                            <button type="button" className="btn btn-default" disabled={!this.canSubmit()} onClick={() => this.save()}>Valider</button>
                        </div>
                    </div>
                </div>
        );
    }
}

export default FormFinancement;
