import React, { Component } from 'react';
import moment from 'moment'
import { Link } from 'react-router';

class ResumeCampagne extends Component {


  displayStatut(campagne) {
    if (campagne.Statut === 'EnPreparation') {
      return 'En préparation';
    }

    if (campagne.Statut === 'Prete') {
      return 'Prête';
    }

    if (campagne.Statut === 'EnCours') {
      return 'En cours';
    }

    if (campagne.Statut === 'Terminee') {
      return 'Terminée';
    }

    if (campagne.Statut === 'Archivee') {
      return 'Archivée';
    }

    return campagne.Statut;
  }

  render() {
    return (
      <div>
        <div className='col-md-12' style={{ marginBottom: '30px' }}>
          <div className='col-md-3'>
            <u>Nom</u> : {this.props.campagne.Nom}
          </div>
          <div className='col-md-3'>
            <u>Société</u> : {this.props.campagne.Societe.Nom}
          </div>
          <div className='col-md-3'>
            <u>Date de début</u> : {this.props.campagne.DateDebutCampagne ? moment(this.props.campagne.DateDebutCampagne).format('DD/MM/YYYY') : ''}
          </div>
          <div className='col-md-3'>
            <u>Date de fin</u> : {this.props.campagne.DateFinCampagne ? moment(this.props.campagne.DateFinCampagne).format('DD/MM/YYYY') : ''}
          </div>
        </div>

        <div className='col-md-12' style={{ marginBottom: '30px' }}>
          <div className='col-md-3'>
            <u>Origine</u> :&nbsp;
            {this.props.campagne.OrigineAction ? 
              this.props.campagne.OrigineAction.Famille + ' / ' + this.props.campagne.OrigineAction.SousFamille + ' / ' + this.props.campagne.OrigineAction.Origine
            : ''}
          </div>
          <div className='col-md-3'>
            <u>Statut</u> :&nbsp;
              {this.props.editable ?
                <select className='form-control' onChange={this.props.onChangeFilterStatut} value={this.props.campagne.Statut} style={{ width: '115px', display: 'inline-block', verticalAlign: 'middle', lineHeight: '13px' }}>
                  <option value="EnCours">En cours</option>
                  <option value="Terminee">Terminée</option>
                  <option value="Archivee">Archivée</option>
                </select>
              : this.displayStatut(this.props.campagne)}
          </div>

          {!this.props.noDisplayLinkSynthese && (this.props.campagne.Statut == 'EnCours' || this.props.campagne.Statut == 'Terminee' || this.props.campagne.Statut == 'Archivee') ?
            <div className='col-md-3'>
              <Link to={'/campagnes/'+this.props.campagne.IDCampagne+'/synthese'}>Voir la synthèse</Link>
            </div>
          : null}
        </div>
      </div>
    );
  }
}

export default ResumeCampagne;
