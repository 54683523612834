import React, { Component } from 'react';

import Tooltip from "rc-tooltip";

class TableRowCampagneOrigine extends Component {
  render() {

    var overlayContent = this.props.origine ? this.props.origine.Famille + ' / ' + this.props.origine.SousFamille + ' / ' + this.props.origine.Origine : null;

    return (<Tooltip trigger={['hover']} placement='left' overlay={<div>{overlayContent}</div>}>
      <span>{this.props.campagne ? this.props.campagne.Nom : (this.props.origine ? this.props.origine.Famille : null)}</span>
    </Tooltip>)
  }
}

export default TableRowCampagneOrigine;
