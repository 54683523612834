import React, { Component } from 'react';

import SkyLight from 'react-skylight';

class ModalDPOContact extends Component {
    constructor(props){
        super(props);
    }

    show() {
        this.refs.modalDPOContact.show();
    }

    afterClose(){
        if(this.props.onClose)
            this.props.onClose();
    }

    render() {
        return (
            <SkyLight hideOnOverlayClicked ref="modalDPOContact"
                dialogStyles={{ padding:0, width: '900px', height: '500px', top: '40%', zIndex: 9999, background:"#efefef"}}
                titleStyle={{display:'none'}}
                closeButtonStyle={{color:'#eeeeee', backgroundColor:'#333', border:'2px solid #eee', borderRadius:'50%', width:'1.5em', height:'1.5em', textAlign:'center', lineHeight:'1.3em', top:'-1em', right:'-0.8em', fontSize:'16px', margin:'auto', padding:'0px'}}
                afterClose={() => this.afterClose()}>
                <div className="inner-large-content" style={{height:'100%'}}>
                    <iframe style={{width:'100%', height:'100%'}} src={this.props.dpoContactIframeUrl} height="100%" width="100%" frameBorder="0"></iframe>
                </div>
            </SkyLight>
        );
    }
}

export default ModalDPOContact;
