import React, { Component } from 'react';
import SkyLight from 'react-skylight';
import Globals from '../../Globals'
import ModalSearchProspect from '../ModalSearchProspect';
import Tooltip from "rc-tooltip";
var lodash = require('lodash')

class ModalGroupement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adherents: [],
            saving: false,
            message: '',
        };
    }

    componentDidMount(){
        this.setState({
            adherents: this.props.prospect ? this.props.prospect.Adherents : [],
        })
    }

    show() {
        this.refs.modalGroupement.show();
        this.setState({
            message: ''
        })
    }

    hide() {
        this.refs.modalGroupement.hide();
    }

    async save() {
        this.setState({
            saving: true,
            message: <span><i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true"></i> Enregistrement en cours</span>
        })
        var adherents = lodash.map(this.state.adherents, (a) => {
            a.NbParts = Number(a.NbParts);
            if(a.NbParts > 0)
                return a;
        });
        await fetch(localStorage.ClientApiURL + '/prospects/'+this.props.prospect.IDProspect+'/groupement', {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
            body: JSON.stringify(adherents)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            this.setState({
                saving: false,
                message: <span className="text-success"><i className="fa fa-check-circle" aria-hidden="true"></i> Groupement enregistré</span>
            }, () => setTimeout(() => this.refs.modalGroupement.hide(), 1000));
            if(this.props.onGroupementSaved)
                this.props.onGroupementSaved(this.state.adherents);
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                saving: false,
                message: <span><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Erreur lors de l'enregistrement</span>
            })
        });
    }

    canSubmit() {
        return !this.state.saving;
    }

    changeParticipationAdherent(IDClient, value){
        value = value.replace(",", ".").replace(/[^0-9.]/g, '');
        var adherents = this.state.adherents;
        var idx = lodash.findIndex(adherents, {IDClient: IDClient});
        if(idx !== null){
            adherents[idx].NbParts = value
        }
        this.setState({
            adherents: adherents
        })
    }

    addAdherent(prospect){
        var adherents = this.state.adherents;
        adherents.push({
            IDClient: prospect.IDProspect,
            Nom: prospect.Nom,
            NbParts: 0
        })
        this.setState({
            adherents: adherents
        });
        this.refs.modalAddAdherent.hide();
    }

    deleteAdherent(IDClient){
        if(window.confirm("Supprimer cet adhérent ?")){
            var adherents = lodash.filter(this.state.adherents, (a) => {return a.IDClient != IDClient});
            this.setState({
                adherents:adherents
            })
        }
    }

    openModalAddAdherent(){
        this.refs.modalAddAdherent.show();
    }

    render() {
        return (
            <SkyLight hideOnOverlayClicked ref="modalGroupement"
                dialogStyles={{ padding: 0, width: '50%', maxWidth: '800px', minWidth: '300px', height: 'auto', minHeight:'500px', left: '0', right: '0', top: '10%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                title="Liste des adhérents"
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}
                >
                <div className="inner-large-content" style={{ height: '100%', padding: '20px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <b>Groupement : {this.props.prospect.Civilite ? this.props.prospect.Civilite+' ' : ''}{this.props.prospect.Nom}</b>
                        </div>
                        <div className="col-md-6 text-right">   
                            <button type="button" className="btn btn-default btn-xs" onClick={this.openModalAddAdherent.bind(this)}>Ajouter un adhérent</button>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table-striped light-table">
                                <thead>
                                    <tr>
                                        <th>Ref adhérent</th>
                                        <th>Nom adhérent</th>
                                        <th>Nb parts</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.adherents.map((a) => {
                                        return (<tr key={a.IDClient}>
                                            <td>{a.IDClient}</td>
                                            <td>
                                                <a href={"/prospect/"+a.IDClient}>{a.Nom}</a>
                                            </td>
                                            <td>
                                                <div className="form-group" style={{marginBottom:0}}>
                                                    <input type='text' className='form-control' style={{width:'75px'}} value={a.NbParts} onChange={(e) => this.changeParticipationAdherent(a.IDClient, e.target.value)}/>
                                                </div>
                                            </td>
                                            <td className='action'>
                                                <Tooltip placement="top" overlayStyle={{zIndex:10000}} trigger={['hover']} overlay="Supprimer">
                                                    <a className="picto picto-delete" onClick={() => this.deleteAdherent(a.IDClient)}></a>
                                                </Tooltip>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br/>
                    <div className="text-right">
                        {
                            this.state.message ? 
                                <div style={{padding:5}}>{this.state.message}</div>
                            : <br/>
                        }
                        <button type="button" className="btn btn-default" disabled={!this.canSubmit()} onClick={() => this.save()}>
                            Enregistrer
                        </button>
                    </div>
                </div>
                <ModalSearchProspect ref="modalAddAdherent" onProspectSelected={(prospect) => this.addAdherent(prospect)} excludeGroupements={true} excludeIDs={lodash.map(this.state.adherents, 'IDClient') + [this.props.prospect.IDProspect]}/>
            </SkyLight>
        );
    }
}

export default ModalGroupement;
