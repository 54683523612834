import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../Components/Loader';
import Globals from '../Globals';

import { Link } from 'react-router';

import Scheduler from '../Pages/Scheduler.js'
var lodash = require('lodash')

class SchedulerIframe extends Component {

  constructor(props) {

    super(props);

    if(!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password || !localStorage.user_profil){
      localStorage.bypass_iFrame = true;
      localStorage.user_IDClient = this.props.location.query.idclient;
      localStorage.user_login = this.props.location.query.login;
      localStorage.user_password = this.props.location.query.password;
      localStorage.user_IDUtilisateur = this.props.location.query.IDPeople;

      localStorage.ClientApiURL = Globals.API_URL;
      var parts = window.location.hostname.split('.');
      if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod') {
        localStorage.ClientApiURL = Globals.API_URL_DEV;
      }
    }

  }

  render() {

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <br /><br/>
            <Scheduler IDPlaque={this.props.location.query.IDPlaque} IDSite={this.props.location.query.IDSite} IDPeople={this.props.location.query.IDPeople} onSelectCalendar={null} viewEventNoTime={true} originIframe={this.props.location.query.origin} />

          </div>
        </div>
      </div>
    );
  }
}

export default SchedulerIframe;
