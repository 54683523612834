import React, { Component } from 'react';

import moment from 'moment'

class ListEssais extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  render() {

    if (!this.props.alertes) {
      return null;
    }

    var alertes = this.props.alertes;
    alertes.sort(function(a, b) {
      if (moment(a.DateDebut) > moment(b.DateDebut))
        return -1;
      return 1;
    });

    return (
      <div>
        {alertes.length ?
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <thead>
              <tr>
                <th>Début</th>
                <th>Fin</th>
                <th>Critères de recherche</th>
              </tr>
            </thead>
            <tbody>
              {alertes.map((alerte, j) => {
                return <tr key={j}>
                  <td>{moment(alerte.DateDebut).format('DD/MM/Y')}</td>
                  <td>{moment(alerte.DateFin).format('DD/MM/Y')}</td>
                  <td>
                    {alerte.CritereRecherche.Marque ? alerte.CritereRecherche.Marque + ';' : null}
                    {alerte.CritereRecherche.Modele ? alerte.CritereRecherche.Modele + ';' : null}
                    {alerte.CritereRecherche.Version ? alerte.CritereRecherche.Version + ';' : null}
                    {alerte.CritereRecherche.PrixMini ? alerte.CritereRecherche.PrixMini + ' mini;' : null}
                    {alerte.CritereRecherche.PrixMaxi ? alerte.CritereRecherche.PrixMaxi + ' maxi;' : null}
                    {alerte.CritereRecherche.KmMaxi ? alerte.CritereRecherche.KmMaxi + ' maxi;' : null}
                    {alerte.CritereRecherche.Age ? alerte.CritereRecherche.Age + ';' : null}
                    {alerte.CritereRecherche.Energie ? alerte.CritereRecherche.Energie + ';' : null}
                    {alerte.CritereRecherche.Transmission ? alerte.CritereRecherche.Transmission + ';' : null}
                    {alerte.CritereRecherche.NbPortes ? alerte.CritereRecherche.NbPortes + ' portes;' : null}
                    {alerte.CritereRecherche.Occasion ? 'Occasion;' : null}
                    {alerte.CritereRecherche.Demo ? 'Direction;' : null}
                    {alerte.CritereRecherche.Location ? 'Location;' : null}
                    {alerte.CritereRecherche.Marchand ? 'Marchand;' : null}
                    {alerte.CritereRecherche.PremiereMain ? 'Première main;' : null}
                    {alerte.CritereRecherche.Cylindree ? alerte.CritereRecherche.Cylindree + ';' : null}
                    {alerte.CritereRecherche.Couchage ? alerte.CritereRecherche.Couchage + ' places couchage;' : null}
                    {alerte.CritereRecherche.ListeTypeLit ? alerte.CritereRecherche.ListeTypeLit + ';' : null}
                    {alerte.CritereRecherche.Puissance ? alerte.CritereRecherche.Puissance + ';' : null}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        : null}
      </div>
    );
  }
}

export default ListEssais;
