import React, { Component } from 'react';

import SkyLight from 'react-skylight';
import Tooltip from "rc-tooltip";

class WelcomeProMail extends Component {

  makeLinksClickable(text) {
    var exp = /(\s(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(exp,"<a href='$1' target='_blank'>$1</a>"); 
  }

  render() {
    if(!this.props.rapport.ActionFaite_DO.ContenuMailClient){
      return null;
    }
    return (<div
      style={!this.props.rapport.Description && !this.props.rapport.ActionFaite_DO.Commentaire ? { display: 'inline-block', fontSize: '14px' } : { display: 'inline-block', marginLeft: '5px', fontSize: '14px' }}>

      <Tooltip trigger={['hover']} overlay={<div>Voir le mail reçu</div>}>
        <i className="fa fa-envelope" aria-hidden="true" onClick={ev => this.refs['WelcomeProMail-'+this.props.rapport.IDLead].show()}></i>
      </Tooltip>

      <SkyLight className="skylight-html" hideOnOverlayClicked ref={'WelcomeProMail-'+this.props.rapport.IDLead} dialogStyles={{ width: '630px', left: '0', right : '0', 'margin' : 'auto', height: '90%', top: '5%', marginTop: '0%', overflowY: 'scroll', zIndex: 9999}}>
        <div style={this.props.rapport.ActionFaite_DO.ContenuMailClient.indexOf("</html>") !== -1 ? { width : '600px' }: { width : '600px', textAlign: 'left', whiteSpace : 'pre-line' } }
        dangerouslySetInnerHTML={{__html: this.makeLinksClickable(this.props.rapport.ActionFaite_DO.ContenuMailClient)}} />
      </SkyLight>
    </div>);
  }
}

export default WelcomeProMail;
