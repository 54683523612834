import React, { Component } from 'react';

import Globals from '../Globals';

import { Link } from 'react-router';
var lodash = require('lodash')

class ChooseSocieteSiteVendeur extends Component {

  constructor(props) {
    super(props);

    this.state = {
      societes: [],
      sites: [],
      utilisateurs: [],

      viewSelectSociete: props.viewSelectSociete,
      viewSelectSite: true,
      viewSelectVendeur: true,

      currentIDPlaque: null,
      currentIDSite: null,
      currentIDUtilisateur: null
    };
  }

  componentWillMount() {
    this.fetchSite();

    if (this.props.IDPlaque && this.props.IDSite) {
      this.fetchutilisateurs();
    }

    if (this.props.IDPeople) {
      this.setState({
        currentIDUtilisateur: this.props.IDPeople
      })
    }
  }

  fetchSite() {
    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var societes = [];
      var sites = [];

      json.map((plaque) => {
        return societes[plaque.IDPlaque] = plaque.Nom;
      })

      json.map((plaque) => {
        sites[plaque.IDPlaque] = [];
        return plaque.Sites.map((site) => {
          if (site.Est_eSellerLeadActif === true && (site.EstVO === true || site.EstVN === true)) {
            sites[site.Plaque.IDPlaque][site.IDSite] = site.Libelle ? site.Libelle : site.Nom;
          }
          return site;
        })
      })

      this.setState({
        societes: societes,
        sites: sites,
      });

    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleChangeSociete(event) {
    this.setState({
      currentIDPlaque: event.target.value,
      currentIDSite: null,
      utilisateurs: [],
      currentIDUtilisateur: null
    }, () => {
      this.props.onSelectSociete(this.state.currentIDPlaque)
    })
  }

  handleChangeSite(event) {
    this.setState({
      currentIDSite: event.target.value,
      utilisateurs: [],
      currentIDUtilisateur: null
    },
    () => {
      this.fetchutilisateurs();
      this.props.onSelectSite(this.state.currentIDSite)
    })
  }

  handleChangeVendeur(event) {

    var value = event.target.value;

    if (!parseInt(value, 10)) {
      value = null;
    }

    this.setState({
      currentIDUtilisateur: value
    }, () => {
      this.props.onSelectVendeur(this.state.currentIDUtilisateur)
    });
  }

  fetchutilisateurs() {
    if(this.props.utilisateurs) {
      this.loadUtilisateursFromProp();
      return;
    }
    
    fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + (this.props.IDSite ? this.props.IDSite : this.state.currentIDSite), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var utilisateurs = lodash.orderBy(json, 'Nom');

      this.setState({
        utilisateurs: utilisateurs,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  loadUtilisateursFromProp() {
    this.setState({
      utilisateurs: this.props.utilisateurs
    });
  }

  render() {

    if (!this.state.societes.length && !this.state.sites.length) {
      return <div />;
    }

    return (
      <div className='container-fluid'>

        <div className='row'>
            <div className='col-md-4'>
              <div className="form-group">
                <label className="col-lg-3 control-label" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Société</label>
                <div className="col-lg-9">
                  {this.props.viewSelectSociete ?
                    <select className="form-control" onChange={this.handleChangeSociete.bind(this)} id="societe" name="societe">
                      <option></option>
                      {this.state.societes.map((societe, id) => {
                        return this.state.sites[id].length ? <option key={id} value={id}>{ societe }</option> : null;
                      })}
                    </select>
                  :
                    <p className="form-control-static">
                      {this.props.IDPlaque && this.state.societes.length ?
                          this.state.societes[this.props.IDPlaque]
                      : null}
                    </p>
                  }

                </div>
              </div>
            </div>

            <div className='col-md-4'>
              <div className="form-group">
                <label className="col-lg-3 control-label" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Site</label>
                <div className="col-lg-9">
                {this.props.viewSelectSite && this.state.currentIDPlaque ?
                  <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="site" name="site">
                    <option></option>
                    {this.state.sites[this.state.currentIDPlaque].map((site, id) => {
                      return <option key={id} value={id}>{site}</option>;
                    })}
                  </select>
                :
                  <p className="form-control-static">
                    {this.props.IDPlaque && this.props.IDSite && this.state.sites.length ?
                        this.state.sites[this.props.IDPlaque][this.props.IDSite]
                    : null}
                  </p>
                }
                </div>
              </div>
            </div>

            <div className={'col-md-4' + (this.props.IDPeople ? ' hide' : '')}>
              <div className="form-group">
                <label className="col-lg-3 control-label" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Vendeur</label>
                <div className="col-lg-9">
                  {this.state.currentIDSite || this.props.IDSite ?
                    <select className="form-control" onChange={this.handleChangeVendeur.bind(this)} value={this.state.currentIDUtilisateur ? this.state.currentIDUtilisateur : 0} id="user" name="user">
                      <option value='0'>Tous</option>
                      {this.state.utilisateurs.map((utilisateur, i) => {
                        if (this.props.IDPlaque && this.props.IDSite) {
                          if (this.props.typeLead === 'VN' && !utilisateur.EstTypeVN)
                            return null;
                          if (this.props.typeLead === 'VO' && !utilisateur.EstTypeVO)
                            return null;
                        }
                        return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>;
                      })}
                    </select>
                  : null}
                </div>
              </div>
            </div>
        </div>

        {this.props.IDPeople && this.props.NomPeople ?
          <div className="row">
            <div className='col-md-4'>
              <div className="form-group">
                <label className="col-lg-3 control-label" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Vendeur</label>
                <div className="col-lg-9"><p className="form-control-static">{this.props.NomPeople}</p></div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="form-group">
                {localStorage.bypass_iFrame == "true" ?
                  <Link className='col-lg-12 btn-default form-group-static text-center' to={'/iframe/holidays/'+this.props.IDPeople}>Gérer les absences du vendeur</Link>
                  : <Link className='col-lg-12 btn-default form-group-static text-center' to={'/holidays/'+this.props.IDPeople}>Gérer les absences du vendeur</Link>
                }
              </div>
            </div>
          </div>
        : null}

        {!this.props.IDPeople && this.state.currentIDUtilisateur && this.props.Component ?
            <div className='row'>
              <div className='col-md-4' style={{ marginLeft : '67%'}}>
                {localStorage.bypass_iFrame == "true" ?
                  <Link className='col-lg-12 btn-default form-group-static text-center' to={'/iframe/holidays/'+this.state.currentIDUtilisateur}>Gérer les absences du vendeur</Link>
                  : <Link className='col-lg-12 btn-default form-group-static text-center' to={'/holidays/'+this.state.currentIDUtilisateur}>Gérer les absences du vendeur</Link>
                }
              </div>
            </div>
            : null
        }
      </div>
    );
  }
}

export default ChooseSocieteSiteVendeur;
