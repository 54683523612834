import React, { Component } from 'react';
import Globals from '../Globals';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterInteret extends Component {

  constructor(props) {
    super(props);

    this.state = {
      //interets_eseller: [],
      interets: [],
      selected: [],
    }
  }

  componentDidMount() {

    /*fetch(localStorage.ClientApiURL + '/NiveauInterets', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        interets_eseller: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });*/

    this.loadInterets();

    if(this.props.selected){
      this.setState({
        selected: this.props.selected
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.interets !== prevProps.interets) {
      this.loadInterets();
    }
  }

  loadInterets() {
    var interets = [];

    lodash.forEach(this.props.interets, function(value){
      if (value) {
        interets.push(value);
      }
    });

    interets.sort(function (a, b) {
      return b.niveau-a.niveau;
    });

    this.setState({
      interets: interets,
    });
  }

  onChange(event, interet) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(interet)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== interet;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('interets', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forEach(this.props.interets, function(value){
        if (value) {
          selected.push(value.name);
        }
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('interets', this.state.selected);
    });
  }

  isChecked(interet) {
    if (this.state.selected.length === 0) {
      return false;
    }

    return this.state.selected.indexOf(interet) > -1 ? true : false;
  }


  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  render() {

    var interets = null;

    if (this.state.interets.length) {
      interets = this.state.interets.map((interet, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={this.removeWhiteSpace(interet.name)} checked={this.isChecked(interet.name)} onChange={(event) => { this.onChange(event, interet.name); }} />
          <label className="label-box" htmlFor={this.removeWhiteSpace(interet.name)}>

            <span className={'pastille round  lvl-' + interet.niveau}>{interet.niveau}</span> &nbsp;
            {interet.name}
            &nbsp;<span className="count">({interet.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par niveau d'intérêt" allSelected={this.state.selected.length === this.state.interets.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {interets}
      </PanelFilter>
    );
  }
}

export default FilterInteret;
