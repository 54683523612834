import Globals from '../Globals';

const LibUtilisateurs = {

  /**
   * Récupère les utilisateurs managés
   * @param IDPeople int IDPeople de l'utilisateur duquel on récupère les utilisateurs managés
   * @param IDSite int Filtrage par site (facultatif)
   * @return array(Utilisateur) https://api.e-seller.selectup.com/Help/ResourceModel?modelName=Utilisateur
   */
  async getUtilisateursManages(IDPeople, IDSite = null) {
    try {
      var url = localStorage.ClientApiURL + '/utilisateurs/' + IDPeople + "/manage";
      if(IDSite) {
        url += "?id_site="+IDSite;
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  },

  /**
   * Récupère les sites de l'utilisateur
   * @param IDPeople int IDPeople de l'utilisateur duquel on récupère les utilisateurs managés
   * @return array(Site) https://api.e-seller.selectup.com/Help/ResourceModel?modelName=Site
   */
  async getSites(IDPeople) {
    try {
      var url = localStorage.ClientApiURL + '/utilisateurs/' + IDPeople + "/sites";
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  },
}

export default LibUtilisateurs;