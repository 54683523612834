import Globals from '../Globals';

const LibDashboard = {

  async getStats() {
    try {
      const response = await fetch(localStorage.ClientApiURL+'/dashBoardLeads', {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  },

  async countNouveauxLeadsParOrigine() {
    try {
      const response = await fetch(localStorage.ClientApiURL+'/dashBoard/countNouveauxLeadsParOrigine', {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  },

  async countActionsParEtat() {
    try {
      const response = await fetch(localStorage.ClientApiURL+'/dashBoard/countActionsParEtat', {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  }
}

export default LibDashboard;