import React, { Component } from 'react';

import Globals from '../Globals';

import moment from 'moment';

import $ from 'jquery';
import ChooseSocieteSiteVendeur from '../Components/ChooseSocieteSiteVendeur';

import fullCalendar from 'fullcalendar-scheduler';

import '../../node_modules/fullcalendar/dist/locale/fr';

import '../../node_modules/fullcalendar/dist/fullcalendar.css';
import '../../node_modules/fullcalendar-scheduler/dist/scheduler.css';
var lodash = require('lodash')

class Scheduler extends Component {
  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      vendeurs: [],
      plaque: null,
      site: null,
      events: [],
      IDPlaque: this.props.IDPlaque,
      IDSite: this.props.IDSite,
      selectedVendeur: null,
      date: moment(),
    }
  }

  componentDidMount() {
    if (this.props.IDPlaque && this.props.IDSite)
      this.fetchVendeurs();

    if (this.props.IDPeople) {
     this.setState({
       selectedVendeur: this.props.IDPeople
     })
    }
  }

  loadCalendar() {

    var originIframe = this.props.originIframe ? this.props.originIframe : null;
    var selectAction = this.selectAction.bind(this);
    var enableSelectAction = this.props.IDPlaque && this.props.IDSite;
    var typeView = this.state.selectedVendeur ? 'agendaWeek' : 'agendaDay';
    if (this.props.IDPeople) {
      typeView = 'month';
    }

    $('#calendar').fullCalendar({
      height: 'auto',
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
			defaultView: typeView,
			defaultDate: moment().format('YYYY-MM-DD'),
			editable: false,
			selectable: true,
			eventLimit: true, // allow "more" link when too many events
      minTime: "08:00:00",
      maxTime: "20:00:00",
      allDaySlot: this.props.IDPeople ? true : false,
      locale: 'fr',
			header: {
				left: 'prev,next today',
				center: 'title',
				right: this.state.selectedVendeur ? 'agendaDay agendaWeek month' : 'agendaDay'
			},
			resources: this.state.selectedVendeur ? null : this.state.vendeurs,
			events: this.state.events,

      viewRender: (view, element) => {

        if (view.start.format('YYYY-MM-DD') !== this.state.date.format('YYYY-MM-DD')) {
          this.setState({
            events: [],
            date: view.start,
          }, () => {
            this.loadEvents();
          });
        }
      },
      select: function(start, end, jsEvent, view, resource) {
        if (view.type === 'month') {
          $('#calendar').fullCalendar('changeView', 'agendaDay');
          $('#calendar').fullCalendar('gotoDate', start);
        }
        else {
          if (enableSelectAction)
            selectAction(start, end, jsEvent, view, resource);
        }
			},
      eventClick: function(calEvent, jsEvent, view, resource) {
        if (calEvent.id[0] == 'H') { // type congés
          return;
        }

        if (originIframe) {
          var prospectId = calEvent.id.split("_")[0];
          window.parent.window.postMessage(prospectId, originIframe)
        }
      }
		});
  }

  selectAction(start, end, jsEvent, view, resource) {
    this.props.onSelectCalendar(start, end, resource ? resource.id : this.state.selectedVendeur)
  }

  fetchVendeurs() {
    if(this.props.vendeurs) {
      this.loadVendeursFromProp();
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + this.state.IDSite , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var vendeurs = [];
      json.map((vendeur) => {

        if (this.props.IDPlaque && this.props.IDSite) {
          if (this.props.typeLead === 'VN' && !vendeur.EstTypeVN)
            return null;
          if (this.props.typeLead === 'VO' && !vendeur.EstTypeVO)
            return null;
        }

        if (vendeur.EstTypeConseiller && !vendeur.EstTypeVN && !vendeur.EstTypeVO) {
          return null;
        }

        vendeurs.push({
          id: vendeur.IDUtilisateur,
          title: vendeur.Nom,
        })
        return vendeur;
      });

      vendeurs = lodash.orderBy(vendeurs, 'title');

      this.setState({
        vendeurs: vendeurs,
      }, () => {
        $("#calendar").fullCalendar('destroy');
        this.loadCalendar();
        this.loadEvents();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  loadVendeursFromProp() {
    var vendeurs = this.props.vendeurs.map((vendeur) => {
      return {
        id: vendeur.IDUtilisateur,
        title: vendeur.Nom,
      }
    });

    this.setState({
      vendeurs: vendeurs
    }, () => {
      $("#calendar").fullCalendar('destroy');
      this.loadCalendar();
      this.loadEvents();
    });
  }

  loadEvents() {

    $("#calendar").fullCalendar('removeEvents');

    if (this.state.selectedVendeur) {
      this.setState({
        events: [],
      }, () => {
        this.loadEventsVendeur(this.state.selectedVendeur);
        this.loadHolidaysVendeur(this.state.selectedVendeur)
      });
      return;
    }

    this.setState({
      events: [],
    }, () => {
      this.state.vendeurs.map((vendeur) => {
        this.loadEventsVendeur(vendeur.id);
        this.loadHolidaysVendeur(vendeur.id);
        return vendeur;
      });
    });
  }

  loadEventsVendeur(IDUtilisateur) {

    var typeView = $('#calendar').fullCalendar('getView');

    if (!typeView.start || !typeView.end) {
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + IDUtilisateur + '/rendez-vous?date_debut=' + typeView.start.format('Y-MM-DD') + '&date_fin=' + typeView.end.format('Y-MM-DD') + '&rdv=' + !this.props.viewEventNoTime  , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var events = [];

      json.map((e, i) => {

        var start = moment(e.ActionAFaire_TODO.DateHRelance);
        var end = null;

        if (start.get('hours') == 0 && start.get('minutes') == 0) {
          start = start.format('YYYY-MM-DD');
          end = start;
        }
        else {
          end = (e.ActionAFaire_TODO.DateHFinRelance) ? moment(e.ActionAFaire_TODO.DateHFinRelance) : moment(e.ActionAFaire_TODO.DateHRelance).add(60, 'minutes');
        }

        var event = {
          id: e.IDLead,
          resourceId: e.SuiviPar.IDUtilisateur,
          start: start,
          end: end,
          title: (e.Affaire && e.Affaire.OrigineAction ? e.Affaire.Type + ' - ' : '') + e.Prospect.Nom + ' ' + (e.Prospect.Prenom ? e.Prospect.Prenom : '') + (e.ActionAFaire_TODO.Etape ? (' - ' + e.ActionAFaire_TODO.Etape.Description) : ''),
        }
         if (!this.props.originIframe){
          event.url='/prospect/'+e.Prospect.IDProspect;
         }
        if (e.Affaire && e.Affaire.OrigineAction && e.Affaire.Type) {
          event.color = e.Affaire.Type === 'VN' ? '#9A0059' : '#3E4955'
        }

        events.push(event);

        return e;
      })

      events = this.state.events.concat(events);

      events = lodash.uniqBy(events, (e) => {
        return e.id;
      })

      this.setState({
        events: events,
      }, () => {
        $("#calendar").fullCalendar('removeEvents');
        $('#calendar').fullCalendar('addEventSource', this.state.events)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  loadHolidaysVendeur(IDUtilisateur) {

    var typeView = $('#calendar').fullCalendar('getView');

    if (!typeView.start || !typeView.end) {
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + IDUtilisateur  +'/holidays?date_debut=' + typeView.start.format('Y-MM-DD') + '&date_fin=' + typeView.end.format('Y-MM-DD'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var events = [];

      json.map((e, i) => {

          /** Si l'absence est récurrente*/
          if(e.EstRecurrent){
              let hstart =e.Creneau==='A'? '12' : '08';
              let hend =e.Creneau==='M'? '12' : '18';
              let dateParcours= moment(typeView.start.format('YYYY-MM-DD'));
              let dateEnd =moment(typeView.start.format('YYYY-MM-DD'));
              dateEnd.set('hours',hend);
              dateParcours.set('hour', hstart);

              while(dateParcours < moment(typeView.end.format('YYYY-MM-DD'))){

                  if(dateParcours>moment(e.DateHStart) && dateParcours<moment(e.DateHEnd)){

                      /** Gestion des fréquences */
                      let week = e.Frequence.IDPeopleHolidayFrequence ===1; /** hebdomadaire*/
                      week = week || (e.Frequence.IDPeopleHolidayFrequence ===2 && dateParcours.isoWeek()%2===0); /** semaine paire*/
                      week = week || (e.Frequence.IDPeopleHolidayFrequence ===3 && dateParcours.isoWeek()%2!==0) /** semaine impaire*/

                      if(dateParcours.day() === e.Jour+1 && week ){
                          let event = {
                              id: 'H' + e.IDPeopleHoliday+'-'+dateParcours.format('YYYY-MM-DD'),
                              resourceId: e.IDPeople,
                              start: moment(dateParcours),
                              end:moment(dateEnd),
                              title: e.Type.Libelle,
                              color: 'orange',
                          }
                          events.push(event);
                      }}
                  dateEnd= dateEnd.add(1,'days');
                  dateParcours = dateParcours.add(1, 'days');
              }

        } else {
            var event = {
                id: 'H' + e.IDPeopleHoliday,
                resourceId: e.IDPeople,
                start: moment(e.DateHStart),
                end: moment(e.DateHEnd),
                title: e.Type.Libelle,
                color: 'red',
            }
            events.push(event);
        }
        return e;
      })

      events = this.state.events.concat(events);

      events = lodash.uniqBy(events, (e) => {
        return e.id;
      })

      this.setState({
        events: events,
      }, () => {
        $("#calendar").fullCalendar('removeEvents');
        $('#calendar').fullCalendar('addEventSource', this.state.events)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onSelectVendeur(IDUtilisateur) {
    this.setState({
      selectedVendeur: IDUtilisateur
    }, () => {
      $("#calendar").fullCalendar('destroy');
      this.loadCalendar();
      this.loadEvents();
    });
  }

  onSelectSociete(IDPlaque) {
    this.setState({
      IDPlaque: IDPlaque
    });
  }

  onSelectSite(IDSite) {
    this.setState({
      IDSite: IDSite
    }, () => {
      this.fetchVendeurs();
    });
  }

  render() {

    return (

      <div className='container-fluid'>

        {this.props.IDPlaque && this.props.IDSite ?

          <ChooseSocieteSiteVendeur
              typeLead={this.props.typeLead} IDPlaque={this.props.IDPlaque}
              IDSite={this.props.IDSite} viewSelectSociete={false}
              viewSelectSite={false}
              onSelectVendeur={this.onSelectVendeur.bind(this)}
              IDPeople={this.props.IDPeople}
              NomPeople={this.props.NomPeople}
              Component={this.props.Component}
              utilisateurs={this.props.vendeurs}
          />
        :
          <ChooseSocieteSiteVendeur
              typeLead={this.props.typeLead}
              viewSelectSociete={true} viewSelectSite={true}
              onSelectVendeur={this.onSelectVendeur.bind(this)}
              onSelectSociete={this.onSelectSociete.bind(this)}
              onSelectSite={this.onSelectSite.bind(this)}
              Component={this.props.Component}
              utilisateurs={this.props.vendeurs}
          />

        }

        <hr />
        <br />

        <div className='row'>
          <div className='col-md-12'>
            <div className={!this.state.IDPlaque || !this.state.IDSite ? 'hide' : ''}>
              <div id='calendar'></div>
            </div>
          </div>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

export default Scheduler;
