import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterEtape extends Component {

  constructor(props) {
    super(props);

    this.state = {
      etapes: [],
      selected: [],
    }
  }

  componentWillMount() {
    this.loadEtapes();
  }

  componentDidMount() {
    if(this.props.selected){
      this.setState({
        selected: this.props.selected
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.etapes !== prevProps.etapes) {
      this.loadEtapes();
    }
  }

  loadEtapes() {
    var etapes = [];

    lodash.forIn(this.props.etapes, function(value, key){
      etapes.push({name: key, count: value});
    });

    let i = 0
    let tabEtapes=[]
    while(i<etapes.length){

      switch(etapes[i].name){
        case "":
            tabEtapes[0]=etapes[i]
          break;

        case "A qualifier":
            tabEtapes[1]=etapes[i]
          break;

        case "Découverte":
            tabEtapes[2]=etapes[i]
          break;

        case "Essai":
            tabEtapes[3]=etapes[i]
          break;

        case "Offre":
            tabEtapes[4]=etapes[i]
          break;

        case "Négociation":
            tabEtapes[5]=etapes[i]
          break;

        case "Commande":
            tabEtapes[6]=etapes[i]
          break;
      }
      i++;
    }
    
    //etapes = lodash.sortBy(etapes, ['name']);

    this.setState({
      etapes: tabEtapes,
    });
  }

  onChange(event, etape) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(etape)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== etape;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('etapes', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.etapes, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('etapes', this.state.selected);
    });
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  isChecked(etape) {
    if (this.state.selected.length === 0) {
      return false;
    }

    return this.state.selected.indexOf(etape) > -1 ? true : false
  }


  render() {

    var etapes = null;

    if (this.state.etapes.length) {
      etapes = this.state.etapes.map((etape, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={this.removeWhiteSpace("etape_"+etape.name)} checked={this.isChecked(etape.name)} onChange={(event) => { this.onChange(event, etape.name); }} />
          <label className="label-box" htmlFor={this.removeWhiteSpace("etape_"+etape.name)}>
            {etape.name}
            &nbsp;<span className="count">({etape.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par étape" allSelected={this.state.selected.length === this.state.etapes.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {etapes}
      </PanelFilter>
    );
  }
}

export default FilterEtape;
