import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Not found</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
