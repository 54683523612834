import React, { Component } from 'react';
import moment from 'moment';

class NewsCard extends Component {

    render() {
        return (
            <div className="panel panel-default news-card" onClick={() => this.props.handleClick(this.props.news.iIDNews)}>
                <div className="panel-heading">
                    {this.props.unread ? <i className="fa fa-circle unread"></i> : null}
                    {this.props.news.sTitre}
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-xs-10 text-left" dangerouslySetInnerHTML={{__html:this.props.news.sMessageNews.replace(/<.+?>/g, "").substr(0,200)+"[ . . . ]"}}></div>
                        <div className="col-xs-2 text-right date">
                            {moment(this.props.news.dDebutValidation).format("DD/MM/YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsCard;
