import React, { Component } from 'react';

import moment from 'moment'

class TableRowDateRelance extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }
  
  render() {

    if (!this.props.date)
      return null;

    const date = moment(this.props.date);

    var color = "";
    if(this.props.colorize) {
      const now = moment();
      if (date.isSame(now, "day")) {
        color = "green";
      }
      else if (date.diff(now, "second") < 0) {
        color = "red";
      }
    }

    return (<span style={{color: color}}>
      {date.format('HHmm') !== '0000' ?
        date.format('DD/MM/Y HH:mm')
      : date.format('DD/MM/Y')}
      </span>)
  }
}

export default TableRowDateRelance;
