import React, {Component} from 'react';

import moment from 'moment'

import InputTime from '../Components/InputTime';
import InputDate from '../Components/InputDate';
import { isMobile } from 'react-device-detect';

class FormRapportAtelier extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      nonJoignable: false,
      devisEnvoye: null,
      demandeTraitee: null,
      rdvPris: null,

      dateRdv: null,
      commentaire: '',
      dateRelance: moment(new Date()).add(1,'days'),

      showFormDateH: false,
    }
  }

  componentDidMount(){
    this.props.onChangeValue(this.state)
  }

  updateChangeValue(event, value = null) {
    const target = event.target;
    if(value === null)
      value = target.type === 'checkbox' ? target.checked : target.value;
    var name = target.name;

    var newState = {
      [name]: value
    };

    if(name === "devisEnvoye" && value === false)
      newState.rdvPris = null;

    this.setState(
      newState
    , () => this.props.onChangeValue(this.state));
  }

  updateChangeValue2(name, value) {
    this.setState({
      [name]: value
    }, () => this.props.onChangeValue(this.state));
  }

  changeDateRdv(value) {
    var dateRdv = this.state.dateRdv ? this.state.dateRdv : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    value.set('hour', dateRdv.get('hour')).set('minute', dateRdv.get('minute'));

    this.setState({
      dateRdv: value
    }, () => this.props.onChangeValue(this.state))
  }

  changeDateRelance(value) {
    var dateRelance = this.state.dateRelance ? this.state.dateRelance : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    value.set('hour', dateRelance.get('hour')).set('minute', dateRelance.get('minute'));
    if(value.isBefore(moment())){
      value.set('hour', moment().get('hour')).set('minute', moment().get('minute'));
    }

    this.setState({
      dateRelance: value
    }, () => this.props.onChangeValue(this.state))
  }

  changeHeureRdv(value) {
    var dateRdv = this.state.dateRdv ? this.state.dateRdv : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dateRdv.set('hour', value.get('hour')).set('minute', value.get('minute'));

    this.setState({
      dateRdv: dateRdv
    }, () => this.props.onChangeValue(this.state))
  }

  changeHeureRelance(value) {
    var dateRelance = this.state.dateRelance ? this.state.dateRelance : moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    dateRelance.set('hour', value.get('hour')).set('minute', value.get('minute'));
    if(dateRelance.isBefore(moment())){
      dateRelance.set('hour', moment().get('hour')).set('minute', moment().get('minute'));
    }

    this.setState({
      dateRelance: dateRelance
    }, () => this.props.onChangeValue(this.state))
  }

  isValidDateRdv(event) {
    return (event >= moment().startOf('day'));
  }

  isValidDateRelance(event) {
    return (event >= moment().startOf('day'));
  }

  showFormDateH(){
    return !this.state.nonJoignable && this.state.rdvPris;
  }

  render() {

    return (
      <div className="panel panel-default form-rapport-atelier">
        <h2><img src="/img/PROCHAINE ACTION Copy 2.svg" role='presentation' /> Action effectuée</h2>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group" style={{marginLeft:0}}>
              <input type="checkbox" name="nonJoignable" id="nonJoignable" onChange={evt => this.updateChangeValue(evt)} checked={this.state.nonJoignable}/>
              <label className={"label-box "+ (isMobile ? "isMobile" : "")} style={{fontSize:'1em'}} htmlFor="nonJoignable">Non joignable, à rappeler </label>
            </div>
            {!this.state.nonJoignable ? 
              <div className="row">
                <div className="col-xs-12">
                  {this.props.typeDemande === "Prise de RDV" ?
                    <div className="btn-group flex-box" style={{alignItems:'center'}}>
                      Rendez-vous pris ? 
                      <button className={'btn btn-default btn-group-xs' + (this.state.rdvPris === true ? ' active' : '')} name="rdvPris" type="button" onClick={(e) => this.updateChangeValue(e, true)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px', marginLeft:'15px'}}>Oui</button>
                      <button className={'btn btn-default btn-group-xs' + (this.state.rdvPris === false ? ' active' : '')} name="rdvPris" type="button" onClick={(e) => this.updateChangeValue(e, false)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px'}}>Non</button>
                    </div>
                  :this.props.typeDemande === "Demande de devis" ?
                    <div>
                      <div className="row">
                        <div className="col-md-3 col-xs-5">
                          Devis accepté ? 
                        </div>
                        <div className="col-xs-6">
                          <div className="btn-group flex-box" style={{alignItems:'center', marginLeft:'-15px'}}>
                            <button className={'btn btn-default btn-group-xs' + (this.state.devisEnvoye === true ? ' active' : '')} name="devisEnvoye" type="button" onClick={(e) => this.updateChangeValue(e, true)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px', marginLeft:'15px'}}>Oui</button>
                            <button className={'btn btn-default btn-group-xs' + (this.state.devisEnvoye === false ? ' active' : '')} name="devisEnvoye" type="button" onClick={(e) => this.updateChangeValue(e, false)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px'}}>Non</button>
                          </div>
                        </div>
                      </div>
                      {this.state.devisEnvoye ? 
                      [
                        <div className="row" style={{marginTop:'10px'}} key="rdvPris">
                          <div className="col-md-3 col-xs-5">
                            Rendez-vous pris ? 
                          </div>
                          <div className="col-xs-6">
                            {/* Il n'est pas possible d'avoir plusieurs btn-group sur la même page, d'où l'utilisation des label et input radio */}
                            <div className="btn-group flex-box" data-toggle="buttons" style={{alignItems:'center'}}>
                              <label className={"btn btn-default btn-group-xs" + (this.state.rdvPris === true ? ' active' : '')} onClick={() => this.updateChangeValue2("rdvPris", true)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px', marginLeft:'15px'}}>
                                  <input type="radio"/>Oui
                              </label>
                              <label className={"btn btn-default btn-group-xs" + (this.state.rdvPris === false ? ' active' : '')} onClick={() => this.updateChangeValue2("rdvPris", false)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px'}}>
                                  <input type="radio"/>Non
                              </label>
                            </div>
                          </div>
                        </div>,
                        this.state.rdvPris === false && localStorage.IsNissan === 'true' ? //Si Nissan et Devis envoyé mais pas rdv pris, traitement spécifique
                        [
                          <div className="form-group" key="dateRelance">
                            <div className="col-md-6">
                              Date de relance <br/>
                              <InputDate isValidDate={this.isValidDateRelance.bind(this)} onChange={(e) => this.changeDateRelance(e)} value={this.state.dateRelance}/>
                            </div>
                          </div>,
                          <div className="form-group" style={{marginBottom:'0px'}} key="heureRelance">
                            <div className="col-md-5 col-xs-10">
                              Heure <br/>
                              <InputTime value={this.state.dateRelance} onChange={this.changeHeureRelance.bind(this)} SelectStyle={{paddingLeft: '1px'}} after={this.state.dateRelance && this.state.dateRelance.isSame(moment(), 'day') ? moment().format("H:m") : null}/>
                            </div>
                            <div className="col-xs-2" style={{marginLeft:'-30px', marginTop:'3px'}}>
                              <br/>
                              <i className="icon-clock icons" style={{ marginLeft:'5px' }}></i>
                            </div>
                          </div>
                        ]
                        :null
                      ]
                      :null}
                    </div>
                  :this.props.typeDemande === "Suivi travaux" || this.props.typeDemande === "Réclamations" || this.props.typeDemande === "Demande d'infos" || this.props.typeDemande === "Demande de dépannage" ?
                    <div className="btn-group flex-box" style={{alignItems:'center'}}>
                      Demande traitée 
                      <button className={'btn btn-default btn-group-xs' + (this.state.demandeTraitee === true ? ' active' : '')} name="demandeTraitee" type="button" onClick={(e) => this.updateChangeValue(e, true)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px', marginLeft:'15px'}}>Oui</button>
                      <button className={'btn btn-default btn-group-xs' + (this.state.demandeTraitee === false ? ' active' : '')} name="demandeTraitee" type="button" onClick={(e) => this.updateChangeValue(e, false)} style={{ textTransform: 'inherit', fontSize: '15px', height:'auto', lineHeight:'19px'}}>Non</button>
                    </div>
                  :null}
                </div>
              </div>
            : 
              <div>
                <div className="form-group">
                  <div className="col-md-6">
                    Date de relance <br/>
                    <InputDate isValidDate={this.isValidDateRelance.bind(this)} onChange={(e) => this.changeDateRelance(e)} value={this.state.dateRelance}/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-5 col-xs-10">
                    Heure <br/>
                    <InputTime value={this.state.dateRelance} onChange={this.changeHeureRelance.bind(this)} SelectStyle={{paddingLeft: '1px'}} after={this.state.dateRelance && this.state.dateRelance.isSame(moment(), 'day') ? moment().format("H:m") : null}/>
                  </div>
                  <div className="col-xs-2" style={{marginLeft:'-30px', marginTop:'3px'}}>
                    <br/>
                    <i className="icon-clock icons" style={{ marginLeft:'5px' }}></i>
                  </div>
                </div>
              </div>
            }
            {this.showFormDateH() ? 
            <div>
              <div className="form-group">
                <div className="col-md-6">
                  Date <br/>
                  <InputDate isValidDate={this.isValidDateRdv.bind(this)} onChange={(e) => this.changeDateRdv(e)} value={this.state.dateRdv}/>
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-5 col-xs-10">
                  Heure <br/>
                  <InputTime value={this.state.dateRdv} onChange={this.changeHeureRdv.bind(this)} SelectStyle={{paddingLeft: '1px'}} />
                </div>
                <div className="col-xs-2" style={{marginLeft:'-30px', marginTop:'3px'}}>
                  <br/>
                  <i className="icon-clock icons" style={{ marginLeft:'5px' }}></i>
                </div>
              </div>
            </div>
            :null}
            <div className="form-group" style={{marginLeft:0}}>
              <label className="control-label">Commentaire</label>
              <textarea className="form-control" onChange={(e) => this.updateChangeValue(e)} name="commentaire"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormRapportAtelier;
