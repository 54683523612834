import React, {Component} from 'react';

import moment from 'moment'

class FormAccessoires extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      comment: this.props.defaultComment,
      poseSouhaitee: false,
    }
  }

  generateComment() {
    let comment = '';

    comment += 'Commentaire :\n' + this.state.comment + '\n';
    comment += 'Pose souhaitée : '+(this.state.poseSouhaitee ? 'Oui' : 'Non');

    return comment;
  }

  updateChangeValue(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      this.props.changeValue('commentAccessoires', this.generateComment());
    });
  }

  render() {

    return (
      <div className="panel panel-default">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-accessoires">
            <h2>
              <i className="fa fa-square" aria-hidden="true"></i>&nbsp; Accessoires
            </h2>
          </a>
        </li>
      </ul>
        <div className="row">
          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <div className="col-md-12">
                  <textarea  name="comment" id="comment" style={{ width: '100%', height: '120px' }} onChange={evt => this.updateChangeValue(evt)} placeholder="Commentaire" value={this.state.comment}></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group" style={{marginTop:0}}>
              <div className="col-md-12">
                <input type="checkbox" name="poseSouhaitee" id="poseSouhaitee" onChange={evt => this.updateChangeValue(evt)} />
                <label className="label-box" htmlFor="poseSouhaitee"> Pose souhaitée </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormAccessoires;
