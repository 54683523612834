import React, { Component } from 'react';
import Globals from '../../Globals';
import ModalTypesProduction from '../ModalTypesProduction';
import Tooltip from "rc-tooltip";
var lodash = require('lodash')

class ListTypesProduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false, 
            loaded: false,
            typesProduction: [], 
            prospectTypesProduction: []
        }
    }

    componentDidMount(){
        this.fetchTypesProduction();
    }

    fetchTypesProduction(){
        this.setState({
            fetching: true
        })
        fetch(localStorage.ClientApiURL + '/agri/typesProduction', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            this.setState({
                typesProduction: JSON.parse(json),
            }, () => this.fetchProspectTypesProduction()); 
        })
        .catch((error) => {
            console.log(error)
        });
    }

    fetchProspectTypesProduction() {
        
        fetch(localStorage.ClientApiURL + '/prospects/'+this.props.IDProspect+'/typesProduction', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var prospectTypesProduction = [];

            for(let i in json){
                let typeProduction = lodash.find(this.state.typesProduction, {IDTypeProduction: json[i].IDTypeProduction});

                if(typeProduction){
                    typeProduction.VolumeProduction = json[i].VolumeProduction;
                    prospectTypesProduction.push(typeProduction);
                }
            }

            prospectTypesProduction = lodash.orderBy(prospectTypesProduction, ['LibelleCategorieProduction', 'LibelleSousCategorieProduction', 'LibelleVolumeProduction']);

            this.setState({
                prospectTypesProduction: prospectTypesProduction,
                loaded: true,
                fetching: false,
            }); 
        })
        .catch((error) => {
            console.log(error)
        });
    }

    openTypesProduction(){
        this.refs.modalTypesProduction.show();
    }

    reloadProspectTypesProduction(newTypesProduction){
        var prospectTypesProduction = [];

        for(let i in newTypesProduction){
            let typeProduction = lodash.find(this.state.typesProduction, {IDTypeProduction: newTypesProduction[i].IDTypeProduction});

            if(typeProduction){
                typeProduction.VolumeProduction = newTypesProduction[i].volumeProduction;
                prospectTypesProduction.push(typeProduction);
            }
        }

        prospectTypesProduction = lodash.orderBy(prospectTypesProduction, ['LibelleCategorieProduction', 'LibelleSousCategorieProduction', 'LibelleVolumeProduction']);

        this.setState({
            prospectTypesProduction: prospectTypesProduction
        }); 
    }

    render() {
        return (
            <div>
                <div className="row" key="list">
                    <div className="col-md-12">
                        <table className="table-striped list" style={{minWidth:'300px'}}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>Types de production</th>
                                    <th colSpan={1} style={{textAlign:'right'}}>
                                        <Tooltip placement="left" trigger={['hover']} overlay="Modifier les types de production">
                                            <a className="picto picto-add" onClick={() => this.openTypesProduction()}></a>
                                        </Tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.state.loaded ? 
                                    <tr><td colSpan={4}>Chargement en cours...</td></tr>
                                :this.state.prospectTypesProduction.length > 0 ?
                                    this.state.prospectTypesProduction.map((type, i) => {
                                        return(<tr key={i}>
                                            <td>{type.LibelleCategorieProduction}</td>
                                            <td>{type.LibelleSousCategorieProduction}</td>
                                            <td>{type.LibelleVolumeProduction}</td>
                                            <td>{type.VolumeProduction}</td>
                                        </tr>);
                                    })
                                :
                                    <tr><td colSpan={4}>Aucun type de production</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <ModalTypesProduction ref="modalTypesProduction" key="modal" IDProspect={this.props.IDProspect} onSubmit={this.reloadProspectTypesProduction.bind(this)}/>
            </div>
        );
    }
}

export default ListTypesProduction;

