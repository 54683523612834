import React, {Component} from 'react';
import Globals from '../Globals';
import ButtonList from '../Components/ButtonList';

var lodash = require('lodash')

class FormVDL extends Component {

  constructor(props) {
    super(props);

    this.state = {
      marques: [],
      saisieMarqueMan: false,

      gamme: '',
      type:'',

      marque : '',
      km: '',
      typeLit: '',
      annee:'',
      hauteur:'',
      longueur:'',
      largeur: '',
      nbCouchages:'',
      boite:'',
      budget:'',

      comment: this.props.defaultComment,
      reprise : false,
      ventemarchand : false,
      financement: false,
      demandeessai: false,
    }

    this.typesLits = ['Lit capucine', 'Lit central', 'Lit central + pavillon', 'Lits jumeaux', 'Lit longitudinal', 'Lit pavillon', 'Lits pavillons superposés', 'Lits superposés', 'Lit toit relevable', 'Lit transversal', 'Lit à la française', 'Lit à la française + pavillon']
    this.hauteurs = ['- de 2m', 'de 2m à 2,5m', 'de 2,5m à 3m', '+ de 3m'];
    this.longueurs = ['- de 6m', 'de 6m à 6,5m', 'de 6,5m à 7m', 'de 7m à 7,5m', '+ de 7,5m'];
    this.nbCouchages = ['2', '3', '4', '5', '6', '+ de 6'];
    this.boitesDeVitesse = ['Automatique', 'Manuelle'];
    this.budgets = ['- de 15 000 €', 'Entre 15 000 et 20 000 €', 'Entre 20 000 et 25 000 €', 'Entre 25 000 à 30 000 €', 'Entre 30 000 à 35 000 €', 'Entre 35 000 à 40 000 €', 'Entre 40 000 à 45 000 €', 'Entre 45 000 à 50 000 €', 'Entre 50 000 à 60 000 €', 'Entre 60 000 à 70 000 €', 'Entre 70 000 à 80 000 €', '+ de 80 000€'];
    this.largeurs = ['- de 2 m', '2m à 2,25m', '+ de 2.25m'];
  }

  componentDidMount() {
    this.fetchMarques();
  }

  fetchMarques() {
    fetch(localStorage.ClientApiURL + '/referentiel/marques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        marques: JSON.parse(json),
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateComment() {
    let comment = '';

    comment += 'Gamme : ' + this.state.gamme + '\n';
    comment += 'Neuf/Occasion : ' + this.state.type + '\n';
    comment += 'Marque : ' + this.state.marque + '\n';
    comment += 'Kilométrage : ' + this.state.km + '\n';
    comment += 'Année : ' + this.state.annee + '\n';
    comment += 'Type de lit : ' + this.state.typeLit + '\n';
    comment += 'Nombre de couchages : ' + this.state.nbCouchages + '\n';
    comment += 'Longueur : ' + this.state.longueur + '\n';
    comment += 'Largeur : ' + this.state.largeur + '\n';
    comment += 'Hauteur : ' + this.state.hauteur + '\n';
    comment += 'Boîte de vitesse : ' + this.state.boite + '\n';
    comment += 'Budget : ' + this.state.budget + '\n';
    comment += 'Reprise : ' + (this.state.reprise ? 'oui' : 'non') + '\n';
    comment += 'Vente à marchand : ' + (this.state.ventemarchand ? 'oui' : 'non') + '\n';
    comment += 'Financement : ' + (this.state.financement ? 'oui' : 'non') + '\n';
    comment += 'Demande d\'essai : ' + (this.state.demandeessai ? 'oui' : 'non') + '\n';
    comment += 'Commentaire :\n' + this.state.comment + '\n';

    return comment;
  }

  updateChangeValue(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name == "marque" && value == "_SAISIE-MANUELLE_"){
      this.setState({
        marque: '',
        saisieMarqueMan: true
      }, () => this.refs.saisieMarqueMan.focus());
      return;
    }

    this.setState({
      [name]: value
    }, () => {
      this.props.changeValue('commentVDL', this.generateComment());
    });
  }

  changeValue(name, value){
    this.setState({
      [name]: value
    }, () => {
      this.props.changeValue('commentVDL', this.generateComment());
    });
  }

  render() {

    return (
      <div className="panel panel-default">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-vn">
            <h2>
              <img src="/img/AFFAIRE VN.svg" role='presentation' /> Interêt 
            </h2>
          </a>
        </li>
      </ul>
      <div className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group" style={{marginLeft:10}}>
            <ButtonList buttons="CAPUCINE,PROFILÉ,INTEGRAL,FOURGON,VAN,CARAVANE,MOBIL HOME" small={true} value={this.state.gamme} onSelect={(e) => this.changeValue("gamme", e.target.value)}/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group" style={{marginLeft:10, marginTop:0}}>
            <ButtonList buttons="Neuf,Occasion,Indifférent" value={this.state.type} small={true} name="type" onSelect={(e) => this.changeValue("type", e.target.value)}/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 col-xs-12">
          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Marque</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              {this.state.saisieMarqueMan ? 
                <input type="text" name="marque" className="form-control" ref="saisieMarqueMan" onChange={(e) => this.changeValue("marque", e.target.value)} />
              :
                <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)}>
                  <option value=""></option>
                  <option value="_SAISIE-MANUELLE_">Saisir une marque</option>
                  {this.state.marques.map((marque) => {
                    return (<option key={marque.IDMarque} value={marque.Descr}>{marque.Descr}</option>)
                  })}
                </select>
              }
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Kilométrage</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="km" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(lodash.range(10000, 150000, 10000), km => {
                  return (<option key={km} value={km}>{km}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label text-nowrap">Type de lit</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="typeLit" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.typesLits, type => {
                  return (<option key={type} value={type}>{type}</option>)
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-xs-12">
          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Hauteur</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="hauteur" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.hauteurs, h => {
                  return (<option key={h} value={h}>{h}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Longueur</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="longueur" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.longueurs, l => {
                  return (<option key={l} value={l}>{l}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Largeur</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="largeur" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.largeurs, l => {
                  return (<option key={l} value={l}>{l}</option>)
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-xs-12">
          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Année</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="annee" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(lodash.range(new Date().getFullYear()+1, 1995, -1), annee => {
                  return (<option key={annee} value={annee}>{annee}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label text-nowrap">Nb couchages</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="nbCouchages" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.nbCouchages, nb => {
                  return (<option key={nb} value={nb}>{nb}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Boîte</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="boite" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.boitesDeVitesse, b => {
                  return (<option key={b} value={b}>{b}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-4 col-md-4 col-sm-4 col-xs-12 control-label">Budget</label>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <select className='form-control' name="budget" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                {lodash.map(this.budgets, b => {
                  return (<option key={b} value={b}>{b}</option>)
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-xs-12">
          <div className="form-group">
            <div className="col-md-12">
                <textarea  name="comment" id="comment" style={{ width: '100%' }} placeholder="Commentaire" onChange={evt => this.updateChangeValue(evt)} value={this.state.comment}></textarea>
            </div>
          </div>
          <div className="form-group checkbox-lines">
            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="reprise" id="reprise" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="reprise"> Reprise </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="financement" id="financement" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="financement"> Financement </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="ventemarchand" id="ventemarchand" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box text-nowrap" htmlFor="ventemarchand"> Vente à Marchand </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="demandeessai" id="demandeessai" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box text-nowrap" htmlFor="demandeessai"> Demande d'essai </label>
            </div>
          </div>
        </div>
      </div>

      </div>
    );
  }
}

export default FormVDL;
