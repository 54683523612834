import React from 'react'
import moment from "moment"
import Tooltip from "rc-tooltip"

export default function TempsRestant(props) {

    function calculerDelai(dateCible) {

        const cible = moment(dateCible)

        // Vérifie si la date est valide
        if (!cible.isValid()) {
            return "Date invalide"
        }

        const maintenant = moment()

        // Calcul de la durée totale en millisecondes
        const duree = moment.duration(cible.diff(maintenant))

        // Calcul des parties (jours, heures, minutes)
        const jours = Math.floor(Math.abs(duree.asDays())) // Total des jours entiers
        const heures = Math.abs(duree.hours())            // Heures restantes
        const minutes = Math.abs(duree.minutes())         // Minutes restantes

        // Préfixe pour indiquer si le délai est passé ou à venir
        const prefixe = cible.isBefore(maintenant) ? "- " : ""

        return `${prefixe}${jours > 0 ? `${jours} j` : ""} ${heures} h ${minutes} m`
    }

    return (
        <>
            <Tooltip trigger={[ 'hover' ]} placement="left" overlay={<div>{moment(props.date).format("DD/MM/YYYY HH:mm")}</div>}>
                <span>{calculerDelai(props.date)}</span>
            </Tooltip>
        </>
    )
}

