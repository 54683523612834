import React, {Component} from 'react';

import moment from 'moment'

import InputTime from '../Components/InputTime';

import 'rc-slider/assets/index.css';
var Datetime = require('react-datetime');

class FormToDoAtelier extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
    }
  }

  componentWillMount() {
  }

  changeDate(event) {
    this.props.onChangeValue('todoDate', event.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
  }

  isValidDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  isValidHeure(current) {
    return true;
  }

  changeHeureRelance(event) {
    this.props.onChangeValue('todoHeure', event);
  }

  changeEtape(event) {
    this.props.onChangeValue('todoEtape', event.target.value);
    this.props.onChangeValue('todoEtapeText', event.target.options[event.target.selectedIndex].text);
  }

  changeActionType(event) {
    this.props.onChangeValue('action', event.target.value);
  }

  changeComment(event) {
    this.props.onChangeValue('todoComment', event.target.value);
  }

  changeRaison(event) {
    this.props.onChangeValue('todoRaison', event.target.value);
  }

  changeAction(event) {
    this.props.onChangeValue('todoAction', event.target.value);
  }

  getTypeDemande(lead) {
    if (!lead.Affaire.Description)
      return 'Autres';

    var matchs = lead.Affaire.Description.match(/Type de demande : (.*)\n/);

    if (!matchs)
      return 'Autres';

    return matchs[1];
  }

  render() {

    return (
      <div style={{ marginBottom: '55px', marginTop: '50px' }}>

        <div className='row'>
          <div className="col-md-12 col-md-offset-1">

            {this.props.displayNextActionType ?
              <div className="form-group btn-group">
                  <button className={this.props.nextActionType === 'continuer'
                    ? 'btn btn-default active'
                    : 'btn btn-default'} type="button" value="continuer" onClick={this.changeActionType.bind(this)} style={{ width: 'auto', }}>Continuer</button>
                {/*localStorage.user_profil === 'Conseiller' && */(this.getTypeDemande(this.props.lead) === 'Prise de RDV' || this.getTypeDemande(this.props.lead) === 'Demande de devis')?
                    <button className={this.props.nextActionType === 'transformer'
                      ? 'btn btn-default active'
                      : 'btn btn-default'} type="button" value="transformer" onClick={this.changeActionType.bind(this)} style={{ width: 'auto',  }}>Transformé</button>
                : null}
                {/*localStorage.user_profil === 'Conseiller' && */(this.getTypeDemande(this.props.lead) === 'Prise de RDV' || this.getTypeDemande(this.props.lead) === 'Demande de devis') ?
                    <button className={this.props.nextActionType  === 'nontransformer'
                      ? 'btn btn-default active'
                      : 'btn btn-default'} type="button" value="nontransformer" onClick={this.changeActionType.bind(this)} style={{ width: 'auto',  }}>Non transformé</button>
                : null}
                {!(this.getTypeDemande(this.props.lead) === 'Prise de RDV' || this.getTypeDemande(this.props.lead) === 'Demande de devis') ?
                  <button className={this.props.nextActionType  === 'traiter'
                    ? 'btn btn-default active'
                    : 'btn btn-default'} type="button" value="traiter" onClick={this.changeActionType.bind(this)} style={{ width: 'auto',  }}>Clôturer</button>
                : null}
                  <button className={this.props.nextActionType  === 'abandon'
                    ? 'btn btn-default active'
                    : 'btn btn-default'} type="button" value="abandon" onClick={this.changeActionType.bind(this)} style={{ width: 'auto', }}>Rejeter</button>
              </div>
            : null}

            {this.props.nextActionType === 'cloturer' ?
              <div className="form-group">
                <label className="col-md-4">Raison</label>
                <div className=" col-md-8">
                  <select className="form-control" id="raison" name="raison" onChange={this.changeRaison.bind(this)} value={this.props.todoRaison} required="required">
                    <option></option>

                    {this.getTypeDemande(this.props.lead) === 'Prise de RDV' ?
                      <option value='Intervention réalisé'>Intervention réalisé</option>
                    : null}
                    {this.getTypeDemande(this.props.lead) === 'Demande de devis' ?
                      <option value='Devis accepté'>Devis accepté</option>
                    : null}
                    {this.getTypeDemande(this.props.lead) !== 'Prise de RDV' && this.getTypeDemande(this.props.lead) !== 'Demande de devis' ?
                      <option value='Traité'>Traité</option>
                    : null}
                  </select>
                </div>
              </div>
            : null}

            {this.props.nextActionType === 'continuer' ?
              <div className="form-group">
                <label className="col-md-4">Étape</label>
                <div className=" col-md-8">
                  <select className="form-control" id="etapes" name="etapes" onChange={this.changeEtape.bind(this)} value={this.props.todoEtape} required="required">
                    {this.getTypeDemande(this.props.lead) === 'Prise de RDV' || this.getTypeDemande(this.props.lead) === 'Demande de devis' ?
                      [<option key={1} value='7'>En cours</option>,
                      <option key={2} value='9'>RDV pris</option>,
                      <option key={3} value='8'>Devis réalisé</option>]
                    : null}
                    {this.getTypeDemande(this.props.lead) !== 'Prise de RDV' && this.getTypeDemande(this.props.lead) !== 'Demande de devis' ?
                      <option key={1} value='7'>En cours</option>
                    : null}
                  </select>
                </div>
              </div>
            : null}

            {this.props.nextActionType === 'continuer' ?
              <div className="form-group datepicker-center">
                <div className="row">
                  <label className="col-md-4">Le</label>
                  <div className="col-md-6">
                    <Datetime className="datetime" closeOnSelect={true} timeFormat={false} locale="fr" isValidDate={this.isValidDate.bind(this)} value={this.props.todoDate} onChange={this.changeDate.bind(this)} />
                  </div>
                  <div className="col-md-1">
                    <i className="icon-calendar icons"></i>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-4">à</label>
                  <div className="col-md-6">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeure} onChange={this.changeHeureRelance.bind(this)} />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <i className="icon-clock icons"></i>
                  </div>
                  </div>
              </div>
            : null}


            <div className="form-group" style={{ marginLeft: '0px', marginRight: '0px', }}>
              <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.props.todoComment} onChange={this.changeComment.bind(this)} />
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default FormToDoAtelier;
