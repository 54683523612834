import React, {Component} from 'react';

import moment from 'moment'
var Datetime = require('react-datetime');

class FormAutres extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      comment: this.props.defaultComment,
    }
  }

  generateComment() {
    let comment = '';

    comment += 'Commentaire :\n' + this.state.comment + '\n';

    return comment;
  }

  updateChangeValue(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      this.props.changeValue('commentAutres', this.generateComment());
    });
  }

  render() {

    return (
      <div className="panel panel-default">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-atelier">
            <h2>
              <i className="fa fa-bell" aria-hidden="true"></i>&nbsp; Autres
            </h2>
          </a>
        </li>
      </ul>
        <div className="row">

          <div className="col-md-4 col-xs-12">
            <div className="form-group">
              <div className="col-md-12">
                  <textarea  name="comment" id="comment" style={{ width: '100%', height: '120px' }} onChange={evt => this.updateChangeValue(evt)} placeholder="Commentaire" value={this.state.comment}></textarea>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default FormAutres;
