import React, { Component } from 'react';

import { browserHistory } from 'react-router'

import Loader from '../../Components/Loader';
import Globals from '../../Globals';

import ResumeCampagne from './ResumeCampagne';

import moment from 'moment'

import { Link } from 'react-router';

var lodash = require('lodash')
var Datetime = require('react-datetime');

class FormCustomFile extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      columns: [],
      firstLine: [],
      lastLine: [],
      columnsMapping: [],
    }
  }

  onChangeFile(event) {
    let files = event.target.files;

    let reader = new FileReader();
    let file = files[0];

    var that = this;

    reader.onload = function(e) {
        var text = reader.result;
        var lines = text.split('\n');
        var firstLine = lines.shift();

        var columns = firstLine.split(';');
        columns = columns.map((c) => {
          if (c[0] === '"' && c[c.length-1] === '"') {
            c = c.slice(1, -1);
          }
          return c;
        })

        var secondLine = lines[1].split(';');
        secondLine = secondLine.map((c) => {
          if (c[0] === '"' && c[c.length-1] === '"') {
            c = c.slice(1, -1);
          }
          return c;
        })

        var lastLine = lines[lines.length-1].split(';');
        if (lines[lines.length-1] === '') {
          lastLine = lines[lines.length-2].split(';');
        }
        lastLine = lastLine.map((c) => {
          if (c[0] === '"' && c[c.length-1] === '"') {
            c = c.slice(1, -1);
          }
          return c;
        })

        that.setState({
          step: 2,
          columns: columns,
          firstLine: secondLine,
          lastLine: lastLine,
          columnsMapping: [],
        })
    }

    reader.readAsText(file, 'ISO-8859-4')
  }

  onChangeSelectMapping(index, event) {

    var columnsMapping = this.state.columnsMapping;

    var indexColumnsMapping = lodash.findIndex(columnsMapping, (c) => {
      return parseInt(c.column, 10) == parseInt(index, 10);
    })

    if (indexColumnsMapping >= 0) {
      columnsMapping[indexColumnsMapping].mapping = event.target.value;
    }
    else {
      columnsMapping.push({
        column: index,
        mapping: event.target.value,
        save: false,
      })
    }

    this.setState({
      columnsMapping: columnsMapping
    })
  }

  getValueSelectMapping(index) {
    var indexColumnsMapping = lodash.findIndex(this.state.columnsMapping, (c) => {
      return parseInt(c.column, 10) == parseInt(index, 10);
    })

    if (indexColumnsMapping < 0) {
      return '';
    }

    return this.state.columnsMapping[indexColumnsMapping].mapping;
  }

  onChangeSaveValue(index, event) {
    var columnsMapping = this.state.columnsMapping;

    var indexColumnsMapping = lodash.findIndex(columnsMapping, (c) => {
      return parseInt(c.column, 10) == parseInt(index, 10);
    })
    
    if (indexColumnsMapping >= 0) {
      columnsMapping[indexColumnsMapping].save = event.target.checked ? true : false;
    }
    else {
      columnsMapping.push({
        column: index,
        mapping: '',
        save: event.target.checked ? true : false,
      })
    }

    this.setState({
      columnsMapping: columnsMapping
    })
  }

  isCheckSaveValue(index) {
    var indexColumnsMapping = lodash.findIndex(this.state.columnsMapping, (c) => {
      return parseInt(c.column, 10) == parseInt(index, 10);
    })

    if (indexColumnsMapping < 0) {
      return '';
    }

    return this.state.columnsMapping[indexColumnsMapping].save;
  }

  render() {
    return (
    <div>

      <div className='col-md-12' style={{ marginTop: '45px' }}>
        <div className='col-md-4'>
          <div className="form-group">
            <label htmlFor="type" className="col-sm-3 control-label">Fichier</label>
            <div className="col-sm-6">
              <input type='file' style={{ border: 'none' }} onChange={this.onChangeFile.bind(this)} accept='.csv,.txt' />
            </div>
          </div>
        </div>
      </div>

      {this.state.columns.length ?
        <div className='col-md-12' style={{ marginTop: '45px' }}>
          <table className='table table-bordered table-hover table-striped'>
            <thead>
              <tr>
                <th>Colonne fichier source</th>
                <th>Première ligne</th>
                <th>Dernière ligne</th>
                <th className='text-center'>Colonne destination</th>
                <th className='text-center'>Mettre à jour la donnée</th>
              </tr>
            </thead>
            <tbody>
              {this.state.columns.map((c, i) => {
                return (<tr key={c}>
                  <td>
                    {c}
                  </td>
                  <td>
                    {this.state.firstLine[i]}
                  </td>
                  <td>
                    {this.state.lastLine[i]}
                  </td>
                  <td className='text-center'>
                    <select className='form-control' style={{ width: '300px', display: 'inline' }} onChange={this.onChangeSelectMapping.bind(this, i)} value={this.getValueSelectMapping(i)} >
                      <option></option>

                      <option key='Civilite' value='Civilite'>Civilité</option>
                      <option key='Name' value='Name'>Nom</option>
                      <option key='FirstName' value='FirstName'>Prénom</option>

                      <option key='Address1' value='Address1'>Adresse</option>
                      <option key='Address2' value='Address2'>Adresse suite</option>
                      <option key='Zip' value='Zip'>Code postal</option>
                      <option key='City' value='City'>Ville</option>
                      <option key='Country' value='Country'>Pays</option>

                      <option key='EMail' value='EMail'>Email</option>
                      <option key='EMailPro' value='EMailPro'>Email professionnel</option>

                      <option key='Phone' value='Phone'>Téléphone professionnel</option>
                      <option key='PhonePort' value='PhonePort'>Téléphone portable</option>
                      <option key='PhonePri' value='PhonePri'>Téléphone privé</option>

                    </select>
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' checked={this.isCheckSaveValue(i)} onChange={this.onChangeSaveValue.bind(this, i)} />
                  </td>
                </tr>)
              })}

            </tbody>
          </table>

        </div>
      : null}

    </div>
    );
  }
}

export default FormCustomFile;
