import React, { Component } from 'react';

import { browserHistory } from 'react-router';
import { Link } from 'react-router';

import moment from 'moment'
import BadgeDiffDay from '../../Components/BadgeDiffDay';

import PastilleInteret from '../../Components/PastilleInteret';
import PastilleVendeur from '../../Components/PastilleVendeur';
import TableRowComment from '../../Components/TableRowComment';
import TableRowDateRelance from '../../Components/TableRowDateRelance';
import IconCanal from '../../Components/IconCanal';
import TableRowSite from '../../Components/TableRowSite';
import WelcomeProMail from '../WelcomeProMail';
import Tooltip from "rc-tooltip";
import Dictionary from '../../Dictionnary';
import Loader from '../Loader';
import LibAffaire from '../../Lib/Affaire';

var lodash = require('lodash')

class ListAffaires extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      affaires: [],
      rapports: [],
      rapportsByAffaire: [],
    }
  }

  componentDidMount() {
    this.loadAffaires();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rapports !== this.props.rapports || prevProps.affaires !== this.props.affaires)
      this.loadAffaires();
  }

  loadAffaires() {
    var _rapports = [];

    this.props.rapports.map((rapport, i) => {
      if (rapport.Affaire) {
        if (!_rapports[rapport.Affaire.IDAffaire])
          _rapports[rapport.Affaire.IDAffaire] = [];
        _rapports[rapport.Affaire.IDAffaire].push(rapport);
        return rapport;
      }
      return null;
    });

    var _rapports_sort = [];

    this.props.affaires.map((affaire, i) => {
      if (_rapports[affaire.IDAffaire]) {
        _rapports[affaire.IDAffaire].sort(function(a, b) {
          if (moment(a.InfoCreation.DateH) > moment(b.InfoCreation.DateH))
            return -1;
          return 1;
        });
      }
      return affaire;
    });

    var _affaires = this.props.affaires;

    _affaires.sort(function(a, b) {
      if (moment(a.DateDebut) > moment(b.DateDebut))
        return -1;
      return 1;
    });

    this.setState({
      affaires: _affaires,
      rapportsByAffaire: _rapports
    })
  }

  addRapport(IDLead, event) {
    event.preventDefault();
    browserHistory.push('/rapport/'+IDLead);
  }

  getRapportWithDate(rapports) {

    var rapports = lodash.filter(rapports, (r) => {
      return r.ActionAFaire_TODO.Etat === "EnCours" && r.ActionAFaire_TODO.DateHProchaineEtape;
    });

    return lodash.first(rapports);
  }

  render() {

    if (!this.state.affaires) {
      return null;
    }

    return (
      <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

        {this.state.affaires.length ? this.state.affaires.map((affaire, i) => {

          return <div className="panel panel-default" key={i} style={{ marginTop: '0', marginBottom: '10px' }}>
              <div className="panel-heading" role="tab" id={"heading-"+i}>
                <h4 className="panel-title">
                  <a role="button" style={{ display: 'block' }} data-toggle="collapse" data-parent="#accordion" href={"#collapse-"+i} aria-expanded="true" aria-controls={"collapse-"+i}>
                    <div className='table-collapse'>
                      <div className='col col-sm-1 col-md-1 col-lg-1' style={{ textAlign:'center', paddingLeft: '4px' }}>
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{affaire.Type ? Dictionary.getUCFirst(affaire.Type) : null}</span>
                        <span className='pull-right'>
                          <TableRowComment comment={affaire.Description} />
                        </span>
                      </div>
                      <div className='col col-sm-1 col-md-1 col-lg-1 text-center' style={{ padding: 0, }}>
                        <strong>Date :</strong> {this.state.rapportsByAffaire[affaire.IDAffaire] ? moment(this.state.rapportsByAffaire[affaire.IDAffaire][0].InfoCreation.DateH).format('DD/MM/Y') : null}
                      </div>
                      <div className='col col-sm-2 col-md-2 col-lg-2 text-center'>
                        <strong>Site :</strong> {this.state.rapportsByAffaire[affaire.IDAffaire] ? <TableRowSite Site={this.state.rapportsByAffaire[affaire.IDAffaire][0].Site} /> : null}
                      </div>
                      <div className='col col-sm-2 col-md-2 col-lg-2 text-center'>
                        <strong>Campagne :</strong> {affaire.Campagne ? affaire.Campagne.Nom : null}
                      </div>
                      <div className='col col-sm-2 col-md-2 col-lg-2 text-center'>
                        <strong>Origine :</strong> {affaire.OrigineAction ? affaire.OrigineAction.Famille + ' / ' + affaire.OrigineAction.SousFamille + ' / ' + affaire.OrigineAction.Origine : null}
                      </div>
                      <div className='col col-sm-1 col-md-1 col-lg-1 text-center'>
                        <strong>Int. :</strong> {this.state.rapportsByAffaire[affaire.IDAffaire] ? <PastilleInteret niveau={this.state.rapportsByAffaire[affaire.IDAffaire][0].ActionAFaire_TODO.NiveauInteret.Niveau} /> : null}
                      </div>
                      <div className='col col-sm-1 col-md-1 col-lg-1 text-center'>
                        <strong>Etape :</strong>&nbsp;
                        {this.state.rapportsByAffaire[affaire.IDAffaire] ?
                          (this.state.rapportsByAffaire[affaire.IDAffaire][0].ActionAFaire_TODO.Etape ? 
                            ((this.state.rapportsByAffaire[affaire.IDAffaire][0].ActionAFaire_TODO.Etape.Description == 'A qualifier' && affaire.Type && affaire.Type == 'Atelier') ? 'A traiter' : this.state.rapportsByAffaire[affaire.IDAffaire][0].ActionAFaire_TODO.Etape.Description)
                          : null)
                        : null}
                      </div>
                      <div className='col col-sm-2 col-md-2 col-lg-2' style={{ paddingLeft: 0 }}>
                        <strong>Relance :</strong>&nbsp;

                        {this.state.rapportsByAffaire[affaire.IDAffaire] && this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]) ?
                          <TableRowDateRelance date={this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]).ActionAFaire_TODO.DateHProchaineEtape} />
                        : null}

                        {this.state.rapportsByAffaire[affaire.IDAffaire] && this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]) ?
                          (this.state.rapportsByAffaire[affaire.IDAffaire][0].ActionAFaire_TODO.DateHProchaineEtape ?
                            <BadgeDiffDay date={moment(this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]).ActionAFaire_TODO.DateHProchaineEtape).format('HHmm') === '0000' ? moment(this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]).ActionAFaire_TODO.DateHProchaineEtape).format('YYYY-MM-DDT08:00:00') : this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]).ActionAFaire_TODO.DateHProchaineEtape} />
                          :
                            <BadgeDiffDay date={this.getRapportWithDate(this.state.rapportsByAffaire[affaire.IDAffaire]).ActionAFaire_TODO.DateHProchaineEtape} />
                          )
                        : null}
                      </div>

                      {(affaire.Type === 'VN' || affaire.Type === 'VO' || affaire.Type === 'VDL') ?
                        <div className='pull-right' style={{ marginRight: '4px' }}>
                          <Tooltip overlay={<div>Créer un nouveau rapport</div>} placement="left">
                            <div className='picto picto-add' onClick={this.addRapport.bind(this, this.state.rapportsByAffaire[affaire.IDAffaire] ? this.state.rapportsByAffaire[affaire.IDAffaire][0].IDLead : null)}></div>
                          </Tooltip>
                        </div>
                      : null}
                    </div>
                  </a>
                </h4>
              </div>
              <div id={"collapse-"+i} className={"panel-collapse collapse" + (this.state.affaires.length === 1 ? "in" : "")} role="tabpanel" aria-labelledby={"heading-"+i}>
                <div className="panel-body">
                  {this.state.rapportsByAffaire.length && this.state.rapportsByAffaire[affaire.IDAffaire] ?
                    <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                      <thead>
                        <tr>
                          <th></th>
                          <th className='text-center'>Canal</th>
                          <th>Date</th>
                          <th>Etape ou type de contact</th>
                          <th>Affecté</th>
                          <th>Relance</th>
                          <th className='text-center'>Commentaire</th>
                          <th className='text-center'>Etat</th>
                          <th className='text-center'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rapportsByAffaire[affaire.IDAffaire].map((rapport, j) => {
                          return <tr key={j}>
                            <td className='text-center'>
                              <PastilleVendeur utilisateur={rapport.InfoCreation.Utilisateur} />
                            </td>
                            <td className='text-center'>
                              <IconCanal canal={rapport.ActionFaite_DO.CanalOrigine} />
                            </td>
                            <td>{moment(rapport.ActionFaite_DO.DateHLead).format('DD/MM/Y HH:mm')}</td>
                            <td>
                              {affaire.Type && affaire.Type == 'Atelier' ?
                                (rapport.ActionAFaire_TODO.Etape ? (rapport.ActionAFaire_TODO.Etape.Description === 'A qualifier' ? 'A traiter' : rapport.ActionAFaire_TODO.Etape.Description) : null)
                              :
                                rapport.ActionAFaire_TODO.Etape ? rapport.ActionAFaire_TODO.Etape.Description : (rapport.ActionFaite_DO.TypeContact ? rapport.ActionFaite_DO.TypeContact.Description : null)
                              }
                            </td>
                            <td>
                              {rapport.InfoCreation.Utilisateur.IDUtilisateur !== rapport.SuiviPar.IDUtilisateur ?
                                <PastilleVendeur utilisateur={rapport.SuiviPar} />
                              : null}
                            </td>
                            <td>
                              <TableRowDateRelance date={rapport.ActionAFaire_TODO.DateHProchaineEtape} />
                            </td>
                            <td className='text-center' style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                              <TableRowComment comment={rapport.ActionFaite_DO.Commentaire} />
                              <WelcomeProMail rapport={rapport} />
                              {LibAffaire.showOngletConstructeur(rapport.Affaire.CodeAffaireSource, rapport.ActionFaite_DO.Commentaire) &&
                              <a href={LibAffaire.getUrlOngletConstructeur(rapport.Affaire.CodeAffaireSource, rapport.ActionFaite_DO.Commentaire)} target="_blank" style={{ marginLeft: "16px", marginRight: "16px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>
                              </a>
                            }
                            </td>
                            <td className='text-center'>
                              {rapport.ActionAFaire_TODO.Etat === "Termine" ? <img src="/img/TABLER ETAT.svg" role='presentation' /> :
                                ((rapport.SuiviPar.Profil && rapport.SuiviPar.TypeProfilLead !== localStorage.user_profil && localStorage.user_profil != 'ChefDesVentes') ?
                                  <span>A traiter</span>
                                :
                                  <Link to={'/rapport'+ 
                                    (!rapport.Affaire.OrigineAction || !rapport.Affaire.Type || rapport.Affaire.Type === 'VN' || rapport.Affaire.Type === 'VO' || rapport.Affaire.Type === 'VDL' ? 
                                      '' 
                                    : rapport.Affaire.Type === "Accessoires" ? 
                                      'Accessoires'
                                    :
                                      'Atelier')
                                    +'/'+rapport.IDLead}>
                                    A traiter
                                  </Link>)
                              }
                            </td>
                            <td>
                              {localStorage.user_profil === 'BDC' && (affaire.Type != 'Atelier') && ((rapport.ActionAFaire_TODO.Etat === "Termine" && localStorage.esellerLeadEditRapportCloture == 'true') || (rapport.ActionAFaire_TODO.Etat !== "Termine")) ?
                                <Tooltip overlay={<div>Modifier</div>}>
                                  <Link to={'/rapport/'+rapport.IDLead+'/edit'}><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                </Tooltip>
                              : null}
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  : null}
                </div>
              </div>
            </div>
        }) 
        :this.props.loading ? 
          <Loader style={{ marginTop: 50 }} styleIcon={{fontSize: '18px'}}/> 
        :
          <em>Aucun lead</em>
        }
      </div>
    );
  }
}

export default ListAffaires;
