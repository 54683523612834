import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterCampagne extends Component {

  constructor(props) {
    super(props);

    this.state = {
      campagnes: [],
      selected: [],
    }
  }

  componentWillMount() {
    this.loadVendeurs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.campagnes !== prevProps.campagnes) {
      this.loadVendeurs();
    }
  }

  loadVendeurs() {
    var campagnes = [];

    lodash.forIn(this.props.campagnes, function(value, key){
      campagnes.push({name: key, count: value});
    });

    campagnes = lodash.sortBy(campagnes, ['name']);

    this.setState({
      campagnes: campagnes,
    });
  }

  onChange(event, campagne) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(campagne)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== campagne;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('campagnes', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.campagnes, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('campagnes', this.state.selected);
    });
  }

  render() {

    var campagnes = null;

    if (this.state.campagnes.length) {
      campagnes = this.state.campagnes.map((campagne, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={campagne.name ? campagne.name : 'campagneNull'} checked={this.state.selected.indexOf(campagne.name ? campagne.name : '') > -1 ? true : false} onChange={(event) => { this.onChange(event, campagne.name); }} />
          <label className="label-box" htmlFor={campagne.name ? campagne.name : 'campagneNull'}>
            {campagne.name}
            <span className="count">({campagne.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par campagne" allSelected={this.state.selected.length === this.state.campagnes.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {campagnes}
      </PanelFilter>
    );
  }
}

export default FilterCampagne;
