import React, { Component } from 'react';

import SkyLight from 'react-skylight';
import ListMessages from '../Components/Prospect/ListMessages';

class ModalHistoriqueMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    show() {
        this.refs.modalHistoriqueMessages.show();
    }

    hide() {
        this.refs.modalHistoriqueMessages.hide();
    }

    refresh() {
        this.refs.listMessages.fetchMessages();
    }

    render() {
        return (
            <SkyLight hideOnOverlayClicked ref="modalHistoriqueMessages" 
              dialogStyles={{ padding: 0, width: '80%', left: '10%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflowY: 'auto', zIndex: 9999, background: "#efefef" }}
              title="Historique des messages"
              titleStyle={{ padding: 10, margin: 0, color: 'white' }}
              closeButtonStyle={{ color: 'white'}}>
              <div className='inner-large-content'>
                <ListMessages ref="listMessages" IDProspect={this.props.prospectId} onLoad={(messages) => {
                  this.props.onMessagesLoaded(messages.length);
                }} />            
              </div>
            </SkyLight>
        );
    }
}

export default ModalHistoriqueMessages;
