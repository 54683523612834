import React, { Component } from 'react';

import Globals from '../Globals';
var lodash = require('lodash');

class TypesProduction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            savingMessage: null,
            updated: false,
            globalConfigLoading: false,
            prospectConfigLoading: false,
            typesProduction: null,

            client: null,

            categCheckedIDs: [],
            vpValues: [],
        };

        this.fetchClient();
    }

    componentDidMount() {
       this.fetchTypesProduction();
       this.fetchProspectTypesProduction();
    }

    fetchTypesProduction(){
        this.setState({
            globalConfigLoading: true
        })
        fetch(localStorage.ClientApiURL + '/agri/typesProduction', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var typesProduction = JSON.parse(json);
            typesProduction = lodash.orderBy(typesProduction, ['LibelleCategorieProduction', 'LibelleSousCategorieProduction', 'LibelleVolumeProduction']);
            lodash.forEach(typesProduction, (c) => {
                if(c.IDCategorieProduction === null)c.IDCategorieProduction = 0;
                if(c.IDSousCategorieProduction === null)c.IDSousCategorieProduction = 0;
                if(c.IDVolumeProduction === null)c.IDVolumeProduction = 0;
            })

            this.setState({
                globalConfigLoading: false,
                typesProduction: typesProduction,
            }); 
        })
        .catch((error) => {
            console.log(error)
        });
    }

    fetchClient() {
        fetch(localStorage.ClientApiURL + '/prospects/'+this.props.IDProspect, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            this.setState({
                client: json,
            }); 
        })
        .catch((error) => {
            console.log(error)
        });
    }

    fetchProspectTypesProduction(){
        this.setState({
            prospectConfigLoading: true
        })
        fetch(localStorage.ClientApiURL + '/prospects/'+this.props.IDProspect+'/typesProduction', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var categCheckedIDs = [];
            var vpValues = [];
            for(var i in json) {
                categCheckedIDs.push(json[i].IDTypeProduction);
                vpValues[json[i].IDTypeProduction] = json[i].VolumeProduction;
            }
            this.setState({
                prospectConfigLoading: false,
                categCheckedIDs: categCheckedIDs,
                vpValues: vpValues
            }); 
        })
        .catch((error) => {
            console.log(error)
        });
    }

    async save() {
        this.setState({
            saving: true,
            savingMessage: <span><i className="fa fa-refresh fa-spin fa-fw" aria-hidden="true"></i>&nbsp;Enregistrement en cours</span>
        })
        var typesProductions = lodash.map(this.state.categCheckedIDs, (IDTypeProduction) => {
            return {
                IDTypeProduction: IDTypeProduction,
                volumeProduction: this.state.vpValues[IDTypeProduction] ? this.state.vpValues[IDTypeProduction] : null
            }
        });
        await fetch(localStorage.ClientApiURL + '/prospects/'+this.props.IDProspect+'/typesProduction', {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'APIKey': Globals.API_KEY,
              'IDClient': localStorage.user_IDClient,
              'Login': localStorage.user_login,
              'Password': localStorage.user_password
            },
            body: JSON.stringify(typesProductions)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            this.setState({
                saving: false,
                updated: false,
                savingMessage: <span><i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Enregistré</span>
            }, () => {
                if(this.props.onSubmit)
                    this.props.onSubmit(typesProductions);
            })
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                savingMessage: <span><i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Erreur lors de l'enregistrement</span>
            })
        });
    }

    isLoading(){
        return this.state.globalConfigLoading || this.state.prospectConfigLoading;
    }

    canSubmit() {
        return !this.isLoading() && !this.state.saving && this.state.updated;
    }

    checkSCP(isChecked, values) {
        var valuesChecked = this.state.categCheckedIDs;
        for(var v in values) {
            var value = values[v];
            if(isChecked)
            {
                if(valuesChecked.indexOf(value) === -1)
                    valuesChecked.push(parseInt(value));
            }
            else 
            {
                valuesChecked = lodash.without(valuesChecked, parseInt(value));
            }
        }

        this.setState({
            updated: true,
            savingMessage: null,
            categCheckedIDs: valuesChecked
        })
    }

    onChangeValueVP(IDTypeProduction, event){
        //console.log(IDTypeProduction, event)
        var vpValues = this.state.vpValues;
        vpValues[IDTypeProduction] = event.target.value;
        this.setState({
            updated: true,
            savingMessage: null,
            vpValues: vpValues
        });
    }

    render() {
        var typesProduction = lodash.groupBy(this.state.typesProduction, 'IDCategorieProduction');

        return (
                <div className="inner-large-content form-categories-agri" style={{ height: '100%', overflowY:'auto', overflowX:'hidden', paddingLeft: '20px', paddingRight:'20px', paddingBottom:'20px', maxHeight: !this.props.iframe ? '80vh' : 'auto'}}>
                {this.isLoading() ? 
                    <div className='col-md-12'>
                        <div className="center-div" style={{paddingTop:'100px'}}>
                            <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                            <br/><br/>Chargement des informations...
                        </div>
                    </div>
                :
                <div>
                    {this.state.typesProduction && this.state.typesProduction.length ?
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/*<h3 style={{ fontSize: '18px' }}>Types de production</h3>*/}
                                    {lodash.map(typesProduction, (cp, idx) => {

                                        var lines = [];
                                        var nbByCol = Math.ceil(cp.length / 2);
                                        for(var i=0; i<nbByCol; ++i){
                                            let j = i+nbByCol;
                                            lines.push(
                                                <div className="row" key={"rowscp_"+idx+"_"+i} style={{marginLeft:0}}>
                                                {cp[i] ? 
                                                    <div className="col-xs-3 scp">
                                                        <span>
                                                            <input type='checkbox' name={"scp["+cp[i].IDTypeProduction+"]"} value={parseInt(cp[i].IDTypeProduction)} checked={this.state.categCheckedIDs.indexOf(cp[i].IDTypeProduction) !== -1} id={"scp_"+cp[i].IDTypeProduction} onChange={(e) => this.checkSCP(e.target.checked, [e.target.value])}/>
                                                            <label className="label-box" htmlFor={"scp_"+cp[i].IDTypeProduction}>{cp[i].LibelleSousCategorieProduction ? cp[i].LibelleSousCategorieProduction : <i>Non précisé</i>}</label>
                                                        </span>
                                                    </div>
                                                :
                                                    <div className="col-xs-3"></div>
                                                }
                                                {cp[i] ? 
                                                    <div className={"col-xs-3 vp form-group" + (this.state.categCheckedIDs.indexOf(cp[i].IDTypeProduction) === -1 ? " faded" : "")}>
                                                        <span>
                                                            <input type="text" maxLength={20} className="volume-production form-control" value={this.state.vpValues[cp[i].IDTypeProduction] ? this.state.vpValues[cp[i].IDTypeProduction] : ""} size={10} onChange={this.onChangeValueVP.bind(this, cp[i].IDTypeProduction)} disabled={this.state.categCheckedIDs.indexOf(cp[i].IDTypeProduction) === -1 ? "disabled" : null}/>
                                                            {cp[i].LibelleVolumeProduction ? cp[i].LibelleVolumeProduction : null}
                                                        </span>
                                                    </div>
                                                :
                                                    <div className="col-xs-3"></div>
                                                }
                                                {cp[j] ? 
                                                    <div className="col-xs-3 scp" style={{paddingLeft:'30px'}}>
                                                        <span>
                                                            <input type='checkbox' name={"scp["+cp[j].IDTypeProduction+"]"} value={parseInt(cp[j].IDTypeProduction)} checked={this.state.categCheckedIDs.indexOf(cp[j].IDTypeProduction) !== -1} id={"scp_"+cp[j].IDTypeProduction} onChange={(e) => this.checkSCP(e.target.checked, [e.target.value])}/>
                                                            <label className="label-box" htmlFor={"scp_"+cp[j].IDTypeProduction}>{cp[j].LibelleSousCategorieProduction ? cp[j].LibelleSousCategorieProduction : <i>Non précisé</i>}</label>
                                                        </span>
                                                    </div>
                                                :
                                                    <div className="col-xs-3"></div>
                                                }
                                                {cp[j] ? 
                                                    <div className={"col-xs-3 vp form-group" + (this.state.categCheckedIDs.indexOf(cp[j].IDTypeProduction) === -1 ? " faded" : "")}>
                                                        <span>
                                                            <input type="text" maxLength={20} className="volume-production form-control" value={this.state.vpValues[cp[j].IDTypeProduction] ? this.state.vpValues[cp[j].IDTypeProduction] : ""} size={10} onChange={this.onChangeValueVP.bind(this, cp[j].IDTypeProduction)} disabled={this.state.categCheckedIDs.indexOf(cp[j].IDTypeProduction) === -1 ? "disabled" : null}/>
                                                            {cp[j].LibelleVolumeProduction ? cp[j].LibelleVolumeProduction : null}
                                                        </span>
                                                    </div>
                                                :
                                                    <div className="col-xs-3"></div>
                                                }
                                                </div>
                                            )
                                        }
                                        return (
                                            <div className="row" key={"cp"+idx}>
                                                <div className="col-xs-12 cp text-orange">
                                                    {cp[0].LibelleCategorieProduction ? cp[0].LibelleCategorieProduction : <i>Non précisé</i>}
                                                </div>
                                                {lines}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    :
                        <div style={{padding:'20px'}}>Aucune configuration des catégories de production n'existe pour cette catégorie de client</div>
                    }
                    <div className="row" style={{marginTop:'30px', marginBottom:'15px'}}>
                        <div className="col-xs-10 col-xs-offset-2 text-right">
                            {this.state.savingMessage ?
                                <span style={{ marginRight: '15px' }}>
                                    {this.state.savingMessage}
                                </span>
                            :null}
                            <button type="button" className="btn btn-default" disabled={!this.canSubmit()} onClick={() => this.save()}>Enregistrer</button>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default TypesProduction;
