import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import SweetAlert from 'react-bootstrap-sweetalert';

import Globals from '../Globals';

import moment from 'moment'

import InputTime from '../Components/InputTime';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

import Loader from '../Components/Loader';
var lodash = require('lodash')
var Datetime = require('react-datetime');

class RapportEdit extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      rapport: null,
      sites: [],

      fetchCreateInProgress: false,
      successCreate: false,
      errorCreate: false,
      errorCreateMessage: '',
    }
  }

  componentDidMount() {
    this.fetchRapport();
    this.fetchEtapes();
    this.fetchInterets();
    this.fetchSites();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.IDLead !== this.props.params.IDLead) {
      this.fetchRapport();
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      fetchCreateInProgress: true,
    });

    var body = this.state.rapport;
    body.Historiques = null;
    body = JSON.stringify(body);

    fetch(localStorage.ClientApiURL + '/Leads/' + this.state.rapport.IDLead, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }

      fetch(localStorage.ClientApiURL + '/Affaires/' + this.state.rapport.Affaire.IDAffaire, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
        body: JSON.stringify(this.state.rapport.Affaire),
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            this.setState({
              fetchCreateInProgress: false,
              errorCreate: true,
              errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
            });
          });

          throw Error(response.statusText);
        }

        this.setState({
          fetchCreateInProgress: false,
          successCreate: true,
          errorCreate: false,
          errorCreateMessage: '',
        });

        return response;
      })
      .catch((error) => {
        console.warn(error);
      });
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  fetchRapport() {

    fetch(localStorage.ClientApiURL + '/Leads/' + this.props.params.IDLead, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      console.log(json)
      this.setState({
        rapport: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSites() {

    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var sites = [];

      json.map((plaque) => {
        return plaque.Sites.map((site) => {
          sites.push(site);
          return site;
        })
      })

      sites = lodash.uniqBy(sites, (site) => {
        return site.IDSite;
      })
      sites = lodash.sortBy(sites, (site) => {
        return site.Plaque.Nom + ' - ' + (site.Libelle ? site.Libelle : site.Nom);
      })

      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchEtapes() {
    this.setState({
      etapes: [
        {
            "IDEtape": 1,
            "Description": "A qualifier"
        },
        {
            "IDEtape": 2,
            "Description": "Découverte"
        },
        {
            "IDEtape": 3,
            "Description": "Essai"
        },
        {
            "IDEtape": 4,
            "Description": "Offre"
        },
        {
            "IDEtape": 5,
            "Description": "Négociation"
        },
        {
            "IDEtape": 6,
            "Description": "Commande"
        }
      ]
    });
  }

  fetchInterets() {
    fetch(localStorage.ClientApiURL + '/NiveauInterets', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        interets: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeEtat(event) {
    var rapport = this.state.rapport;
    rapport.ActionAFaire_TODO.Etat = event.target.value;
    this.setState({
      rapport: rapport,
    })
  }

  getInteretDescription() {
    let choiceInteret;

    this.state.interets.map((interet, i) => {
      if (interet.IDNiveauInteret === this.state.rapport.ActionAFaire_TODO.NiveauInteret.IDNiveauInteret) {
        choiceInteret = interet;
      }
      return interet
    });

    if (choiceInteret)
      return choiceInteret.Description;

    return '';
  }

  getInteretNiveau() {
    let choiceInteret;

    if (!this.state.interets) {
      return 0;
    }

    this.state.interets.map((interet, i) => {
      if (interet.IDNiveauInteret === this.state.rapport.ActionAFaire_TODO.NiveauInteret.IDNiveauInteret) {
        choiceInteret = interet;
      }
      return interet
    });

    if (choiceInteret)
      return choiceInteret.Niveau;

    return 4;
  }

  changeNiveauInteret(value) {
    var rapport = this.state.rapport;
    rapport.ActionAFaire_TODO.NiveauInteret = {IDNiveauInteret: this.state.interets[value].IDNiveauInteret, Niveau: value};
    this.setState({
      rapport: rapport,
    })
  }

  changeDate(event) {

    var rapport = this.state.rapport;
    var current = moment(rapport.ActionAFaire_TODO.DateHRelance);
    rapport.ActionAFaire_TODO.DateHRelance = event.set('hour', current.get('hour')).set('minute', current.get('minute')).set('second', 0).set('millisecond', 0).format('YYYY-MM-DD HH:mm:ss');
    rapport.ActionAFaire_TODO.DateHFinRelance = null;
    rapport.ActionAFaire_TODO.DateHProchaineEtape = rapport.ActionAFaire_TODO.DateHRelance;
    this.setState({
      rapport: rapport,
    })
  }

  isValidDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  changeHeureRelance(event) {

    var rapport = this.state.rapport;
    var current = moment(rapport.ActionAFaire_TODO.DateHRelance);
    rapport.ActionAFaire_TODO.DateHRelance = current.set('hour', event.get('hour')).set('minute', event.get('minute')).set('second', 0).set('millisecond', 0).format('YYYY-MM-DD HH:mm:ss');
    rapport.ActionAFaire_TODO.DateHFinRelance = null;
    rapport.ActionAFaire_TODO.DateHProchaineEtape = rapport.ActionAFaire_TODO.DateHRelance;
    this.setState({
      rapport: rapport,
    })
  }

  changeEtape(event) {
    var rapport = this.state.rapport;
    rapport.ActionAFaire_TODO.Etape.IDEtape = event.target.value;
    this.setState({
      rapport: rapport,
    })
  }

  changeAction(event) {
    var rapport = this.state.rapport;
    rapport.ActionAFaire_TODO.ActionAFaire = {Description: event.target.value};
    this.setState({
      rapport: rapport,
    })
  }

  changeComment(event) {
    var rapport = this.state.rapport;
    rapport.ActionFaite_DO.Commentaire = event.target.value;
    this.setState({
      rapport: rapport,
    })
  }

  hideAlert() {
    this.setState({
      errorCreate: false,
      errorCreateMessage: '',
    });
  }

  propageSuccess() {
    browserHistory.push('/prospect/' + this.state.rapport.Prospect.IDProspect);
  }

  getValueDate() {

    if (!this.state.rapport.ActionAFaire_TODO.DateHRelance) {
      return '';
    }

    var _return = moment();
    var date = moment(this.state.rapport.ActionAFaire_TODO.DateHRelance)

    _return.set('date', date.get('date')).set('month', date.get('month')).set('year', date.get('year'));
    return _return;
  }

  getValueHeure() {

    if (!this.state.rapport.ActionAFaire_TODO.DateHRelance) {
      return '';
    }

    if (moment(this.state.rapport.ActionAFaire_TODO.DateHRelance).format('HHmm') === '0000') {
      return '';
    }

    var _return = moment();
    var date = moment(this.state.rapport.ActionAFaire_TODO.DateHRelance)
    _return.set('hour', date.get('hour')).set('minute', date.get('minute'));
    return _return;
  }

  changeSite(event) {
    var value = event.target.value;
    var values = value.split('-');

    var rapport = this.state.rapport;

    rapport.Affaire.Type = values[0];

    if (localStorage.IsVDL === 'true') {
      rapport.Site = {IDSite: event.target.value};
    }
    else {
      rapport.Site = {IDSite: parseInt(values[1], 10)};
    }

    this.setState({
      rapport: rapport,
    })
  }

  render() {

    if (!this.state.rapport) {
      return <div className='container-fluid'><Loader style={{ marginTop: 50 }} /></div>
    }

    return (
      <div className='container-fluid'>
        <div className='row'>

        {this.state.successCreate ?
          <SweetAlert success title='' onConfirm={() => this.propageSuccess()}>
            Édition de l'action avec succès !
          </SweetAlert>
        : null}

        {this.state.errorCreate ?
          <SweetAlert error title="Une erreur s'est produite" onConfirm={() => this.hideAlert()}>
            {this.state.errorCreateMessage}
          </SweetAlert>
        : null}

          <div className='col-md-12'>
            <h2 className='title'>Édition de l'action</h2>
          </div>

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className='col-md-4'>
              <div className="form-group">
                <label className="col-md-4">État</label>
                <div className=" col-md-8">
                  <select className="form-control" id="raison" name="raison" onChange={this.changeEtat.bind(this)} value={this.state.rapport.ActionAFaire_TODO.Etat} required="required">
                    <option value='EnCours'>En cours</option>
                    <option value='Termine'>Terminé</option>
                  </select>
                </div>
              </div>

              {this.state.etapes ?
                <div className="form-group">
                  <label className="col-md-4">Étape</label>
                  <div className=" col-md-8">
                    <select className="form-control" id="etapes" name="etapes" onChange={this.changeEtape.bind(this)} value={this.state.rapport.ActionAFaire_TODO.Etape ? this.state.rapport.ActionAFaire_TODO.Etape.IDEtape : ''} >
                      <option></option>
                      {this.state.etapes.map((etape, i) => {

                        if (etape.Description === 'Rejet' || etape.Description === 'Abandon')
                          return null;

                        return <option key={i} value={etape.IDEtape}>{etape.Description}</option>
                      })}
                    </select>
                  </div>
                </div>
              : null}

              <div className="form-group">
                <label className="col-md-4">Action</label>
                <div className=" col-md-8">
                  <select className="form-control" id="action" name="action" onChange={this.changeAction.bind(this)} value={this.state.rapport.ActionAFaire_TODO.ActionAFaire ? this.state.rapport.ActionAFaire_TODO.ActionAFaire.Description : ''}>
                    <option value=''></option>
                    <option value='Email'>Email</option>
                    <option value='Téléphoner'>Téléphoner</option>
                    <option value='SMS'>SMS</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label className="col-md-4">Site</label>
                <div className=" col-md-8">
                  <select className="form-control" id="action" name="action" onChange={this.changeSite.bind(this)} value={localStorage.IsVDL === 'true' ? this.state.rapport.Site.IDSite : this.state.rapport.Affaire.Type+'-'+this.state.rapport.Site.IDSite}>
                  {localStorage.IsVDL === 'true' ?
                    this.state.sites.map((site, i) => {
                      return <option key={i} value={site.IDSite}>{site.Plaque.Nom + ' - ' + (site.Libelle ? site.Libelle : site.Nom)}</option>
                    })
                  :
                    [this.state.sites.map((site, i) => {
                      if (!site.EstVN) {
                        return null;
                      }
                      return <option key={'VN-' + i} value={'VN-' + site.IDSite}>VN - {site.Plaque.Nom + ' - ' + (site.Libelle ? site.Libelle : site.Nom)}</option>
                    }),
                    this.state.sites.map((site, i) => {
                      if (!site.EstVO) {
                        return null;
                      }
                      return <option key={'VO-' + i} value={'VO-' + site.IDSite}>VO - {site.Plaque.Nom + ' - ' + (site.Libelle ? site.Libelle : site.Nom)}</option>
                    })]
                  }
                  </select>
                </div>
              </div>

            </div>

            <div className='col-md-4'>
              <div className="form-group datepicker-center">
                <div className="row">
                  <label className="col-md-4">Le</label>
                  <div className="col-md-6">
                    <Datetime className="datetime" dateFormat="DD/MM/YYYY" closeOnSelect={true} timeFormat={false} locale="fr" isValidDate={this.isValidDate.bind(this)} value={this.getValueDate()} onChange={this.changeDate.bind(this)} />
                  </div>
                  <div className="col-md-1">
                    <i className="icon-calendar icons"></i>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-4">à</label>
                  <div className="col-md-6">
                      <InputTime value={this.getValueHeure()} onChange={this.changeHeureRelance.bind(this)} />
                  </div>
                  <div className="col-md-1">
                    <i className="icon-clock icons"></i>
                  </div>
                  </div>
              </div>

              <div className="form-group">
                <label className="col-md-4">INTERET</label>
                <div className="col-md-8">
                  <Slider min={0} max={5} defaultValue={this.getInteretNiveau()} onChange={this.changeNiveauInteret.bind(this)} />
                </div>
                {this.state.interets ?
                  <div className="col-md-8 col-md-offset-4">
                    <span className="help-block" style={{ fontSize: '12px', textAlign: 'left' }}>
                      {this.getInteretNiveau()} : {this.getInteretDescription()}
                    </span>
                  </div>
                : null }
                <div className='clearfix'></div>
              </div>

            </div>

            <div className='col-md-4'>
              <div className="form-group">
                <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.state.rapport.ActionFaite_DO.Commentaire ? this.state.rapport.ActionFaite_DO.Commentaire.replace(/(?:\\[rn])+/g, '\n') : ''} onChange={this.changeComment.bind(this)} />
              </div>
            </div>

            <div className='col-md-12 text-center'>
              <br />
              <button type="submit" className="text-center btn btn-default submit-vn" disabled={this.state.fetchCreateInProgress}>
                {this.state.fetchCreateInProgress ?
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                : null}
                Valider
               </button>
             </div>
          </form>

        </div>
      </div>
    );
  }
}

export default RapportEdit;
