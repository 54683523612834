import React, { Children, Component } from 'react';

class FilterBlock extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isOpen === true
    }
  }

  openOrClose() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    return (
      <div className={'filter-block '+this.props.className}>
        {this.props.title ? 
          <div className='filter-block-title' onClick={this.openOrClose.bind(this)}>
            <div className='filter-block-title-text'>
              {this.props.title}
            </div>
            <div className={'filter-block-title-arrow '+(!this.state.isOpen ? 'filter-block-title-arrow-closed' : null)}>
              &#9650;
            </div>
          </div>
        :null}
        <div className={'filter-block-body '+(!this.props.title ? 'filter-block-body-no-title' : '')} style={{display: this.state.isOpen ? "block" : "none"}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default FilterBlock;
