import moment from 'moment';
import Globals from '../Globals';

/**
 * Rapports
 */
const LibRapports = {

  /**
   * Récupère les Rapports de l'utilisateur
   * @param IDPeople integer IDPeople de l'utilisateur 
   * @param date_relance_debut YYYY-MM-DD Rapports dont la relance est égale à ou après cette date 
   * @param date_relance_fin YYYY-MM-DD Rapports dont la relance est égale à ou avant cette date 
   * @param only_actions bool Si true, seulement les rapports non liés à une affaire
   * @return array
   */
  async list(IDPeople, date_relance_debut = null, date_relance_fin = null, only_actions = false) {
    try {
      const response = await fetch(localStorage.ClientApiURL+'/utilisateurs/'+IDPeople+'/rapports?date_relance_debut='+date_relance_debut+'&date_relance_fin='+date_relance_fin+'&only_actions='+only_actions, {
        method: 'GET',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
      });
      if(response.ok) {
        return response.json();
      }
      else {
        const error = await response.json();
        console.log(error);
        return [];
      }
    }
    catch(error){
      console.log(error);
      return [];
    }
  },

  /**
   * Récupère les actions de l'utilisteurs (rapports non liés à une affaire)
   * @param IDPeople integer IDPeople de l'utilisateur 
   * @param date_relance_debut YYYY-MM-DD Rapports dont la relance est égale à ou après cette date 
   * @param date_relance_fin YYYY-MM-DD Rapports dont la relance est égale à ou avant cette date 
   * @return array
   */
  async getActions(IDPeople, date_relance_debut = null, date_relance_fin = null) {
    return this.list(IDPeople, date_relance_debut, date_relance_fin, true);
  },

  async getActionsATraiter(IDPeople) {
    return this.list(IDPeople, moment().format("YYYY-MM-DD"), null, true);
  },

  async getActionsEnRetard(IDPeople) {
    return this.list(IDPeople, null, moment().add(-1, 'day').format("YYYY-MM-DD")+"T23:59:59", true);
  },

  /**
   * Transfert plusieurs rapports/leads à un utilisateur
   * @param Lead lead https://api.e-seller.selectup.com/Help/ResourceModel?modelName=Lead 
   * @param int IDPeople Destinataire des rapports/leads
   */
  async transfertManyTo(leads, IDPeople) {
    try {
      const response = await fetch(localStorage.ClientApiURL + '/leads/transferer_many?id_destinataire=' + IDPeople, {
        method: 'POST',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
        body: JSON.stringify(leads)
      });
      if(response.ok) {
        return true;
      }
      else {
        const error = await response.json();
        console.log(error);
        return false;
      }
    }
    catch(error){
      console.log(error);
      return false;
    }
  },

  /**
   * Clôturer un ou plusieurs rapports
   * Attention si le rapport est lié à une affaire celle-ci ne sera pas clôturée
   * @param Lead lead https://api.e-seller.selectup.com/Help/ResourceModel?modelName=Lead 
   */
  async cloturerRapports(leads) {
    try {
      const response = await fetch(localStorage.ClientApiURL + '/leads/cloturer_rapports', {
        method: 'POST',
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json',
          'APIKey':       Globals.API_KEY,
          'IDClient':     localStorage.user_IDClient,
          'Login':        localStorage.user_login,
          'Password':     localStorage.user_password
        },
        body: JSON.stringify(leads)
      });
      if(response.ok) {
        return true;
      }
      else {
        const error = await response.json();
        console.log(error);
        return false;
      }
    }
    catch(error){
      console.log(error);
      return false;
    }
  }
}

export default LibRapports;