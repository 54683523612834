import React, {Component} from 'react';

import moment from 'moment'
import InputTime from '../Components/InputTime';
var Datetime = require('react-datetime');

var lodash = require('lodash');

const styleButtonRaisonAppelSortant = {
  height: '30px',
  lineHeight: '15px',
  display: 'block',
  margin: '5px',
}

class FormAppelSortant extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      comment: '',
      qualification: '',
    }
  }

  codesAppelsSortants = [
    {'code':61, 'libelle':'Répondeur/occupé', 'type': 'Non abouti', 'cloture': true, 'relance': true, 'creation': true},
    {'code':62, 'libelle':'Faux numéro', 'type': 'Non abouti', 'cloture': true, 'relance': false, 'creation': true},
    {'code':63, 'libelle':'Toujours injoignable', 'type': 'Non abouti', 'cloture': true, 'relance':true, 'creation':true},

    {'code':15, 'libelle':'Entretien récent fait', 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':50, 'libelle':'Contactera la concession', 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':30, 'libelle':'Réclamation client', 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':25, 'libelle':'A quitté la région', 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':26, 'libelle':"N'a plus le véhicule", 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':16, 'libelle':"Non acceptation de l'offre", 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':18, 'libelle':'Autre raison', 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':10, 'libelle':'Barrage/Décisionnaire injoignable', 'type': 'Refus argumenté',  'cloture': true, 'relance': true, 'creation': true},
    {'code':17, 'libelle':"N'a pas reçu l'offre", 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},
    {'code':14, 'libelle':"Déjà en relation avec un garage", 'type': 'Refus argumenté', 'cloture': true, 'relance': true, 'creation': true},

    {'code':71, 'libelle':'Refus de répondre aux questions', 'type': 'Refus non argumenté', 'cloture': true, 'relance': false, 'creation': true},
    {'code':79, 'libelle':'Décédé', 'type': 'Refus non argumenté', 'cloture': true, 'relance': false, 'creation': true},
    {'code':73, 'libelle':'Stop téléphone', 'type': 'Refus non argumenté', 'cloture': true, 'relance': false, 'creation': true},
    {'code':64, 'libelle':'Numéro réaffecté', 'type': 'Refus non argumenté', 'cloture': true, 'relance': false, 'creation': true},
  ];

  generateComment() {

    var comment = '';
    
    if (this.state.qualification) {
      comment += "Qualification de l'appel sortant : " + this.state.qualification + "\n";
    }

    comment += this.state.comment;

    return comment;
  }

  changeRelanceDate(event) {
    this.props.changeValue('relanceDate', event.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
  }

  isValidRelanceDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  changeRelance(event) {
    this.props.changeValue('relance', event.target.checked);
  }

  isValidHeure(current) {
    return true;
  }

  changeRelanceHeure(event) {
    this.props.changeValue('relanceHeure', event);
  }

  updateChangeValue(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      this.props.changeValue('appelSortantComment', this.generateComment());
    });
  }

  onClickRaison(codeRaisonClotureAppelSortant, event) {

    this.setState({
      codeRaisonClotureAppelSortant: codeRaisonClotureAppelSortant,
      qualification: event.target.textContent,
    }, () => {

      this.props.changeValue('appelSortantComment', this.generateComment());

      var code = lodash.find(this.codesAppelsSortants, {'code': codeRaisonClotureAppelSortant});

      this.props.changeValue('appelSortantDisableRelance', !code.relance); // enable/disable relance
      this.props.changeValue('appelSortantDisableCloture', !code.cloture); // enable/disable cloture
      this.props.changeValue('codeRaisonClotureAppelSortant', this.state.codeRaisonClotureAppelSortant);
    });
  }

  render() {

    return (
      <div className="panel panel-default">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-atelier">
            <h2>
              <i className="fa fa-bell" aria-hidden="true"></i>&nbsp; Appel sortant
            </h2>
          </a>
        </li>
      </ul>
        <div className="row">

          {this.props.displayRaisonCloture ?
            <div className="col-md-12">

              <div className="col-md-4">
                <h3 className='text-center'>Non abouti</h3>

                {lodash.map(this.codesAppelsSortants, function(a) {
                  if(a.type === 'Non abouti')
                  return (
                    <a className='btn btn-primary' style={styleButtonRaisonAppelSortant} disabled={this.state.codeRaisonClotureAppelSortant && this.state.codeRaisonClotureAppelSortant != a.code} onClick={this.onClickRaison.bind(this, a.code)}>
                      <b>{a.code}</b> - {a.libelle}
                    </a>
                  );
                }.bind(this))}

              </div>

              <div className="col-md-4">
                <h3 className='text-center'>Refus argumenté</h3>

                {lodash.map(this.codesAppelsSortants, function(a) {
                  if(a.type === 'Refus argumenté')
                  return (
                    <a className='btn btn-primary' style={styleButtonRaisonAppelSortant} disabled={this.state.codeRaisonClotureAppelSortant && this.state.codeRaisonClotureAppelSortant != a.code} onClick={this.onClickRaison.bind(this, a.code)}>
                      <b>{a.code}</b> - {a.libelle}
                    </a>
                  );
                }.bind(this))}

              </div>

              <div className="col-md-4">
                <h3 className='text-center'>Refus non argumenté</h3>

                {lodash.map(this.codesAppelsSortants, function(a) {
                  if(a.type === 'Refus non argumenté')
                  return (
                    <a className='btn btn-primary' style={styleButtonRaisonAppelSortant} disabled={this.state.codeRaisonClotureAppelSortant && this.state.codeRaisonClotureAppelSortant != a.code} onClick={this.onClickRaison.bind(this, a.code)}>
                      <b>{a.code}</b> - {a.libelle}
                    </a>
                  );
                }.bind(this))}

              </div>

            </div>
          : null}

          <div className="col-md-12 col-xs-12">
            <div className="form-group">

              <div className="col-md-12">
                  <textarea  name="comment" id="comment" style={{ width: '100%', height: '120px' }} onChange={evt => this.updateChangeValue(evt)} placeholder="Commentaire" value={this.state.comment}></textarea>
              </div>
            </div>

            {this.props.appelSortantRelanceForm ?
              <div className="col-md-4 col-md-offset-4 col-xs-12">
                <div className="form-group datepicker-center">
                  <label className="col-md-2">Le</label>
                  <div className="col-md-8">
                    <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false}  isValidDate={this.isValidRelanceDate.bind(this)} defaultValue={this.props.relanceDate} open={false} onChange={this.changeRelanceDate.bind(this)} />
                  </div>
                  <div className="col-md-2">
                    <i className="icon-calendar icons" style={{ paddingTop: '4px', display: 'block' }}></i>
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">à</label>
                  <div className="col-md-8">
                    <InputTime value={this.props.relanceHeure} onChange={this.changeRelanceHeure.bind(this)} SelectStyle={{paddingLeft: '3px'}} />
                  </div>
                  <div className="col-md-2">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>
              </div>
            : null}
          </div>

        </div>
      </div>
    );
  }
}

export default FormAppelSortant;
