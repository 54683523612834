import React, {Component} from 'react';
import { browserHistory } from 'react-router'

import Globals from '../Globals';
import { Link } from 'react-router';

import moment from 'moment'

import Loader from '../Components/Loader';
import BadgeDiffDay from '../Components/BadgeDiffDay';
import PastilleInteret from '../Components/PastilleInteret';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowComment from '../Components/TableRowComment';
import TableRowDateRelance from '../Components/TableRowDateRelance';

import FormToDoRapport from '../LeadCreate/FormToDoRapport.js'

import FormRelance from '../LeadCreate/FormRelance.js'

import SweetAlert from 'react-bootstrap-sweetalert';

import SkyLight from 'react-skylight';
import Scheduler from '../Pages/Scheduler.js'
var lodash = require('lodash')

class RapportCreate extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      prospect: null,

      fetchCreateInProgress: false,
      successCreate: false,
      errorCreate: false,
      errorCreateMessage: '',

      relanceAction: '',
      relanceComment: '',
    }
  }

  componentDidMount() {
    this.fetchProspect();
  }

  fetchProspect() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.IDProspect, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        prospect: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }


  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      fetchCreateInProgress: true,
    });

    let body = {
      IDLead: null,
      "Campagne" : null,
      Site: {
        IDSite: this.state.prospect.InfoModification && this.state.prospect.InfoModification.Site ? this.state.prospect.InfoModification.Site.IDSite : this.state.prospect.InfoCreation.Site.IDSite,
      },
      Prospect: {
        IDProspect: this.props.params.IDProspect,
      },
      "OrigineAction": {
        "IDOrigineAction": null,
      },
      "SuiviPar": {
        "IDUtilisateur": localStorage.user_IDUtilisateur,
      },
      ActionFaite_DO: {
        "DateHLead": moment().format(),
        "TypeContact": {
          "Description": this.state.relanceAction,
        },
        Commentaire: this.state.relanceComment,
      },
      "ActionAFaire_TODO": {
        "Etape": null,
        "ActionAFaire": null,
        "NatureActionAFaire": "Téléphone",
        "NiveauInteret": {
          "IDNiveauInteret": 1,
        },
        "EstDateLimite": false,
        "DateHRelance": null,
        "DateHFinRelance": null,
        "DateHProchaineEtape": null,
        "Etat": "Termine",
      },
    };

    body = JSON.stringify(body);

    console.log(body)

    fetch(localStorage.ClientApiURL + '/Leads/creer', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        fetchCreateInProgress: false,
        successCreate: true,
        errorCreate: false,
        errorCreateMessage: '',
      });
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  hideAlert() {
    this.setState({
      errorCreate: false,
      errorCreateMessage: '',
    });
  }

  propageSuccess() {
    browserHistory.push('/prospect/' + this.props.params.IDProspect);
  }

  render() {

    if (!this.state.prospect) {
      return <div className='container-fluid'><Loader style={{ marginTop: 50 }} /></div>
    }

    return (
      <div className='container-fluid'>

        {this.state.successCreate ?
          <SweetAlert success title='' onConfirm={() => this.propageSuccess()}>
            Ajout du rapport avec succès !
          </SweetAlert>
        : null}

        {this.state.errorCreate ?
          <SweetAlert error title="Une erreur s'est produite" onConfirm={() => this.hideAlert()}>
            {this.state.errorCreateMessage}
          </SweetAlert>
        : null}

        <div className='row'>

          <div className='col-md-12' style={{ marginBottom: '20px' }}>
            <h2><img src="/img/PROSPECT.svg" role='presentation' /> Prospect</h2>

            <div className="rapport-details">
              <Link to={'/prospect/' + this.state.prospect.IDProspect}>
                {this.state.prospect.Nom}, {this.state.prospect.Prenom}, {this.state.prospect.Adresse}, {this.state.prospect.CodePostal}, {this.state.prospect.Ville}, {this.state.prospect.Telephones ? this.state.prospect.Telephones.map((phone, i) => {return phone.Numero+' '}) : null}, {this.state.prospect.Mail}
              </Link>
            </div>
          </div>

          <form className="form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
            <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">

              <div className="form-group">
                <label className="col-md-4">Action</label>
                <div className=" col-md-8">
                  <select className="form-control" id="action" name="action" onChange={(ev) => this.setState({relanceAction: ev.target.value})} value={this.state.relanceAction} required="required">
                    <option value=''></option>
                    <option value='Email'>Email</option>
                    <option value='Téléphoner'>Téléphoner</option>
                    <option value='SMS'>SMS</option>
                  </select>
                </div>
              </div>

              <div className="form-group" style={{ marginLeft: '0px', marginRight: '0px', }}>
                <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.state.relanceComment} onChange={(ev) => this.setState({relanceComment: ev.target.value})} />
              </div>

              <div className="col-md-12">
                <div className="center-div bigger-bottom">
                  <button type="submit" className="text-center btn btn-default submit-vn" disabled={this.state.fetchCreateInProgress}>
                    {this.state.fetchCreateInProgress ?
                      <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    : null}
                    Valider
                   </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default RapportCreate;
