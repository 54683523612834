import React, { Component } from 'react';
import PanelFilter from '../Leads/PanelFilter';

var lodash = require('lodash')

class FilterUrgent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      priorites: [],
      selected: [],
    }
  }

  componentWillMount() {
    this.loadpriorites();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.priorites !== prevProps.priorites) {
      this.loadpriorites();
    }
  }

  loadpriorites() {
    var priorites = [];

    lodash.forIn(this.props.priorites, function(value, key){
      priorites.push({name: key, count: value});
    });

    priorites = lodash.sortBy(priorites, ['name']);

    this.setState({
      priorites: priorites,
    });
  }

  onChange(event, priorite) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(priorite)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== priorite;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('prioritesDemandes', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.priorites, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('prioritesDemandes', this.state.selected);
    });
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  isChecked(priorite) {
    if (this.state.selected.length === 0) {
      return false;
    }

    return this.state.selected.indexOf(priorite) > -1 ? true : false
  }


  render() {

    var priorites = null;

    if (this.state.priorites.length) {
      priorites = this.state.priorites.map((priorite, i) => {
        if (!priorite.name)
          return null;

        return <div className="checkbox" key={i}>
          <input type="checkbox" id={this.removeWhiteSpace("priorite_"+priorite.name)} checked={this.isChecked(priorite.name)} onChange={(event) => { this.onChange(event, priorite.name); }} />
          <label className="label-box" htmlFor={this.removeWhiteSpace("priorite_"+priorite.name)}>
            {priorite.name}
            &nbsp;<span className="count">({priorite.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Priorité" allSelected={this.state.selected.length === this.state.priorites.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {priorites}
      </PanelFilter>
    );
  }
}

export default FilterUrgent;
