/**
 * Permet de faire la correspondance des termes qui différent selon l'activité, par exemple Véhicule/Matériel
 * S'initialise automatiquement à la première utilisation.
 * 
 * Exemple d'utilisation :
 * import Dictionary from './Dictionary;
 * 
 * Dictionary.getUCFirst("véhicule"); //Retourne "Matériel" sur Agri, sinon "Véhicule"
 */

const lodash = require('lodash');

const TermsByActivity = {
  "Agri": {
    "véhicule": "matériel",
    "VN": "neuf",
    "VO": "occ",
    "VN/VO": "Neuf/Occ"
  },
  "VDL": {
    "VN": "VDL",
    "VN/VO": "VDL"
  }
}

const Dictionary = {
  activity: null,
  terms: {},

  init: function(){
    console.log("Dico init");
    if(localStorage.IsAgri === 'true') {
      this.activity = "Agri";
    }
    else if(localStorage.IsVDL === 'true') {
      this.activity = "VDL";
    }
    else {
      this.activity = "Auto";
    }

    if(TermsByActivity[this.activity])
      this.terms = TermsByActivity[this.activity];
  },

  /**
   * Retourne le terme correspondant pour l'activité courante
   * @param {string} term 
   */
  get: function(term){
    if(!this.activity)
      this.init();

    return this.terms[term] ? this.terms[term] : term;
  },

  /**
   * Retourne le terme correspondant pour l'activité courante, avec la première lettre en majuscule
   * @param {string} term 
   */
  getUCFirst: function(term){
    return lodash.upperFirst(this.get(term));
  },
}

export default Dictionary;