import React, { Component } from 'react';
import PanelFilter from './PanelFilter';
var lodash = require('lodash')

class FilterTypeClient extends Component {

  constructor(props) {
    super(props);

    this.state = {
      types: [],
      selected: [],
    }

    this.key = this.props.name || ""
  }

  componentDidMount() {
    this.initDatas();
    if(this.props.selected){
      this.setState({
        selected: this.props.selected
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leads !== prevProps.leads) {
      this.initDatas();
    }
  }

  initDatas() {
    var types = lodash.map(
      lodash.countBy(this.props.leads, (lead) => {
        return lead.Prospect.TypeClient;
      }),
      (nb, type) => {
        return {name: type, nb: nb}
      }
    );

    types = lodash.sortBy(types, ['name']);

    this.setState({
      types: types,
    });
  }

  onChangeType(event) {
    var type = event.target.name;
    var selected = this.state.selected;

    if(event.target.checked){
      selected.push(type);
    }
    else{
      selected = lodash.remove(selected, (t) => {
        return t != type;
      });
    }

    this.setState({
      selected: selected,
    }, () => {
      this.props.onChange('typesClient', this.state.selected);
    });
  }

  render() {

    var content = null;

    if (this.state.types.length) {
      content = this.state.types.map((type) => {
        let id = this.key+"filtreType-"+type.name;
        return (
          <div className="checkbox" key={type.name}>
            <input type="checkbox" name={type.name} id={id} checked={(this.state.selected.indexOf(type.name) !== -1)} onChange={this.onChangeType.bind(this)} />
            <label className="label-box" htmlFor={id}>
              {type.name}
              &nbsp;<span className="count">({type.nb})</span>
            </label>
          </div>
        );
      });
    }

    return (
      <PanelFilter title="Par type de client">
        {content}
      </PanelFilter>
    );
  }
}

export default FilterTypeClient;
