import React, { Component } from 'react';
import PanelFilter from './PanelFilter';

var lodash = require('lodash')

class FilterAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      selected: [],
    }
  }

  componentWillMount() {
    this.loadActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      this.loadActions();
    }
  }

  loadActions() {
    var actions = [];

    lodash.forIn(this.props.actions, function(value, key){
      actions.push({name: key, count: value});
    });

    actions = lodash.sortBy(actions, ['name']);

    this.setState({
      actions: actions,
    });
  }

  onChange(event, action) {
    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(action)
    }
    else {
      selected = lodash.remove(selected, function(n) {
        return n !== action;
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('actions', this.state.selected);
    });
  }

  onChangeAll(value) {
    var selected = [];

    if (value) {
      lodash.forIn(this.props.actions, function(value, key){
        selected.push(key);
      });
    }

    this.setState({selected: selected}, () => {
      this.props.onChange('actions', this.state.selected);
    });
  }

  removeWhiteSpace(str){
    str= str.replace(/\s/g, '');
    return str;
  }

  isChecked(action) {
    if (this.state.selected.length === 0) {
      return false;
    }

    return this.state.selected.indexOf(action) > -1 ? true : false
  }


  render() {

    var actions = null;

    if (this.state.actions.length) {
      actions = this.state.actions.map((action, i) => {
        return <div className="checkbox" key={i}>
          <input type="checkbox" id={this.removeWhiteSpace("action_"+action.name)} checked={this.isChecked(action.name)} onChange={(event) => { this.onChange(event, action.name); }} />
          <label className="label-box" htmlFor={this.removeWhiteSpace("action_"+action.name)}>
            {action.name}
            &nbsp;<span className="count">({action.count})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par action" allSelected={this.state.selected.length === this.state.actions.length ? true : false} onChangeAll={this.onChangeAll.bind(this)}>
        {actions}
      </PanelFilter>
    );
  }
}

export default FilterAction;
