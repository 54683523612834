import React, { Component } from 'react';

class ButtonList extends Component {

  constructor(props) {
    super(props);
  }

  onSelect(e){
    this.props.onSelect(e);
  }

  render() {
    return (
      <div className={"button-list " + (this.props.small ? 'sm' : '')}>
        <div className="btn-group flex-box">
        {
          typeof this.props.buttons === 'string' ?
            this.props.buttons.split(",").map((button, i) => {
              return (<button className={"btn btn-default btn-group-xs" + (button === this.props.value ? " active" : "")} 
                type="button" key={i} value={button} onClick={this.onSelect.bind(this)}>{button}</button>)
            })
          :
            this.props.buttons.map((button, i) => {
              return (<button className={"btn btn-default btn-group-xs" + (button.value === this.props.value ? " active" : "")} 
                type="button" key={i} value={button.value} onClick={this.onSelect.bind(this)}>{button.text ? button.text : button.value}</button>)
            })
        }
        </div>
      </div>
    );
  }
}

export default ButtonList;
