import React, { Component } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import equal from 'fast-deep-equal'
import { Link } from 'react-router';

import TableRowSite from '../Components/TableRowSite';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import BadgeDiffDay from '../Components/BadgeDiffDay';
import PastilleInteret from '../Components/PastilleInteret';
import PastilleVendeur from '../Components/PastilleVendeur';
import Loader from '../Components/Loader';

import Dictionary from '../Dictionnary';
import moment from 'moment'

class AffairesEncoursMap extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      lat: 48.866667,
      lng: 2.333333,
      zoom: 9,
      markers:[],
      coordinatesNotFound:0,
      fetchInProgress:true
    }
  }
  componentWillMount(){
    
  }

  componentDidMount(){

    navigator.geolocation.getCurrentPosition(
      (position) => {
       console.log(position);
       this.setState({
        lat:position.coords.latitude,
        lng:position.coords.longitude
        });
      },
    )
 
    this.fetchMarker()
    this.setState({
      fetchInProgress: false,
    });
  }
  
  componentDidUpdate(prevProps){
    if(!equal(this.props.leads, prevProps.leads) || !equal(this.props.filterName, prevProps.filterName))
    {
      this.fetchMarker()
      this.setState({
        fetchInProgress: false,
      });
    }
  }

  increaseCoordinatesNotFound(){
    this.setState(prevState => {
      return {coordinatesNotFound: prevState.coordinatesNotFound + 1}
   })
  }

  isEmptyOrSpaces(str){
    return str === null;
  }

  fetchMarker(){
    this.setState({
      markers:[],
      coordinatesNotFound:0,
    });
    var addresse = "";
    var urlApiGeoGouv ="";
    this.props.leads.map((lead, i) => {
        if (lead.Prospect.Nom.concat(' ' + lead.Prospect.Prenom).toLowerCase().search(this.props.filterName.toLowerCase()) === -1) {
          return false;
        }
        setTimeout(function(){},40); 
        addresse = lead.Prospect.Adresse + " " +  lead.Prospect.Ville
        if(!addresse.includes("null") && !this.isEmptyOrSpaces(addresse)){
          if(lead.Prospect.CodePostal == null || lead.Prospect.CodePostal == undefined){
            urlApiGeoGouv = "https://api-adresse.data.gouv.fr/search/?q="+addresse+"&limit=1"
          }else{
            urlApiGeoGouv = "https://api-adresse.data.gouv.fr/search/?q="+addresse+"&postcode="+lead.Prospect.CodePostal+"&limit=1"
          } 
          fetch(urlApiGeoGouv , {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then(json => {
            var {markers} = this.state;
            if(json.features.length > 0){
              console.log(json.features[0].properties.label)
              markers.push({"position":[json.features[0].geometry.coordinates[1],json.features[0].geometry.coordinates[0]],"lead":lead})
              this.setState({
                markers:markers,
              });
            }else{
              this.increaseCoordinatesNotFound()
            }
          })
          .catch(error => {
            this.increaseCoordinatesNotFound()
            console.log(error)
          });
        }else{
          this.increaseCoordinatesNotFound()
        }
    })
  }
  
  render() {

    const position = [this.state.lat, this.state.lng]
    return (
      <div className="" >
        {this.state.fetchInProgress === false ?
         
          <div>           
            <Map center={position} zoom={this.state.zoom} style={{ height: "75vh", width:"60vw", marginLeft: "25px", }}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {this.state.markers.map((marker, idx) => 
                <Marker key={`marker-${idx}`} position={marker.position}>
                <Popup>                 
                  <p style={{ margin:"5px" }}><PastilleVendeur utilisateur={marker.lead.SuiviPar} /></p>
                  <div style={{ margin:"5px" }}><span>Date relance : </span>
                  {marker.lead.ActionAFaire_TODO.DateHProchaineEtape ?
                    <TableRowDateRelance date={marker.lead.ActionAFaire_TODO.DateHProchaineEtape} />
                  :
                    <TableRowDateRelance date={marker.lead.InfoCreation.DateH} />
                  }
                  </div>
                  <p style={{ margin:"5px" }}>Site : <TableRowSite Site={marker.lead.Site} /></p>
                  <p style={{ margin:"5px" }}>Campagne / Origine : <TableRowCampagneOrigine campagne={marker.lead.Campagne} origine={marker.lead.ActionFaite_DO.OrigineAction} /></p>
                  <p style={{ margin:"5px" }}>Contact : {marker.lead.Prospect.Nom} {marker.lead.Prospect.Prenom}</p>
                  <p style={{ margin:"5px" }}>Type Matériel :
                    <span style={{ marginLeft:"5px" }}> 
                      {Dictionary.getUCFirst(marker.lead.Affaire.Type)}
                      {marker.lead.Affaire.Designation}
                    </span>
                  </p>
                  <div style={{ margin:"5px" }}>
                    <span style={{ marginLeft:"5px" }}>
                      <PastilleInteret niveau={marker.lead.ActionAFaire_TODO.NiveauInteret.Niveau} style={{ marginLeft:"10px" }}/> &nbsp;&nbsp;
                      {marker.lead.ActionAFaire_TODO.Etape ? marker.lead.ActionAFaire_TODO.Etape.Description : null}
                      {marker.lead.ActionAFaire_TODO.DateHProchaineEtape ?
                        <BadgeDiffDay date={moment(marker.lead.ActionAFaire_TODO.DateHProchaineEtape).format('HHmm') === '0000' ? moment(marker.lead.ActionAFaire_TODO.DateHProchaineEtape).format('YYYY-MM-DDT08:00:00') : marker.lead.ActionAFaire_TODO.DateHProchaineEtape} />
                      :
                        <BadgeDiffDay date={marker.lead.InfoCreation.DateH} />
                      }
                    </span>
                  </div>
                  </Popup>
                  </Marker>
                )}
              </Map>
              {this.state.coordinatesNotFound > 0 ?
                <p className='pull-left'  style={{ marginLeft: "25px", fontSize:'20px'}}><i className="fa fa-ban" aria-hidden="true"></i> Leads non localisé : {this.state.coordinatesNotFound}</p>
              :null}
          </div>
        : <Loader style={{ marginTop: 150 }} />}
      </div>
     );
  }
}

export default AffairesEncoursMap;
