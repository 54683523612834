import React, { Component } from 'react';

import moment from 'moment'

import Globals from '../Globals';

import { Link } from 'react-router';
var lodash = require('lodash')

class ListPeopleHolidaysManage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchDelete: [],
    }
  }

  deleteHoliday(IDPeopleHoliday, event) {
    var list = this.state.fetchDelete;
    list.push(IDPeopleHoliday);

    this.setState({
      fetchDelete: list
    })

    fetch(localStorage.ClientApiURL + '/PeopleHolidays/' + IDPeopleHoliday, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      this.props.refresh();
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {
    if (!this.props.holidays) {
      return null;
    }

      return (<table className='table table-eseller-lead table-striped'>
      <thead>
        <tr>
          <th>Début</th>
          <th>Fin</th>
          <th>Type</th>
          <th>Récurrent</th>
          <th style={{ width: '170px' }}></th>
        </tr>
      </thead>
      <tbody>
        {this.props.holidays.map((holiday) => {
          return (<tr key={holiday.IDPeopleHoliday}>
            <td>{moment(holiday.DateHStart).format('DD/MM/YYYY HH:mm')}</td>
            <td>{moment(holiday.DateHEnd).format('DD/MM/YYYY HH:mm')}</td>
            <td>{holiday.Type.Libelle}</td>
               {holiday.EstRecurrent ?

                    <td>{console.log(holiday)}{this.props.arrayJours[holiday.Jour]} {this.props.arrayCreneaux[holiday.Creneau]}  ({ holiday.Frequence.Libelle})</td>
                : <td>Non</td>}
            <td>


              {localStorage.bypass_iFrame == "true" ?
                <Link to={'/iframe/holidays/'+ holiday.IDPeopleHoliday +'/edit'} className='btn btn-default'><i className='fa fa-pencil'></i></Link>
              :
                <Link to={'/holidays/'+ holiday.IDPeopleHoliday +'/edit'} className='btn btn-default'><i className='fa fa-pencil'></i></Link>
              }
              &nbsp;
              <span className='btn btn-default' onClick={this.deleteHoliday.bind(this, holiday.IDPeopleHoliday)}>
                {this.state.fetchDelete.indexOf(holiday.IDPeopleHoliday) !== -1 ?
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                : <i className='fa fa-trash'></i>}
              </span>
            </td>
          </tr>)
        })}
      </tbody>
    </table>)
  }
}

export default ListPeopleHolidaysManage;
