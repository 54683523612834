import React, { Component } from 'react';

import Tooltip from "rc-tooltip";

class TableRowSite extends Component {
  render() {
    return (<Tooltip trigger={['hover']} overlay={<div>{this.props.Site.Plaque.Nom} - {this.props.Site.Nom} ({this.props.Site.Code})</div>}>
      <span style={this.props.style}>{this.props.displayVille ? this.props.Site.Ville : this.props.Site.Code}</span>
    </Tooltip>)
  }
}

export default TableRowSite;
