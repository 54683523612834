import React, { Component } from 'react';
import Header from './Header.js';
import NewsModal from './Pages/News/Modal.js';

import { browserHistory } from 'react-router';
import Globals from './Globals';

import './BigCalendar.css';
import '../node_modules/rc-tooltip/assets/bootstrap.css';

import ReactGA from 'react-ga';
import { isMobileOnly, isMobile } from 'react-device-detect';
import {SetAppStyleAndTitle} from './Utils';

var lodash = require('lodash');
var NotificationSystem = require('react-notification-system');
var Auth = require('./Auth').default;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsFetchedToken: "",
      nbLeads: null,
    }

    //Reset du localstorage lorsqu'on se connecte depuis l'iframe et qu'on n'était pas précédemment sur l'iframe
    if (this.props.location.query.idclient && localStorage.token !== 'iframe') {
      localStorage.clear();
    }
  }
  
  componentDidMount() {
    if (localStorage.access_redistribuer === 'true' && (localStorage.user_profil === 'BDC' || localStorage.user_profil === 'ChefDesVentes')) {
      this.fetchLeadsARedistribuer();
      // setInterval(() => {
      //   this.fetchLeadsARedistribuer();
      // }, 120000);
    }

    // Check user is connected
    if (localStorage.user_IDClient && localStorage.user_IDUtilisateur && localStorage.user_profil) {
      this.fetchNonReadNews();

      var parts = window.location.hostname.split('.');
      var subdomain = parts.shift();

      var GoogleAnalyticsCodeUA = 'UA-111922883-1'; // Code de suivi DEV

      if (parts[0] === 'lead' || parts[1] === 'lead') {
        GoogleAnalyticsCodeUA = 'UA-111922883-2'; // Code de suivi PROD
      }

      ReactGA.initialize(GoogleAnalyticsCodeUA, {
        debug: true,
      });

      ReactGA.set({
        userId: localStorage.user_IDClient +'_'+ localStorage.user_IDUtilisateur,
        dimension2: localStorage.user_IDClient,
        dimension1: localStorage.user_profil,
      });
    }

    if(isMobile) //Active les évènemets touch sur mobile permettant d'avoir les effets "onclick/hover"
      window.addEventListener('touchstart', () => {}, false);

    // if(localStorage.user_profil === 'BDC' && !isMobileOnly){
    //   setInterval(() => {//Vérification si la réactualisation des compteurs du header est nécessaire
    //     if(localStorage.refreshHeaderCounters === "1"){
    //       this.fetchLeadsARedistribuer(false);
    //       delete localStorage.refreshHeaderCounters;
    //     }
    //   }, 30000);
    // }

    SetAppStyleAndTitle();
  }

  componentDidUpdate() { //Pour effectuer une récup des news après s'être connecté (vu que la page n'est pas actualisée componentDidMount n'est pas déclenché)
    if(Auth.loggedIn()){

      if(this.state.newsFetchedToken != localStorage.token)
      {
        this.fetchCrawlCRMs();
        this.fetchNonReadNews();
        if (localStorage.access_redistribuer === 'true' && localStorage.user_profil === 'BDC')
          this.fetchLeadsARedistribuer(false);
      }

      if(!Auth.refreshing && Date.now() - localStorage.lastInfoCheck > 86400000 && localStorage.token !== 'iframe') //(24h:86400000 ms)
      {
        //console.log(Date.now() - localStorage.lastInfoCheck);
        Auth.refreshInfo();
      }
    }
  }

  fetchCrawlCRMs() {
    fetch(localStorage.ClientApiURL + '/crawlCRM?actif=true' , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      localStorage.crawlCrms = JSON.stringify(json)
    })
    .catch((error) => {
      console.log(error)
    });
  }
  
  fetchLeadsARedistribuer(showNotification = true) {
    if(typeof showNotification === 'undefined')showNotification = true;

    fetch(localStorage.ClientApiURL +'/nbLeadsARedistribuer', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var count = 0;
      for(var t in json)
        count += json[t];

      if (count && showNotification) {
        this.refs.notificationSystem.addNotification({
          title: 'Leads à redistribuer',
          message: 'Vous avez '+ count +' lead(s) à redistribuer',
          autoDismiss: 15,
          level: 'info',
          position: 'br',
          action: {
            label: 'Voir les leads à redistribuer',
            callback: function() {
              browserHistory.push('/leads/LeadsARedistribuer');
            }
          }
        });
      }

      this.setState({
        nbLeads: json
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchNonReadNews(){
    fetch(localStorage.ClientApiURL + '/news', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var resultNews = json['soap:Envelope']['soap:Body']['GetNewsResponse']['GetNewsResult'];
      if(resultNews && resultNews['UtilisateursNews'])
          var news = resultNews['UtilisateursNews'];
      else 
          var news = [];
      if (news.length === undefined) {
        news = [news];
      }
      var newsReadIds = [];
      var newsReadIds_ = localStorage.newsReadIds;
      if(newsReadIds_)
        var newsReadIds = JSON.parse(newsReadIds_);
      var nbNonReadNews = 0;
      lodash.forEach(news, function(n) {
        if(newsReadIds.indexOf(n.iIDNews) === -1)nbNonReadNews++;
      });
      
      if(nbNonReadNews > 0)
      {
        //console.log(nbNonReadNews+" news non lues !");
        this.refs.newsModal.setNews(news);
        this.refs.newsModal.show();
      }
      if(this.state.newsFetchedToken != localStorage.token){
        this.setState({
          newsFetchedToken: localStorage.token,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {

    return (
      <div className={(isMobileOnly ? "AppMobile" : "App")+" "+(this.props.location.query.iframeFullWidth ? "iframe-full-width" : "")}>

        <div className="AppHeader">
          {Auth.loggedIn() && localStorage.token !== 'iframe' &&
            <Header nbLeads={this.state.nbLeads}/>
          }
        </div>

        <div className="AppContent" style={!Auth.loggedIn() || localStorage.token === 'iframe' ? {paddingTop: '0'} : null} >
          { this.props.children }
        </div>

        <NotificationSystem ref="notificationSystem" />

        {!isMobileOnly ? 
          <NewsModal ref="newsModal"/>
        :
         null}

      </div>
    );
  }
}

export default App;
