import React, { Component } from 'react';

import SkyLight from 'react-skylight';

import SendMessage from './SendMessage';

class SkyLightEx extends SkyLight {
    hide() {
        if(this.props.closable)
            super.hide();    
        else if(this.props.unclosableMessage)
            alert(this.props.unclosableMessage);
    }
}

class ModalSendMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    show() {
        this.refs.modalSendMessage.show();
    }

    hide() {
        this.refs.modalSendMessage.hide();
    }

    /**
     * Après que la modal ait été fermée
     */
    afterClose(){
        
    }

    render() {
        return (
            <SkyLightEx hideOnOverlayClicked ref="modalSendMessage"
                dialogStyles={{ padding: 0, width: '50%', maxWidth: '800px', minWidth: '300px', height: 'auto', left: '0', right: '0', top: '25%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                title="Envoyer Email / SMS"
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}
                afterClose={() => this.afterClose()}
                closable={!this.state.sendingMessage}
                unclosableMessage="L'envoi d'un message est en cours">
                <SendMessage prospect={this.props.prospect} affaires={this.props.affaires} onClickClose={this.hide.bind(this)} onMessageSent={() => this.props.onMessageSent()}/>
            </SkyLightEx>
        );
    }
}

export default ModalSendMessage;
