import React, { Component, useState } from 'react';
import PastilleInteret from '../Components/PastilleInteret';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowOrigine from '../Components/TableRowOrigine'; 
import TableRowSite from '../Components/TableRowSite';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import TableRowComment from '../Components/TableRowComment';
import FilterSocieteSite from '../Leads/FilterSocieteSite';
import FilterBlock from '../Components/FilterBlock';
import FilterOrigine from '../Leads/FilterOrigine';
import FilterEtape from '../Leads/FilterEtape';
import FilterVendeur from '../Leads/FilterVendeur';
import FilterType from '../Leads/FilterType';
import PanelFilter from '../Leads/PanelFilter';

import Globals from '../Globals';
import LibInfo from '../Lib/Info';
import LibLeads from '../Lib/Leads';
import moment from 'moment'
import LibRapports from '../Lib/Rapports';
import LibUtilisateurs from '../Lib/Utilisateurs';
import { GetNestedData } from '../Utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import InputDate from '../Components/InputDate';
const lodash = require('lodash');


class IframeActionsAFaire extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
          tab: "leads",
          fetchingUserInfo: true,
          userInfo: null,

          fetchingLeads: false,
          leadsARedistribuer: [],
          leadsAQualifier: [],
          leadsEnCours: [],
          allLeadsARedistribuer: [],
          allLeadsAQualifier: [],
          allLeadsEnCours: [],
          allLeads: [],
          nbLeads: null,
          etapesLeads: [],
          vendeursLeads: [],

          fetchingActions: false,
          fetchingActionsEnRetard: false,
          actions: [],
          actionsEnRetard: [],
          allActions: [],
          allActionsEnRetard: [],
          nbActions: null,

          filtersLeads: {},
          filtersActions: {
            "dateRelanceTo": moment().startOf('day').add(30, "days"),
          },
          activesPages: { //Dans le cas où il y a pagination, numéros de la page active pour chaque tableau
            "leadsAQualifier": 1,
            "leadsARedistribuer": 1,
            "leadsEnCours": 1,
            "actions": 1,
            "actionsEnRetard": 1,
          },
          affActionsEnRetard: false,

          selectedLeads: [],
          selectedLeadsIDSite: null,
          selectedLeadsType: null,
          showTransfertForm: false,

          selectedActions: [],
          selectedActionsIDSite: null,

          utilisateursTransfert: [],
          fetchingUtilisateursTransfert: false,
          transfertTo: null,
          submitting: false,
          closingActions: false,
          submittingMessage: null,

          showAlertMessage: false,

          loadingFromStorage: false,
          loadedFromStorage: false,
        }

        this.nbLinesPerPage = 20;

        if(!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password || localStorage.user_IDClient != this.props.location.query.idclient || localStorage.user_login != this.props.location.query.login){
            localStorage.bypass_iFrame = true;
            localStorage.user_IDClient = this.props.location.query.idclient;
            localStorage.user_login = this.props.location.query.login;
            localStorage.user_password = this.props.location.query.password;
            localStorage.token = 'iframe'
            localStorage.version = Globals.VERSION
            delete localStorage.iframeActionsAFaire_state;
            delete localStorage.iframeActionsAFaire_ts 

            localStorage.ClientApiURL = Globals.API_URL;
            var parts = window.location.hostname.split('.');
            if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod' || parts[1] === 'preprod' || parts[0] === 'localhost') {
              localStorage.ClientApiURL = Globals.API_URL_DEV;
            }

            if (parts[0] === 'devagri' || parts[1] === 'devagri') {
              localStorage.ClientApiURL = Globals.API_URL_DEV_AGRI;
            }
        }
    }

    async componentDidMount() {
      var loadedFromStorage = this.loadFromStorage();
      this.setFiltersFromQuery();
      if(!loadedFromStorage || this.isStorageOld()) {
        await this.fetchUserInfo();
        this.fetchLeads();
        this.fetchActions();
      }
    }

    //Définit certains paramètres de filtrage depuis l'url (pour l'instant seulement l'onglet)
    setFiltersFromQuery() {
      var stateFiltersChanges = {};
      if(this.props.location.query.tab) {
        stateFiltersChanges.tab = this.props.location.query.tab
      }
      if(this.props.location.query.origines) {
        stateFiltersChanges.filtersLeads = {
          "origines": this.props.location.query.origines.split(",")
        }
      }
      if(stateFiltersChanges) {
        this.setState(stateFiltersChanges)
      }
    }

    saveStateInStorage(setTs = false) {
      localStorage.iframeActionsAFaire_state = JSON.stringify(lodash.pick(this.state, [
        "tab", "userInfo", "nbLeads", "nbActions", "allLeadsARedistribuer", "allLeadsAQualifier", "allLeadsEnCours", "allActions", "allActionsEnRetard", "filtersLeads", "filtersActions", "affActionsEnRetard", "etapesLeads", "vendeursLeads", "activesPages"
      ]))
      if(setTs) {
        localStorage.iframeActionsAFaire_ts = Date.now();
      }
    }

    loadFromStorage() {
      try {
        if(localStorage.iframeActionsAFaire_state !== undefined) {
          var state = JSON.parse(localStorage.iframeActionsAFaire_state);
          state.loadingFromStorage = true;
          state.allLeads = lodash.compact(state.allLeadsEnCours.concat(state.allLeadsAQualifier, state.leadsARedistribuer));
          if(this.props.location.query.resetFilters) {
            state.filtersLeads = {};
            state.filtersActions = {
              "dateRelanceTo": moment().startOf('day').add(30, "days"),
            };
            const url = new URL(window.location.href)
            url.searchParams.delete("resetFilters")
            window.history.replaceState("", "", url)
          }
          this.setState(state, () => {
            //console.log(this.state)
            this.applyFiltersLeads();
            this.applyFiltersActions();
            this.setState({
              loadingFromStorage: false,
              loadedFromStorage: true,
              fetchingUserInfo: false,
            });
          });
          console.log("Data loaded from storage");
          return state.allLeadsEnCours.length > 0; //Si on quitte la page pendant le chargement des leads, qui peut être long, alors le storage peut se retrouver vide et il faut refetch
        }
        else {
          return false;
        }
      }
      catch(e) {
        console.log("Data can't be loaded from storage");
        console.log(e)
        return false;
      }
    }

    isStorageOld() {
      /** 
       * 20000 ms = 20 secondes
       * Ce cache ne sert que si on va sur une fiche puis qu'on fait rapidement back.
       * Si le vendeur fait une action il veut voir le changement dans la liste rapidement (on suppose qu'au plus vite il met 20 secondes à faire son action)
       * Délai à adapter si besoin
       */
      if(localStorage.iframeActionsAFaire_ts && Date.now() - localStorage.iframeActionsAFaire_ts < 20000) { 
        return false;
      }
      console.log("storage is old")
      return true;
    }

    async fetchUserInfo() {
      this.setState({
        fetchingUserInfo: true
      })
      var userInfo = await LibInfo.get();
      userInfo = lodash.pick(userInfo, ["Utilisateur.TypeProfilLead", "Utilisateur.IDUtilisateur", "BDGp"]);
      this.setState({
        userInfo: userInfo,
        fetchingUserInfo: false
      }, () => {
        this.saveStateInStorage()
      })
    }

    async fetchLeads() {
      this.setState({
        fetchingLeads: true
      })

      var leadsAQualifier = [];
      var leadsARedistribuer = [];
      var leadsEnCours = [];

      if(this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes") {
        leadsARedistribuer = await LibLeads.getLeads("ARedistribuer");
        leadsEnCours = await LibLeads.getLeads("AffaireEnCours");
      }
      else if(this.state.userInfo.Utilisateur.TypeProfilLead === "Vendeur") {
        leadsAQualifier = await LibLeads.getLeads("AQualifier");
        leadsEnCours = await LibLeads.getLeads("EnCours");
      }

      leadsARedistribuer = lodash.sortBy(leadsARedistribuer, (l) => {return moment(l.ActionAFaire_TODO.DateHRelance || l.InfoCreation.DateH).format("X")});
      leadsAQualifier = lodash.sortBy(leadsAQualifier, (l) => {return moment(l.ActionAFaire_TODO.DateHRelance || l.InfoCreation.DateH).format("X")});
      leadsEnCours = lodash.sortBy(leadsEnCours, (l) => {return moment(l.ActionAFaire_TODO.DateHRelance || l.InfoCreation.DateH).format("X")});

      this.setState({
        leadsAQualifier: leadsAQualifier,
        leadsEnCours: leadsEnCours,
        leadsARedistribuer: leadsARedistribuer,
        nbLeads: leadsAQualifier.length + leadsEnCours.length + leadsARedistribuer.length,
        fetchingLeads: false,
        allLeadsAQualifier: leadsAQualifier,
        allLeadsEnCours: leadsEnCours,
        allLeadsARedistribuer: leadsARedistribuer,
        allLeads: leadsEnCours.concat(leadsAQualifier, leadsARedistribuer),
      }, () => {
        this.applyFiltersLeads();
        this.loadEtapesLeads();
        this.loadVendeursLeads();
        this.saveStateInStorage(true);
      })
    }

    async fetchActions() {
      this.setState({
        fetchingActions: true
      })
      var actions = await LibRapports.getActionsATraiter(this.state.userInfo.Utilisateur.IDUtilisateur);
      actions = lodash.sortBy(actions, (a) => {return moment(a.ActionAFaire_TODO.DateHRelance).format("X")});
      this.setState({
        actions: actions,
        nbActions: actions.length,
        fetchingActions: false,
        allActions: actions,
      }, () => {
        this.applyFiltersActions(true)
        this.saveStateInStorage(true);
      })
    }

    async fetchActionsEnRetard() {
      this.setState({
        fetchingActionsEnRetard: true
      })
      var actions = await LibRapports.getActionsEnRetard(this.state.userInfo.Utilisateur.IDUtilisateur);
      actions = lodash.sortBy(actions, (a) => {return moment(a.ActionAFaire_TODO.DateHRelance).format("X")});
      this.setState({
        actionsEnRetard: actions,
        fetchingActionsEnRetard: false,
        allActionsEnRetard: actions
      }, () => {
        this.applyFiltersActions();
        this.saveStateInStorage();
      })
    }

    async fetchUtilisateursTransfert(IDSite, typeLead = null) {
      this.setState({
        fetchingUtilisateursTransfert: true
      })
      var utilisateurs = await LibUtilisateurs.getUtilisateursManages(this.state.userInfo.Utilisateur.IDUtilisateur, IDSite);
      if(typeLead) {
        utilisateurs = lodash.filter(utilisateurs, u => {
          if(typeLead == "VN") {
            return u.EstTypeVN
          }
          else if(typeLead == "VN") {
            return u.EstTypeVO
          }
          else if(typeLead == "Atelier") {
            return u.EstTypeConseiller
          }
          else if(typeLead == "Autres") {
            return u.EstTypeAutre
          }
        })
      }
      utilisateurs = lodash.orderBy(utilisateurs, 'Nom');
      this.setState({
        fetchingUtilisateursTransfert: false,
        utilisateursTransfert: utilisateurs
      })
    }

    submitTransfert() {
      if(this.state.tab === "actions") {
        this.submitTransfertActions();
      }
      else {
        this.submitTransfertLeads();
      }
    }

    async submitTransfertLeads() {
      this.setState({
        submitting: true,
        submittingMessage: "Transfert des leads sélectionnés..."
      })
      var leads = lodash.filter(this.state.leadsARedistribuer, (lead) => {
        return this.state.selectedLeads.indexOf(lead.IDLead) !== -1;
      });
      var result = await LibRapports.transfertManyTo(leads, this.state.transfertTo);
      if(result) {
        var leadsARedistribuer = lodash.filter(this.state.leadsARedistribuer, (lead) => {
          return this.state.selectedLeads.indexOf(lead.IDLead) === -1;
        })
        var allLeads = lodash.filter(this.state.allLeads, (lead) => {
          return this.state.selectedLeads.indexOf(lead.IDLead) === -1;
        })
        this.setState({
          submitting: false,
          showTransfertForm: false,
          utilisateursTransfert: [],
          transfertTo: null,
          leadsARedistribuer: leadsARedistribuer,
          allLeads: allLeads,
          showAlertMessage: "Transfert effectué avec succès"
        })
      }
      else {
        alert("Erreur lors du transfert d'au moins un lead");
      }
    }

    async submitTransfertActions() {
      this.setState({
        submitting: true,
        submittingMessage: "Transfert des actions sélectionnées..."
      })
      var actions = lodash.filter(this.state.actions, (action) => {
        return this.state.selectedActions.indexOf(action.IDLead) !== -1;
      });
      actions = actions.concat(lodash.filter(this.state.actionsEnRetard, (action) => {
        return this.state.selectedActions.indexOf(action.IDLead) !== -1;
      }));

      var result = await LibRapports.transfertManyTo(actions, this.state.transfertTo);
      if(result) {
        var actions = lodash.filter(this.state.actions, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        });
        var actionsEnRetard = lodash.filter(this.state.actionsEnRetard, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        })
        var allActions = lodash.filter(this.state.allActions, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        })
        var allActionsEnRetard = lodash.filter(this.state.allActionsEnRetard, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        })
        this.setState({
          submitting: false,
          showTransfertForm: false,
          utilisateursTransfert: [],
          transfertTo: null,
          actions: actions,
          actionsEnRetard: actionsEnRetard,
          allActions: allActions,
          allActionsEnRetard: allActionsEnRetard,
          showAlertMessage: "Transfert effectué avec succès"
        })
      }
      else {
        alert("Erreur lors du transfert d'au moins une action");
      }
    }

    async submitClotureActions() {
      var text;
      if(this.state.selectedActions.length > 1) {
        text = "Clôturer ces "+this.state.selectedActions.length+" actions ?";
      }
      else {
        text = "Clôturer cette action ?";
      }

      if(!window.confirm(text)) {
        return false;
      }

      this.setState({
        closingActions: true,
        submittingMessage: "Clôture des actions sélectionnées..."
      })
      var actions = lodash.filter(this.state.actions, (action) => {
        return this.state.selectedActions.indexOf(action.IDLead) !== -1;
      });
      actions = actions.concat(lodash.filter(this.state.actionsEnRetard, (action) => {
        return this.state.selectedActions.indexOf(action.IDLead) !== -1;
      }));

      var result = await LibRapports.cloturerRapports(actions);
      if(result) {
        var actions = lodash.filter(this.state.actions, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        });
        var actionsEnRetard = lodash.filter(this.state.actionsEnRetard, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        })
        var allActions = lodash.filter(this.state.allActions, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        })
        var allActionsEnRetard = lodash.filter(this.state.allActionsEnRetard, (action) => {
          return this.state.selectedActions.indexOf(action.IDLead) === -1;
        })
        this.setState({
          closingActions: false,
          actions: actions,
          actionsEnRetard: actionsEnRetard,
          allActions: allActions,
          allActionsEnRetard: allActionsEnRetard,
          showAlertMessage: this.state.selectedActions.length > 1 ? "Actions clôturées avec succès" : "Action clôturée avec succès",
        })
      }
      else {
        alert("Erreur lors de la clôture d'au moins une action");
      }
    }


    loadEtapesLeads() {
      var etapes = lodash.countBy(this.state.allLeads, function(lead) {
        return lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
      });
      this.setState({
        etapesLeads: etapes
      }, () => {
        this.saveStateInStorage();
      })
    }

    loadVendeursLeads() {
      var vendeurs = lodash.countBy(this.state.allLeads, function(lead) {
        return lead.SuiviPar ? lead.SuiviPar.Nom : "";
      });
      this.setState({
        vendeursLeads: vendeurs
      }, () => {
        this.saveStateInStorage();
      })
    }

    onChangeFiltersLeadsDate(type, date) {
      if(date) {
        date = moment(date);
        if(!date.isValid()) {
          return;
        }
      }

      this.onChangeFiltersLeads(type, date);
    }

    onChangeFiltersActionsDate(type, date) {
      if(date) {
        date = moment(date);
        if(!date.isValid()) {
          return;
        }
      }

      this.onChangeFiltersActions(type, date);
    }

    onChangeFiltersLeads(type, datas) {
      var currentFilters = this.state.filtersLeads;
      currentFilters[type] = datas;
      var activesPages = this.state.activesPages;
      activesPages["leadsEnCours"] = 1;
      activesPages["leadsAQualifier"] = 1;
      activesPages["leadsARedistribuer"] = 1;
      this.setState({
        filtersLeads: currentFilters,
        activesPages: activesPages,
      }, () => {
        this.applyFiltersLeads()
        this.saveStateInStorage()
      });
    }

    onChangeFiltersActions(type, datas) {
      var currentFilters = this.state.filtersActions;
      currentFilters[type] = datas;
      var activesPages = this.state.activesPages;
      activesPages["actions"] = 1;
      activesPages["actionsEnRetard"] = 1;
      this.setState({
        filtersActions: currentFilters
      }, () => {
        this.applyFiltersActions()
        this.saveStateInStorage()
      });
    }

    onChangeAffActionsEnRetard() {
      if(!this.state.affActionsEnRetard && !this.state.fetchingActionsEnRetard && !this.state.actionsEnRetard.length) {
        this.fetchActionsEnRetard();
      }
      this.setState({
        affActionsEnRetard: !this.state.affActionsEnRetard,
      }, () => {
        this.saveStateInStorage()
      });
    }

    onChangeAffActions30J() {
      var currentFilters = this.state.filtersActions;
      var date = null;
      if(!currentFilters["dateRelanceTo"]) {
        date = moment().startOf('day').add(30, "days");
      }
      this.onChangeFiltersActions("dateRelanceTo", date);
    }

    onChangeTransfertTo(e) {
      this.setState({
        transfertTo: e.target.value
      })
    }

    onChangeTab(tab) {
      this.setState({
        tab: tab,
        selectedLeads: [],
        selectedLeadsIDSite: null,
        selectedLeadsType: null,
        showTransfertForm: false,

        selectedActions: [],
        selectedActionsIDSite: null,

        utilisateursTransfert: [],
        fetchingUtilisateursTransfert: false,
        transfertTo: null,
      }, () => {
        this.saveStateInStorage()
      })
    }

    onChangePage(tableName, change, e) {
      e.preventDefault();
      var activesPages = this.state.activesPages;
      activesPages[tableName] += change;
      this.setState({
        activesPages: activesPages
      }, () => {
        this.saveStateInStorage();
      })
    }

    filterOrigine(leads, filter, origineIn = "Affaire") {
      return lodash.filter(leads, function(lead) {
        var check = lead[origineIn].OrigineAction ? lead[origineIn].OrigineAction.Famille+"-"+lead[origineIn].OrigineAction.SousFamille+"-"+lead[origineIn].OrigineAction.Origine : "";
        return filter.indexOf(check) !== -1;
      });
    }

    filterEtape(leads, filter) {
      return lodash.filter(leads, function(lead) {
        var check = lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
        return filter.indexOf(check) !== -1;
      });
    }

    filterSite(leads, filter) {
      return lodash.filter(leads, function(lead) {
        var check = lead.Site.IDSite;
        return filter.indexOf(check) !== -1;
      });
    }

    filterVendeur(leads, filter) {
      return lodash.filter(leads, function(lead) {
        var check = lead.SuiviPar ? lead.SuiviPar.Nom : "";
        return filter.indexOf(check) !== -1;
      });
    }

    filterType(leads, filter, typeIn = "Affaire") {
      return lodash.filter(leads, function(lead) {
        var check = GetNestedData(lead, typeIn+".Type");
        return filter.indexOf(check) != -1;
      });
    }

    filterDateRelance(type, leads, dateFrom, dateTo) {
      if(dateTo) {
        dateTo = moment(dateTo).set({"hour": 23, "minute": 59, "second": 59});
      }

      return lodash.filter(leads, function(lead) {
        var dateRelance = lead.ActionAFaire_TODO.DateHRelance;
        if(!dateRelance && type == "leads") {
          dateRelance = lead.InfoCreation.DateH;
        }
        if(!dateRelance) {
          return false;
        }

        dateRelance = moment(dateRelance)

        if(dateFrom) {
          if(dateRelance.isBefore(dateFrom)) {
            return false;
          }
        }

        if(dateTo) {
          if(dateRelance.isAfter(dateTo)) {
            return false;
          }
        }

        return true;
      });
    }

    applyFiltersLeads() {
      var leads = {
        leadsAQualifier: this.state.allLeadsAQualifier,
        leadsARedistribuer: this.state.allLeadsARedistribuer,
        leadsEnCours: this.state.allLeadsEnCours,
      }

      if (this.state.filtersLeads) {
        if (this.state.filtersLeads['origines'] && this.state.filtersLeads['origines'].length) {
          for(var t in leads) {
            leads[t] = this.filterOrigine(leads[t], this.state.filtersLeads['origines']);
          }
        }
  
        if (this.state.filtersLeads['etapes'] && this.state.filtersLeads['etapes'].length) {
          for(var t in leads) {
            leads[t] = this.filterEtape(leads[t], this.state.filtersLeads['etapes'])
          }
        }

        if (this.state.filtersLeads['sites'] && this.state.filtersLeads['sites'].length) {
          for(var t in leads) {
            leads[t] = this.filterSite(leads[t], this.state.filtersLeads['sites']);
          }
        }

        if (this.state.filtersLeads['vendeurs'] && this.state.filtersLeads['vendeurs'].length) {
          for(var t in leads) {
            leads[t] = this.filterVendeur(leads[t], this.state.filtersLeads['vendeurs']);
          }
        }

        if (this.state.filtersLeads['types'] && this.state.filtersLeads['types'].length) {
          for(var t in leads) {
            leads[t] = this.filterType(leads[t], this.state.filtersLeads['types']);
          }
        }

        if (this.state.filtersLeads['dateRelanceFrom'] || this.state.filtersLeads['dateRelanceTo'] ) {
          for(var t in leads) {
            leads[t] = this.filterDateRelance("leads", leads[t], this.state.filtersLeads['dateRelanceFrom'], this.state.filtersLeads['dateRelanceTo']);
          }
        }
      }

      this.setState(leads);
    }

    /**
     * Si setNbActions = true alors après filtrage on stock le nombre d'actions pour affichage dans l'onglet. Cela permet de pouvoir prendre en compte les filtres par défaut.
     */
    applyFiltersActions(setNbActions = false) {
      var actions = {
        actions: this.state.allActions,
        actionsEnRetard: this.state.allActionsEnRetard
      }

      if (this.state.filtersActions) {
        if (this.state.filtersActions['origines'] && this.state.filtersActions['origines'].length) {
          for(var t in actions) {
            actions[t] = this.filterOrigine(actions[t], this.state.filtersActions['origines'], "ActionFaite_DO");
          }
        }

        if (this.state.filtersActions['sites'] && this.state.filtersActions['sites'].length) {
          for(var t in actions) {
            actions[t] = this.filterSite(actions[t], this.state.filtersActions['sites']);
          }
        }

        if (this.state.filtersActions['vendeurs'] && this.state.filtersActions['vendeurs'].length) {
          for(var t in actions) {
            actions[t] = this.filterVendeur(actions[t], this.state.filtersActions['vendeurs']);
          }
        }

        if (this.state.filtersActions['types'] && this.state.filtersActions['types'].length) {
          for(var t in actions) {
            actions[t] = this.filterType(actions[t], this.state.filtersActions['types'], "ActionFaite_DO.OrigineAction");
          }
        }

        if (this.state.filtersActions['dateRelanceFrom'] || this.state.filtersActions['dateRelanceTo'] ) {
          for(var t in actions) {
            actions[t] = this.filterDateRelance("actions", actions[t], this.state.filtersActions['dateRelanceFrom'], this.state.filtersActions['dateRelanceTo']);
          }
        }
      }

      if(setNbActions) {
        actions["nbActions"] = actions["actions"].length;
      }

      this.setState(actions)
    }

    isLoading() {
      return (this.state.fetchingUserInfo || this.state.fetchingLeads || this.state.loadingFromStorage) && !this.state.loadedFromStorage;
    }

    isLoadingInBackground() {
      return (this.state.fetchingUserInfo || this.state.fetchingLeads);
    }

    isNissan() {
      return this.state.userInfo && this.state.userInfo.BDGp.indexOf("BDGpNi") !== -1;
    }

    goToProspect(IDProspect, e) {
      e.preventDefault();
      window.parent.postMessage({
        action: "goToProspect",
        IDProspect: IDProspect
      }, "*");
      return false;
    }

    goToRapport(IDLead, e) {
      e.preventDefault();
      var dataIDLead = IDLead.split("_");
      window.parent.postMessage({
        action: "goToRapport",
        IDProspect: dataIDLead[0],
        IDComRapp: dataIDLead[1],
        IDPeople: this.state.userInfo.Utilisateur.IDUtilisateur
      }, "*");
      return false;
    }

    selectLead(IDLead, IDSite, Type) {
      var selectedLeads = this.state.selectedLeads;
      var selectedLeadsIDSite = this.state.selectedLeadsIDSite;
      var selectedLeadsType = this.state.selectedLeadsType;
      var utilisateursTransfert = this.state.utilisateursTransfert;
      var showTransfertForm = this.state.showTransfertForm;
      var transfertTo = this.state.transfertTo;
      if(selectedLeads.indexOf(IDLead) !== -1) {
        selectedLeads = selectedLeads.filter(s => s != IDLead);
        if(selectedLeads.length === 0) {
          selectedLeadsIDSite = null;
          selectedLeadsType = null;
          utilisateursTransfert = [];
          showTransfertForm = false;
          transfertTo = null;
        }
      }
      else {
        selectedLeads.push(IDLead);
        if(selectedLeads.length === 1) {
          selectedLeadsIDSite = IDSite;
          selectedLeadsType = Type;
        }
      }

      this.setState({
        selectedLeads: selectedLeads,
        selectedLeadsIDSite: selectedLeadsIDSite,
        selectedLeadsType: selectedLeadsType,
        utilisateursTransfert: utilisateursTransfert,
        showTransfertForm: showTransfertForm,
        transfertTo: transfertTo
      })
    }

    selectAction(IDLead, IDSite) {
      var selectedActions = this.state.selectedActions;
      var selectedActionsIDSite = this.state.selectedActionsIDSite;
      var utilisateursTransfert = this.state.utilisateursTransfert;
      var showTransfertForm = this.state.showTransfertForm;
      var transfertTo = this.state.transfertTo;
      if(selectedActions.indexOf(IDLead) !== -1) {
        selectedActions = selectedActions.filter(s => s != IDLead);
        if(selectedActions.length === 0) {
          selectedActionsIDSite = null;
          utilisateursTransfert = [];
          showTransfertForm = false;
          transfertTo = null;
        }
      }
      else {
        selectedActions.push(IDLead);
        if(selectedActions.length === 1) {
          selectedActionsIDSite = IDSite;
        }
      }

      this.setState({
        selectedActions: selectedActions,
        selectedActionsIDSite: selectedActionsIDSite,
        utilisateursTransfert: utilisateursTransfert,
        showTransfertForm: showTransfertForm,
        transfertTo: transfertTo
      })
    }

    showTransfertForm() {
      var IDSite = (this.state.tab === "actions" ? this.state.selectedActionsIDSite : this.state.selectedLeadsIDSite);
      var type = (this.state.tab === "actions" ? null : this.state.selectedLeadsType);
      this.fetchUtilisateursTransfert(IDSite, type);
      this.setState({
        showTransfertForm: true
      })
    }

    renderTransfertForm(disableButton) {
      return (
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', minHeight:'36px'}}>
          {this.state.showTransfertForm ? 
            this.state.utilisateursTransfert.length ?
              <div className="form-group" style={{ marginBottom: '0px', display:'flex', flexDirection:'row', alignItems:'center' }}>
                <div className='text-nowrap'>Transférer à&nbsp;</div>
                <select className="form-control" id="vendeur" name="vendeur" onChange={this.onChangeTransfertTo.bind(this)} value={this.state.transfertTo || ""} required={this.state.showTransfertForm}>
                  <option></option>
                  <option value={this.state.userInfo.Utilisateur.IDUtilisateur}>Moi-même</option>
                  {this.state.utilisateursTransfert.map((utilisateur, i) => {
                    return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                  })}
                </select>
                &nbsp;
                <button onClick={this.submitTransfert.bind(this)} disabled={!this.state.transfertTo || this.state.submitting} className='btn btn-default' style={{borderRadius: 5}} >
                  {this.state.submitting ? 
                    <i className="fa fa-spinner fa-spin fa-fw" style={{marginRight: 5}} aria-hidden="true"></i>
                  :null}
                  Valider
                </button>
              </div>
            : 
              <div className="form-group" style={{ marginBottom: '0px', display:'flex', flexDirection:'row', alignItems:'center' }}>
                {this.state.fetchingUtilisateursTransfert ? 
                  <label>Récupération des utilisateurs...</label>
                : 
                  <label className="warning">[Aucun vendeur paramétré]</label>
                }
              </div>
          :
            <button onClick={this.showTransfertForm.bind(this)} disabled={disableButton} className='btn btn-default' style={{borderRadius: 5}} >Transférer</button>
          }
        </div>
      )
    }

    renderLeadsARedistribuer() {
      const leads = this.state.leadsARedistribuer.slice((this.state.activesPages["leadsARedistribuer"] - 1) * this.nbLinesPerPage, this.state.activesPages["leadsARedistribuer"] * this.nbLinesPerPage);
      return (
        <div style={{border:'1px solid #DDD', borderRadius: '5px', overflow: 'hidden'}}>
          <div style={{backgroundColor: "#232F3D", color: "#fff", padding: "0.5em", textAlign:"left", fontSize:"1.1em"}}>Leads à redistribuer ({this.state.leadsARedistribuer.length})</div>
          {this.renderListLeads(leads, true)}
          {this.renderPagination("leadsARedistribuer", this.state.leadsARedistribuer.length)}
        </div>
      );
    }

    renderLeadsAQualifier() {
      const leads = this.state.leadsAQualifier.slice((this.state.activesPages["leadsAQualifier"] - 1) * this.nbLinesPerPage, this.state.activesPages["leadsAQualifier"] * this.nbLinesPerPage);
      return (
        <div style={{border:'1px solid #DDD', borderRadius: '5px', overflow: 'hidden'}}>
          <div style={{backgroundColor: "#232F3D", color: "#fff", padding: "0.5em", textAlign:"left", fontSize:"1.1em"}}>Leads à qualifier ({this.state.leadsAQualifier.length})</div>
          {this.renderListLeads(leads)}
          {this.renderPagination("leadsAQualifier", this.state.leadsAQualifier.length)}
        </div>
      );
    }

    renderLeadsEnCours() {
      const leads = this.state.leadsEnCours.slice((this.state.activesPages["leadsEnCours"] - 1) * this.nbLinesPerPage, this.state.activesPages["leadsEnCours"] * this.nbLinesPerPage);
      return (
        <div style={{border:'1px solid #DDD', borderRadius: '5px', overflow: 'hidden'}}>
          <div style={{backgroundColor: "#232F3D", color: "#fff", padding: "0.5em", textAlign:"left", fontSize:"1.1em"}}>Affaires en cours ({this.state.leadsEnCours.length})</div>
          {this.renderListLeads(leads)}
          {this.renderPagination("leadsEnCours", this.state.leadsEnCours.length)}
        </div>
      );
    }

    renderActions() {
      const actions = this.state.actions.slice((this.state.activesPages["actions"] - 1) * this.nbLinesPerPage, this.state.activesPages["actions"] * this.nbLinesPerPage);
      return (
        <div style={{border:'1px solid #DDD', borderRadius: '5px', overflow: 'hidden'}}>
          <div style={{backgroundColor: "#232F3D", color: "#fff", padding: "0.5em", textAlign:"left", fontSize:"1.1em"}}>Actions ({this.state.actions.length})</div>
          {this.renderListActions(actions)}
          {this.renderPagination("actions", this.state.actions.length)}
        </div>
      )
    }

    renderActionsEnRetard() {
      const actions = this.state.actionsEnRetard.slice((this.state.activesPages["actionsEnRetard"] - 1) * this.nbLinesPerPage, this.state.activesPages["actionsEnRetard"] * this.nbLinesPerPage);
      return (
        <div style={{border:'1px solid #DDD', borderRadius: '5px', overflow: 'hidden'}}>
          <div style={{backgroundColor: "#232F3D", color: "#fff", padding: "0.5em", textAlign:"left", fontSize:"1.1em"}}>Actions en retard ({this.state.actionsEnRetard.length})</div>
          {this.state.fetchingActionsEnRetard ? 
            <div className="center-div" style={{padding:30}}>
              <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
            </div>
          :
            <>
              {this.renderListActions(actions)}
              {this.renderPagination("actionsEnRetard", this.state.actionsEnRetard.length)}
            </>
          }
        </div>
      )
    }

    renderListLeads(leads, aRedistribuer = false) {
      return (
        <table className="table table-eseller-lead table-striped table-hover table-condensed list2">
          {leads.length ? 
            <thead>
              <tr>
                <th>
                  {!aRedistribuer ? "Vend" : "CDV"}
                </th>
                <th>Type</th>
                <th>Site</th>
                <th>Contact</th>
                <th>Ville</th>
                <th>Origine</th>
                <th>Campagne</th>
                <th>Etape</th>
                <th></th>
                <th>Relance</th>
                <th></th>
              </tr>
            </thead>
          :null}
          <tbody>
          {leads.length ? 
            leads.map((lead, i) => {
              return (
                <tr key={i}>
                  <td>
                    <PastilleVendeur utilisateur={lead.SuiviPar} />
                  </td>
                  <td>
                    {lead.Affaire.Type}
                  </td>
                  <td>
                    <TableRowSite Site={lead.Site} />
                  </td>
                  <td>
                    <a href="#" onClick={this.goToProspect.bind(this, lead.Prospect.IDProspect)}>
                      {lead.Prospect.Nom} {lead.Prospect.Prenom}
                    </a>
                  </td>
                  <td>
                    {lead.Prospect.Ville}
                  </td>
                  <td>
                    {lead.Affaire.OrigineAction ? <TableRowOrigine origine={lead.Affaire.OrigineAction}/> : null}
                  </td>
                  <td>
                    {lead.Campagne ? lead.Campagne.Nom : ""}
                  </td>
                  <td>
                    {lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : null}
                  </td>
                  <td className='text-center'>
                    <TableRowComment comment={lead.ActionFaite_DO.Commentaire} />
                  </td>
                  <td className='text-nowrap'>
                    <a href="#" onClick={this.goToRapport.bind(this, lead.IDLead)}>
                      {lead.ActionAFaire_TODO.DateHRelance ?
                        <TableRowDateRelance date={lead.ActionAFaire_TODO.DateHRelance} colorize={true}/>
                      :
                        <TableRowDateRelance date={lead.InfoCreation.DateH} colorize={true}/>
                      }
                    </a>
                  </td>
                  <td>
                    {aRedistribuer && this.state.selectedLeadsIDSite === null || (lead.Site.IDSite == this.state.selectedLeadsIDSite && lead.Affaire.Type == this.state.selectedLeadsType) ? 
                      <div className="checkbox-form active">
                        <input type="checkbox" name={"lead_"+lead.IDLead} id={"lead_"+lead.IDLead} checked={this.state.selectedLeads.indexOf(lead.IDLead) !== -1} onChange={this.selectLead.bind(this, lead.IDLead, lead.Site.IDSite, lead.Affaire.Type)} />
                        <label className="label-box" htmlFor={"lead_"+lead.IDLead}></label>
                      </div>
                    :null}
                  </td>
                </tr>
              );
            })
          :
            <tr>
              <td>Aucun lead</td>
            </tr>
          }
          </tbody>
        </table>
      )
    }

    renderListActions(actions) {
      return (
        <table className="table table-eseller-lead table-striped table-hover table-condensed list2">
          {actions.length ? 
            <thead>
              <tr>
                <th>Type</th>
                <th>Site</th>
                <th>Contact</th>
                <th>Ville</th>
                <th>Origine</th>
                <th>Campagne</th>
                <th>Action</th>
                <th></th>
                <th>Relance</th>
                <th></th>
              </tr>
            </thead>
          :null}
          <tbody>
          {actions.length ? 
            actions.map((action, i) => {
              return (
                <tr key={i}>
                  <td>
                    {action.ActionFaite_DO.OrigineAction ? action.ActionFaite_DO.OrigineAction.Type : null}
                  </td>
                  <td>
                    <TableRowSite Site={action.Site} />
                  </td>
                  <td>
                    <a href="#" onClick={this.goToProspect.bind(this, action.Prospect.IDProspect)}>
                      {action.Prospect.Nom} {action.Prospect.Prenom}
                    </a>
                  </td>
                  <td>
                    {action.Prospect.Ville}
                  </td>
                  <td>
                    {action.ActionFaite_DO.OrigineAction ? <TableRowOrigine origine={action.ActionFaite_DO.OrigineAction}/> : null}
                  </td>
                  <td>
                    {action.Campagne ? action.Campagne.Nom : ""}
                  </td>
                  <td>
                    {action.ActionAFaire_TODO.ActionAFaire ? action.ActionAFaire_TODO.ActionAFaire.Description : ''}
                  </td>
                  <td className='text-center'>
                    <TableRowComment comment={action.ActionFaite_DO.Commentaire} />
                  </td>
                  <td className='text-nowrap'>
                    {action.IDLead ?
                      <a href="#" onClick={this.goToRapport.bind(this, action.IDLead)}>
                        <TableRowDateRelance date={action.ActionAFaire_TODO.DateHRelance} colorize={true}/>
                      </a>
                    :null}
                  </td>
                  <td>
                    {this.state.selectedActionsIDSite === null || (action.Site.IDSite == this.state.selectedActionsIDSite) ? 
                      <div className="checkbox-form active">
                        <input type="checkbox" name={"action_"+action.IDLead} id={"action_"+action.IDLead} checked={this.state.selectedActions.indexOf(action.IDLead) !== -1} onChange={this.selectAction.bind(this, action.IDLead, action.Site.IDSite)} />
                        <label className="label-box" htmlFor={"action_"+action.IDLead}></label>
                      </div>
                    :null}
                  </td>
                </tr>
              );
            })
          :
            <tr>
              <td>Aucune action</td>
            </tr>
          }
          </tbody>
        </table>
      )
    }

    renderPagination(tableName, nbItems) {
      const nbPages = Math.ceil(nbItems / this.nbLinesPerPage);
      if(nbPages <= 1) {
        return null;
      }

      return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between', padding:'0.5em'}}>
          <a href="#" className={this.state.activesPages[tableName] <= 1 ? "invisible" : ""} onClick={this.onChangePage.bind(this, tableName, -1)}>&laquo; Page précédente</a>
          <span>{this.state.activesPages[tableName]}/{nbPages}</span>
          <a href="#" style={{textAlign:'right'}} className={this.state.activesPages[tableName] >= nbPages ? "invisible" : ""} onClick={this.onChangePage.bind(this, tableName, 1)}>Page suivante &raquo;</a>
        </div>
      )
    }

    render() {

      const allActions = this.state.allActions.concat(this.state.allActionsEnRetard);

      return (
            <div style={{maxWidth:'100vw'}} className={this.isNissan() ? "style-nissan" : ""}>
              {this.isLoading() ?
                <div className="center-div" style={{paddingTop:'100px'}}>
                  <br/>
                  <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                </div>
              :
                <div>
                  <ul className={"nav nav-tabs eseller"}>
                    <li className={"nav-item "+(this.state.tab === "leads" ? "active" : "")}>
                      <a className={"nav-link"} role="tab" data-toggle="tab" href="#leads" onClick={this.onChangeTab.bind(this, "leads")}>Leads {this.state.nbLeads !== null ? "("+this.state.nbLeads+")" : ""}</a>
                    </li>
                    <li className={"nav-item "+(this.state.tab === "actions" ? "active" : "")}>
                      <a className={"nav-link"} role="tab" data-toggle="tab" href="#actions" onClick={this.onChangeTab.bind(this, "actions")}>Actions {this.state.nbActions !== null ? "("+this.state.nbActions+")" : ""}</a>
                    </li>
                    {this.isLoadingInBackground() ? 
                      <li style={{float:'right', margin:'0.5em'}}>
                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                      </li>
                    :null}
                  </ul>
                  <div className="tab-content" style={{padding: '0.5em'}}>
                    <div role="tabpanel" className={"tab-pane "+(this.state.tab === "leads" ? "active" : "")} id="leads">
                      <div style={{display:"flex", flexDirection:"row"}}>

                        <div style={{width: '100%', display:'flex', flexDirection:'column', marginTop:'1em', gap:'0.5em'}}>
                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div></div>
                            <div>
                              {this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes" ? 
                                this.renderTransfertForm(!this.state.selectedLeads.length)
                              :null}
                            </div>
                          </div>

                          <div>
                            {this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes" ? 
                              this.renderLeadsARedistribuer()
                            :
                              this.renderLeadsAQualifier()
                            }
                          </div>

                          <div>
                            {this.renderLeadsEnCours()} 
                          </div>
                        </div>

                        <div id="sidebar" className="eseller" style={{display:'flex', flexDirection:'column', gap:5, padding:'15px 0px 0px 10px', overflow: 'visible'}}>
                          <FilterBlock title="Date de relance">
                            <div className='form-group' style={{display: "flex", flexDirection: "row", alignItems: "center", padding:"0.5em", gap:5}}>
                              <span>Du<InputDate className="align-right" onChange={this.onChangeFiltersLeadsDate.bind(this, "dateRelanceFrom")}/></span>
                              <span>Au<InputDate className="align-right" onChange={this.onChangeFiltersLeadsDate.bind(this, "dateRelanceTo")}/></span>
                            </div>
                          </FilterBlock>
                          <FilterBlock title="Société/Site" isOpen={!!this.state.filtersLeads["sites"]}>
                            <FilterSocieteSite name={"filterSocieteSiteLeads"} leads={this.state.allLeads} onChange={this.onChangeFiltersLeads.bind(this)} selected={this.state.filtersLeads["sites"] || null} />
                          </FilterBlock>
                          {this.state.userInfo.Utilisateur.TypeProfilLead !== "Vendeur" ?
                            <FilterBlock title="Vendeur" isOpen={!!this.state.filtersLeads["vendeurs"]}>
                              <FilterVendeur vendeurs={this.state.vendeursLeads} onChange={this.onChangeFiltersLeads.bind(this)} selected={this.state.filtersLeads["vendeurs"]} />
                            </FilterBlock>
                          :null}
                          <FilterBlock title="Origine" isOpen={!!this.state.filtersLeads["origines"]}>
                            <FilterOrigine name={"filterOrigineLeads"} leads={this.state.allLeads} type="ActionsAFaire" onChange={this.onChangeFiltersLeads.bind(this)} selected={this.state.filtersLeads["origines"]} />
                          </FilterBlock>
                          <FilterBlock title="Étape" isOpen={!!this.state.filtersLeads["etapes"]}>
                            <FilterEtape etapes={this.state.etapesLeads} onChange={this.onChangeFiltersLeads.bind(this)} selected={this.state.filtersLeads["etapes"]} />
                          </FilterBlock>
                          <FilterBlock title="Type" isOpen={!!this.state.filtersLeads["types"]}>
                            <FilterType name={"filterTypeLeads"} leads={this.state.allLeads} onChange={this.onChangeFiltersLeads.bind(this)} selected={this.state.filtersLeads["types"]} />
                          </FilterBlock>
                        </div>

                      </div>
                    </div>

                    <div role="tabpanel" className={"tab-pane "+(this.state.tab === "actions" ? "active" : "")} id="actions">
                      {this.state.fetchingActions && !this.state.loadedFromStorage ? 
                        <div className="center-div" style={{paddingTop:'100px'}}>
                          <br/>
                          <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                        </div>
                      :
                        <div style={{display:"flex", flexDirection:"row"}}>

                          <div style={{width: '100%', display:'flex', flexDirection:'column', marginTop:'1em', gap:'0.5em'}}>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                              <div></div>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', gap:5}}>
                                {this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes" ? 
                                  <>
                                    {this.renderTransfertForm(!this.state.selectedActions.length)}
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', minHeight:'36px'}}>
                                      <button onClick={this.submitClotureActions.bind(this)} disabled={!this.state.selectedActions.length} className='btn btn-default' style={{borderRadius: 5}} >Clôturer</button>
                                    </div>
                                  </>
                                :null}
                              </div>
                            </div>

                            <div>
                              {this.renderActions()}
                            </div>
                            {this.state.affActionsEnRetard ? 
                              <div>
                                {this.renderActionsEnRetard()} 
                              </div>
                            :null}
                          </div>

                          <div id="sidebar" className="eseller" style={{display:'flex', flexDirection:'column', 'gap':'5px', padding:'15px 0px 0px 10px', overflow:'visible'}}>
                            <FilterBlock isOpen={true}>
                              <PanelFilter>
                                <div className="checkbox">
                                  <input type="checkbox" checked={this.state.affActionsEnRetard} id="affActionsEnRetard" onChange={this.onChangeAffActionsEnRetard.bind(this)} />
                                  <label className="label-box" htmlFor="affActionsEnRetard">
                                    Afficher les actions en retard
                                  </label>
                                </div>
                                <div className="checkbox">
                                  <input type="checkbox" checked={!this.state.filtersActions["dateRelanceTo"]} id="affActions30J" onChange={this.onChangeAffActions30J.bind(this)} />
                                  <label className="label-box" htmlFor="affActions30J">
                                    Afficher les actions à venir dans + de 30 jours
                                  </label>
                                </div>
                              </PanelFilter>
                            </FilterBlock>
                            <FilterBlock title="Date de relance" isOpen={true}>
                              <div className='form-group' style={{display: "flex", flexDirection: "row", alignItems: "center", padding:"0.5em", gap:5}}>
                                <span>Du<InputDate className="align-right" onChange={this.onChangeFiltersActionsDate.bind(this, "dateRelanceFrom")} value={this.state.filtersActions["dateRelanceFrom"] ? moment(this.state.filtersActions["dateRelanceFrom"]).format("DD/MM/YYYY") : ""}/></span>
                                <span>Au<InputDate className="align-right" onChange={this.onChangeFiltersActionsDate.bind(this, "dateRelanceTo")} value={this.state.filtersActions["dateRelanceTo"] ? moment(this.state.filtersActions["dateRelanceTo"]).format("DD/MM/YYYY") : ""}/></span>
                              </div>
                            </FilterBlock>
                            <FilterBlock title="Société/Site" isOpen={!!this.state.filtersActions["sites"]}>
                              <FilterSocieteSite name={"filterSocieteSiteActions"} leads={allActions} onChange={this.onChangeFiltersActions.bind(this)} selected={this.state.filtersActions["sites"]} />
                            </FilterBlock>
                            <FilterBlock title="Origine" isOpen={!!this.state.filtersActions["origines"]}>
                              <FilterOrigine name={"filterOrigineActions"} leads={allActions} onChange={this.onChangeFiltersActions.bind(this)} selected={this.state.filtersActions["origines"]} />
                            </FilterBlock>
                            <FilterBlock title="Type" isOpen={!!this.state.filtersActions["types"]}>
                              <FilterType name={"filterTypeActions"} leads={allActions} typeAffichage="Actions" onChange={this.onChangeFiltersActions.bind(this)} selected={this.state.filtersActions["types"]} />
                            </FilterBlock>
                          </div>

                        </div>
                      }
                    </div>
                  </div>
                </div>
              }

              {this.state.closingActions || this.state.submitting ? 
                <SweetAlert title="" showConfirm={false} onConfirm={() => {}}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                    <span style={{marginTop:15}}>{this.state.submittingMessage}</span>
                  </div>
                </SweetAlert>
              :null}

              {this.state.showAlertMessage ? 
                <SweetAlert success title="" onConfirm={() => {this.setState({showAlertMessage: false})}}>
                  <span>{this.state.showAlertMessage}</span>
                </SweetAlert>
              :null}
            </div>
        );
    }
}

export default IframeActionsAFaire;
