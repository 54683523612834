import React, { Component } from 'react';

import moment from 'moment'

class ListEssais extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  render() {

    if (!this.props.essais) {
      return null;
    }

    var essais = this.props.essais;
    essais.sort(function(a, b) {
      if (moment(a.Date) > moment(b.Date))
        return -1;
      return 1;
    });

    return (
      <div>
        {essais.length ?
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <thead>
              <tr>
                <th>Date de l'essai</th>
                <th>Type</th>
                <th>Marque</th>
                <th>Modèle</th>
                <th>Version</th>
                <th>Réalisé par</th>
              </tr>
            </thead>
            <tbody>
              {essais.map((essai, j) => {
                return <tr key={j}>
                  <td>{moment(essai.Date).format('DD/MM/Y')}</td>
                  <td>{essai.TypeVehicule}</td>
                  <td>{essai.Marque}</td>
                  <td>{essai.Modele}</td>
                  <td>{essai.Version}</td>
                  <td>{essai.Utilisateur.Nom}</td>
                </tr>
              })}
            </tbody>
          </table>
        : null}
      </div>
    );
  }
}

export default ListEssais;
