import React, {Component} from 'react';
import Globals from '../Globals';

import moment from 'moment'
import InputTime from '../Components/InputTime';
import { WorkflowBDC } from '../Enums/WorkflowBDC';
var Datetime = require('react-datetime');

var lodash = require('lodash')

class FormToDoCreate extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      etapes: [],
      utilisateurs: null,
      fetchingUtilisateurs: false,
      workflowBDC: null,
      profilSelectedUtilisateurTransfert: null, //Profil de l'utilisateur à qui on va transférer. Ne sert que si le workflow est 
    }
  }

  componentWillMount() {
    this.fetchEtapes();
    this.fetchutilisateurs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.site !== this.props.site || (localStorage.user_profil === "BDC" && (prevProps.IDOrigine !== this.props.IDOrigine))) {
      this.fetchutilisateurs();
    }
  }

  fetchEtapes() {
    this.setState({
      etapes: [
        {
            "IDEtape": 1,
            "Description": "A qualifier"
        },
        {
            "IDEtape": 2,
            "Description": "Découverte"
        },
        {
            "IDEtape": 3,
            "Description": "Essai"
        },
        {
            "IDEtape": 4,
            "Description": "Offre"
        },
        {
            "IDEtape": 5,
            "Description": "Négociation"
        },
        {
            "IDEtape": 6,
            "Description": "Commande"
        }
      ]
    });
  }

  fetchutilisateursBySite() {

    fetch(localStorage.ClientApiURL + '/Utilisateurs?EstActif=true&LoadDetailDroitProfil=false&LoadPlaqueMarques=false', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var utilisateurs = lodash.filter(json, (utilisateur) => {
        return lodash.find(utilisateur.Sites, (site, i) => {
          return parseInt(site.IDSite, 10) === parseInt(this.props.site, 10) && utilisateur.TypeProfilLead === 'Vendeur';
        });
      });

      utilisateurs = lodash.orderBy(utilisateurs, 'Nom');
      utilisateurs = this.filterUtilisateursByTypeActive(utilisateurs);

      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchutilisateurs() {

    if (localStorage.user_IDUtilisateur == '-1' && localStorage.bypass_iFrame == 'true') {
      this.fetchutilisateursBySite();
      return;
    }

    if (localStorage.user_profil === "BDC") {
      this.fetchWorkflow();
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + this.props.site , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var utilisateurs = lodash.orderBy(json, 'Nom');
      utilisateurs = this.filterUtilisateursByTypeActive(utilisateurs);

      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

    //Récupère le workflow BDC du couple origine/site, ou du groupe par défaut
  fetchWorkflow() {
    if(!this.props.site || !this.props.IDOrigine || !this.props.typeActive) {
      this.setState({
        utilisateurs: null,
        workflowBDC: null,
      });
      return;
    }

    this.props.onChangeValue('todoToVendeur', false);
    this.props.onChangeValue('todoVendeur', 0);

    fetch(localStorage.ClientApiURL + '/workflowsLeads/workflowBDC?IDSite=' + this.props.site + '&IDOrigine=' + this.props.IDOrigine, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      if(json.WorkflowBDC) {
        this.setState({
          workflowBDC: json.WorkflowBDC
        }, () => {
          if(this.state.workflowBDC != WorkflowBDC.BdcToPotCommunVendeurs) {
            this.fetchUtilisateursWorkflowBDC()
          }
          else {
            this.setState({
              utilisateurs: null
            })
          }
        })
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  //Récupère les utilisateurs à qui le bdc peut redistribuer, en fonction workflow configuré pour le couple origine/site et du type d'affaire (filtrage)
  fetchUtilisateursWorkflowBDC(){
    this.setState({
      fetchingUtilisateurs: true,
      utilisateurs: null
    })
    fetch(localStorage.ClientApiURL + '/workflowsLeads/workflowBDC/utilisateurs?IDSite='+this.props.site+'&IDOrigine='+this.props.IDOrigine+'&affaireType='+this.props.typeActive, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        utilisateurs: json,
        fetchingUtilisateurs: false,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeDate(event) {
    var date = event.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    this.props.onChangeValue('todoDate', date);
  }

  isValidDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  isValidHeure(current) {
    return true;
  }

  changeHeureRelance(event) {
    this.props.onChangeValue('todoHeure', event);
    this.props.onChangeValue('todoHeureFin', moment(event).add(1, 'hours'));
  }

  changeHeureRelanceFin(event) {
    this.props.onChangeValue('todoHeureFin', event);
  }

  changeToVendeur(event) {
    this.props.onChangeValue('todoToVendeur', event.target.checked);
  }

  changeVendeur(event) {
    this.props.onChangeValue('todoVendeur', event.target.value);

    var utilisateur = lodash.find(this.state.utilisateurs, (u) => u.IDUtilisateur == event.target.value);
    if(utilisateur) {
      this.setState({
        profilSelectedUtilisateurTransfert: utilisateur.TypeProfilLead
      })
    }
  }

  changeEtape(event) {
    this.props.onChangeValue('todoEtape', event.target.value);
    this.props.onChangeValue('todoEtapeText', event.target.options[event.target.selectedIndex].text);
  }

  changeComment(event) {
    this.props.onChangeValue('todoComment', event.target.value);
  }

  changeToPotCommunVendeurs(event) {
    this.props.onChangeValue('transfertToPotCommunVendeurs', event.target.checked);
  }

  filterUtilisateursByTypeActive(utilisateurs) {
    var utilisateursType = lodash.filter(utilisateurs, (utilisateur) => {
      if (this.props.typeActive){
        if(this.props.typeActive === "VN")
          return utilisateur.EstTypeVN;
        else if(this.props.typeActive === "VO")
          return utilisateur.EstTypeVO;
        else if(this.props.typeActive === "VDL")
          return true;
      }
    });
    return utilisateursType;
  }

  getLabelWorkflow() {
    if(this.state.workflowBDC === WorkflowBDC.BdcToChefsVentes) {
      return "Chef des Ventes"
    }
    if(this.state.workflowBDC === WorkflowBDC.BdcToChefsVentesVendeurs || this.state.workflowBDC === WorkflowBDC.BdcToChefsVentesVendeursPotCommunVendeurs) {
      return "Vendeur ou Chef des Ventes"
    }
    
    return "Vendeur"
  }

  renderSelectUtilisateur() {
    if(this.state.workflowBDC === WorkflowBDC.BdcToChefsVentesVendeurs || this.state.workflowBDC === WorkflowBDC.BdcToChefsVentesVendeursPotCommunVendeurs) {
      const vendeurs = lodash.filter(this.state.utilisateurs, (u) => u.TypeProfilLead === "Vendeur");
      const chefsDesVentes = lodash.filter(this.state.utilisateurs, (u) => u.TypeProfilLead === "ChefDesVentes");

      return (
        <select className="form-control" id="vendeur" name="vendeur" onChange={this.changeVendeur.bind(this)} value={this.props.todoVendeur} required='required'>
          <option>Sélectionner le Vendeur ou le Chef des ventes</option>
          <optgroup label="Vendeurs">
            {vendeurs.map((utilisateur, i) => {
              return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
            })}
          </optgroup>
          <optgroup label="Chef des ventes">
            {chefsDesVentes.map((utilisateur, i) => {
              return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
            })}
          </optgroup>
        </select>
      );
    }
    
    return (
      <select className="form-control" id="vendeur" name="vendeur" onChange={this.changeVendeur.bind(this)} value={this.props.todoVendeur} required='required'>
        <option value="">Sélectionner le {this.getLabelWorkflow()}</option>
        {this.state.utilisateurs.length ? 
          this.state.utilisateurs.map((utilisateur, i) => {
            return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
          })
        :null}
      </select>
    )
  }

  render() {
    return (
      <div style={{ marginBottom: '55px' }}>

        <div className='row'>
          <div className="col-md-10 col-md-offset-1">

          {this.state.workflowBDC === WorkflowBDC.BdcToPotCommunVendeurs || this.state.workflowBDC === WorkflowBDC.BdcToChefsVentesVendeursPotCommunVendeurs ?
            <div className="form-group" style={{ marginBottom: '0px' }}>
              <div className="col-md-12" style={{ textAlign: 'left' }}>
                <input type='checkbox' id='toPotCommunVendeur' onChange={this.changeToPotCommunVendeurs.bind(this)} />
                <label className="label-box" htmlFor="toPotCommunVendeur">Transférer au pot commun Vendeurs</label>
              </div>
            </div>
          :null}

          {this.state.workflowBDC !== WorkflowBDC.BdcToPotCommunVendeurs ?
            this.state.utilisateurs === null ?
              this.state.fetchingUtilisateurs ?
                <div>
                  <div className="form-group" style={{ marginBottom: '0px' }}>
                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                      <label>Récupération des utilisateurs...</label>
                    </div>
                  </div>
                </div>
              :null
            : this.state.utilisateurs.length ?
              <div>
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <div className="col-md-12" style={{ textAlign: 'left' }}>
                    <input type='checkbox' id='toVendeur' onChange={this.changeToVendeur.bind(this)} />
                    <label className="label-box text-nowrap" htmlFor="toVendeur">{this.props.transfertVendeurLabel ? 'Transférer à un '+this.getLabelWorkflow() : 'Affecter à un '+this.getLabelWorkflow()}</label>
                  </div>
                </div>
              </div>
            : 
              <div>
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <label className="warning">[Aucun vendeur paramétré]</label>
                  </div>
                </div>
              </div>
          :null}

            {this.props.todoToVendeur ?
              <div>

                <div className="form-group">
                  <div className="col-md-12">
                    {this.renderSelectUtilisateur()}
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: '25px' }}>
                  <div className='col-md-12'>
                    <a className='btn btn-default' onClick={() => {this.props.openWeekCalendar(this.state.utilisateurs)}} style={{ width: '100%', fontSize: '12px', padding: '6px 0px 4px 0px' }}>Calendrier vendeurs</a>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-4">TO DO</label>
                  <div className="col-md-8">
                    <select className="form-control" id="etapes" name="etapes" onChange={this.changeEtape.bind(this)} value={this.props.todoEtape} required="required">
                      <option value=''>Votre étape</option>
                      {this.state.etapes.map((etape, i) => {

                        if (etape.Description === 'Rejet' || etape.Description === 'Abandon')
                          return null;

                        return <option key={i} value={etape.IDEtape}>{etape.Description}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group" style={{ height: '90px' }}>
                  <div className="col-md-12">
                    <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.props.todoComment} onChange={this.changeComment.bind(this)} />
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">Le</label>

                  <div className="col-md-8">
                    <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false}  isValidDate={this.isValidDate.bind(this)} value={this.props.todoDate} onChange={this.changeDate.bind(this)} />
                  </div>
                  <div className="col-md-2">
                    <i className="icon-calendar icons" style={{ paddingTop: '4px', display: 'block' }}></i>
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">de</label>
                  <div className="col-md-8">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeure} onChange={this.changeHeureRelance.bind(this)} SelectStyle={{paddingLeft: '3px', paddingRight: '0px',  boxSizing: 'content-box', height:'19px'}} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">à</label>
                  <div className="col-md-8">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeureFin} onChange={this.changeHeureRelanceFin.bind(this)} SelectStyle={{paddingLeft: '3px', paddingRight: '0px',  boxSizing: 'content-box', height:'19px'}} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>

              </div>
            : null}

          </div>
        </div>

      </div>
    );
  }
}

export default FormToDoCreate;
